import { TableHead, TableRow, TableCell } from '@mui/material';

function TimeTableHeader({ name, worktype }) {
  return (
    <TableHead>
      <TableRow
        sx={{
          height: '72px',
          backgroundColor: '#F1F3F5',
        }}
      >
        <TableCell>Task Name</TableCell>
        <TableCell>Company</TableCell>
        <TableCell>Description</TableCell>
        {name && <TableCell>Team Member</TableCell>}
        <TableCell>{worktype ? 'Team Member' : 'Worktype'}</TableCell>
        <TableCell>Time</TableCell>
        <TableCell>Duration</TableCell>
      </TableRow>
    </TableHead>
  );
}

export default TimeTableHeader;
