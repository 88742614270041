import { Autocomplete, TextField } from '@mui/material';

function SearchableListField({
  searchableListFieldID,
  label,
  listOfOptions,
  propertiesToDisplay,
  onChangeFunction,
}) {
  const handleOnChange = (...args) => {
    // * Check if the second argument is available.
    if (args.length > 1) {
      // eslint-disable-next-line
      const [_, value] = args;
      onChangeFunction(value);
    } else {
      const [value] = args;
      onChangeFunction(value);
    }
  };

  const getLabel = (option) =>
    propertiesToDisplay
      .map((prop) => option[prop])
      .filter(Boolean)
      .join(' ');

  return (
    <Autocomplete
      id={searchableListFieldID}
      options={
        Array.isArray(listOfOptions) && listOfOptions.length
          ? listOfOptions
          : []
      }
      includeInputInList
      multiple
      getOptionLabel={getLabel}
      onChange={handleOnChange}
      style={{ width: 300, marginTop: '0.7rem' }}
      sx={{
        '& .MuiAutocomplete-inputRoot': {
          flexWrap: 'nowrap',
          overflow: 'hidden',
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          color="secondary"
          variant="standard"
          sx={{
            '& .MuiInputBase-input': {
              fontSize: '1rem',
              fontFamily: 'Open Sans',
              fontWeight: '500',
              color: '#000000',
              textAlign: 'left',
            },
            '& .MuiInput-underline:before': {
              borderBottom: '2px solid #711fff',
            },
            '& .MuiInput-underline:after': {
              borderBottom: '2px solid #711fff',
            },
            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
              borderBottom: '2px solid #711fff',
            },
          }}
          InputProps={{
            ...params.InputProps,
            style: { width: 250 },
          }}
        />
      )}
    />
  );
}

export default SearchableListField;
