function MeInactive() {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.4623 23.3882H4.4116C2.60937 23.3882 1.14648 21.9276 1.14648 20.1231V7.645C1.14648 6.89292 1.54545 6.19816 2.19435 5.81754L10.8662 0.747892C11.5265 0.362682 12.3428 0.362682 13.0009 0.747892L21.675 5.81754C22.3239 6.19587 22.7229 6.89292 22.7229 7.645V20.1231C22.7229 21.9253 21.2623 23.3882 19.4578 23.3882H19.4623V23.3882Z"
        stroke="#03071E"
        strokeMiterlimit={10}
      />
      <path
        d="M5.63818 17.9727H11.9024H18.2745"
        stroke="#03071E"
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M6.63811 12.5428C7.23836 12.5428 7.72496 12.0562 7.72496 11.456C7.72496 10.8557 7.23836 10.3691 6.63811 10.3691C6.03787 10.3691 5.55127 10.8557 5.55127 11.456C5.55127 12.0562 6.03787 12.5428 6.63811 12.5428Z"
        fill="#03071E"
      />
      <path
        d="M17.2358 12.5174C17.836 12.5174 18.3226 12.0308 18.3226 11.4306C18.3226 10.8303 17.836 10.3438 17.2358 10.3438C16.6355 10.3438 16.1489 10.8303 16.1489 11.4306C16.1489 12.0308 16.6355 12.5174 17.2358 12.5174Z"
        fill="#03071E"
      />
    </svg>
  );
}

function MeActive() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5612_64716)">
        <path
          d="M19.4623 23.3882H4.4116C2.60937 23.3882 1.14648 21.9276 1.14648 20.1231V7.645C1.14648 6.89292 1.54545 6.19816 2.19435 5.81754L10.8662 0.747892C11.5265 0.362682 12.3428 0.362682 13.0009 0.747892L21.675 5.81754C22.3239 6.19587 22.7229 6.89292 22.7229 7.645V20.1231C22.7229 21.9253 21.2623 23.3882 19.4578 23.3882H19.4623V23.3882Z"
          stroke="#711FFF"
          strokeMiterlimit="10"
        />
        <path
          d="M5.67188 16.957C9.88397 19.0459 14.0938 19.0459 18.3082 16.957"
          stroke="#711FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.64544 13.9774C7.24569 13.9774 7.73228 13.4908 7.73228 12.8906C7.73228 12.2903 7.24569 11.8037 6.64544 11.8037C6.04519 11.8037 5.55859 12.2903 5.55859 12.8906C5.55859 13.4908 6.04519 13.9774 6.64544 13.9774Z"
          fill="#711FFF"
        />
        <path
          d="M17.2431 13.9501C17.8433 13.9501 18.3299 13.4635 18.3299 12.8632C18.3299 12.263 17.8433 11.7764 17.2431 11.7764C16.6428 11.7764 16.1562 12.263 16.1562 12.8632C16.1562 13.4635 16.6428 13.9501 17.2431 13.9501Z"
          fill="#711FFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_5612_64716">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

function ChatInactive() {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5612_64716)">
        <path
          d="M19.4623 23.3882H4.4116C2.60937 23.3882 1.14648 21.9276 1.14648 20.1231V7.645C1.14648 6.89292 1.54545 6.19816 2.19435 5.81754L10.8662 0.747892C11.5265 0.362682 12.3428 0.362682 13.0009 0.747892L21.675 5.81754C22.3239 6.19587 22.7229 6.89292 22.7229 7.645V20.1231C22.7229 21.9253 21.2623 23.3882 19.4578 23.3882H19.4623V23.3882Z"
          stroke="#711FFF"
          strokeMiterlimit={10}
        />
        <path
          d="M5.67188 16.957C9.88397 19.0459 14.0938 19.0459 18.3082 16.957"
          stroke="#711FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.64544 13.9774C7.24569 13.9774 7.73228 13.4908 7.73228 12.8906C7.73228 12.2903 7.24569 11.8037 6.64544 11.8037C6.04519 11.8037 5.55859 12.2903 5.55859 12.8906C5.55859 13.4908 6.04519 13.9774 6.64544 13.9774Z"
          fill="#711FFF"
        />
        <path
          d="M17.2431 13.9501C17.8433 13.9501 18.3299 13.4635 18.3299 12.8632C18.3299 12.263 17.8433 11.7764 17.2431 11.7764C16.6428 11.7764 16.1562 12.263 16.1562 12.8632C16.1562 13.4635 16.6428 13.9501 17.2431 13.9501Z"
          fill="#711FFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_5612_64716">
          <rect width={24} height={24} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

function ChatActive() {
  return (
    <svg
      width={24}
      height={21}
      viewBox="0 0 24 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.97461 5.32176V2.12972C4.97461 1.7622 5.27392 1.46289 5.64143 1.46289H22.9542C23.3218 1.46289 23.6211 1.7622 23.6211 2.12972V16.0269C23.6211 16.457 23.1285 16.7013 22.7856 16.4437L20.4726 14.0568C20.2585 13.8389 19.9668 13.7139 19.6599 13.7139H19.2166"
        stroke="#711FFF"
        strokeMiterlimit={10}
      />
      <path
        d="M18.548 17.5704H4.19611C3.90817 17.5704 3.63727 17.6954 3.44972 17.9133L1.40378 20.3002C1.0609 20.5579 0.568359 20.3154 0.568359 19.8853V5.98811C0.568359 5.6206 0.867673 5.32129 1.23518 5.32129H18.548C18.9155 5.32129 19.2148 5.6206 19.2148 5.98811V16.9055C19.2148 17.273 18.9155 17.5723 18.548 17.5723V17.5704Z"
        stroke="#711FFF"
        strokeMiterlimit={10}
      />
      <path
        d="M6.94015 12.6098C7.44966 12.6098 7.86271 12.1967 7.86271 11.6872C7.86271 11.1777 7.44966 10.7646 6.94015 10.7646C6.43063 10.7646 6.01758 11.1777 6.01758 11.6872C6.01758 12.1967 6.43063 12.6098 6.94015 12.6098Z"
        fill="#711FFF"
      />
      <path
        d="M10.0046 12.6098C10.5141 12.6098 10.9272 12.1967 10.9272 11.6872C10.9272 11.1777 10.5141 10.7646 10.0046 10.7646C9.49508 10.7646 9.08203 11.1777 9.08203 11.6872C9.08203 12.1967 9.49508 12.6098 10.0046 12.6098Z"
        fill="#711FFF"
      />
      <path
        d="M13.0651 12.6098C13.5747 12.6098 13.9877 12.1967 13.9877 11.6872C13.9877 11.1777 13.5747 10.7646 13.0651 10.7646C12.5556 10.7646 12.1426 11.1777 12.1426 11.6872C12.1426 12.1967 12.5556 12.6098 13.0651 12.6098Z"
        fill="#711FFF"
      />
    </svg>
  );
}

function ProjectInactive() {
  return (
    <svg
      width={25}
      height={24}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5612_65087)">
        <path
          d="M1.13672 12.0332H9.6466"
          stroke="#03071E"
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M12.7467 15.1331C14.459 15.1331 15.847 13.7451 15.847 12.0329C15.847 10.3206 14.459 8.93262 12.7467 8.93262C11.0345 8.93262 9.64648 10.3206 9.64648 12.0329C9.64648 13.7451 11.0345 15.1331 12.7467 15.1331Z"
          stroke="#03071E"
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M15.8867 12.0332H24.3985"
          stroke="#03071E"
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M12.1448 10.45C12.0425 10.3476 11.8936 10.2881 11.7392 10.2881C11.5847 10.2881 11.4358 10.3476 11.3335 10.45C11.1102 10.6733 11.1102 11.038 11.3335 11.2613L13.4679 13.3958C13.5759 13.5037 13.7192 13.5633 13.8736 13.5633C14.0281 13.5633 14.1714 13.5037 14.2793 13.3958C14.3872 13.2879 14.4468 13.1446 14.4468 12.9901C14.4468 12.8357 14.3872 12.6924 14.2793 12.5844L12.1448 10.45ZM14.203 12.9901C14.203 13.0776 14.1695 13.1613 14.1081 13.2227C14.0485 13.2823 13.9648 13.3158 13.8755 13.3158C13.7862 13.3158 13.7024 13.2823 13.6429 13.2227L11.5084 11.0883C11.38 10.9599 11.38 10.7514 11.5084 10.623C11.5698 10.5616 11.6536 10.5281 11.741 10.5281C11.8285 10.5281 11.9122 10.5616 11.9736 10.623L14.1081 12.7575C14.1695 12.8189 14.203 12.9026 14.203 12.9901Z"
          fill="#03071E"
        />
        <path
          d="M13.8637 11.4471C14.1838 11.4471 14.4462 11.1866 14.4462 10.8647C14.4462 10.5428 14.1856 10.2822 13.8637 10.2822C13.5418 10.2822 13.2812 10.5428 13.2812 10.8647C13.2812 11.1866 13.5418 11.4471 13.8637 11.4471ZM13.8637 10.5279C14.0498 10.5279 14.2005 10.6786 14.2005 10.8647C14.2005 11.0508 14.0498 11.2015 13.8637 11.2015C13.6776 11.2015 13.5269 11.0508 13.5269 10.8647C13.5269 10.6786 13.6776 10.5279 13.8637 10.5279Z"
          fill="#03071E"
        />
        <path
          d="M11.7465 12.4004C11.4264 12.4004 11.1641 12.6609 11.1641 12.9829C11.1641 13.3048 11.4246 13.5653 11.7465 13.5653C12.0685 13.5653 12.329 13.3048 12.329 12.9829C12.329 12.6609 12.0685 12.4004 11.7465 12.4004ZM12.0833 12.9829C12.0833 13.1689 11.9326 13.3197 11.7465 13.3197C11.5604 13.3197 11.4097 13.1689 11.4097 12.9829C11.4097 12.7968 11.5604 12.646 11.7465 12.646C11.9326 12.646 12.0833 12.7968 12.0833 12.9829Z"
          fill="#03071E"
        />
      </g>
      <defs>
        <clipPath id="clip0_5612_65087">
          <rect
            width={24}
            height={24}
            fill="white"
            transform="translate(0.763672)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

function ProjectsActive() {
  return (
    <svg
      width={25}
      height={14}
      viewBox="0 0 25 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.44181 10.2967C6.25637 10.2967 7.72737 8.82571 7.72737 7.01115C7.72737 5.19658 6.25637 3.72559 4.44181 3.72559C2.62724 3.72559 1.15625 5.19658 1.15625 7.01115C1.15625 8.82571 2.62724 10.2967 4.44181 10.2967Z"
        stroke="#711FFF"
        strokeMiterlimit={10}
      />
      <path
        d="M7.72656 7.01074H24.3717"
        stroke="#711FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.51367 4.46257L9.18632 1.78992C9.49092 1.48532 9.90324 1.31445 10.3323 1.31445H17.5795"
        stroke="#711FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.51367 9.69238L9.18632 12.365C9.49092 12.6696 9.90324 12.8405 10.3323 12.8405H13.4451"
        stroke="#711FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.83045 5.32662C3.7283 5.22447 3.57972 5.16504 3.42556 5.16504C3.27141 5.16504 3.12282 5.22447 3.02067 5.32662C2.7978 5.5495 2.7978 5.91353 3.02067 6.13641L5.15099 8.26673C5.25872 8.37445 5.40173 8.43388 5.55588 8.43388C5.71004 8.43388 5.85305 8.37445 5.96077 8.26673C6.0685 8.159 6.12793 8.01599 6.12793 7.86184C6.12793 7.70768 6.0685 7.56467 5.96077 7.45694L3.83045 5.32662ZM5.88463 7.86184C5.88463 7.94913 5.85119 8.03271 5.7899 8.094C5.73047 8.15343 5.64689 8.18686 5.55774 8.18686C5.46859 8.18686 5.38501 8.15343 5.32558 8.094L3.19526 5.96368C3.0671 5.83552 3.0671 5.62751 3.19526 5.49935C3.25655 5.43806 3.34013 5.40463 3.42742 5.40463C3.51471 5.40463 3.59829 5.43806 3.65958 5.49935L5.7899 7.62967C5.85119 7.69096 5.88463 7.77454 5.88463 7.86184Z"
        fill="#711FFF"
      />
      <path
        d="M5.54813 6.32185C5.86759 6.32185 6.12947 6.06183 6.12947 5.74051C6.12947 5.4192 5.86944 5.15918 5.54813 5.15918C5.22682 5.15918 4.9668 5.4192 4.9668 5.74051C4.9668 6.06183 5.22682 6.32185 5.54813 6.32185ZM5.54813 5.40434C5.73386 5.40434 5.8843 5.55478 5.8843 5.74051C5.8843 5.92624 5.73386 6.07668 5.54813 6.07668C5.3624 6.07668 5.21196 5.92624 5.21196 5.74051C5.21196 5.55478 5.3624 5.40434 5.54813 5.40434Z"
        fill="#711FFF"
      />
      <path
        d="M3.43485 7.27246C3.11539 7.27246 2.85352 7.53248 2.85352 7.8538C2.85352 8.17511 3.11354 8.43513 3.43485 8.43513C3.75616 8.43513 4.01618 8.17511 4.01618 7.8538C4.01618 7.53248 3.75616 7.27246 3.43485 7.27246ZM3.77102 7.8538C3.77102 8.03953 3.62058 8.18997 3.43485 8.18997C3.24912 8.18997 3.09868 8.03953 3.09868 7.8538C3.09868 7.66807 3.24912 7.51762 3.43485 7.51762C3.62058 7.51762 3.77102 7.66807 3.77102 7.8538Z"
        fill="#711FFF"
      />
    </svg>
  );
}

export {
  MeInactive,
  MeActive,
  ChatInactive,
  ChatActive,
  ProjectInactive,
  ProjectsActive,
};
