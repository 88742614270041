import Me from '../../Assets/NavLink/Me.png';
import MeActive from '../../Assets/NavLink/MeActive.png';
import Contacts from '../../Assets/NavLink/Contacts.png';
import ContactsActive from '../../Assets/NavLink/ContactsActive.png';
import Stream from '../../Assets/NavLink/Stream.png';
import StreamActive from '../../Assets/NavLink/streamActive.png';
import Oppertunites from '../../Assets/NavLink/Oppertunites.png';
import OppertunitiesActive from '../../Assets/NavLink/OppertunitiesActive.png';
import Reports from '../../Assets/NavLink/Reports.png';
import ReportsActive from '../../Assets/NavLink/ReportsActive.png';
import NetNetU from '../../Assets/NavLink/netNetU.png';
import NetNetUAvtive from '../../Assets/NavLink/netNetUAvtive.png';

import JobsIdle from '../../Assets/NavLink/Jobs-Idle.svg';
import JobsActive from '../../Assets/NavLink/Jobs-Active.svg';
import TaskIdle from '../../Assets/NavLink/Tasks-Idle.svg';
import TaskActive from '../../Assets/NavLink/Tasks-Active.svg';

const SidebarData = [
  {
    title: 'Me',
    path: null,
    icon: Me,
    activeIcon: MeActive,
    subRoutes: [
      {
        title: 'My Tasks',
        path: '/My_Tasks',
      },
      {
        title: 'My Time',
        path: '/My_Time',
      },
      {
        title: 'Welcome',
        path: '/Welcome',
      },
    ],
  },
  {
    title: 'Chat',
    path: '/Chat',
    icon: Stream,
    activeIcon: StreamActive,
  },
  {
    title: 'Jobs',
    path: '/Projects',
    icon: JobsIdle,
    activeIcon: JobsActive,
  },
  {
    title: 'Tasks',
    path: '/tasks',
    icon: TaskIdle,
    activeIcon: TaskActive,
  },
  {
    title: 'Contacts',
    path: null,
    icon: Contacts,
    activeIcon: ContactsActive,
    subRoutes: [
      {
        title: 'People',
        path: '/People',
      },
      {
        title: 'Companies',
        path: '/Company',
      },
    ],
  },
  {
    title: 'Sales',
    path: '/Opportunities',
    icon: Oppertunites,
    activeIcon: OppertunitiesActive,
  },
  {
    title: 'Reports',
    path: '/reports',
    icon: Reports,
    activeIcon: ReportsActive,
  },
  {
    title: 'NetNet U',
    path: '/NetNet_U',
    icon: NetNetU,
    activeIcon: NetNetUAvtive,
  },
];

export default SidebarData;
