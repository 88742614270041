import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slide from 'react-reveal/Slide';

import { Box, Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Alert from '@mui/material/Alert';
import MUIRichTextEditor from 'mui-rte';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

import { stateToHTML } from 'draft-js-export-html';

import dayjs from 'dayjs';
import moment from 'moment';

import { stopTimer, getQuickTask } from '../../pages/Projects/proRedux';
import { ENDPOINT, getTimeTracking } from '../../pages/Opportunity/Redux/oppor';
import { NetProgress } from '../ProgressBar';
import { PreLoader1 } from '../Misc';

function Culminate({
  data,
  currentTarget,
  setShow,
  // toggleDrawer, // ? Analyze if this is needed.
  setState,
  state,
  // setIsActive, // ? Analyze if this is needed.
}) {
  if (!currentTarget) {
    setShow(false);
    return null;
  }

  const dispatch = useDispatch();
  const access_token = useSelector((state) => state.auth.user.access);
  const currentUser = useSelector((item) => item.userData.userData);

  const [selectDate, setSelectDate] = useState(dayjs(data.time_tracking_start));
  const [startTime, setStartTime] = useState(dayjs(data.time_tracking_start));
  const [endTime, setEndTime] = useState(dayjs(data.time_tracking_stop));
  const [initialsSeconds, setInitialsSeconds] = useState(0);

  const [succ, setSucc] = useState(false);
  const [errorCreatingTime, setErrorCreatingTime] = useState(false);
  const [isSureToDelete, setIsSureToDelete] = useState(false);
  const [isDeletionLoading, setIsDeletionLoading] = useState(false);
  const [userHasChangedTimeOnPicker, setUserHasChangedTimeOnPicker] =
    useState(false);

  const [html, setHtml] = useState('');
  const [showEditNotes, setShowEditNotes] = useState(false);
  const parentElementForNotesEditorRef = useRef(null);

  const handleNotesEditorContentChange = (newVal) => {
    setHtml(stateToHTML(newVal.getCurrentContent()));
  };

  const handleAutoTimeSave = async () => {
    let adjustedEndDate = selectDate;

    if (endTime.isBefore(startTime)) {
      adjustedEndDate = selectDate.add(1, 'day');
    }

    const formatDateTime = (date, time) =>
      // eslint-disable-next-line
      date
        .set('hour', time.hour())
        .set('minute', time.minute())
        .set('second', time.second())
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss.SSS[000]') + 'Z';

    const formData = {
      // eslint-disable-next-line
      date: selectDate.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[000]') + 'Z',
      time_tracking_start: formatDateTime(selectDate, startTime),
      time_tracking_stop: formatDateTime(adjustedEndDate, endTime),
    };

    const res = await fetch(
      `${ENDPOINT}/api/project/task/timetracking/update/${data.id}/`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${access_token}`,
        },
        body: JSON.stringify({
          note: html,
          time_tracking_start: formData.time_tracking_start,
          time_tracking_stop: formData.time_tracking_stop,
        }),
      },
    );

    if (res.ok) {
      setSucc(true);

      dispatch(
        getQuickTask({
          access_token,
        }),
      );
      dispatch(getTimeTracking({ access_token }));
      dispatch(stopTimer(currentTarget));

      setState({ ...state, bottom: false });

      setTimeout(() => {
        setSucc(false);
      }, 3000);
    } else {
      setErrorCreatingTime(true);
      setTimeout(() => {
        setErrorCreatingTime(false);
      }, 3000);
    }
  };

  const handleAutoTimeDelete = async () => {
    setIsDeletionLoading(true);

    const res = await fetch(
      `${ENDPOINT}/api/project/task/timetracking/delete/${data.id}/`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${access_token}`,
        },
      },
    );

    if (res.ok) {
      setSucc(true);
      setIsDeletionLoading(false);

      setState({ ...state, bottom: false });

      dispatch(
        getQuickTask({
          access_token,
        }),
      );

      dispatch(stopTimer(currentTarget));

      setTimeout(() => {
        setIsSureToDelete(false);
        setSucc(false);
      }, 3000);
    } else {
      setErrorCreatingTime(true);
      setIsDeletionLoading(false);
      setIsSureToDelete(false);

      dispatch(
        getQuickTask({
          access_token,
        }),
      );

      setTimeout(() => {
        setErrorCreatingTime(false);
      }, 3000);
    }
  };

  const calculateTotalMinutes = (start, end) => {
    const startTime = dayjs(start);
    let endTime = dayjs(end);

    const isNextDay = endTime.isBefore(startTime);
    if (isNextDay) {
      endTime = endTime.add(1, 'day');
    }

    const totalMinutes = endTime.diff(startTime, 'minute');
    const totalSeconds = endTime.diff(startTime, 'second');
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const seconds = totalSeconds % 60;

    return {
      totalMinutes,
      hours,
      minutes,
      seconds,
      isNextDay,
    };
  };

  // * Used when rendering the total time to be logged.
  const [timeDetails, setTimeDetails] = useState({
    totalMinutes: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    isNextDay: false,
  });

  useEffect(() => {
    if (data) {
      setInitialsSeconds(data.seconds);

      let roundedHours = data.hours;
      let roundedMinutes = data.minutes;
      let initialSeconds = data.seconds;

      if (initialSeconds > 0) {
        initialSeconds = 0;
        roundedMinutes += 1;
      }

      if (roundedMinutes > 59) {
        roundedMinutes = 0;
        roundedHours += 1;
      }

      setTimeDetails((prevTimeDetails) => ({
        ...prevTimeDetails,
        hours: roundedHours,
        minutes: roundedMinutes,
        seconds: 0,
      }));

      const newStartTime = dayjs(data.time_tracking_start);
      const newEndTime = newStartTime
        .add(roundedHours, 'hours')
        .add(roundedMinutes, 'minutes')
        .add(initialSeconds, 'seconds');

      // * To reflect the changes on the TimePickers.
      setStartTime(newStartTime);
      setEndTime(newEndTime);
    }
  }, [data]);

  useEffect(() => {
    setTimeDetails((prevTimeDetails) => ({
      ...prevTimeDetails,
      ...calculateTotalMinutes(startTime, endTime),
    }));
  }, [startTime, endTime, userHasChangedTimeOnPicker]);

  const areBothTimesSelectedEqual = timeDetails.totalMinutes === 0;
  const areInitialSecondsLessThanAMinute = initialsSeconds < 60;

  // * For DatePicker.
  const isToday =
    dayjs().format().slice(0, 10) === selectDate.format().slice(0, 10);

  const myTheme = createTheme({});

  const currentTaskID = currentTarget.id;
  const tasksFromGlobalState = useSelector((state) => state.projects.tasks);
  const currentTaskTimeTrackingArray = tasksFromGlobalState.filter(
    (task) => task.id === currentTaskID,
  )[0].time_tracking;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5rem',
        marginBottom: '4.5rem',
      }}
    >
      <div
        style={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          padding: '3.5rem 1rem',
          borderRadius: '10px',
          flexWrap: 'wrap',
          gap: '1.5rem',
          maxWidth: '1600px',
          border: '1px solid #d9d9d9',
        }}
      >
        <span
          title="Task name"
          style={{
            borderBottom: '2px dotted #d9d9d9',
          }}
        >
          {currentTarget.task_name || ''}
        </span>

        <TableCell
          id="cypress-projectTask-description-pTag"
          title="Click to edit notes"
          onClick={() => setShowEditNotes(true)}
          ref={parentElementForNotesEditorRef}
          align="center"
          sx={{
            color: '#000',
            padding: '0',
            maxWidth: { xl: '150px', lg: '120px', md: '100px' },
            minWidth: '45px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            borderBottom: '2px dotted #d9d9d9',
          }}
        >
          {html ? (
            <p
              onMouseEnter={(e) =>
                (e.target.style.borderBottom = '1px dashed gray')
              }
              onMouseLeave={(e) =>
                (e.target.style.borderBottom = '1px solid transparent')
              }
              style={{
                cursor: 'pointer',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                maxHeight: '30px',
                alignItems: 'center',
              }}
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{
                __html: html !== '<p><br></p>' ? html : '&nbsp;',
              }}
            />
          ) : (
            <p
              style={{
                cursor: 'pointer',
                whiteSpace: 'nowrap',
                alignItems: 'center',
              }}
            >
              Notes
            </p>
          )}
        </TableCell>

        <NetProgress
          status={currentTarget.status}
          obt={
            Array.isArray(currentTaskTimeTrackingArray) &&
            currentTaskTimeTrackingArray.length
              ? currentTaskTimeTrackingArray
                  .filter((item) => item.created_by.id === currentUser.id)
                  .filter((item) => item.tracked_time_hours)
                  .reduce((total, entry) => {
                    const [hours, minutes] = entry.tracked_time_hours
                      ? entry.tracked_time_hours.split(':')
                      : [0, 0];
                    const hoursInMinutes =
                      parseInt(hours, 10) + parseInt(minutes, 10) / 60;
                    return total + hoursInMinutes;
                  }, 0)
              : 0
          }
          eta={
            currentTarget.assignee_hours ||
            currentTarget.assignee_task.filter(
              (task) => task.assignee_user === currentUser.id,
            )[0].hours ||
            currentTarget.time
          }
        />

        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          style={{
            borderBottom: '2px dotted #d9d9d9',
          }}
        >
          <DesktopDatePicker
            inputFormat="MM-dd-yyyy"
            title="Pick a date"
            value={selectDate}
            onChange={(newValue) => setSelectDate(newValue)}
            sx={{
              '& .MuiInputBase-root': {
                flexDirection: 'row-reverse',
                width: isToday ? '15px' : '135px',
                mx: 'auto',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                outline: 'none !important',
                border: 'none !important',
              },
              '& .MuiOutlinedInput-input': {
                display: isToday ? 'none' : 'block',
              },
            }}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line
                {...params}
                sx={{
                  width: '0px',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: '1px solid #d2b8ff',
                    },
                    '&:hover fieldset': {
                      border: '1px solid #d2b8ff',
                    },
                    '&.Mui-error fieldset': {
                      border: '1px solid #d2b8ff',
                    },
                  },
                }}
              />
            )}
          />
          {isToday ? (
            <Typography
              sx={{
                marginLeft: '-8px',
              }}
            >
              Today
            </Typography>
          ) : (
            ''
          )}
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            id="cypress-addTime-startTime-input"
            label="Start"
            value={startTime}
            onChange={(newValue) => {
              setUserHasChangedTimeOnPicker(true);
              setStartTime(newValue);
            }}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock,
            }}
            sx={{
              width: '135px',
              '& .MuiButtonBase-root': {
                p: 0,
                m: 0,
                mr: 1,
              },
              '& .MuiOutlinedInput-input': {
                padding: '0.8rem',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: areBothTimesSelectedEqual ? '1px solid red' : '',
              },
            }}
          />
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            id="cypress-addTime-endTime-input"
            label="End"
            value={endTime}
            onChange={(newValue) => {
              setUserHasChangedTimeOnPicker(true);
              setEndTime(newValue);
            }}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock,
            }}
            sx={{
              width: '135px',
              '& .MuiButtonBase-root': {
                p: 0,
                m: 0,
                mr: 1,
              },
              '& .MuiOutlinedInput-input': {
                padding: '0.8rem',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: areBothTimesSelectedEqual ? '1px solid red' : '',
              },
            }}
          />
        </LocalizationProvider>

        <div
          style={{
            padding: '0.8rem',
            backgroundColor: '#d9d9d9',
            color: '#711fff',
            fontSize: '1.2rem',
            fontWeight: '700',
            borderRadius: '4px',
          }}
          aria-label="your are about to log"
          title={
            areBothTimesSelectedEqual
              ? 'Select different times'
              : 'Time you are about to log'
          }
        >
          <span>
            {areBothTimesSelectedEqual
              ? `00:00:00 Hrs`
              : `${timeDetails.hours}:${timeDetails.minutes.toString().padStart(2, '0')}:00 Hrs${
                  timeDetails.isNextDay ? ' (+1 Day)' : ''
                }`}
          </span>
        </div>

        <button
          className="noBorder"
          id="cypress-addTime-timeAdd-Button"
          disabled={areBothTimesSelectedEqual}
          style={{
            padding: '1rem 1.7rem',
            backgroundColor: '#b3ffef',
            textTransform: 'uppercase',
            fontWeight: '700',
            borderRadius: '4px',
          }}
          type="button"
          title={
            areBothTimesSelectedEqual ? 'Select different times' : 'Add time'
          }
          onClick={() => {
            if (!areBothTimesSelectedEqual) {
              handleAutoTimeSave();
            }
          }}
        >
          add time
        </button>

        <button
          type="button"
          className="noBorder"
          style={{
            padding: '1rem 1.7rem',
            backgroundColor: '#d32f2f',
            textTransform: 'uppercase',
            fontWeight: '700',
            borderRadius: '4px',
            color: '#ffffff',
          }}
          title="Double click to delete"
          onClick={() => setIsSureToDelete(true)}
        >
          delete
        </button>
      </div>

      {showEditNotes && (
        <Box
          id="cypress-editor-to-updateTaskNotes"
          className="df-r-j-c-gp-5 Editor-para10"
          sx={{
            width: 'clamp(400px, 50vw, 610px)',
            borderRadius: '9px',
            backgroundColor: '#ffffff',
            border: '1px solid rgb(217, 217, 217)',
            padding: '0.5rem 0 0 0.5rem',
          }}
        >
          <ThemeProvider theme={myTheme}>
            <MUIRichTextEditor
              label="Your notes here..."
              inlineToolbar={false}
              onChange={handleNotesEditorContentChange}
              controls={[
                'bold',
                'italic',
                'underline',
                'strikethrough',
                'link',
                'numberList',
                'bulletList',
                'quote',
              ]}
              customControls={[
                {
                  name: 'stateLine',
                  icon: '|',
                  type: 'inline',
                },
                {
                  name: 'stateLine2',
                  icon: '|',
                  type: 'inline',
                },
              ]}
            />
          </ThemeProvider>
        </Box>
      )}

      {isSureToDelete && (
        <section
          className="loading"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100%',
            backgroundColor: 'rgba(255,255,255,0.5)',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 99999999999,
          }}
        >
          <Slide bottom>
            <Box
              sx={{
                width: '500px',
                height: '130px',
                backgroundColor: 'rgba(255,255,255)',
                border: '1px solid #d9d9d9',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: '8px',
                padding: '0.68rem 1rem',
              }}
            >
              <p
                className="fstxt"
                style={{ textAlign: 'center', marginTop: '.3rem' }}
              >
                Are you sure you want to Delete this time?
              </p>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '75%',
                  marginTop: '1rem',
                }}
              >
                <button
                  type="button"
                  className="cancel-btn"
                  onClick={() => setIsSureToDelete(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  style={{ background: isDeletionLoading && '#FA2D23' }}
                  className="netDanger-btn"
                  onClick={handleAutoTimeDelete}
                >
                  {isDeletionLoading ? (
                    <Box
                      sx={{
                        width: '21px',
                        height: '21px',
                        margin: '0rem 0.7rem',
                      }}
                    >
                      <PreLoader1 />
                    </Box>
                  ) : (
                    'Delete'
                  )}{' '}
                </button>
              </Box>
            </Box>
          </Slide>
        </section>
      )}

      {areInitialSecondsLessThanAMinute && (
        <Alert severity="info" sx={{ width: '50%' }}>
          We will round up your seconds to the nearest minute.
        </Alert>
      )}

      {errorCreatingTime && (
        <Alert severity="error" sx={{ width: '50%' }}>
          An error occurred while Saving/Deleting your time. Please try again.
        </Alert>
      )}

      {succ && (
        <Alert severity="success" sx={{ width: '50%' }}>
          Your time has been successfully recorded.
        </Alert>
      )}
    </div>
  );
}

// eslint-disable-next-line
export function Watch({
  currentTarget,
  setCurrentTarget,
  toggleDrawer,
  state,
  setState,
}) {
  if (!currentTarget) {
    setCurrentTarget(false);
    return null;
  }

  const { time_tracking } = currentTarget;
  const [isActive, setIsActive] = useState(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState({});
  const [timeTrackingId, setTimeTrackingId] = useState(null);
  // * Baseline start timestamp.
  const [startTimestamp, setStartTimestamp] = useState(null);
  // * Timer state to display elapsed time.
  const [elapsedSeconds, setElapsedSeconds] = useState(0);

  useEffect(() => {
    if (Array.isArray(time_tracking) && time_tracking.length) {
      const { time_tracking_start, time_tracking_stop, id } =
        time_tracking[time_tracking.length - 1];
      setTimeTrackingId(id);

      if (time_tracking_start && !time_tracking_stop) {
        setStartTimestamp(dayjs(time_tracking_start));
        setIsActive(true);
      }
    }
  }, [time_tracking]);

  useEffect(() => {
    if (!isActive) return;

    const intervalId = setInterval(() => {
      if (startTimestamp) {
        setElapsedSeconds(dayjs().diff(startTimestamp, 'second'));
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [isActive, startTimestamp]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden && isActive && startTimestamp) {
        setElapsedSeconds(dayjs().diff(startTimestamp, 'second'));
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () =>
      document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, [isActive, startTimestamp]);

  const hours = Math.floor(elapsedSeconds / 3600);
  const minutes = Math.floor((elapsedSeconds % 3600) / 60);
  const seconds = elapsedSeconds % 60;

  const handlePlayPause = () => {
    if (isActive) {
      setIsActive(false);
    } else {
      // * Set baseline based on current elapsed time.
      setStartTimestamp(dayjs().subtract(elapsedSeconds, 'second'));
      setIsActive(true);
    }
  };

  const handleStop = () => {
    setIsActive(false);
    setData({
      id: timeTrackingId,
      time_tracking_start:
        time_tracking[time_tracking.length - 1]?.time_tracking_start,
      time_tracking_stop: new Date().toISOString(),
      hours,
      minutes,
      seconds,
    });
    setShow(true);
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#8945ff',
          padding: '0.44rem 0.88rem',
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
        }}
      >
        <div className="static-display">
          {String(hours).padStart(2, '0')}:{String(minutes).padStart(2, '0')}:
          {String(seconds).padStart(2, '0')}
        </div>
        {isActive ? (
          <PauseIcon onClick={handlePlayPause} />
        ) : (
          <PlayArrowIcon onClick={handlePlayPause} />
        )}
        |
        <StopIcon onClick={handleStop} />
      </Box>

      <Offcanvas show={show} onHide={() => setShow(false)} placement="bottom">
        <Offcanvas.Header>
          <button
            type="button"
            aria-label="Close Modal"
            className="close-modal"
            onClick={() => setShow(false)}
          />
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Culminate
            data={data}
            currentTarget={currentTarget}
            setShow={setShow}
            toggleDrawer={toggleDrawer}
            state={state}
            setState={setState}
            setIsActive={setIsActive}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
