import dayjs from 'dayjs';

import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

// * Helper function to generate all dates in the given range.
const getDatesInRange = (startDate, endDate) => {
  const dates = [];
  let currentDate = dayjs(startDate);
  const finalDate = dayjs(endDate);

  while (currentDate <= finalDate) {
    dates.push(currentDate.format('YYYY-MM-DD'));
    currentDate = currentDate.add(1, 'days');
  }

  return dates;
};

function BarCharts({ apiData }) {
  const timeToDecimal = (time) => {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    // * Calculate the total hours as a decimal, including minutes and seconds as fractions.
    const totalHours = hours + minutes / 60 + seconds / 3600;
    return totalHours;
  };

  const groupedData = {};

  apiData?.forEach((item) => {
    const timeTrackings = item?.time_tracking || [];

    timeTrackings.forEach((entry) => {
      const date = dayjs(entry.date).format('YYYY-MM-DD');

      // * Sums the hours for each date.
      if (!groupedData[date]) {
        groupedData[date] = 0;
      }
      groupedData[date] += timeToDecimal(entry.tracked_time_hours);
    });
  });

  // * Determine the date range from the first and last entries.
  const allDates = Object.keys(groupedData);
  const startDate = dayjs(
    Math.min(...allDates.map((date) => dayjs(date).valueOf())),
  );
  const endDate = dayjs(
    Math.max(...allDates.map((date) => dayjs(date).valueOf())),
  );

  // * Generate a full list of dates within the date range.
  const fullDateRange = getDatesInRange(startDate, endDate);
  const totalDays = endDate.diff(startDate, 'day');

  const modifiedData = fullDateRange.map((date) => {
    return {
      date: dayjs(date).format('MMM DD, YYYY'),
      duration: groupedData[date] ? groupedData[date].toFixed(2) : 0,
    };
  });

  // * Takes into account all months and February as the lesser month.
  const interval = totalDays >= 28 ? 3 : 0;

  return (
    <ResponsiveContainer
      width="100%"
      height={450}
      className="bar-chart"
      id="time-chart"
    >
      <BarChart
        barGap={-55}
        width={550}
        height={400}
        data={modifiedData}
        margin={{ top: 25, bottom: 50, left: 0, right: 0 }}
      >
        <CartesianGrid vertical={false} strokeDasharray="3 3" />

        <XAxis
          dataKey="date"
          interval={interval}
          allowDataOverflow={true}
          type="category"
          tick={{ fontSize: 13, angle: -20, textAnchor: 'end' }}
        />

        <YAxis type="number" tickFormatter={(value) => `${value} hours`} />

        <Bar dataKey="duration" barSize={40} fill="#5ee690" />

        <Tooltip cursor={false} />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default BarCharts;
