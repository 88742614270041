import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  Paper,
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Button,
  Popover,
  Grid,
} from '@mui/material';
import { Box } from '@mui/system';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro';
import { CalendarMonthOutlined } from '@mui/icons-material';
import ExpandMoreSharpIcon from '@mui/icons-material/ExpandMoreSharp';

import moment from 'moment';
import dayjs from 'dayjs';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import uuid from 'react-uuid';

import Estimates from '../../Componenets/TopNav/Add/Estimates.png';
import moreIco from '../Opportunity/assets/moreIco.png';

function Sales() {
  const [RealData, setRealData] = useState();
  const opportunities = useSelector((state) => state.oppertunity.opportunites);
  const estimates = useSelector((state) => state.oppertunity.estimates);

  const OpportunityTotal =
    Array.isArray(RealData) && RealData.length
      ? RealData.reduce((total, item) => {
          return (
            total +
            (item.opportunity_type === 'Project_opportunity' ||
            item.opportunity_type === 'Retainer_opportunity'
              ? item?.month_to_month === null && item?.duration
                ? Number(item?.budget) * Number(item?.duration)
                : Number(item.budget)
              : 0)
          );
        }, 0)
      : 0;

  const foramtedOppertunityTotal = OpportunityTotal.toLocaleString();

  const totalBudgetForWonEstimates =
    Array.isArray(RealData) &&
    RealData.length > 0 &&
    RealData.filter((estimate) => estimate?.estimate_status === 'won').reduce(
      (total, estimate) =>
        total +
        (estimate?.type === 'retainer'
          ? estimate?.month_to_month === null &&
            estimate?.duration &&
            estimate?.duration > 0
            ? Number(estimate?.actual) * Number(estimate?.duration)
            : Number(estimate?.actual)
          : Number(estimate?.actual)
            ? Number(estimate?.actual)
            : 0),
      0,
    );

  const formateTotalForWons = totalBudgetForWonEstimates.toLocaleString();

  const totalBudgetForLostEstimates =
    Array.isArray(RealData) &&
    RealData.length > 0 &&
    RealData.filter((estimate) => estimate?.estimate_status === 'lost').reduce(
      (total, estimate) =>
        total +
        (estimate?.type === 'retainer'
          ? estimate.month_to_month === null && estimate?.duration
            ? Number(estimate?.actual) * Number(estimate?.duration)
            : Number(estimate?.actual)
          : Number(estimate?.actual)
            ? Number(estimate?.actual)
            : 0),
      0,
    );

  const formateTotalForLost = totalBudgetForLostEstimates.toLocaleString();

  const estimate_total =
    Array.isArray(RealData) && RealData.length
      ? RealData.filter((item) => item?.estimate_status === 'active').reduce(
          (total, item) => {
            return (
              total +
              (item?.opportunity_type === 'Project Estimate ' ||
              item?.opportunity_type === 'Retainer Estimate '
                ? item.month_to_month === null && item?.duration
                  ? Number(item?.actual) * Number(item?.duration)
                  : Number(item.actual)
                : 0)
            );
          },
          0,
        )
      : 0;

  const formatedEstimate = estimate_total.toLocaleString();

  const project =
    Array.isArray(RealData) &&
    RealData.length &&
    RealData.filter(
      (item) => item.type === 'project' && item.estimate_status === 'active',
    );

  const project_total =
    Array.isArray(project) && project.length
      ? project.reduce((total, item) => {
          return total + (item.actual ? Number(item.actual) : 0);
        }, 0)
      : 0;

  const formatedProject = project_total.toLocaleString();

  const retainer =
    Array.isArray(RealData) &&
    RealData.length &&
    RealData.filter(
      (item) => item.type === 'retainer' && item.estimate_status === 'active',
    );

  const retainer_total =
    Array.isArray(retainer) && retainer.length
      ? retainer.reduce((total, item) => {
          return (
            total +
            (item.actual
              ? item.month_to_month === null && item?.duration
                ? Number(item?.actual) * Number(item?.duration)
                : Number(item.actual)
              : 0)
          );
        }, 0)
      : 0;

  const formatedRetainer = retainer_total.toLocaleString();

  let projects = [];

  if (Array.isArray(estimates) && Array.isArray(opportunities)) {
    projects = [...estimates, ...opportunities];
  }

  const currentCompanies = useSelector((state) => state.Contacts.companies);

  const months = {
    '01': 'Jan',
    '02': 'Feb',
    '03': 'Mar',
    '04': 'Apr',
    '05': 'May',
    '06': 'Jun',
    '07': 'Jul',
    '08': 'Aug',
    '09': 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec',
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const rows = projects.map((item) => {
    return {
      id: item?.id,
      name: item?.title ? item?.title : item?.name ? item.name : '-----',

      opportunity_type: item?.opportunity_type
        ? capitalizeFirstLetter(item?.opportunity_type)
        : `${capitalizeFirstLetter(item.type)} Estimate `,

      company:
        currentCompanies.find((c) => c.id === parseInt(item.company, 10))
          ?.name ?? '---------',
      target_start: item?.target_start,
      target_end: item?.finish_date,
      formatedStartDate: item?.target_start
        ? `${months[item?.target_start.split('-')[1]]} ${
            item?.target_start.split('-')[2]
          }, ${item.target_start.split('-')[0]}`
        : false,

      formatedEndDate: item.target_end
        ? `${new Date(item.target_end).getDate()} ${
            months[new Date(item.target_end).getMonth() + 1]
          } ${new Date(item.target_end).getFullYear()}`
        : false,
      duration: item?.duration ? Number(item?.duration) : null,

      est_startdate: item.est_startdate
        ? moment(item.est_startdate).format('D MMM, YYYY')
        : '',
      est_enddate: item.est_enddate
        ? moment(item.est_enddate).format('D MMM, YYYY')
        : '',
      // eslint-disable-next-line
      isOpa: 'name' in item ? true : false,
      unique_id: item.unique_id || item.id,
      collaborator: item.collaborator,
      budget: item.budget ?? null,
      // eslint-disable-next-line
      is_retainer: item.type === 'retainer' ? true : false,
      estimate_status: item?.estimate_status ?? null,
      type: item?.type ?? null,
      month_to_month: item?.month_to_month ?? null,
      actual: item?.actual ?? null,
    };
  });

  const [day, setDay] = useState('This Month');
  const buttonNames = [day, 'Export As'];

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverIndex, setPopoverIndex] = useState(null);

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setPopoverIndex(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setPopoverIndex(null);
  };

  const [dateRange, setDateRange] = useState([
    dayjs().startOf('month'),
    dayjs().endOf('month'),
  ]);

  const handleDateRange = (v) => {
    setDateRange(v);
  };

  const handleClickDay = (v) => {
    setDay(v);
    if (v === 'Today') {
      const today = dayjs();
      setDateRange([today]);
    } else if (v === 'Yesterday') {
      const today = dayjs();
      setDateRange([today.add(1, 'day')]);
    } else if (v === 'This Week') {
      const today = dayjs();
      setDateRange([today.startOf('week'), today.endOf('week')]);
    } else if (v === 'Last Week') {
      const today = dayjs();
      const prevWeek = today.subtract(7, 'day');
      setDateRange([prevWeek.startOf('week'), prevWeek.endOf('week')]);
    } else if (v === 'This Month') {
      const today = dayjs();
      setDateRange([today.startOf('month'), today.endOf('month')]);
    } else if (v === 'Last Month') {
      const today = dayjs();
      const startOfNextMonth = today.startOf('month').subtract(1, 'month');
      setDateRange([startOfNextMonth, startOfNextMonth.endOf('month')]);
    } else if (v === 'This Year') {
      const today = dayjs();
      setDateRange([today.startOf('year'), today.endOf('year')]);
    } else if (v === 'Last Year') {
      const today = dayjs();
      setDateRange([
        today.startOf('year').subtract(1, 'year'),
        today.endOf('year').subtract(1, 'year'),
      ]);
    }
  };

  const [Stage, setStage] = useState([null]);
  const [type, setType] = useState([null]);

  useEffect(() => {
    if (Stage[0] === null && type[0] === null) {
      setRealData(rows);
    }
  }, [Stage, type]);

  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  useEffect(() => {
    let filteredProjects = rows;

    if (Stage[0] !== null) {
      if (Stage[0] === 'Both') {
        // No additional filtering needed
      } else if (Stage[0] === 'Estimate') {
        filteredProjects = rows?.filter(
          (item) =>
            item.opportunity_type === 'Project Estimate ' ||
            item.opportunity_type === 'Retainer Estimate ',
        );
      } else if (Stage[0] === 'Opportunity') {
        filteredProjects = rows?.filter(
          (item) =>
            item.opportunity_type === 'Project_opportunity' ||
            item.opportunity_type === 'Retainer_opportunity',
        );
      }
    }

    if (type[0]) {
      if (type[0] === 'Retainer') {
        filteredProjects = filteredProjects?.filter(
          (item) =>
            item.opportunity_type === 'Retainer Estimate ' ||
            item.opportunity_type === 'Retainer_opportunity',
        );
      } else if (type[0] === 'Project') {
        filteredProjects = filteredProjects?.filter(
          (item) =>
            item.opportunity_type === 'Project Estimate ' ||
            item.opportunity_type === 'Project_opportunity',
        );
      }
    }

    const finalFilteredProjects = filteredProjects?.filter((project) => {
      const projectStartDate = new Date(
        project?.opportunity_type === 'Retainer_opportunity' ||
        project?.opportunity_type === 'Project_opportunity'
          ? project?.target_start
          : project?.est_startdate,
      );
      const projectEndDate = new Date(
        project?.opportunity_type === 'Retainer_opportunity' ||
        project?.opportunity_type === 'Project_opportunity'
          ? project?.target_end
          : project?.est_enddate,
      );

      return (
        (projectStartDate >= dateRange[0] &&
          projectStartDate < addDays(dateRange[1], 1)) ||
        (projectEndDate >= dateRange[0] &&
          projectEndDate < addDays(dateRange[1], 1))
      );
    });

    setRealData(finalFilteredProjects || []);
  }, [Stage, type, dateRange]);

  const [exportAs, setExportAs] = useState(0);
  const printDocument = () => {
    // eslint-disable-next-line
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'in',
      format: 'letter',
      compress: true,
    });

    const margin = 1;
    const chartElement = document.getElementById('data-chart');
    if (!chartElement) {
      return;
    }

    html2canvas(chartElement).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const imgWidth = doc.internal.pageSize.getWidth() - 2 * margin;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      doc.addImage(imgData, 'PNG', margin, margin, imgWidth, imgHeight);

      let startY = imgHeight + margin + 0.3;

      doc.setFontSize(12);
      doc.setFont('helvetica', 'bold');
      doc.text('Sales', margin, startY);

      startY += 0.2;

      const tableColumn = [
        'Name',
        'Company',
        'Type',
        'Start Date - End Date',
        'Total Amount',
      ];

      const tableRows = RealData.map((item) => {
        const name = item.name || '---';
        const opportunityType = item.opportunity_type || '---';
        const company = item?.company;
        const startDate = item?.est_startdate
          ? moment(item.est_startdate).format('MM/DD/YYYY')
          : '---';
        const endDate = item?.est_enddate
          ? moment(item.est_enddate).format('MM/DD/YYYY')
          : '---';
        const dateRange = `${startDate} - ${endDate}`;

        const totalAmount =
          item?.opportunity_type === 'Project_opportunity' ||
          item?.opportunity_type === 'Retainer_opportunity'
            ? (
                (item?.month_to_month === null &&
                item?.duration &&
                item?.duration !== 0
                  ? Number(item?.budget) * Number(item?.duration)
                  : Number(item?.budget)) ?? 0
              ).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })
            : item?.opportunity_type === 'Project Estimate ' ||
                item?.opportunity_type === 'Retainer Estimate '
              ? (
                  (item?.month_to_month === null &&
                  item?.duration &&
                  item?.duration !== 0
                    ? Number(item?.actual) * Number(item?.duration)
                    : Number(item?.actual)) ?? 0
                ).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })
              : '---';

        return [name, company, opportunityType, dateRange, totalAmount];
      });

      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        margin: { left: margin, right: margin },
        startY: startY + 0.1,
        theme: 'plain',
        styles: {
          fontSize: 7,
        },
        headStyles: {
          fillColor: [240, 240, 240],
          textColor: [0, 0, 0],
        },
        alternateRowStyles: {
          fillColor: [230, 230, 230],
        },
        bodyStyles: {
          fillColor: [245, 245, 245],
        },
        columnStyles: {
          0: { cellWidth: 1.2 },
          1: { cellWidth: 1.2 },
          2: { cellWidth: 1.1 },
          3: { cellWidth: 2.1 },
          4: { cellWidth: 1 },
        },
      });

      doc.save(`Sales-Report${new Date()}.pdf`);
    });
  };

  const downloadCSV = () => {
    const table = document.getElementById('div-to-csv');

    const rows = Array.from(table.getElementsByTagName('tr'));

    const csvData = rows.map((row) => {
      const cells = Array.from(row.getElementsByTagName('td'));
      return cells.map((cell) => cell.innerText.trim());
    });

    const csvContent = csvData.map((row) => row.join(',')).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `Sales-CSV-${new Date()}.csv`;
    link.click();
  };

  const downloadXLS = () => {
    const table = document.getElementById('div-to-csv');

    const rows = Array.from(table.getElementsByTagName('tr'));

    const data = rows.map((row) => {
      const cells = Array.from(row.getElementsByTagName('td'));
      return cells.map((cell) => cell.innerText.trim());
    });

    const worksheet = XLSX.utils.aoa_to_sheet([
      ['Name', 'Company', 'Type', 'Start Date - End Date', 'Total Amount'],
      ...data,
    ]);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sales');

    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });

    saveAs(blob, `Sales-XLS-${new Date()}`);
  };
  const handleClickExportAs = (v, i) => {
    if (v === 'PDF') {
      printDocument();
    } else if (v === 'CSV') {
      downloadCSV();
    } else if (v === 'XLS') {
      downloadXLS();
    }
    setExportAs(i);
    handleClose();
  };

  return (
    <>
      <Grid
        sx={{
          backgroundColor: 'inherit',
          padding: '1rem 0rem',
          borderBottom: '1px solid #e2e2e2',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '2.9rem',
            width: '95%',
            backgroundColor: '#F1F3F5',
            padding: '1rem 1rem',
            margin: '0 auto',
            borderRadius: '8px',
          }}
        >
          <div>
            {buttonNames.map((name, index) => (
              <>
                <Button
                  variant="contained"
                  aria-describedby={index}
                  onClick={(event) => handleClick(event, index)}
                  startIcon={index === 0 && <CalendarMonthOutlined />}
                  endIcon={
                    <ExpandMoreSharpIcon
                      style={{
                        width: '18px',
                        height: '18px',
                        fontWeight: 200,
                        color: '#03071E',
                      }}
                    />
                  }
                  style={{
                    marginRight: 10,
                    backgroundColor: '#E0E0DF',
                    color: 'black',
                    textTransform: 'inherit',
                    boxShadow: 'inherit',
                    fontWeight: 400,
                    fontSize: '14px',
                    '&:hover': {
                      backgroundColor: '#E0E0DF',
                      color: 'black',
                      boxShadow: 'inherit',
                    },
                  }}
                >
                  {index === 1 ? name : name}
                </Button>
                <Popover
                  id={index}
                  open={popoverIndex === index}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  sx={{
                    '& .MuiPaper-root': {
                      boxShadow: 'inherit',
                      border: '1px solid #E0E0DF',
                      borderRadius: 2,
                    },
                  }}
                >
                  {index === 0 && (
                    <Box
                      align="center"
                      sx={{
                        width: 'auto',
                        height: 'auto',
                        px: '30px',
                        display: { xs: 'block', md: 'flex' },
                      }}
                    >
                      <Box
                        align="left"
                        sx={{
                          backgroundColor: '#F8F9FA',
                          width: { xs: '100%', md: '200px' },
                          p: 1,
                          pb: 3,
                          my: 4,
                          borderRadius: 2,
                        }}
                      >
                        {[
                          'Today',
                          'Yesterday',
                          'This Week',
                          'Last Week',
                          'This Month',
                          'Last Month',
                          'This Year',
                          'Last Year',
                        ].map((v, i) => {
                          return (
                            <Typography
                              key={uuid()}
                              onClick={() => handleClickDay(v, i)}
                              sx={
                                day === v
                                  ? {
                                      fontSize: '16px',
                                      fontWeight: 400,
                                      cursor: 'pointer',
                                      color: '#711FFF',
                                      my: 3,
                                      ml: 2,
                                    }
                                  : {
                                      fontSize: '16px',
                                      fontWeight: 400,
                                      cursor: 'pointer',
                                      color: '#03071E',
                                      my: 3,
                                      ml: 2,
                                    }
                              }
                            >
                              {v}
                            </Typography>
                          );
                        })}
                      </Box>
                      <Box sx={{ mx: 4, my: 4 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateRangeCalendar
                            value={dateRange}
                            onChange={(newValue) => handleDateRange(newValue)}
                            sx={{
                              '& .css-10wpov9-MuiTypography-root ': {
                                fontWeight: '700 !important',
                              },
                              '& .css-cyzddl-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition:not(.MuiDateRangeCalendar-dayDragging) .MuiDateRangePickerDay-dayOutsideRangeInterval':
                                {
                                  color: '#03071E !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500,
                                },
                              '& .css-1gbl7yn-MuiDateRangePickerDay-root': {
                                backgroundColor: '#310085 !important',
                                color: 'white !important',
                                borderRadius: '0px !important',
                              },
                              '& .css-1e841vg-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected':
                                {
                                  backgroundColor: '#711FFF !important',
                                  color: 'white !important',
                                  borderRadius: '0px !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500,
                                },
                              '& .css-1ckjanr-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected':
                                {
                                  backgroundColor: '#711FFF !important',
                                  color: 'white !important',
                                  borderRadius: '0px !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500,
                                },
                              '& .css-1a4q4r2-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected':
                                {
                                  backgroundColor: '#711FFF !important',
                                  color: 'white !important',
                                  borderRadius: '0px !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500,
                                },
                              '& .css-2ko3hu-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day':
                                {
                                  backgroundColor: '#310085 !important',
                                  color: 'white !important',
                                  opacity: 'inherit !important',
                                  borderRadius: '0px',
                                  border: 'none !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500,
                                },
                              '& .css-1ku4sqv-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day':
                                {
                                  backgroundColor: '#711FFF !important',
                                  color: 'white !important',
                                  borderRadius: '0px !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500,
                                },
                              '& .css-ahwqre-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:not(.Mui-selected)':
                                {
                                  backgroundColor: '#310085 !important',
                                  color: '#e569db !important',
                                  borderRadius: '0px !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500,
                                },
                              '& .css-jef1b6-MuiDateRangeCalendar-container:not(:last-of-type)':
                                {
                                  borderRight: 'none !important',
                                },
                              '& .css-3wduhr-Mu.iDateRangeCalendar-root': {
                                flexDirection: 'column !important',
                              },

                              '& .css-grqin-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day':
                                {
                                  color: 'inherit',
                                  fontSize: '16px !important',
                                  opacity: 'inherit',
                                  fontWeight: 500,
                                },
                              '& .css-1kex3oi-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:not(.Mui-selected)':
                                {
                                  backgroundColor: 'inherit',
                                  color: 'black !important',
                                  borderRadius: '50%',
                                  border: '1px solid gray',
                                  fontSize: '16px',
                                  fontWeight: 500,
                                },
                              '& .MuiTypography-subtitle1': {
                                color: ' #03071E !important',
                                fontSize: '16px',
                                fontWeight: 700,
                              },
                              '& .MuiDayCalendar-weekDayLabel': {
                                color: ' #03071ECC !important',
                                fontSize: '16px',
                                fontWeight: 400,
                              },
                              '& .MuiSvgIcon-fontSizeInherit': {
                                backgroundColor: 'black',
                                color: 'white',
                                borderRadius: '50%',
                              },
                              '& .MuiPickersDay-today': {
                                backgroundColor: '#711FFF',
                                color: 'white',
                                borderRadius: '0px',
                                fontSize: '16px',
                                fontWeight: 500,
                              },
                              '& .css-grqin-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected':
                                {
                                  backgroundColor: '#711FFF',
                                  color: 'white',
                                  borderRadius: '0px',
                                  fontSize: '16px',
                                  fontWeight: 500,
                                },
                              '& .css-gtjfra-MuiDateRangePickerDay-root': {
                                backgroundColor: '#310085',
                                color: 'white !important',
                                borderRadius: '0px',
                                fontSize: '16px',
                                fontWeight: 500,
                              },
                              '& .css-1i2r8k1-MuiDateRangePickerDay-root': {
                                backgroundColor: '#310085',
                                color: 'white !important',
                                borderRadius: '0px',
                                fontSize: '16px',
                                fontWeight: 500,
                              },
                              '& .MuiDateRangePickerDay-notSelectedDate': {},
                            }}
                          />
                        </LocalizationProvider>

                        <Box sx={{ display: 'flex', my: 4 }}>
                          <Box sx={{ display: 'flex', mr: 4 }}>
                            <Box
                              sx={{
                                width: '20px',
                                height: '20px',
                                backgroundColor: '#711FFF',
                                borderRadius: 2,
                                mr: 2,
                              }}
                            />
                            <Typography
                              sx={{ fontWeight: 400, fontSize: '14px' }}
                            >
                              Start Date and End Date
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex' }}>
                            <Box
                              sx={{
                                width: '20px',
                                height: '20px',
                                backgroundColor: '#310085',
                                borderRadius: 2,
                                mr: 2,
                              }}
                            />
                            <Typography
                              sx={{ fontWeight: 400, fontSize: '14px' }}
                            >
                              Date Range
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        align=""
                        my={3}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          alignItems: '',
                        }}
                      >
                        <Box align="left" mt={4}>
                          <Box align="left">
                            <Typography
                              sx={{
                                color: '#03071E99',
                                fontSize: '16px',
                                fontWeight: 400,
                              }}
                            >
                              Start Date
                            </Typography>
                            <Typography
                              sx={{
                                color: '#03071E',
                                fontSize: '16px',
                                fontWeight: 400,
                              }}
                            >
                              {dateRange[0] &&
                                dateRange[0].format().slice(0, 10)}
                            </Typography>
                          </Box>
                          <Box sx={{ mt: 2 }}>
                            <Typography
                              sx={{
                                color: '#03071E99',
                                fontSize: '16px',
                                fontWeight: 400,
                              }}
                            >
                              End Date
                            </Typography>
                            <Typography
                              sx={{
                                color: '#03071E',
                                fontSize: '16px',
                                fontWeight: 400,
                              }}
                            >
                              {dateRange[1] &&
                                dateRange[1].format().slice(0, 10)}
                            </Typography>
                          </Box>
                        </Box>

                        <Box align="right">
                          <Button
                            variant="text"
                            sx={{
                              textDecoration: 'inherit',
                              color: 'black',
                              width: { xs: 'auto', lg: '100px' },
                              mr: 1,
                            }}
                            onClick={handleClose}
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={handleClose}
                            variant="contained"
                            sx={{
                              textDecoration: 'inherit',
                              backgroundColor: '#711FFF',
                              width: { xs: 'auto', lg: '100px' },
                              mr: 1,
                              '&:hover': {
                                backgroundColor: '#711FFF',
                              },
                            }}
                          >
                            Save
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  )}

                  {index === 1 && (
                    <Box sx={{ pb: 2, width: '150px', textAlign: 'left' }}>
                      {['PDF', 'XLS', 'CSV'].map((v, i) => {
                        return (
                          <Typography
                            key={uuid()}
                            fullWidth
                            onClick={() => handleClickExportAs(v, i)}
                            sx={
                              exportAs === i
                                ? {
                                    color: 'white',
                                    backgroundColor: '#711FFF',
                                    borderRadius: 1,
                                    textTransform: 'inherit',
                                    fontSize: '14px',
                                    m: 2,
                                    px: 1,
                                    '&:hover': { backgroundColor: '#E0E0DF' },
                                  }
                                : {
                                    color: 'black',
                                    borderRadius: 1,
                                    textTransform: 'inherit',
                                    fontSize: '14px',
                                    m: 2,
                                    px: 1,
                                    '&:hover': { backgroundColor: '#E0E0DF' },
                                  }
                            }
                          >
                            {v}
                          </Typography>
                        );
                      })}
                    </Box>
                  )}
                </Popover>
              </>
            ))}
          </div>
        </Box>

        <Box
          sx={{
            display: 'flex',
            width: '95%',
            alignItems: 'center',
            margin: '0 auto',
          }}
        >
          <Autocomplete
            id="company"
            options={['Both', 'Opportunity', 'Estimate']}
            includeInputInList
            onChange={(_, value) => {
              // Filter out undefined values and update the Stage state
              setStage([value]);
            }}
            // multiple
            getOptionLabel={(option) => {
              return option;
            }}
            style={{ width: 300, marginTop: '0.7rem' }}
            sx={{
              '& .MuiAutocomplete-inputRoot': {
                flexWrap: 'nowrap',
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Filter By Stage"
                color="secondary"
                variant="standard"
                sx={{
                  '& .MuiInputBase-input': {
                    fontSize: '1rem',
                    fontFamily: 'Open Sans',
                    fontWeight: '500',
                    color: '#000000',
                    textAlign: 'left',
                  },
                  '& .MuiInput-underline:before': {
                    borderBottom: '2px solid #711fff',
                  },
                  '& .MuiInput-underline:after': {
                    borderBottom: '2px solid #711fff',
                  },
                  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                    borderBottom: '2px solid #711fff',
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  style: { width: 250 },
                }}
              />
            )}
          />

          <Autocomplete
            id="company"
            options={['Project', 'Retainer']}
            includeInputInList
            getOptionLabel={(option) => {
              return option;
            }}
            onChange={(_, value) => {
              setType([value]);
            }}
            sx={{
              '& .MuiAutocomplete-inputRoot': {
                flexWrap: 'nowrap',
              },
            }}
            style={{ width: 300, marginTop: '0.7rem' }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Filter By Type"
                color="secondary"
                variant="standard"
                sx={{
                  '& .MuiInputBase-input': {
                    fontSize: '1rem',
                    fontFamily: 'Open Sans',
                    fontWeight: '500',
                    color: '#000000',
                    textAlign: 'left',
                  },
                  '& .MuiInput-underline:before': {
                    borderBottom: '2px solid #711fff',
                  },
                  '& .MuiInput-underline:after': {
                    borderBottom: '2px solid #711fff',
                  },
                  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                    borderBottom: '2px solid #711fff',
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  style: { width: 250 },
                }}
              />
            )}
          />
        </Box>
      </Grid>

      <div id="div-to-print">
        <Box
          id="data-chart"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '0px 15px',
            width: '100%',
            margin: '15px 0px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem',
              width: '22%',
              height: '250px',
              borderRadius: '8px',
              padding: '11px 10px 11px 10px',
              backgroundColor: '#e0f0ff',
              cursor: 'pointer',
              flexDirection: 'column',
              fontWeight: 'bold',
              color: '#000',
            }}
          >
            <img
              src={Estimates}
              alt="Estimate"
              style={{ margin: '10px 0px' }}
            />
            Opportunities
            <Box
              sx={{
                width: '100%',
                height: '100%',
                background: '#c6e4ff',
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '10px',
              }}
            >
              <Typography sx={{ fontWeight: '600', fontSize: '15px' }}>
                TOTAL
              </Typography>
              <Typography sx={{ fontWeight: '600', fontSize: '15px' }}>
                ${foramtedOppertunityTotal ?? 0}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem',
              width: '22%',
              height: '250px',
              borderRadius: '8px',
              padding: '11px 10px 11px 10px',
              backgroundColor: '#c6ede5',
              cursor: 'pointer',
              flexDirection: 'column',
              fontWeight: 'bold',
              color: '#000',
            }}
          >
            <img
              src={Estimates}
              alt="Estimate"
              style={{ margin: '10px 0px' }}
            />
            Estimates
            <Box
              sx={{
                width: '100%',
                background: '#0dd66e',
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '10px',
                padding: '0.5rem 0rem',
              }}
            >
              <Typography sx={{ fontWeight: '600', fontSize: '15px' }}>
                TOTAL
              </Typography>
              <Typography sx={{ fontWeight: '600', fontSize: '15px' }}>
                ${formatedEstimate ?? 0}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '80%',
                margin: '0 auto',
                fontSize: '12px',
                justifyContent: 'space-between',
                fontWeight: '500',
              }}
            >
              <li>PROJECTS:</li>
              <p>${formatedProject ?? 0}</p>
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '80%',
                margin: '0 auto',
                fontSize: '12px',
                justifyContent: 'space-between',
                fontWeight: '500',
              }}
            >
              <li>RETAINERS:</li>
              <p>${formatedRetainer ?? 0}</p>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem',
              width: '22%',
              height: '250px',
              borderRadius: '8px',
              padding: '11px 10px 11px 10px',
              backgroundColor: '#f5c98c',
              cursor: 'pointer',
              flexDirection: 'column',
              fontWeight: 'bold',
              color: '#000',
            }}
          >
            <img
              src={Estimates}
              alt="Estimate"
              style={{ margin: '10px 0px' }}
            />
            Lost
            <Box
              sx={{
                width: '100%',
                height: '100%',
                background: '#e38b12',
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '10px',
              }}
            >
              <Typography sx={{ fontWeight: '600', fontSize: '15px' }}>
                TOTAL
              </Typography>
              <Typography
                sx={{ fontWeight: '600', fontSize: '15px' }}
              >{`$${formateTotalForLost}`}</Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem',
              width: '22%',
              height: '250px',
              borderRadius: '8px',
              padding: '11px 10px 11px 10px',
              backgroundColor: '#c6ede5',
              cursor: 'pointer',
              flexDirection: 'column',
              fontWeight: 'bold',
              color: '#000',
            }}
          >
            <img
              src={Estimates}
              alt="Estimate"
              style={{ margin: '10px 0px' }}
            />
            Won
            <Box
              sx={{
                width: '100%',
                height: '100%',
                background: '#c6e4ff',
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '10px',
              }}
            >
              <Typography sx={{ fontWeight: '600', fontSize: '15px' }}>
                TOTAL
              </Typography>
              <Typography
                sx={{ fontWeight: '600', fontSize: '15px' }}
              >{`$${formateTotalForWons}`}</Typography>
            </Box>
          </Box>
        </Box>
        <div id="div-to-csv">
          <Box sx={{ padding: '10px 10px' }}>
            <Typography
              sx={{ fontSize: '17px', color: '#000', fontWeight: '600' }}
            >
              Sales
            </Typography>
            <TableContainer
              component={Paper}
              sx={{
                width: '100%',
                border: '1px solid #adadad',
                borderRadius: '8px',
                m: '1rem 0',
              }}
            >
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      height: '72px',
                      backgroundColor: '#f8f9fa',
                    }}
                  >
                    <TableCell sx={{ fontSize: '15px' }}>Name</TableCell>
                    <TableCell sx={{ fontSize: '15px' }}>Company</TableCell>
                    <TableCell sx={{ fontSize: '15px' }}>Type</TableCell>
                    <TableCell sx={{ fontSize: '15px' }}>
                      Start Date - End Date
                    </TableCell>
                    <TableCell sx={{ fontSize: '15px' }}>
                      Total Amount
                    </TableCell>
                    <TableCell> </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody sx={{ padding: '0 10px' }}>
                  {RealData?.map((item) => {
                    return (
                      <TableRow key={uuid()} sx={{ height: '92px' }}>
                        <TableCell sx={{ fontSize: '15px', color: '#000' }}>
                          {item.name}
                        </TableCell>
                        <TableCell sx={{ fontSize: '15px', color: '#000' }}>
                          {item.company}
                        </TableCell>
                        <TableCell sx={{ fontSize: '15px', color: '#000' }}>
                          {item.opportunity_type}
                        </TableCell>
                        <TableCell sx={{ fontSize: '15px', color: '#000' }}>
                          {item?.est_startdate ? item?.est_startdate : '---'}{' '}
                          {item.est_enddate ? item?.est_enddate : '---'}
                        </TableCell>
                        <TableCell sx={{ fontSize: '15px', color: '#000' }}>
                          $
                          {item?.opportunity_type === 'Project_opportunity' ||
                          item?.opportunity_type === 'Retainer_opportunity'
                            ? ((item?.month_to_month === null &&
                              item?.duration &&
                              item?.duration !== 0
                                ? Number(item?.budget) * Number(item?.duration)
                                : Number(item?.budget)) ?? 0)
                            : item?.opportunity_type === 'Project Estimate ' ||
                                item?.opportunity_type === 'Retainer Estimate '
                              ? ((item?.month_to_month === null &&
                                item?.duration &&
                                item?.duration !== 0
                                  ? Number(item?.actual) *
                                    Number(item?.duration)
                                  : Number(item?.actual)) ?? 0)
                              : 0}
                        </TableCell>
                        <TableCell
                          id="demo-customized-button"
                          aria-controls={
                            // eslint-disable-next-line
                            open ? 'demo-customized-menu' : undefined
                          }
                          aria-haspopup="true"
                          // eslint-disable-next-line
                          aria-expanded={open ? 'true' : undefined}
                          variant="contained"
                          disableElevation
                          onClick={handleClick}
                        >
                          <img
                            alt="More Icon"
                            src={moreIco}
                            style={{ cursor: 'pointer' }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </div>
      </div>
    </>
  );
}

export default Sales;
