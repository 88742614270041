/* eslint-disable jsx-a11y/label-has-associated-control */
import Alert from '@mui/material/Alert';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Grid, Box, TextField, Typography } from '@mui/material';
import { setUser, setMyEmail } from '../Redux/auth';
import { ENDPOINT } from '../../../pages/Opportunity/Redux/oppor';
import './auth.css';
import NetNetLogo from '../../Logo';
import { PreLoader1 } from '../../Misc';
import logo from '../Assets/MobileLogo.png';
import Loginsvg from '../Assets/Profile-Register_fsn5.svg';

export function Header() {
  const LogoDiv = {
    bgDiv: {
      width: '100%',
      height: '40px',
      backgroundColor: '#1c04bd',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    netNetDiv: {
      width: '91px',
      height: '30px',
      backgroundColor: 'none',
      margin: '0 auto',
    },
  };

  return (
    <div style={LogoDiv.bgDiv}>
      <div style={LogoDiv.netNetDiv}>
        <NetNetLogo />
      </div>
    </div>
  );
}

function IntroText() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '0.5rem',
        width: { lg: '60%', md: '70%', xs: '100%' },
        padding: '0 1.5rem',
      }}
    >
      <p className="sub-heading">Sign In</p>
      <p className="fstxt">Welcome Back To Net Net</p>
    </Box>
  );
}

function UsefulLinks() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '0.5rem',
        width: { lg: '60%', md: '70%', xs: '100%' },
        padding: '0 1.5rem',
      }}
    >
      <p className="fstxt">
        Forgot Password?{' '}
        <Link className="link" exact to="/reset">
          Reset Password
        </Link>
      </p>

      <p className="fstxt">
        Don&apos;t have Account?{' '}
        <Link className="link" exact to="/register">
          Sign Up
        </Link>
      </p>
    </Box>
  );
}

function LoginForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const logginSuccess = useSelector((state) => state.auth.logginSuccess);

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  useEffect(() => {
    if (email !== undefined) {
      if (email.length > 0) {
        setError(false);
        setErrorMessage('');
      }
    }
  }, [email]);

  useEffect(() => {
    if (logginSuccess) {
      navigate('/');
    }
  }, [logginSuccess]);

  useEffect(() => {
    if (password !== undefined) {
      if (password.length > 0) {
        setError(false);
        setErrorMessage('');
      }
    }
  }, [password]);

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmission = async (e) => {
    e.preventDefault();
    if (email === '' || password === '') {
      setError(true);
      setErrorMessage('Please fill all the fields');
    } else {
      setLoading(true);
      dispatch(setMyEmail(email));
      const response = await fetch(`${ENDPOINT}/auth/jwt/create/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
        }),
      });
      const data = await response.json();
      if (!response.ok) {
        setError(true);
        setLoading(false);
        setErrorMessage(data.detail);
      } else {
        setLoading(false);
        dispatch(setUser(data));
      }
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: { lg: 'flex-start', md: 'flex-start', xs: 'center' },
        gap: '0.2rem',
        width: { lg: '60%', md: '70%', xs: '100%' },
        padding: '0 1.5rem',
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleSubmission(e);
        }
      }}
    >
      <TextField
        type="email"
        variant="outlined"
        sx={{
          width: '100%',
          margin: '0.20rem 0rem',
          fontWeight: '600',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#ADADAD',
            },
            '&:hover fieldset': {
              borderColor: '#711fff',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#5a0bdf',
            },

            '&.Mui-error fieldset': {
              borderColor: 'red',
            },

            '&.Mui-disabled fieldset': {
              borderColor: 'grey',
            },
          },
          '& .MuiFormLabel-root': {
            color: '#03071e66',
          },
          '& .MuiFormLabel-root.Mui-focused': {
            color: '#5a0bdf',
          },
          '& .MuiFormLabel-root.Mui-error': {
            color: 'red',
          },
          '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
            top: '-1px',
          },
        }}
        label="Email Address"
        onChange={handleEmail}
        onInvalid={(e) => {
          e.target.setCustomValidity('Please Enter a Valid Email Address');
        }}
        onInput={(e) => {
          e.target.setCustomValidity('');
          e.target.reportValidity();
          setErrorMessage('');
          setError(false);
        }}
        onClick={() => {
          setErrorMessage('');
          setError(false);
        }}
        value={email}
      />

      <TextField
        type="password"
        label="Password"
        variant="outlined"
        sx={{
          width: '100%',
          margin: '0.90rem 0rem',
          fontWeight: 'bold',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#ADADAD',
            },
            '&:hover fieldset': {
              borderColor: '#711fff',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#5a0bdf',
            },

            '&.Mui-error fieldset': {
              borderColor: 'red',
            },

            '&.Mui-disabled fieldset': {
              borderColor: 'grey',
            },
          },
          '& .MuiFormLabel-root': {
            color: '#03071e66',
          },
          '& .MuiFormLabel-root.Mui-focused': {
            color: '#5a0bdf',
          },
          '& .MuiFormLabel-root.Mui-error': {
            color: 'red',
          },

          '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
            top: '-1px',
          },
        }}
        onChange={handlePassword}
        onInput={(e) => {
          e.target.setCustomValidity('');
          setErrorMessage('');
          setError(false);
        }}
        onClick={() => {
          setErrorMessage('');
          setError(false);
        }}
        value={password}
      />

      {error && (
        <Alert sx={{ width: '100%' }} severity="error">
          {errorMessage}
        </Alert>
      )}
      <button
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        type="submit"
        className="btn inline"
        onClick={handleSubmission}
      >
        {loading ? (
          <Box sx={{ width: '40px', height: '40px' }}>
            <PreLoader1 />
          </Box>
        ) : (
          'LOGIN'
        )}{' '}
      </button>
    </Box>
  );
}

function Auth() {
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Grid
      container
      component="main"
      justifyContent={screenSize < 900 ? 'center' : null}
      sx={{ height: screenSize < 900 ? '78vh' : '100vh' }}
    >
      {screenSize >= 900 && (
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundColor: '#47d1fc',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              width: {
                xs: '70%',
                lg: '60%',
                xl: '50%',
                xxl: '50%',
              },
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <img src={logo} width="100%" height="auto" alt="Mobile Logo" />
            </Box>
            <Box sx={{ my: 3 }}>
              <Typography
                sx={{
                  fontSize: '20px',
                  lineHeight: '26px',
                  fontWeight: '400',
                  fontFamily: 'open Sans',
                  color: 'white',
                }}
              >
                Putting Profits Into Your Workflow Tools!
              </Typography>
              <Typography
                sx={{
                  fontSize: '20px',
                  lineHeight: '26px',
                  fontWeight: '400',
                  fontFamily: 'open Sans',
                  color: 'white',
                  mt: 1,
                }}
              >
                - opportunities & sales
              </Typography>
              <Typography
                sx={{
                  fontSize: '20px',
                  lineHeight: '26px',
                  fontWeight: '400',
                  fontFamily: 'open Sans',
                  color: 'white',
                  mt: 1,
                }}
              >
                - project, retainer & task management
              </Typography>
              <Typography
                sx={{
                  fontSize: '20px',
                  lineHeight: '26px',
                  fontWeight: '400',
                  fontFamily: 'open Sans',
                  color: 'white',
                  mt: 1,
                }}
              >
                - time tracking
              </Typography>
              <Typography
                sx={{
                  fontSize: '20px',
                  lineHeight: '26px',
                  fontWeight: '400',
                  fontFamily: 'open Sans',
                  color: 'white',
                  mt: 1,
                }}
              >
                - team communications
              </Typography>
            </Box>
            <Box>
              <img
                src={Loginsvg}
                alt="Login SVG"
                width="100%"
                height="auto"
                style={{
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              />
            </Box>
          </Box>
        </Grid>
      )}
      {screenSize < 900 && <Header />}
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={5}
        lg={5}
        xl={5}
        sx={{
          gap: '0.45rem',
          padding: '0.45rem 0',
          justifyContent: 'center',
          position: 'relative',
        }}
        direction="column"
        alignItems="center"
      >
        <IntroText />
        <LoginForm />
        <UsefulLinks />
      </Grid>
    </Grid>
  );
}
export default Auth;
