import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { Box } from '@mui/material';
import TimerIcon from '@mui/icons-material/Timer';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';

import {
  addCurrentTarget,
  stopTimer,
  getQuickTask,
} from '../pages/Projects/proRedux';
import { ENDPOINT } from '../pages/Opportunity/Redux/oppor';
import TimeStart from './TopNav/assets/TimeStart.png';

function StopWatch({ params, noPlay, setVal, setObjection }) {
  if (!params) {
    return;
  }

  const prevTracking = useSelector((state) => state.projects.currentTarget);

  const currentUser = useSelector((state) => state.userData.userData);

  const allTasks = useSelector((state) => state.projects.tasks);

  const [realTarget, setRealTarget] = useState({});

  useEffect(() => {
    if (params.id) {
      setRealTarget(allTasks.find((t) => t.id === params.id));
    }
  }, [params]);

  // * create a stop watch with hours minutes and seconds.
  const dispatch = useDispatch();
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const access_token = useSelector((state) => state.auth.user.access);

  const { time_tracking } = params;

  const createNewTracking = async (params) => {
    const res = await fetch(`${ENDPOINT}/api/project/task/timetracking/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${access_token}`,
      },
      body: JSON.stringify({
        task_id: params.id,
        time_tracking_start: new Date().toISOString(),
      }),
    });

    const response = await res.json();

    if (!res.ok) {
      return;
    }
    // eslint-disable-next-line
    return response;
  };

  useEffect(() => {
    dispatch(getQuickTask({ access_token }));
  }, [isActive]);

  const [update, setUpdate] = useState({});

  useEffect(() => {
    if (Array.isArray(time_tracking) && time_tracking.length) {
      const { tracked_time_hours, time_tracking_start, time_tracking_stop } =
        time_tracking[time_tracking.length - 1];

      const tracked_time = tracked_time_hours;
      const startedAt = time_tracking_start;
      if (time_tracking_start && time_tracking_stop) {
        const newTracking = createNewTracking(params);
        setSeconds(0);
        setMinutes(0);
        setHours(0);
        setUpdate({
          ...update,
          time_tracking_start: newTracking.time_tracking_start,
        });
      } else if (startedAt && !time_tracking_stop) {
        setUpdate({
          ...update,
          time_tracking_start: startedAt,
        });
        const startAt = moment(startedAt);
        const currentTime = moment();
        const duration = moment.duration(currentTime.diff(startAt));
        const totalSeconds = duration.asSeconds();

        setHours(Math.floor(totalSeconds / 3600));
        setMinutes(Math.floor((totalSeconds % 3600) / 60));
        setSeconds(Math.floor(totalSeconds % 60));
      } else if (tracked_time !== '00:00:00' && startedAt !== null) {
        const startAt = new Date(startedAt);
        const currentTime = new Date();
        const totalSeconds = Math.floor((currentTime - startAt) / 1000);

        setSeconds(totalSeconds % 60);
        setMinutes(Math.floor(totalSeconds / 60));
        setHours(Math.floor(totalSeconds / 3600));
      } else {
        setSeconds(0);
        setMinutes(0);
        setHours(0);
      }
    } else {
      // eslint-disable-next-line
      const myFetch = async () => {
        const res = await fetch(`${ENDPOINT}/api/project/task/timetracking/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${access_token}`,
          },
          body: JSON.stringify({
            task_id: params.id,
            time_tracking_start: new Date().toISOString(),
          }),
        });
        if (res.ok) {
          setSeconds(0);
          setMinutes(0);
          setHours(0);
          return 1;
        }
      };
      myFetch();
    }
  }, [time_tracking]);

  useEffect(() => {
    let interval = null;
    if (isActive === true) {
      interval = setInterval(() => {
        if (seconds > 58) {
          setSeconds(0);
          setMinutes(minutes + 1);
        } else {
          setSeconds(seconds + 1);
        }
        if (minutes > 58) {
          setMinutes(0);
          setHours(hours + 1);
        }
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isActive, seconds, minutes, hours]);

  // eslint-disable-next-line
  return !noPlay ? (
    <div>
      {!isActive ? (
        <button
          className="noBorder"
          type="button"
          title="Start Timer"
          style={{
            padding: '1rem 1.7rem',
            backgroundColor: '#b3ffef',
            textTransform: 'uppercase',
            fontWeight: '700',
            borderRadius: '4px',
          }}
          onClick={async () => {
            if (!prevTracking) {
              const res2 = await fetch(
                `${ENDPOINT}/api/project/task/timetracking/`,
                {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `JWT ${access_token}`,
                  },
                  body: JSON.stringify({
                    task_id: params.id,
                    time_tracking_start: new Date().toISOString(),
                    date: new Date().toISOString(),
                  }),
                },
              );

              const data2 = await res2.json();
              if (!res2.ok) {
                return;
              }
              const response = await fetch(
                `${ENDPOINT}/api/project/task/update/${params.id}/`,
                {
                  method: 'PUT',
                  headers: {
                    Authorization: `JWT ${access_token}`,
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    status: 'inprogress',
                  }),
                },
              );
              const data = await response.json();
              if (!response.ok) {
                // eslint-disable-next-line
                console.error('Error', data);
              }
              const dateStart = new Date();
              const object = {
                dateStart,
                dataTaskask: {
                  ...realTarget,
                  time_tracking: [
                    {
                      ...data2,
                      created_by: currentUser,
                    },
                  ],
                },
              };
              localStorage.setItem('TrackingStartTime', JSON.stringify(object));
              dispatch(getQuickTask({ access_token }));
              dispatch(
                addCurrentTarget({
                  ...realTarget,
                  time_tracking: [
                    {
                      ...data2,
                      created_by: currentUser,
                    },
                  ],
                }),
              );
              setIsActive(true);
            } else {
              setObjection(true);
            }
          }}
        >
          start
        </button>
      ) : (
        <div className="StopWatch-display">
          {hours === 0 ? (
            <span>00</span>
          ) : hours >= 10 ? (
            <span>{hours}</span>
          ) : (
            <span>0{hours}</span>
          )}
          :
          {minutes === 0 ? (
            <span>00</span>
          ) : minutes >= 10 ? (
            <span>{minutes}</span>
          ) : (
            <span>0{minutes}</span>
          )}
          :
          {seconds === 0 ? (
            <span>00</span>
          ) : seconds >= 10 ? (
            <span>{seconds}</span>
          ) : (
            <span>0{seconds}</span>
          )}
        </div>
      )}
    </div>
  ) : (
    <Box
      sx={{
        backgroundColor: '#8945ff',
        padding: '0.44rem 0.88rem',
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
      }}
    >
      <div className="static-display">
        {hours === 0 ? (
          <span>00</span>
        ) : hours >= 10 ? (
          <span>{hours}</span>
        ) : (
          <span>0{hours}</span>
        )}
        :
        {minutes === 0 ? (
          <span>00</span>
        ) : minutes >= 10 ? (
          <span>{minutes}</span>
        ) : (
          <span>0{minutes}</span>
        )}
        :
        {seconds === 0 ? (
          <span>00</span>
        ) : seconds >= 10 ? (
          <span>{seconds}</span>
        ) : (
          <span>0{seconds}</span>
        )}
      </div>
      <PauseIcon />
      |
      <StopIcon
        onClick={() => {
          setIsActive(false);
          dispatch(stopTimer(params));
          setVal(3);
        }}
      />
    </Box>
  );
}

function MbStopWatch({ params, noPlay, setVal, setData }) {
  if (!params) {
    return;
  }

  const prevTracking = useSelector((state) => state.projects.currentTarget);

  const currentUser = useSelector((state) => state.userData.userData);

  const allTasks = useSelector((state) => state.projects.tasks);
  const [realTarget, setRealTarget] = useState({});

  useEffect(() => {
    if (params.id) {
      setRealTarget(allTasks.find((t) => t.id === params.id));
    }
  }, [params]);
  /* create a stop watch with hours minutes and seconds */
  const dispatch = useDispatch();
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const access_token = useSelector((state) => state.auth.user.access);

  const { time_tracking } = params;

  useEffect(() => {
    dispatch(getQuickTask({ access_token }));
  }, [isActive]);

  const [update, setUpdate] = useState({});
  const [tt, setTT] = useState(0);

  useEffect(() => {
    if (Array.isArray(time_tracking) && time_tracking.length) {
      const {
        tracked_time_hours,
        time_tracking_start,
        id,
        time_tracking_stop,
      } = time_tracking[time_tracking.length - 1];
      setTT(id);
      const tracked_time = tracked_time_hours;
      const startedAt = time_tracking_start;

      if (startedAt && !time_tracking_stop) {
        setUpdate({
          ...update,
          time_tracking_start: startedAt,
        });
        const startAt = moment(startedAt);
        const currentTime = moment();
        const duration = moment.duration(currentTime.diff(startAt));
        const totalSeconds = duration.asSeconds();

        setHours(Math.floor(totalSeconds / 3600));
        setMinutes(Math.floor((totalSeconds % 3600) / 60));
        setSeconds(Math.floor(totalSeconds % 60));
      } else if (tracked_time !== '00:00:00' && startedAt !== null) {
        const startAt = new Date(startedAt);
        const currentTime = new Date();
        const totalSeconds = Math.floor((currentTime - startAt) / 1000);

        setSeconds(totalSeconds % 60);
        setMinutes(Math.floor(totalSeconds / 60));
        setHours(Math.floor(totalSeconds / 3600));
      } else {
        setSeconds(0);
        setMinutes(0);
        setHours(0);
      }
    } else {
      // eslint-disable-next-line
      const myFetch = async () => {
        const res = await fetch(`${ENDPOINT}/api/project/task/timetracking/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${access_token}`,
          },
          body: JSON.stringify({
            task_id: params.id,
            time_tracking_start: new Date().toISOString(),
          }),
        });
        if (res.ok) {
          setSeconds(0);
          setMinutes(0);
          setHours(0);
          return 1;
        }
      };
      myFetch();
    }
  }, [time_tracking]);
  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        if (seconds > 58) {
          setSeconds(0);
          setMinutes(minutes + 1);
        } else {
          setSeconds(seconds + 1);
        }
        if (minutes > 58) {
          setMinutes(0);
          setHours(hours + 1);
        }
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds, minutes, hours]);

  // eslint-disable-next-line
  return !noPlay ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        borderRadius: '8px',
        height: '119px',
        padding: '0 1.5rem',
      }}
    >
      {!isActive ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <button
            type="button"
            className="noBorder"
            onClick={async () => {
              if (!prevTracking) {
                const res2 = await fetch(
                  `${ENDPOINT}/api/project/task/timetracking/`,
                  {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `JWT ${access_token}`,
                    },
                    body: JSON.stringify({
                      task_id: params.id,
                      time_tracking_start: new Date().toISOString(),
                    }),
                  },
                );

                const data2 = await res2.json();
                if (!res2.ok) {
                  return;
                }
                dispatch(
                  addCurrentTarget({
                    ...realTarget,
                    time_tracking: [
                      {
                        ...data2,
                        created_by: currentUser,
                      },
                    ],
                  }),
                );
                setIsActive(true);
              } else {
                setIsActive(true);
              }
            }}
          >
            <img src={TimeStart} alt="Start Timer" />
          </button>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              backgroundColor: '#4300b8',
              height: '100%',
              padding: '0.22rem 0.44rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <TimerIcon
              sx={{
                fill: '#fff',
              }}
              onClick={async () => {
                const res = await fetch(
                  `${ENDPOINT}/api/project/task/timetracking/update/${tt}/`,
                  {
                    method: 'PUT',
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `JWT ${access_token}`,
                    },
                    body: JSON.stringify({
                      time_tracking_start: update.time_tracking_start,
                      time_tracking_stop: new Date().toISOString(),
                      note: '',
                    }),
                  },
                );
                if (!res.ok) {
                  setIsActive(false);
                }

                setData({
                  id: tt,
                  time_tracking_start: update.time_tracking_start,
                  time_tracking_stop: new Date().toISOString(),
                  hours,
                  minutes,
                  seconds,
                });
                dispatch(getQuickTask({ access_token }));
                setIsActive(false);
              }}
            />
          </Box>
          <div className="StopWatch-display">
            {hours === 0 ? (
              <span>00</span>
            ) : hours >= 10 ? (
              <span>{hours}</span>
            ) : (
              <span>0{hours}</span>
            )}
            :
            {minutes === 0 ? (
              <span>00</span>
            ) : minutes >= 10 ? (
              <span>{minutes}</span>
            ) : (
              <span>0{minutes}</span>
            )}
            :
            {seconds === 0 ? (
              <span>00</span>
            ) : seconds >= 10 ? (
              <span>{seconds}</span>
            ) : (
              <span>0{seconds}</span>
            )}
          </div>
        </Box>
      )}
    </Box>
  ) : (
    <Box
      sx={{
        backgroundColor: '#8945ff',
        padding: '0.44rem 0.88rem',
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
      }}
    >
      <div className="static-display">
        {hours === 0 ? (
          <span>00</span>
        ) : hours >= 10 ? (
          <span>{hours}</span>
        ) : (
          <span>0{hours}</span>
        )}
        :
        {minutes === 0 ? (
          <span>00</span>
        ) : minutes >= 10 ? (
          <span>{minutes}</span>
        ) : (
          <span>0{minutes}</span>
        )}
        :
        {seconds === 0 ? (
          <span>00</span>
        ) : seconds >= 10 ? (
          <span>{seconds}</span>
        ) : (
          <span>0{seconds}</span>
        )}
      </div>
      <PauseIcon />
      |
      <StopIcon
        onClick={() => {
          setIsActive(false);
          dispatch(stopTimer(params));
          setVal(3);
        }}
      />
    </Box>
  );
}

export { MbStopWatch };
export default StopWatch;
