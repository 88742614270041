const calculateTotalTimeObtained = (timeTrackingArray) => {
  if (
    !timeTrackingArray ||
    !Array.isArray(timeTrackingArray) ||
    timeTrackingArray.length === 0
  ) {
    return 0;
  }

  let totalDuration = 0;

  if (Array.isArray(timeTrackingArray) && timeTrackingArray.length > 0) {
    timeTrackingArray.forEach((item) => {
      // Parse the tracked_time_hours string into hours, minutes, and seconds
      let [hours, minutes, seconds] = item?.tracked_time
        ?.split(':')
        .map(Number);

      // Convert hours, minutes, and seconds into total hours
      let totalSeconds = hours * 3600 + minutes * 60 + seconds;
      let totalHoursForItem = totalSeconds / 3600;

      // Add to the total duration accumulator
      totalDuration += totalHoursForItem;
    });
  }

  return totalDuration;
};

export default calculateTotalTimeObtained;
