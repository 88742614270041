import TextField from '@mui/material/TextField';
import React from 'react';

export function NetnetField({
  onChange,
  value,
  label,
  className,
  type,
  onKeyPress,
  id,
}) {
  return (
    <TextField
      id={id}
      label={label}
      value={value}
      type={type}
      onChange={onChange}
      variant="outlined"
      color="secondary"
      className={className}
      onKeyPress={onKeyPress}
      fullWidth
      sx={{
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#f2ebff',
            padding: '10.6px 14px',
          },
          '&:hover fieldset': {
            borderColor: '#711fff',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#5a0bdf',
          },

          '&.Mui-error fieldset': {
            borderColor: 'red',
          },
          '&.Mui-disabled fieldset': {
            borderColor: 'grey',
          },
        },
        '& .MuiFormLabel-root': {
          color: '#03071e66',
        },
        '& .MuiFormLabel-root.Mui-focused': {
          color: '#5a0bdf',
        },
        '& .MuiFormLabel-root.Mui-error': {
          color: 'red',
        },
      }}
      InputProps={{
        style: { width: '100%' },
      }}
    />
  );
}

export function NetnetField2({
  onChange,
  value,
  label,
  className,
  type,
  onKeyPress,
  placeholder,
  id,
}) {
  return (
    <TextField
      id={id}
      label={label}
      placeholder={placeholder}
      value={value}
      type={type}
      onChange={onChange}
      variant="outlined"
      color="secondary"
      className={className}
      onKeyPress={onKeyPress}
      InputLabelProps={{
        style: {
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          backgroundColor: 'white',
          width: '100%',
        },
      }}
      sx={{
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#ADADAD',
          },
          '&:hover fieldset': {
            borderColor: '#711fff',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#5a0bdf',
          },

          '&.Mui-error fieldset': {
            borderColor: 'red',
          },

          '&.Mui-disabled fieldset': {
            borderColor: 'grey',
          },
        },
        '& .MuiFormLabel-root': {
          color: '#03071e66',
        },
        '& .MuiFormLabel-root.Mui-focused': {
          color: '#5a0bdf',
        },
        '& .MuiFormLabel-root.Mui-error': {
          color: 'red',
        },
      }}
      InputProps={{
        style: {
          lineHeight: 'normal',
          backgroundColor: 'white',
          width: '100%',
        },
      }}
    />
  );
}
