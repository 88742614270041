import { useSelector } from 'react-redux';
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  LabelList,
} from 'recharts';
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Grid,
  Alert,
} from '@mui/material';

import BarCharts from './Utility/BarChart';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { calculateTotalDurationObtained } from '../QuickTasks';

const GetBar = () => {
  const data2 = [
    {
      title: 'Total LOE: ESTIMATE VS ACTUAL',
      estimatedTime: 10,
      actualTime: 8,
    },
    {
      title: 'Total TIMELINE: ESTIMATE VS ACTUAL',
      estimatedTime: 12,
      actualTime: 14,
    },
  ];

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={data2} layout="vertical">
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" />
        <YAxis dataKey="actualTime" type="category" />
        <Bar
          dataKey="estimatedTime"
          stackId="time"
          fill="rgba(94, 230, 144, 0.4)"
          barSize={60}
        >
          <LabelList dataKey="title" position="top" />
        </Bar>
        <Bar dataKey="actualTime" stackId="time" fill="#5ee690" barSize={50}>
          <LabelList dataKey="" position="top" />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

const KPI = ({ dileverable, setDeliverable }) => {
  const token = useSelector((state) => state.auth.user.access);

  const tableData = [
    {
      dent: 'Jobs Delieverables Data Overview',
      data: dileverable.map((item) => {
        const obt =
          Array.isArray(item.task_deliverable) && item.task_deliverable.length
            ? item.task_deliverable.reduce((acc, actual) => {
                return (
                  acc + calculateTotalDurationObtained(actual.time_tracking)
                );
              }, 0)
            : 0;

        return {
          title: item.deliverable_name,
          estimitedTime: item.deliverable_worktype.reduce((acc, item) => {
            return acc + Number(item.hours);
          }, 0),
          elapsedTime: Math.round(obt),
        };
      }),
    },
    {
      should: true,
      dent: 'TOTAL BY WORKTYPES ASSESSMENT',
      data: dileverable.map((dat) => {
        const netReturn = dat.deliverable_worktype.map((wk) => {
          const filteredReturns =
            Array.isArray(dat.task_deliverable) && dat.task_deliverable.length
              ? dat.task_deliverable.filter((eachTak) => {
                  if (
                    !Array.isArray(eachTak.task_worktype) ||
                    !eachTak.task_worktype.length
                  ) {
                    return false;
                  }
                  return eachTak.task_worktype.some(
                    (wa) => Number(wa.id) === Number(wk.id)
                  );
                })
              : [];
          const realObt =
            Array.isArray(filteredReturns) && filteredReturns.length
              ? filteredReturns.reduce((acc, eachReturn) => {
                  return (
                    acc +
                    calculateTotalDurationObtained(eachReturn.time_tracking)
                  );
                }, 0)
              : 0;
          return {
            title: wk.name,
            estimitedTime: Number(wk.hours),
            elapsedTime: Math.round(realObt),
          };
        });
        return netReturn;
      }),
    },
    {
      def: true,
      dent: 'WORKTYPES BY DELIEVERABLE ASSESSMENT',
      data: dileverable.map((ent) => {
        const realWork = ent.deliverable_worktype.map((wt) => {
          return {
            head_name: ent.deliverable_name,
            title: wt.name,
            estimitedTime: Number(wt.hours),
            elapsedTime: 1,
          };
        });
        return realWork;
      }),
    },
    {
      dent: 'TEAM MEMBER ASSESSMENT',
    },
  ];

  return (
    <div
      className="main-container"
      style={{
        background: '#fff',
        borderRadius: '10px',
        padding: '21px',
        width: '100%',
      }}
    >
      <Grid container direction="column" alignItems="flex-start">
        <Typography
          sx={{
            fontFamily: 'Open Sans',
            fontWeight: '600',
            fontSize: '1.3rem',
            color: '#000',
          }}
        >
          OVERALL JOBS PROCESS
        </Typography>
        <GetBar />
      </Grid>

      <div className="kpi-middle-container">
        <div className="kpi-middle">
          <hr />
          <div className="kpi-barchart"></div>
          {tableData.map((item, nt) => {
            return (
              <Accordion
                disableGutters={true}
                elevation={0}
                sx={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'column',
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  id={nt + 1}
                  sx={{
                    width: '100%',
                    '&MuiAccordionSummary-content': {
                      justifyContent: 'space-between',
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: '#310085',
                      fontWeight: '600',
                      fontFamily: 'Open Sans',
                      fontSize: '1.2rem',
                      textTransform: 'uppercase',
                      m: '0 1rem',
                    }}
                  >
                    {item.dent}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {Array.isArray(item.data) && item.data.length && !item.def ? (
                    <BarCharts
                      data={item.should ? item.data[0] : item.data}
                      isUnit={true}
                      isLegend={true}
                    />
                  ) : item.def &&
                    Array.isArray(item.data) &&
                    item.data.length ? (
                    item.data.map((da) => {
                      return (
                        <>
                          <Typography
                            sx={{ fontWeight: '600', fontSize: '0.9rem' }}
                          >
                            {da[0].head_name}
                          </Typography>
                          <BarCharts data={da} isUnit={true} isLegend={true} />
                        </>
                      );
                    })
                  ) : (
                    <Alert severity="info">No KPI's yet</Alert>
                  )}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default KPI;