import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Bar,
  LabelList,
  Cell,
} from 'recharts';
import { getColor } from './helper';
import { legened } from './Legend';

const BarCharts = ({ data, isUnit = false, isLegend = false }) => {
  return (
    <ResponsiveContainer width="100%" height={450} className="bar-chart">
      <BarChart
        barGap={-55}
        width={500}
        height={400}
        data={data}
        margin={{ top: 25 }}
      >
        <CartesianGrid vertical={false} strokeDasharray="3 3" />
        <XAxis dataKey="title" allowDataOverflow={true} />
        {isUnit ? (
          <YAxis
            unit="Hours"
            axisLine={false}
            dataKey="estimitedTime"
            padding={{ top: 10, left: 10, right: 10 }}
          />
        ) : (
          <YAxis axisLine={false} />
        )}
        {isLegend ? <Legend align="left" content={legened} /> : null}
        <Bar
          dataKey="estimitedTime"
          barSize={60}
          fill="rgba(94, 230, 144, 0.4)"
        >
          <LabelList
            dataKey={(value) => {
              if (value.estimitedTime >= value.elapsedTime)
                return `${value.elapsedTime} / ${value.estimitedTime}`;
            }}
            position="top"
          />
        </Bar>

        <Bar dataKey="elapsedTime" barSize={50} fill="#5ee690" maxBarSize={60}>
          {data.map((each, index) => {
            return (
              <Cell
                key={`cell-${index}`}
                fill={getColor(each.elapsedTime, each.estimitedTime)}
              />
            );
          })}
          <LabelList
            dataKey={(value) => {
              if (value.elapsedTime > value.estimitedTime)
                return `${value.elapsedTime} / ${value.estimitedTime}`;
            }}
            position="top"
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};
export default BarCharts;
