import React, { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Box from '@mui/material/Box';
import { Skeleton, Typography } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import './stream.css';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { EditorState } from 'draft-js';
import { useSelector } from 'react-redux';
import RichEditor from '../../Componenets/RichEditor/RichEditor';
import PreLoader, { DirectReplyButton, ReplyButton } from '../../Componenets/Misc';
import he from 'he';
import { ENDPOINT } from '../Opportunity/Redux/oppor';
import uuid from 'react-uuid';
import Bounce from 'react-reveal/Bounce';

const groupByDate = (chats) => {
  const groupedChats = chats.reduce((acc, chat) => {
    const date =
      new Date(chat.created).getDate() === new Date().getDate()
        ? 'today'
        : new Date(chat.created).toLocaleDateString([], {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        });
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(chat);
    return acc;
  }, {});
  return Object.entries(groupedChats);
};

const ChatBody = ({
  Projects,
  setCurrentTarget,
  currentTargetOfEstimate,
  setUserMsg,
  page,
  chatData,
  currentTarget,
  userMsg,
  parentCall,
  localGeneral,
  localProjectState,
  setLocalProjectState,
  mentionState,
  setLocalGeneral,
  search,
  estimateMsg,
  setEstimateMessage,
  projectTeam,
  project_deliverable,
  messageScroll,
  screenHeight,
  proLoading,
  estimateProLoading,
}) => {
  const [responseBox, setResponseBox] = useState(null);


  const { userData } = useSelector((state) => state.userData);


  // useEffect(() => {
  //   if (messageScroll) {

  //     const messageId = messageScroll.message.id;
  //     console.error(messageId, "messagehere")
  //     const index = messageScroll.message_in_channel.findIndex(item => item.id === messageId);
  //     console.error(index, 'index')
  //     if (index !== -1) {
  //       // Scroll to the message
  //       const element = document.getElementById(`message-${messageId}`);
  //       console.error(element, 'Target')

  //       if (element) {
  //         element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  //         element.style.transition = 'background-color 5s ease';
  //         element.style.backgroundColor = 'red'; 


  //         setTimeout(() => {
  //           element.style.backgroundColor = ''; 
  //         }, 3000);
  //       }
  //     }
  //   }
  // }, [messageScroll,   currentTarget, page]).





  useEffect(() => {
    if (messageScroll?.message) {
      const messageId = messageScroll?.message.id;
      const index = messageScroll?.message_in_channel?.findIndex(item => item.id === messageId);
      if (index !== -1) {
        // Scroll to the message
        const element = document.getElementById(`message-${messageId}`);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });

          // Call the function to apply background color transition
          applyBackgroundColorTransition(`message-${messageId}`);
        }
      }
    }
  }, [messageScroll, page, currentTarget]);


  const applyBackgroundColorTransition = (elementId) => {


    const element = document.getElementById(elementId);
    if (element) {

      element.style.backgroundColor = '#711fff';

      setTimeout(() => {
        element.style.backgroundColor = '';
      }, 1000);
    }
  };

  const userTeam = useSelector((state) => state?.userTeam?.team?.member);
  const formatDate = (messageDate, previousDate) => {
    const currentDate = new Date();
    const messageDateTime = new Date(messageDate);

    // Check if the message date is today
    if (
      messageDateTime.getDate() === currentDate.getDate() &&
      messageDateTime.getMonth() === currentDate.getMonth() &&
      messageDateTime.getFullYear() === currentDate.getFullYear()
    ) {
      if (previousDate === '') {
        return 'Today';
      }
    }

    // Check if the message date is yesterday
    const yesterday = new Date(currentDate);
    yesterday.setDate(yesterday.getDate() - 1);
    if (
      messageDateTime.getDate() === yesterday.getDate() &&
      messageDateTime.getMonth() === yesterday.getMonth() &&
      messageDateTime.getFullYear() === yesterday.getFullYear()
    ) {
      return 'Yesterday';
    }

    // Return the formatted date for other cases
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return messageDateTime.toLocaleDateString(undefined, options);
  };

  // Usage:
  const previousDate = useRef('');


  function decodeHtmlEntities(html) {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.documentElement.textContent;
  }


  const userM =
    userMsg &&
    userMsg.filter((p) =>
      decodeHtmlEntities(p.message)
        .toLowerCase()
        .startsWith(search.toLowerCase())
    );
  const newUserMessages = search ? userM : userMsg;



  const generalMsg = localGeneral.filter((p) =>
    decodeHtmlEntities(p.message).toLowerCase().startsWith(search.toLowerCase())
  );
  const generalFilter = search ? generalMsg : generalMsg;






  generalFilter.sort((a, b) => {
    // Assuming 'created_by' is a string field, modify the comparison logic for ascending order
    if (a.created > b.created) return 1; // Return 1 to sort 'b' before 'a'
    if (a.created < b.created) return -1; // Return -1 to sort 'a' before 'b'
    return 0; // Return 0 if 'a' and 'b' are equal
  });

  const allChats = Array.isArray(chatData) && chatData.length && chatData?.map((p) => {
    return {
      ...p,
      message_in_channel: p.message_in_channel.filter((i) => {
        return decodeHtmlEntities(i.message)
          .toLowerCase()
          .startsWith(search.toLowerCase());
      }),
    };
  });

  const newChatData1 = search ? allChats : chatData;
  const newChatData = newChatData1?.filter((item) => item?.deliverable === null)
  const projectMsg = localProjectState?.filter(item => decodeHtmlEntities(item?.message).toLocaleLowerCase().startsWith(search?.toLowerCase()))
  const newLocalProjectFiltered = search ? projectMsg : localProjectState
  const newLocalProject = newLocalProjectFiltered?.sort((a, b) => new Date(a?.created) - new Date(b?.created))
  const mentionsChat = mentionState.filter(item => decodeHtmlEntities(item.message).toLowerCase().startsWith(search.toLowerCase()))
  const newMentionState = search ? mentionsChat : mentionState


  const channal = Array.isArray(chatData) && chatData.length && chatData?.filter(msg => {
    return mentionsChat.some(mention => mention?.channel === msg?.id);
  });

  let channalData = null

  if (channal?.length) {
    channalData = channal
  }


  const getMostRecentMessages = (messages) => {
    // Sort messages by created date in descending order
    const sortedMessages = messages?.sort((a, b) => new Date(b.created) - new Date(a.created));

    const seen = new Set();
    return sortedMessages?.filter(msg => {
      const receiverId = msg?.receiver?.id;
      if (seen?.has(receiverId)) {
        return false;
      } else {
        seen?.add(receiverId);
        return true;
      }
    });
  };

  const mostRecentMessages = getMostRecentMessages(userMsg);

  return (
    <>
      {page === 'estimate' && (

        estimateProLoading ?
          <Box sx={{
            height: screenHeight - 400,
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column-reverse',
            padding: '10px',
            gap: '1rem'
          }}>
            {
              [1, 3, 2, 2, 3, 4, 5, 6, 7, 8].map((item) => {
                return (
                  <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                    <Skeleton animation="wave" variant="circular" width={40} height={40} />
                    <Box sx={{ width: '80%', display: 'flex', flexDirection: 'column', gap: '5px', marginLeft: '5px', justifyContent: 'center' }}>
                      <Skeleton variant="rectangular" width={'25%'} height={10} />
                      <Skeleton variant="rectangular" width={'60%'} height={10} />
                    </Box>
                  </Box>
                )
              })
            }
          </Box>
          : <Grid
            item
            container
            sx={{
              height: screenHeight - 400,
              overflowY: 'auto',
              display: 'flex',
              flexDirection: 'column-reverse',
            }}
            className="chating"
          >
            {estimateMsg?.length > 0 ? (
              <div className="d-flex flex-column">
                <Bounce bottom>
                  <List sx={{ width: '100%' }}>
                    {estimateMsg
                      .sort(
                        (a, b) => new Date(a.created) - new Date(b.created)
                      )
                      .map((item, idx) => (
                        <Box >
                          {idx === 0 && (
                            <div
                              className="w-100 py-1 bg-light rounded text-center"
                              style={{ fontSize: '12px' }}
                            >
                              {new Date(item.created).toLocaleDateString([], {
                                day: 'numeric',
                                month: 'long',
                                year: 'numeric',
                              })}
                            </div>
                          )}


                          <ListItem key={item.id} id={`message-${item.id}`} >
                            <ListItemAvatar>
                              <Avatar
                                alt={item?.sender?.first_name ? item?.sender?.first_name : null}
                                src={item?.sender?.avatar ? item?.sender?.avatar : "/static/images/avatar/1.jpg"}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="subtitle2"
                                  sx={{
                                    color: '#03071E',
                                    letterSpacing: '-0.3px',
                                    fontWeight: 'bold',
                                    fontSize: '1rem',
                                    fontFamily: 'Open Sans',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '0.5rem',
                                    opacity: item?.tempId ? 0.5 : 1,
                                  }}
                                >
                                  {item?.sender?.first_name}

                                  <p
                                    style={{
                                      color: 'rgba(3, 7, 30, 0.4)',
                                      letterSpacing: '-0.3px',
                                      fontSize: '11px',
                                      textAlign: 'left',
                                      fontFamily: 'Open Sans',
                                      marginBottom: '0',
                                      opacity: item?.tempId ? 0.5 : 1,
                                    }}
                                  >
                                    {!item?.tempId ? `${new Date(item.created).toLocaleTimeString(
                                      [],
                                      {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        hour12: true,
                                      }
                                    )}, ${new Date(item.created).toDateString()}` : 'Sending'}
                                  </p>
                                </Typography>
                              }
                              secondary={
                                <div
                                  style={{
                                    fontWeight: item.seen ? '400' : '600',
                                    fontSize: '14px',
                                    lineHeight: '22px',
                                    fontStyle: 'normal',
                                    color: item.seen ? '#03071E' : '#000',
                                    opacity: item?.tempId ? 0.5 : 1,
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: item.message,
                                  }}
                                />
                              }
                            />
                          </ListItem>
                          {search ? null : (
                            <div className="ms-5 ps-1">
                              {responseBox === item.id ? (
                                <div className="response-dropdown">
                                  {item?.reply_to_chatmesage?.map((cv, int) => {
                                    return (
                                      <div className="ms-3 mb-3 d-flex align-items-start" key={cv.id}>
                                        <Avatar
                                          alt={cv?.sender?.first_name}
                                          src={cv?.sender?.avatar ? cv?.sender?.avatar : "/static/images/avatar/1.jpg"}
                                          className="me-2"
                                        />
                                        <div>
                                          <div className="d-flex align-items-center pt-2">
                                            <p
                                              className="fw-bold mb-0 me-2"
                                              style={{
                                                fontSize: '14px',
                                              }}
                                            >
                                              {cv?.sender?.first_name ?? ''}
                                            </p>
                                            <p
                                              style={{
                                                color: 'rgba(3, 7, 30, 0.4)',
                                                letterSpacing: '-0.3px',
                                                fontSize: '11px',
                                                textAlign: 'left',
                                                fontFamily: 'Open Sans',
                                              }}
                                            >
                                              {new Date().toLocaleTimeString([], {
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                hour12: true,
                                              })}
                                              , {new Date().toDateString()}
                                            </p>
                                          </div>
                                          <p
                                            style={{
                                              fontWeight: '400',
                                              fontSize: '14px',
                                              lineHeight: '22px',
                                              fontStyle: 'normal',
                                              color: '#03071E',
                                            }}
                                            dangerouslySetInnerHTML={{
                                              __html: cv?.message ?? '',
                                            }}
                                          ></p>
                                        </div>
                                      </div>
                                    );
                                  })}

                                  <ReplyButton
                                    project_deliverable={project_deliverable}
                                    currentTargetOfEstimate={currentTargetOfEstimate}
                                    setEstimateMessage={setEstimateMessage}
                                    estimateMsg={estimateMsg}
                                    projectTeam={projectTeam}
                                    setLocalProjectState={setLocalProjectState}
                                    userData={userData}
                                    setUserMsg={setUserMsg}
                                    userMsg={userMsg}
                                    setCurrentTarget={setCurrentTarget}
                                    currentTarget={currentTarget}
                                    channel_id={item}
                                    page={page}
                                  />
                                  <button
                                    className="response-btn"
                                    onClick={() => {
                                      setResponseBox(false);
                                    }}
                                  >
                                    Hide Responses
                                  </button>
                                </div>
                              ) : (
                                <>
                                  {item?.reply_to_chatmesage?.length && item?.reply_to_chatmesage?.length > 0 ? (
                                    <div className="respose">
                                      <AvatarGroup
                                        className="response-avatar"
                                        max={3}
                                        sx={{
                                          justifyContent: 'flex-end',
                                          paddingLeft: '15px',
                                        }}
                                      >
                                        {Array.isArray(item?.reply_to_chatmesage?.length) &&
                                          item?.reply_to_chatmesage?.length
                                          ? [
                                            ...new Set(
                                              item?.reply_to_chatmesage.map((va) =>
                                                JSON.stringify(va.sender)
                                              )
                                            ),
                                          ]
                                            .map((la) => JSON.parse(la))
                                            .map((per) => {
                                              return (
                                                <Avatar
                                                  key={per.id}
                                                  src={per.avatar ? per.avatar : "/static/images/avatar/1.jpg"}
                                                  alt={per.first_name}
                                                />
                                              );
                                            })
                                          : null}
                                      </AvatarGroup>

                                      <button
                                        className="response-btn"
                                        onClick={() => {
                                          setResponseBox(item.id);
                                        }}
                                      >
                                        <span className="me-1">
                                          {item?.reply_to_chatmesage?.length + ' ' + 'Responses'}
                                        </span>
                                      </button>
                                    </div>
                                  ) : (
                                    <div className="response-dropdown">
                                      <DirectReplyButton
                                        project_deliverable={project_deliverable}
                                        setEstimateMessage={setEstimateMessage}
                                        currentTargetOfEstimate={currentTargetOfEstimate}
                                        projectTeam={projectTeam}
                                        setLocalProjectState={setLocalProjectState}
                                        userData={userData}
                                        setUserMsg={setUserMsg}
                                        userMsg={userMsg}
                                        setCurrentTarget={setCurrentTarget}
                                        currentTarget={currentTarget}
                                        channel_id={item}
                                        page={page}
                                      />
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          )}
                        </Box>
                      ))}
                  </List>
                </Bounce>
              </div>
            ) : (
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: '100%',
                  height: '100%',
                }}
              >
                <Bounce bottom>
                  <Box sx={{ textAlign: 'center' }}>
                    <Grid item>
                      <p className="sub-heading">No messages yet</p>
                    </Grid>
                    <Grid item>
                      <p className="fstxt">
                        Start typing and share your thoughts with your team. Press "@"
                        to mention someone directly.
                      </p>
                    </Grid>
                  </Box>
                </Bounce>
              </Grid>
            )}
          </Grid>

      )}

      {
        page === 'allconversation' && (

          <Grid
            item
            sx={{
              overflowY: 'scroll',
              '::-webkit-scrollbar': { display: 'none' },
              height: screenHeight - 290,
              overflowY: 'auto',

            }}
          >
            {Array.isArray(mostRecentMessages) && mostRecentMessages.length > 0 ? (
              groupByDate(mostRecentMessages).map(([date, chats], chatIdx) => {

                return (
                  <Bounce bottom key={date}>
                    <List
                      sx={{
                        width: '100%',
                        paddingBottom: 0,
                        paddingTop: 0
                      }}
                    >
                      {chats[chatIdx]?.message && Array.isArray(chats[chatIdx]?.message) && chats[chatIdx].message.length ? (
                        <div
                          className="w-100 py-1 bg-light rounded text-center"
                          style={{ fontSize: '12px' }}
                        >
                          {date}
                        </div>
                      ) : null}
                      {chats.map((chat, idx) => (
                        chat.message.length > 0 ? (
                          <React.Fragment key={chat.id}>
                            <li
                              style={{
                                display: 'flex',
                                width: '100%',
                                alignItems: 'center',
                              }}
                            >
                              <Divider
                                variant="inset"
                                component="li"
                                textAlign="left"
                                sx={{
                                  width: { xs: '76%', sm: '76%', md: '100%' },
                                  ml: 3,
                                  margin: 'auto',
                                }}
                              >
                                <Typography
                                  variant="caption"
                                  sx={{
                                    color: 'rgba(3, 7, 30, 0.6)',
                                    letterSpacing: '-0.3px',
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    fontFamily: 'Open Sans',
                                  }}
                                >
                                  {chat.receiver.first_name + " " + chat.receiver.last_name}
                                </Typography>
                              </Divider>
                              <Typography
                                variant="caption"
                                sx={{
                                  color: '#00E6B8',
                                  cursor: 'pointer',
                                  position: 'absolute',
                                  right: '0px',
                                  background: 'white',
                                }}
                                onClick={() =>
                                  parentCall({
                                    ...chat,
                                    title: chat.name ? chat.name : '',
                                  })
                                }
                              >
                                Go to Chat <ArrowRightAltIcon />
                              </Typography>
                            </li>
                            <Box key={chat.id}>
                              <ListItem
                                alignItems="flex-start"
                                sx={{ paddingBottom: 0 }}
                              >
                                <ListItemAvatar>
                                  <Avatar
                                    alt={chat.sender.first_name}
                                    src={chat.sender.avatar || "/static/images/avatar/1.jpg"}
                                  />
                                </ListItemAvatar>
                                <ListItemText
                                  primary={
                                    <Typography
                                      variant="subtitle2"
                                      sx={{
                                        color: '#03071E',
                                        letterSpacing: '-0.3px',
                                        fontWeight: '700',
                                        fontSize: '14px',
                                        fontFamily: 'Open Sans',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '0.5rem',
                                      }}
                                    >
                                      {chat.sender.first_name}
                                      <p
                                        style={{
                                          color: 'rgba(3, 7, 30, 0.4)',
                                          letterSpacing: '-0.3px',
                                          fontSize: '11px',
                                          fontWeight: '400',
                                          textAlign: 'left',
                                          fontFamily: 'Open Sans',
                                          marginBottom: '0',
                                        }}
                                      >
                                        {`${new Date(chat.created).toLocaleTimeString([], {
                                          hour: '2-digit',
                                          minute: '2-digit',
                                          hour12: true,
                                        })}, ${new Date(chat.created).toDateString()}`}
                                      </p>
                                    </Typography>
                                  }
                                  secondary={
                                    <div
                                      style={{
                                        fontWeight: chat.seen ? '400' : '600',
                                        fontSize: '14px',
                                        lineHeight: '22px',
                                        fontStyle: 'normal',
                                        color: chat.seen ? '#03071E' : '#000',
                                      }}
                                      dangerouslySetInnerHTML={{ __html: chat.message }}
                                    />
                                  }
                                />
                              </ListItem>
                              {search ? null : (
                                <div className="ms-5 ps-1">
                                  {responseBox === chat.id ? (
                                    <div className="response-dropdown">
                                      {chat?.reply_to_dm?.map((cv, int) => {
                                        return (
                                          <div className="ms-3 mb-3 d-flex align-items-start" key={cv.id}>
                                            <Avatar
                                              alt={cv?.sender?.first_name}
                                              src={cv?.sender?.avatar ? cv?.sender?.avatar : "/static/images/avatar/1.jpg"}
                                              className="me-2"
                                            />
                                            <div>
                                              <div className="d-flex align-items-center pt-2">
                                                <p
                                                  className="fw-bold mb-0 me-2"
                                                  style={{
                                                    fontSize: '14px',
                                                  }}
                                                >
                                                  {cv?.sender?.first_name ?? ''}
                                                </p>
                                                <p
                                                  style={{
                                                    color: 'rgba(3, 7, 30, 0.4)',
                                                    letterSpacing: '-0.3px',
                                                    fontSize: '11px',
                                                    textAlign: 'left',
                                                    fontFamily: 'Open Sans',
                                                  }}
                                                >
                                                  {new Date().toLocaleTimeString([], {
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hour12: true,
                                                  })}
                                                  , {new Date().toDateString()}
                                                </p>
                                              </div>
                                              <p
                                                style={{
                                                  fontWeight: '400',
                                                  fontSize: '14px',
                                                  lineHeight: '22px',
                                                  fontStyle: 'normal',
                                                  color: '#03071E',
                                                }}
                                                dangerouslySetInnerHTML={{
                                                  __html: cv?.message ?? '',
                                                }}
                                              ></p>
                                            </div>
                                          </div>
                                        );
                                      })}

                                      <ReplyButton
                                        project_deliverable={project_deliverable}
                                        currentTargetOfEstimate={currentTargetOfEstimate}
                                        projectTeam={projectTeam}
                                        setLocalProjectState={setLocalProjectState}
                                        userData={userData}
                                        setUserMsg={setUserMsg}
                                        userMsg={userMsg}
                                        setCurrentTarget={setCurrentTarget}
                                        currentTarget={currentTarget}
                                        channel_id={chat}
                                        page={page}
                                      />
                                      <button
                                        className="response-btn"
                                        onClick={() => {
                                          setResponseBox(false);
                                        }}
                                      >
                                        Hide Responses
                                      </button>
                                    </div>
                                  ) : (
                                    <>
                                      {chat?.reply_to_dm?.length && chat?.reply_to_dm?.length > 0 ? (
                                        <div className="respose">
                                          <AvatarGroup
                                            className="response-avatar"
                                            max={3}
                                            sx={{
                                              justifyContent: 'flex-end',
                                              paddingLeft: '15px',
                                            }}
                                          >
                                            {Array.isArray(chat?.reply_to_dm?.length) &&
                                              chat?.reply_to_dm?.length
                                              ? [
                                                ...new Set(
                                                  chat?.reply_to_dm.map((va) =>
                                                    JSON.stringify(va.sender)
                                                  )
                                                ),
                                              ]
                                                .map((la) => JSON.parse(la))
                                                .map((per) => {
                                                  return (
                                                    <Avatar
                                                      key={per.id}
                                                      src={per.avatar ? per.avatar : "/static/images/avatar/1.jpg"}
                                                      alt={per.first_name}
                                                    />
                                                  );
                                                })
                                              : null}
                                          </AvatarGroup>

                                          <button
                                            className="response-btn"
                                            onClick={() => {
                                              setResponseBox(chat.id);
                                            }}
                                          >
                                            <span className="me-1">
                                              {chat?.reply_to_dm?.length + ' ' + 'Responses'}
                                            </span>
                                          </button>
                                        </div>
                                      ) : (
                                        <div className="response-dropdown">
                                          <DirectReplyButton
                                            project_deliverable={project_deliverable}
                                            currentTargetOfEstimate={currentTargetOfEstimate}
                                            projectTeam={projectTeam}
                                            setLocalProjectState={setLocalProjectState}
                                            userData={userData}
                                            setUserMsg={setUserMsg}
                                            userMsg={userMsg}
                                            setCurrentTarget={setCurrentTarget}
                                            currentTarget={currentTarget}
                                            channel_id={chat}
                                            page={page}
                                          />
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                              )}
                            </Box>
                          </React.Fragment>
                        ) : null
                      ))}
                    </List>
                  </Bounce>
                );
              })
            ) : (
              ""
            )}

            {Array.isArray(newChatData) && newChatData.length ? (
              groupByDate(newChatData).map(([date, chats], chatIdx) => (
                <Bounce bottom key={date}>
                  <List
                    sx={{
                      width: '100%',
                      paddingBottom: 0,
                      paddingTop: 0
                    }}
                  >
                    {chats[chatIdx]?.message_in_channel &&
                      Array.isArray(chats[chatIdx].message_in_channel) &&
                      chats[chatIdx].message_in_channel.length ? (
                      <div
                        className="w-100 py-1 bg-light rounded text-center"
                        style={{ fontSize: '12px' }}
                      >
                        {date}
                      </div>
                    ) : null}
                    {chats.map((chat, idx) => {

                      return chat.message_in_channel.length > 0 ? (
                        <React.Fragment key={chat.id}>
                          <li
                            style={{
                              display: 'flex',
                              width: '100%',
                              alignItems: 'center',
                            }}
                          >
                            <Divider
                              variant="inset"
                              component="li"
                              textAlign="left"
                              sx={{
                                width: { xs: '76%', sm: '76%', md: '100%' },
                                ml: 3,
                                margin: 'auto',
                              }}
                            >
                              <Typography
                                variant="caption"
                                sx={{
                                  color: 'rgba(3, 7, 30, 0.6)',
                                  letterSpacing: '-0.3px',
                                  fontSize: '14px',
                                  fontWeight: '400',
                                  fontFamily: 'Open Sans',
                                }}
                              >
                                {chat?.name?.trim() === `${userData?.first_name?.trim()}'s Team` ? "General Chat" : chat?.name}
                              </Typography>
                            </Divider>
                            <Typography
                              variant="caption"
                              sx={{
                                color: '#00E6B8',
                                cursor: 'pointer',
                                position: 'absolute',
                                right: '0px',
                                background: 'white',
                              }}
                              onClick={() =>
                                parentCall({
                                  ...chat,
                                  title: chat.name ? chat.name : '',
                                })
                              }
                            >
                              Go to Chat <ArrowRightAltIcon />
                            </Typography>
                          </li>
                          <Box key={chat.id}>
                            {chat.message_in_channel.length > 0 ? (
                              <>
                                <ListItem
                                  alignItems="flex-start"
                                  sx={{ paddingBottom: 0 }}
                                >
                                  <ListItemAvatar>
                                    <Avatar
                                      alt={
                                        chat.message_in_channel.length > 0
                                          ? chat.message_in_channel[0].sender
                                            .first_name
                                          : null
                                      }
                                      src={
                                        chat.message_in_channel.length > 0 && chat.message_in_channel[0].sender.avatar
                                          ? chat.message_in_channel[0].sender.avatar
                                          : "/static/images/avatar/1.jpg"
                                      }
                                    />
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={
                                      <Typography
                                        variant="subtitle2"
                                        sx={{
                                          color: '#03071E',
                                          letterSpacing: '-0.3px',
                                          fontWeight: '700',
                                          fontSize: '14px',
                                          fontFamily: 'Open Sans',
                                          display: 'flex',
                                          alignItems: 'center',
                                          gap: '0.5rem',
                                        }}
                                      >
                                        {chat.message_in_channel.length > 0
                                          ? chat.message_in_channel[0].sender
                                            .first_name
                                          : null}
                                        <p
                                          style={{
                                            color: 'rgba(3, 7, 30, 0.4)',
                                            letterSpacing: '-0.3px',
                                            fontSize: '11px',
                                            fontWeight: '400',
                                            textAlign: 'left',
                                            fontFamily: 'Open Sans',
                                            marginBottom: '0',
                                          }}
                                        >
                                          {`${new Date(
                                            chat.message_in_channel[0].created
                                          ).toLocaleTimeString([], {
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: true,
                                          })}, ${new Date(
                                            chat.message_in_channel[0].created
                                          ).toDateString()}`}
                                        </p>
                                      </Typography>
                                    }
                                    secondary={
                                      <div
                                        style={{
                                          fontWeight: chat.message_in_channel[0].seen
                                            ? '400'
                                            : '600',
                                          fontSize: '14px',
                                          lineHeight: '22px',
                                          fontStyle: 'normal',
                                          color: chat.message_in_channel[0].seen
                                            ? '#03071E'
                                            : '#000',
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            chat.message_in_channel[0].message,
                                        }}
                                      />
                                    }
                                  />
                                </ListItem>
                                {search ? null : (
                                  <div className="ms-5 ps-1">
                                    {responseBox === chatIdx ? (
                                      <div className="response-dropdown">
                                        {chat?.message_in_channel[0]?.reply_to_chatmesage?.map((cv, int) => {

                                          return (
                                            <div className="ms-3 mb-3 d-flex align-items-start" key={cv.id}>
                                              <Avatar
                                                alt={cv?.sender?.first_name}
                                                src={cv?.sender?.avatar ? cv?.sender?.avatar : "/static/images/avatar/1.jpg"}
                                                className="me-2"
                                              />
                                              <div>
                                                <div className="d-flex align-items-center pt-2">
                                                  <p
                                                    className="fw-bold mb-0 me-2"
                                                    style={{
                                                      fontSize: '14px',
                                                    }}
                                                  >
                                                    {cv?.sender?.first_name ?? ''}
                                                  </p>
                                                  <p
                                                    style={{
                                                      color: 'rgba(3, 7, 30, 0.4)',
                                                      letterSpacing: '-0.3px',
                                                      fontSize: '11px',
                                                      textAlign: 'left',
                                                      fontFamily: 'Open Sans',
                                                    }}
                                                  >
                                                    {new Date().toLocaleTimeString(
                                                      [],
                                                      {
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                        hour12: true,
                                                      }
                                                    )}
                                                    , {new Date().toDateString()}
                                                  </p>
                                                </div>
                                                <p
                                                  style={{
                                                    fontWeight: '400',
                                                    fontSize: '14px',
                                                    lineHeight: '22px',
                                                    fontStyle: 'normal',
                                                    color: '#03071E',
                                                  }}
                                                  dangerouslySetInnerHTML={{
                                                    __html: cv?.message ?? '',
                                                  }}
                                                ></p>
                                              </div>
                                            </div>
                                          );
                                        })}
                                        <ReplyButton
                                          project_deliverable={project_deliverable}
                                          currentTargetOfEstimate={currentTargetOfEstimate}
                                          projectTeam={projectTeam}
                                          setLocalProjectState={setLocalProjectState}
                                          userData={userData}
                                          setUserMsg={setUserMsg}
                                          userMsg={userMsg}
                                          setCurrentTarget={setCurrentTarget}
                                          currentTarget={currentTarget}
                                          channel_id={chat}
                                          page={page}
                                        />
                                        <button
                                          className="response-btn"
                                          onClick={() => {
                                            setResponseBox(false);
                                          }}
                                        >
                                          Hide Responses
                                        </button>
                                      </div>
                                    ) : (
                                      <>
                                        {chat?.message_in_channel[0]?.reply_to_chatmesage?.length && chat?.message_in_channel[0]?.reply_to_chatmesage?.length > 0 ? (
                                          <div className="respose">
                                            <AvatarGroup
                                              className="response-avatar"
                                              max={3}
                                              sx={{
                                                justifyContent: 'flex-end',
                                                paddingLeft: '15px',
                                              }}
                                            >
                                              {Array.isArray(chat?.message_in_channel[0]?.reply_to_chatmesage) &&
                                                chat?.message_in_channel[0]?.reply_to_chatmesage.length
                                                ? [
                                                  ...new Set(
                                                    chat?.message_in_channel[0]?.reply_to_chatmesage.map(
                                                      (va) =>
                                                        JSON.stringify(va.sender)
                                                    )
                                                  ),
                                                ]
                                                  .map((la) => JSON.parse(la))

                                                  .map((per) => {
                                                    console.error(per, "ReplySender");
                                                    return (
                                                      <Avatar
                                                        key={per?.id}
                                                        src={per?.avatar ? per.avatar : "/static/images/avatar/1.jpg"}
                                                        alt={per?.first_name}
                                                      />
                                                    );
                                                  })
                                                : null}
                                            </AvatarGroup>
                                            <button
                                              className="response-btn"
                                              onClick={() => {
                                                setResponseBox(chatIdx);
                                                console.error(chat?.message_in_channel[0]?.reply_to_chatmesage?.length, "CheckingLength")
                                              }}
                                            >
                                              <span className="me-1">
                                                {chat?.message_in_channel[0]?.reply_to_chatmesage?.length +
                                                  ' ' +
                                                  'Responses'}
                                              </span>
                                            </button>
                                          </div>
                                        ) : (
                                          <div className="response-dropdown">
                                            <DirectReplyButton
                                              project_deliverable={project_deliverable}
                                              currentTargetOfEstimate={currentTargetOfEstimate}
                                              projectTeam={projectTeam}
                                              setLocalProjectState={setLocalProjectState}
                                              userData={userData}
                                              setUserMsg={setUserMsg}
                                              userMsg={userMsg}
                                              setCurrentTarget={setCurrentTarget}
                                              currentTarget={currentTarget}
                                              channel_id={chat}
                                              page={page}
                                            />
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </div>
                                )}
                              </>
                            ) : (
                              <Typography
                                sx={{
                                  color: '#03071E',
                                  letterSpacing: '-0.3px',
                                  fontWeight: '600',
                                  fontSize: '14px',
                                  fontFamily: 'Open Sans',
                                  textAlign: 'center',
                                }}
                              >
                                No conversation yet
                              </Typography>
                            )}
                          </Box>
                        </React.Fragment>
                      ) : null;
                    })}
                  </List>
                </Bounce>
              ))
            ) : null}

          </Grid>

        )
      }

      {
        page === 'general' && (

          <>
            {Array.isArray(userTeam) && userTeam.length ? (
              <Grid
                item
                container
                sx={{
                  height: screenHeight - 400,
                  overflowY: 'auto',
                  display: 'flex',
                  flexDirection: 'column-reverse',
                }}
                className="chating"
              >
                {generalFilter.length > 0 ? (
                  <div className="d-flex flex-column">
                    <Bounce bottom>
                      <>
                        {groupByDate(generalFilter).map(([date, chats], chatIdx) => {

                          return (
                            <List sx={{ width: '100%' }} key={chatIdx}>
                              <div
                                className="w-100 py-1 bg-light rounded text-center"
                                style={{ fontSize: '12px' }}
                              >
                                {date}
                              </div>
                              {chats.map((item, idx) => {
                                console.log('Item:', item);
                                return (
                                  <Box key={item.id}>
                                    <li
                                      style={{
                                        display: 'flex',
                                        width: '100%',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Divider
                                        variant="inset"
                                        component="li"
                                        textAlign="left"
                                        sx={{
                                          width: { xs: '76%', sm: '76%', md: '100%' },
                                          ml: 3,
                                          margin: 'auto',
                                        }}
                                      >
                                        <Typography
                                          variant="caption"
                                          sx={{
                                            color: 'rgba(3, 7, 30, 0.6)',
                                            letterSpacing: '-0.3px',
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            fontFamily: 'Open Sans',
                                          }}
                                        >
                                          {item.sender.first_name}
                                        </Typography>
                                      </Divider>
                                    </li>

                                    <ListItem alignItems="flex-start" key={item.id} id={`message-${item.id}`}>
                                      <ListItemAvatar>
                                        <Avatar
                                          alt={item.sender.first_name}
                                          src={item.sender.avatar ? item.sender.avatar : "/static/images/avatar/1.jpg"}
                                        />
                                      </ListItemAvatar>
                                      <ListItemText
                                        primary={
                                          <Typography
                                            variant="subtitle2"
                                            sx={{
                                              color: '#03071E',
                                              letterSpacing: '-0.3px',
                                              fontWeight: 'bold',
                                              fontSize: '1rem',
                                              fontFamily: 'Open Sans',
                                              display: 'flex',
                                              alignItems: 'center',
                                              gap: '0.5rem',
                                            }}
                                          >
                                            {item?.sender?.first_name}

                                            <p
                                              style={{
                                                color: 'rgba(3, 7, 30, 0.4)',
                                                letterSpacing: '-0.3px',
                                                fontSize: '11px',
                                                fontWeight: '400',
                                                textAlign: 'left',
                                                fontFamily: 'Open Sans',
                                                marginBottom: '0',
                                              }}
                                            >
                                              {`${new Date(item.created).toLocaleTimeString([], {
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                hour12: true,
                                              })}, ${new Date(item.created).toDateString()}`}
                                            </p>
                                          </Typography>
                                        }
                                        secondary={
                                          <div
                                            style={{
                                              fontWeight: '400',
                                              fontSize: '14px',
                                              lineHeight: '22px',
                                              fontStyle: 'normal',
                                              color: '#03071E',
                                            }}
                                            dangerouslySetInnerHTML={{
                                              __html: item.message,
                                            }}
                                          />
                                        }
                                      />
                                    </ListItem>
                                    {search ? null : (
                                      <div className="ms-5 ps-1">
                                        {responseBox === item.id ? (
                                          <div className="response-dropdown">
                                            {item?.reply_to_chatmesage?.map((cv, int) => {
                                              console.log('CV:', cv);
                                              return (
                                                <div className="ms-3 mb-3 d-flex align-items-start" key={cv.id}>
                                                  <Avatar
                                                    alt={cv?.sender?.first_name}
                                                    src={cv?.sender?.avatar ? cv?.sender?.avatar : "/static/images/avatar/1.jpg"}
                                                    className="me-2"
                                                  />
                                                  <div>
                                                    <div className="d-flex align-items-center pt-2">
                                                      <p
                                                        className="fw-bold mb-0 me-2"
                                                        style={{
                                                          fontSize: '14px',
                                                        }}
                                                      >
                                                        {cv?.sender?.first_name ?? ''}
                                                      </p>
                                                      <p
                                                        style={{
                                                          color: 'rgba(3, 7, 30, 0.4)',
                                                          letterSpacing: '-0.3px',
                                                          fontSize: '11px',
                                                          textAlign: 'left',
                                                          fontFamily: 'Open Sans',
                                                        }}
                                                      >
                                                        {new Date().toLocaleTimeString([], {
                                                          hour: '2-digit',
                                                          minute: '2-digit',
                                                          hour12: true,
                                                        })}
                                                        , {new Date().toDateString()}
                                                      </p>
                                                    </div>
                                                    <p
                                                      style={{
                                                        fontWeight: '400',
                                                        fontSize: '14px',
                                                        lineHeight: '22px',
                                                        fontStyle: 'normal',
                                                        color: '#03071E',
                                                      }}
                                                      dangerouslySetInnerHTML={{
                                                        __html: cv?.message ?? '',
                                                      }}
                                                    ></p>
                                                  </div>
                                                </div>
                                              );
                                            })}

                                            <ReplyButton
                                              project_deliverable={project_deliverable}
                                              currentTargetOfEstimate={currentTargetOfEstimate}
                                              projectTeam={projectTeam}
                                              setLocalProjectState={setLocalProjectState}
                                              userData={userData}
                                              setUserMsg={setUserMsg}
                                              userMsg={userMsg}
                                              setCurrentTarget={setCurrentTarget}
                                              currentTarget={currentTarget}
                                              channel_id={item}
                                              page={page}
                                            />
                                            <button
                                              className="response-btn"
                                              onClick={() => {
                                                setResponseBox(false);
                                              }}
                                            >
                                              Hide Responses
                                            </button>
                                          </div>
                                        ) : (
                                          <>
                                            {item?.reply_to_chatmesage?.length && item?.reply_to_chatmesage?.length > 0 ? (
                                              <div className="respose">
                                                <AvatarGroup
                                                  className="response-avatar"
                                                  max={3}
                                                  sx={{
                                                    justifyContent: 'flex-end',
                                                    paddingLeft: '15px',
                                                  }}
                                                >
                                                  {Array.isArray(item?.reply_to_chatmesage?.length) &&
                                                    item?.reply_to_chatmesage?.length
                                                    ? [
                                                      ...new Set(
                                                        item?.reply_to_chatmesage.map((va) =>
                                                          JSON.stringify(va.sender)
                                                        )
                                                      ),
                                                    ]
                                                      .map((la) => JSON.parse(la))
                                                      .map((per) => {
                                                        console.error(la, per, "CheckingData");

                                                        return (
                                                          <Avatar
                                                            key={per.id}
                                                            src={per.avatar ? per.avatar : "/static/images/avatar/1.jpg"}
                                                            alt={per.first_name}
                                                          />
                                                        );
                                                      })
                                                    : null}
                                                </AvatarGroup>

                                                <button
                                                  className="response-btn"
                                                  onClick={() => {
                                                    setResponseBox(item.id);
                                                  }}
                                                >
                                                  <span className="me-1">
                                                    {item?.reply_to_chatmesage?.length + ' ' + 'Responses'}
                                                  </span>
                                                </button>
                                              </div>
                                            ) : (
                                              <div className="response-dropdown">
                                                <DirectReplyButton
                                                  project_deliverable={project_deliverable}
                                                  currentTargetOfEstimate={currentTargetOfEstimate}
                                                  projectTeam={projectTeam}
                                                  setLocalProjectState={setLocalProjectState}
                                                  userData={userData}
                                                  setUserMsg={setUserMsg}
                                                  userMsg={userMsg}
                                                  setCurrentTarget={setCurrentTarget}
                                                  currentTarget={currentTarget}
                                                  channel_id={item}
                                                  page={page}
                                                />
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    )}
                                  </Box>
                                );
                              })}
                            </List>
                          );
                        })}

                      </>
                    </Bounce>
                  </div>
                ) : (
                  <Grid
                    container
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <Bounce bottom>
                      <Box sx={{ textAlign: 'center' }}>
                        <Grid item>
                          <h2 className="sub-heading">#General</h2>
                        </Grid>
                        <Grid item>
                          <p className="fstxt">No messages yet</p>
                        </Grid>
                      </Box>
                    </Bounce>
                  </Grid>
                )}
              </Grid>
            ) : (
              <Grid
                sx={{
                  height: { xl: '560px', lg: '350px', md: '300px', sm: '300px' },
                  overflowY: 'auto',
                  display: 'flex',
                  flexDirection: 'column-reverse',
                }}
              >
                <Grid
                  container
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <Bounce bottom>
                    <Box sx={{ textAlign: 'center' }}>
                      <Grid item>
                        <h2 className="sub-heading">#General</h2>
                      </Grid>
                      <Grid item>
                        <p className="fstxt">No Team members yet</p>
                      </Grid>
                    </Box>
                  </Bounce>
                </Grid>
              </Grid>
            )}
          </>

        )
      }

      {page === 'myMentions' && (

        <Grid
          item
          container
          sx={{
            height: screenHeight - 290,
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column-reverse',

          }}
          className="chating"
        >
          {Array.isArray(newMentionState) && newMentionState?.length ? (


            <div className="d-flex flex-column">
              <Bounce bottom>
                <List sx={{ width: '100%' }} key={uuid()}>
                  {newMentionState.map((item) => {

                    const formattedDate = formatDate(
                      item.created,
                      previousDate.current
                    );
                    if (item.created !== previousDate.current) {
                      previousDate.current = item.created;
                    }



                    const newchannelData = chatData?.find((chann) => chann?.id === item?.channel)


                    return (
                      <React.Fragment key={item.id}>
                        <div
                          className="w-100 py-1 bg-light rounded text-center"
                          style={{ fontSize: '12px' }}
                        >
                          {formattedDate}
                        </div>
                        <Box>
                          <li
                            style={{
                              display: 'flex',
                              width: '100%',
                              alignItems: 'center',
                            }}
                          >
                            <Divider
                              variant="inset"
                              component="li"
                              textAlign="left"
                              sx={{
                                width: { xs: '76%', sm: '76%', md: '100%' },
                                ml: 3,
                                margin: 'auto',
                              }}
                            >
                              <Typography
                                variant="caption"
                                sx={{
                                  color: '#711FFF',
                                  letterSpacing: '-0.3px',
                                  fontSize: '14px',
                                  fontWeight: '600',
                                  fontFamily: 'Open Sans',
                                }}
                              >
                                {item.sender.first_name +
                                  ' ' +
                                  item.sender.last_name}
                              </Typography>
                            </Divider>
                            <Typography
                              variant="caption"
                              sx={{
                                color: '#00E6B8',
                                cursor: 'pointer',
                                position: 'absolute',
                                right: '0px',
                                background: 'white',
                              }}
                              onClick={() =>
                                parentCall({
                                  ...newchannelData,
                                  title: newchannelData?.name ? newchannelData?.name : "",
                                  message: item
                                })}
                            >
                              Go to Chat <ArrowRightAltIcon />
                            </Typography>
                          </li>

                          <ListItem alignItems="flex-start" key={item.id}   >
                            <ListItemAvatar>
                              <Avatar
                                alt={item.sender.first_name}
                                src={item.sender.avatar ? item.sender.avatar : "/static/images/avatar/1.jpg"}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="subtitle2"
                                  sx={{
                                    color: '#03071E',
                                    letterSpacing: '-0.3px',
                                    fontWeight: 'bold',
                                    fontSize: '1rem',
                                    fontFamily: 'Open Sans',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '0.5rem',
                                  }}
                                >
                                  {item.sender.first_name}

                                  <p
                                    style={{
                                      color: 'rgba(3, 7, 30, 0.4)',
                                      letterSpacing: '-0.3px',
                                      fontSize: '11px',
                                      fontWeight: '400',
                                      textAlign: 'left',
                                      fontFamily: 'Open Sans',
                                      marginBottom: '0',
                                    }}
                                  >
                                    {`${new Date(item.created).toLocaleTimeString(
                                      [],
                                      {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        hour12: true,
                                      }
                                    )}, ${new Date(item.created).toDateString()}`}
                                  </p>
                                </Typography>
                              }
                              secondary={
                                <div
                                  style={{
                                    fontWeight: item.seen ? '400' : '600',
                                    fontSize: '14px',
                                    lineHeight: '22px',
                                    fontStyle: 'normal',
                                    color: item.seen ? '#03071E' : '#000',
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: item.message,
                                  }}
                                />
                              }
                            />
                          </ListItem>
                        </Box>
                      </React.Fragment>
                    );
                  })}
                </List>
              </Bounce>
            </div>
          ) : (
            <Grid
              container
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Bounce botto>
                <Box sx={{ textAlign: 'center' }}>
                  <Grid item>
                    <h2 className="sub-heading">@My</h2>
                  </Grid>
                  <Grid item>
                    <p className="fstxt">No mentions yet</p>
                  </Grid>
                </Box>
              </Bounce>
            </Grid>
          )}
        </Grid>
      )
      }

      {
        page === 'chat' && (

          proLoading ?
            <Box sx={{
              height: screenHeight - 400,
              overflowY: 'auto',
              display: 'flex',
              flexDirection: 'column-reverse',
              padding: '10px',
              gap: '1rem'
            }}>
              {
                [1, 3, 2, 2, 3, 4, 5, 6, 7, 8].map((item) => {
                  return (
                    <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                      <Skeleton animation="wave" variant="circular" width={40} height={40} />
                      <Box sx={{ width: '80%', display: 'flex', flexDirection: 'column', gap: '5px', marginLeft: '5px', justifyContent: 'center' }}>
                        <Skeleton variant="rectangular" width={'25%'} height={10} />
                        <Skeleton variant="rectangular" width={'60%'} height={10} />
                      </Box>
                    </Box>
                  )
                })
              }
            </Box>
            :
            <Grid
              item
              container
              sx={{
                height: screenHeight - 400,
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'column-reverse',
              }}
              className="chating"
            >
              {newLocalProject?.length > 0 ? (
                <Bounce bottom>
                  <List sx={{ width: '100%' }}>
                    {newLocalProject
                      .sort(
                        (a, b) => new Date(a.message_time) - new Date(b.message_time)
                      )
                      .map((item, idx) => (
                        <Box>
                          {idx === 0 && (
                            <div
                              className="w-100 py-1 bg-light rounded text-center"
                              style={{ fontSize: '12px' }}
                            >
                              {new Date(item.created).toLocaleDateString([], {
                                day: 'numeric',
                                month: 'long',
                                year: 'numeric',
                              })}
                            </div>
                          )}
                          <ListItem id={`message-${item.id}`} >
                            <ListItemAvatar>
                              <Avatar
                                src={item?.sender?.avatar ? item?.sender?.avatar : "/static/images/avatar/1.jpg"}
                                alt={item.sender.first_name}
                              />

                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="subtitle2"
                                  sx={{
                                    color: '#03071E',
                                    letterSpacing: '-0.3px',
                                    fontWeight: 'bold',
                                    fontSize: '1rem',
                                    fontFamily: 'Open Sans',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '0.5rem',
                                    opacity: item?.tempId ? 0.5 : 1,
                                  }}
                                >
                                  {item.sender.first_name}

                                  <p
                                    style={{
                                      color: 'rgba(3, 7, 30, 0.4)',
                                      letterSpacing: '-0.3px',
                                      fontSize: '11px',
                                      textAlign: 'left',
                                      fontFamily: 'Open Sans',
                                      marginBottom: '0',
                                      opacity: item?.tempId ? 0.5 : 1,
                                    }}
                                  >
                                    {!item?.tempId ? `${new Date(item.created).toLocaleTimeString(
                                      [],
                                      {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        hour12: true,
                                      }
                                    )}, ${new Date(item.created).toDateString()}` : 'Sending'}
                                  </p>
                                </Typography>
                              }
                              secondary={
                                <div
                                  style={{
                                    fontWeight: item.seen ? '400' : '600',
                                    fontSize: '14px',
                                    lineHeight: '22px',
                                    fontStyle: 'normal',
                                    color: item.seen ? '#03071E' : '#000',
                                    opacity: item?.tempId ? 0.5 : 1,
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: item.message,
                                  }}
                                />
                              }
                            />
                          </ListItem>
                          {search ? null : (
                            <div className="ms-5 ps-1">
                              {responseBox === item.id ? (
                                <div className="response-dropdown">
                                  {item?.reply_to_chatmesage?.map((cv, int) => {
                                    console.log('CV:', cv);
                                    return (
                                      <div className="ms-3 mb-3 d-flex align-items-start" key={cv.id}>
                                        <Avatar
                                          alt={cv?.sender?.first_name}
                                          src={cv?.sender?.avatar ? cv?.sender?.avatar : "/static/images/avatar/1.jpg"}
                                          className="me-2"
                                        />
                                        <div>
                                          <div className="d-flex align-items-center pt-2">
                                            <p
                                              className="fw-bold mb-0 me-2"
                                              style={{
                                                fontSize: '14px',
                                              }}
                                            >
                                              {cv?.sender?.first_name ?? ''}
                                            </p>
                                            <p
                                              style={{
                                                color: 'rgba(3, 7, 30, 0.4)',
                                                letterSpacing: '-0.3px',
                                                fontSize: '11px',
                                                textAlign: 'left',
                                                fontFamily: 'Open Sans',
                                              }}
                                            >
                                              {new Date().toLocaleTimeString([], {
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                hour12: true,
                                              })}
                                              , {new Date().toDateString()}
                                            </p>
                                          </div>
                                          <p
                                            style={{
                                              fontWeight: '400',
                                              fontSize: '14px',
                                              lineHeight: '22px',
                                              fontStyle: 'normal',
                                              color: '#03071E',
                                            }}
                                            dangerouslySetInnerHTML={{
                                              __html: cv?.message ?? '',
                                            }}
                                          ></p>
                                        </div>
                                      </div>
                                    );
                                  })}

                                  <ReplyButton
                                    project_deliverable={project_deliverable}
                                    currentTargetOfEstimate={currentTargetOfEstimate}
                                    projectTeam={projectTeam}
                                    setLocalProjectState={setLocalProjectState}
                                    userData={userData}
                                    setUserMsg={setUserMsg}
                                    userMsg={userMsg}
                                    setCurrentTarget={setCurrentTarget}
                                    currentTarget={currentTarget}
                                    channel_id={item}
                                    page={page}
                                  />
                                  <button
                                    className="response-btn"
                                    onClick={() => {
                                      setResponseBox(false);
                                    }}
                                  >
                                    Hide Responses
                                  </button>
                                </div>
                              ) : (
                                <>
                                  {item?.reply_to_chatmesage?.length && item?.reply_to_chatmesage?.length > 0 ? (
                                    <div className="respose">
                                      <AvatarGroup
                                        className="response-avatar"
                                        max={3}
                                        sx={{
                                          justifyContent: 'flex-end',
                                          paddingLeft: '15px',
                                        }}
                                      >
                                        {Array.isArray(item?.reply_to_chatmesage?.length) &&
                                          item?.reply_to_chatmesage?.length
                                          ? [
                                            ...new Set(
                                              item?.reply_to_chatmesage.map((va) =>
                                                JSON.stringify(va.sender)
                                              )
                                            ),
                                          ]
                                            .map((la) => JSON.parse(la))
                                            .map((per) => {
                                              console.error(la, per, "CheckingData");

                                              return (
                                                <Avatar
                                                  key={per.id}
                                                  src={per.avatar ? per.avatar : "/static/images/avatar/1.jpg"}
                                                  alt={per.first_name}
                                                />
                                              );
                                            })
                                          : null}
                                      </AvatarGroup>

                                      <button
                                        className="response-btn"
                                        onClick={() => {
                                          setResponseBox(item.id);
                                        }}
                                      >
                                        <span className="me-1">
                                          {item?.reply_to_chatmesage?.length + ' ' + 'Responses'}
                                        </span>
                                      </button>
                                    </div>
                                  ) : (
                                    <div className="response-dropdown">
                                      <DirectReplyButton
                                        project_deliverable={project_deliverable}
                                        currentTargetOfEstimate={currentTargetOfEstimate}
                                        projectTeam={projectTeam}
                                        setLocalProjectState={setLocalProjectState}
                                        userData={userData}
                                        setUserMsg={setUserMsg}
                                        userMsg={userMsg}
                                        setCurrentTarget={setCurrentTarget}
                                        currentTarget={currentTarget}
                                        channel_id={item}
                                        page={page}
                                      />
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          )}
                        </Box>
                      ))}
                  </List>
                </Bounce>
              ) : (
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <Bounce bottom>
                    <Box sx={{ textAlign: 'center' }}>
                      <Grid item>
                        <p className="sub-heading">No messages yet</p>
                      </Grid>
                      <Grid item>
                        <p className="fstxt">
                          Start typing and share your thoughts with your team. Press "@"
                          to mention someone directly.
                        </p>
                      </Grid>
                    </Box>
                  </Bounce>
                </Grid>
              )}
            </Grid>
        )
      }

      {
        page === 'people' && (
          <>
            <Grid
              item
              container
              sx={{
                height: screenHeight - 400,
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'column-reverse',
              }}
              className="chating"
            >
              {newUserMessages && newUserMessages.length < 1 ? (
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <Bounce bottom>

                    <Box sx={{ textAlign: 'center' }}>
                      <Grid item>
                        <p className="sub-heading">No Messages yet</p>
                      </Grid>
                      <Grid item>
                        <p className="fstxt">Start typing and share your thoughts!</p>
                      </Grid>
                    </Box>

                  </Bounce>
                </Grid>
              ) : (
                <Bounce bottom>
                  <List
                    sx={{
                      width: '100%',
                    }}
                  >
                    {newUserMessages
                      .filter(
                        (item) =>
                          item?.sender?.id === currentTarget?.id ||

                          item?.receiver?.id === currentTarget?.id
                      )
                      .sort((a, b) => new Date(a?.created) - new Date(b?.created))
                      .map((item, idx) => (

                        <Box key={item.id}>
                          {idx === 0 && (
                            <div
                              className="w-100 py-1 bg-light rounded text-center"
                              style={{ fontSize: '12px' }}
                            >
                              {new Date(item.created).toLocaleDateString([], {
                                day: 'numeric',
                                month: 'long',
                                year: 'numeric',
                              })}
                            </div>
                          )}
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar
                                alt={item.sender.first_name}
                                src={item.sender.avatar ? item.sender.avatar : "/static/images/avatar/1.jpg"}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="subtitle2"
                                  sx={{
                                    color: '#03071E',
                                    letterSpacing: '-0.3px',
                                    fontWeight: 'bold',
                                    fontSize: '1rem',
                                    fontFamily: 'Open Sans',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '0.5rem',
                                    opacity: item?.tempId ? 0.5 : 1,
                                  }}
                                >
                                  {item.sender.first_name}

                                  <p
                                    style={{
                                      color: 'rgba(3, 7, 30, 0.4)',
                                      letterSpacing: '-0.3px',
                                      fontSize: '11px',
                                      textAlign: 'left',
                                      fontFamily: 'Open Sans',
                                      marginBottom: '0',
                                    }}
                                  >
                                    {!item?.tempId ? `${new Date(item.created).toLocaleTimeString(
                                      [],
                                      {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        hour12: true,
                                      }
                                    )}, ${new Date(item.created).toDateString()}` : 'Sending'}
                                  </p>
                                </Typography>
                              }
                              secondary={
                                <div
                                  style={{
                                    fontWeight: item.seen ? '400' : '600',
                                    fontSize: '14px',
                                    lineHeight: '22px',
                                    fontStyle: 'normal',
                                    color: item.seen ? '#03071E' : '#000',
                                    opacity: item?.tempId ? 0.5 : 1,
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: item.message,
                                  }}
                                />
                              }
                            />
                          </ListItem>
                          {search ? null : (
                            <div className="ms-5 ps-1">
                              {responseBox === item.id ? (
                                <div className="response-dropdown">
                                  {item?.reply_to_dm?.map((cv, int) => {
                                    console.log('CV:', cv);
                                    return (
                                      <div className="ms-3 mb-3 d-flex align-items-start" key={cv.id}>
                                        <Avatar
                                          alt={cv?.sender?.first_name}
                                          src={cv?.sender?.avatar ? cv?.sender?.avatar : "/static/images/avatar/1.jpg"}
                                          className="me-2"
                                        />
                                        <div>
                                          <div className="d-flex align-items-center pt-2">
                                            <p
                                              className="fw-bold mb-0 me-2"
                                              style={{
                                                fontSize: '14px',
                                              }}
                                            >
                                              {cv?.sender?.first_name ?? ''}
                                            </p>
                                            <p
                                              style={{
                                                color: 'rgba(3, 7, 30, 0.4)',
                                                letterSpacing: '-0.3px',
                                                fontSize: '11px',
                                                textAlign: 'left',
                                                fontFamily: 'Open Sans',
                                              }}
                                            >
                                              {new Date().toLocaleTimeString([], {
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                hour12: true,
                                              })}
                                              , {new Date().toDateString()}
                                            </p>
                                          </div>
                                          <p
                                            style={{
                                              fontWeight: '400',
                                              fontSize: '14px',
                                              lineHeight: '22px',
                                              fontStyle: 'normal',
                                              color: '#03071E',
                                            }}
                                            dangerouslySetInnerHTML={{
                                              __html: cv?.message ?? '',
                                            }}
                                          ></p>
                                        </div>
                                      </div>
                                    );
                                  })}

                                  <ReplyButton
                                    project_deliverable={project_deliverable}
                                    currentTargetOfEstimate={currentTargetOfEstimate}
                                    projectTeam={projectTeam}
                                    setLocalProjectState={setLocalProjectState}
                                    userData={userData}
                                    setUserMsg={setUserMsg}
                                    userMsg={userMsg}
                                    setCurrentTarget={setCurrentTarget}
                                    currentTarget={currentTarget}
                                    channel_id={item}
                                    page={page}
                                  />
                                  <button
                                    className="response-btn"
                                    onClick={() => {
                                      setResponseBox(false);
                                    }}
                                  >
                                    Hide Responses
                                  </button>
                                </div>
                              ) : (
                                <>
                                  {item?.reply_to_dm?.length && item?.reply_to_dm?.length > 0 ? (
                                    <div className="respose">
                                      <AvatarGroup
                                        className="response-avatar"
                                        max={3}
                                        sx={{
                                          justifyContent: 'flex-end',
                                          paddingLeft: '15px',
                                        }}
                                      >
                                        {Array.isArray(item?.reply_to_dm?.length) &&
                                          item?.reply_to_dm?.length
                                          ? [
                                            ...new Set(
                                              item?.reply_to_dm.map((va) =>
                                                JSON.stringify(va.sender)
                                              )
                                            ),
                                          ]
                                            .map((la) => JSON.parse(la))
                                            .map((per) => {
                                              console.error(la, per, "CheckingData");

                                              return (
                                                <Avatar
                                                  key={per.id}
                                                  src={per.avatar ? per.avatar : "/static/images/avatar/1.jpg"}
                                                  alt={per.first_name}
                                                />
                                              );
                                            })
                                          : null}
                                      </AvatarGroup>

                                      <button
                                        className="response-btn"
                                        onClick={() => {
                                          setResponseBox(item.id);
                                        }}
                                      >
                                        <span className="me-1">
                                          {item?.reply_to_dm?.length + ' ' + 'Responses'}
                                        </span>
                                      </button>
                                    </div>
                                  ) : (
                                    <div className="response-dropdown">
                                      <DirectReplyButton
                                        project_deliverable={project_deliverable}
                                        currentTargetOfEstimate={currentTargetOfEstimate}
                                        projectTeam={projectTeam}
                                        setLocalProjectState={setLocalProjectState}
                                        userData={userData}
                                        setUserMsg={setUserMsg}
                                        userMsg={userMsg}
                                        setCurrentTarget={setCurrentTarget}
                                        currentTarget={currentTarget}
                                        channel_id={item}
                                        page={page}
                                      />
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          )}
                        </Box>
                      ))}
                  </List>
                </Bounce>
              )}
            </Grid>
          </>
        )
      }
    </>
  );
};
export default ChatBody;
