import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Board from 'react-trello';
import Bounce from 'react-reveal/Bounce';

import { Button, Avatar, Tooltip } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SearchIcon from '@mui/icons-material/Search';
import SubdirectoryArrowRightRoundedIcon from '@mui/icons-material/SubdirectoryArrowRightRounded';
// eslint-disable-next-line
import { DataGrid } from '@mui/x-data-grid';
import { motion, AnimatePresence } from 'framer-motion';
import moment from 'moment';

import HelpVideo from '../../Componenets/TopNav/HelpVideo';
import ListIco from '../Projects/assets/ListIco.png';
import kanBanIco from '../Projects/assets/KanBanIco.png';
import MyTaskChat from './MyTaskChat';
import Timer from './assets/Time-Active-Purple.svg';
import { TaskDetail } from '../Projects/_module';
import Dots from '../Projects/assets/Dots.png';
import Gear from '../Projects/assets/Gear.png';
import { getMessages, New_channels } from '../Stream/streamRedux';
import helpVid from '../Opportunity/assets/Section_Help-idle.svg';
import { DashedField } from '../../Componenets/Misc';
import calculateTotalTimeObtained from '../../utils/calculateTotalTimeObtained';
import { NetProgress } from '../../Componenets/ProgressBar';
import { getQuickTask } from '../Projects/proRedux';
import { ENDPOINT } from '../Opportunity/Redux/oppor';
import { Step2Content } from '../../Componenets/TopNav/TopNav';

const darkTypography = {
  color: '#310085',
  textTransform: 'uppercase',
  fontWeight: '600',
  fontFamily: 'Open Sans',
  fontSize: '1.2rem',
};

export { darkTypography };

function PrevBox({
  timeParams,
  args,
  projects,
  setProjects,
  myArr3,
  newWithAssine,
  searchQuery,
  setSearchQuery,
  setTimeParams,
  setArgs,
  myArr,
  currentUser,
  setKanbanShow,
}) {
  return (
    <Box
      sx={{
        backgroundColor: '#f2f2f2',
        borderRadius: '8px 0px 0 0',
        borderTopRightRadius: '8px',
        height: '70px',
        width: '100%',
        border: '1px solid rgba(224, 224, 224, 1)',
        borderBottom: '1.5px solid  #d9d9d9',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0.5rem 1rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: { xxl: '20%', xl: '30%', lg: '45%', md: '50%' },
          gap: '1rem',
        }}
      >
        <FormControl
          sx={{
            width: '350px',
          }}
        >
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            sx={{
              fontWeight: '600',
              textAlign: 'center',
              '& .MuiSelect-select:focus': {
                backgroundColor: '#711fff',
                color: '#fff',
                outline: '1px solid #711fff',
                border: '1px solid #711fff',
              },
              '& .MuiSelect-select:hover': {
                backgroundColor: '#711fff',
                color: '#fff',
                outline: '1px solid #711fff',
                border: '1px solid #711fff',
              },
              '& .MuiSelect-select': {
                backgroundColor: '#711fff',
                color: '#fff',
                outline: '1px solid #711fff',
                border: '1px solid #711fff',
                padding: '10px 32px 10px 10px',
              },
              '& .MuiSelect-icon': {
                color: '#fff',
              },
              '& .MuiSelect-selectMenu': {
                color: '#fff',
              },
            }}
            value={args}
            onChange={(e) => {
              setArgs(e.target.value);
              setTimeParams('all');

              let result = [];
              if (e.target.value === 'archive') {
                const value = ['archive', 'completed'];
                result =
                  Array.isArray(newWithAssine) &&
                  newWithAssine.filter((flt) =>
                    value.some((som) => som === flt.status),
                  );
              }
              if (e.target.value === 'all2') {
                const value = ['archive', 'completed'];
                result =
                  Array.isArray(newWithAssine) &&
                  newWithAssine.filter((item) =>
                    value.every((s) => item.status !== s),
                  );
              }
              if (e.target.value === 'backlog') {
                const value = ['backlog'];
                result =
                  Array.isArray(newWithAssine) &&
                  newWithAssine.filter((flt) =>
                    value.some((som) => som === flt.status),
                  );
              }

              const filteredMyArr =
                Array.isArray(newWithAssine) && newWithAssine.length > 0
                  ? newWithAssine.filter(
                      (item) => item.task_type === e.target.value,
                    )
                  : [];

              let myArr2 =
                e.target.value && e.target.value === 'archive'
                  ? result
                  : e.target.value === 'all2'
                    ? result
                    : e.target.value === 'backlog'
                      ? result
                      : filteredMyArr;

              if (e?.target?.value !== 'backlog') {
                myArr2 = myArr2.filter((flt) => flt.status !== 'backlog');
              }
              setProjects(myArr2);
            }}
          >
            <MenuItem value="all2">Quick & Project Tasks</MenuItem>
            <MenuItem value="quick_task">Quick Tasks</MenuItem>
            <MenuItem value="project_task">Project Tasks</MenuItem>
            <MenuItem value="archive">Archive/Completed Tasks</MenuItem>
            <MenuItem value="backlog">Backlog Tasks</MenuItem>
          </Select>
        </FormControl>

        <FormControl
          sx={{
            width: '350px',
          }}
        >
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={timeParams}
            onChange={(e) => {
              setTimeParams(e.target.value);
              setArgs('all2');
              setProjects(() => {
                const shallow_copy =
                  Array.isArray(myArr) && myArr.map((item) => ({ ...item }));

                if (e.target.value === 'due_soon') {
                  const dueSoon = shallow_copy
                    .filter((tsk) => tsk.due_date !== null)
                    .filter((obj) => {
                      const finishData = obj.due_date;
                      return moment(finishData).isBefore(
                        moment().add(7, 'days'),
                      );
                    });
                  return dueSoon;
                }

                if (e.target.value === 'month') {
                  const dueMonth = shallow_copy
                    .filter((et) => et.due_date !== null)
                    .filter((ent) => {
                      const finishDate = ent.due_date;
                      return moment(finishDate).isSame(moment(), 'month');
                    });
                  return dueMonth;
                }

                if (e.target.value === 'six_months') {
                  const semester = shallow_copy
                    .filter((iin) => iin.due_date !== null)
                    .filter((nnn) => {
                      const culmination = nnn.due_date;
                      return moment(culmination).isAfter(
                        moment().add(4, 'months'),
                        'month',
                      );
                    });
                  return semester;
                }

                return Array.isArray(myArr) && myArr.map((pro) => ({ ...pro }));
              });
            }}
            sx={{
              fontWeight: '600',
              textAlign: 'center',
              '& .MuiSelect-select:focus': {
                backgroundColor: '#D2B8FF',
                color: '#000',
                outline: '0.5px solid #A0A2AA',
                border: '0.5px solid #A0A2AA',
              },
              '& .MuiSelect-select:hover': {
                backgroundColor: '#D2B8FF',
                color: '#000',
                outline: '0.5px solid #A0A2AA',
                border: '0.5px solid #A0A2AA',
              },
              '& .MuiSelect-select': {
                backgroundColor: '#fff',
                color: '#000',
                outline: '0.5px solid #A0A2AA',
                border: '0.5px solid #A0A2AA',
                transition: 'all 0.3s ease-in-out',
                padding: '10px 32px 10px 10px',
              },
              '& .MuiSelect-icon': {
                color: '#000',
              },
              '& .MuiSelect-selectMenu': {
                color: '#000',
              },
            }}
            color="secondary"
          >
            <MenuItem value="due_soon">Due Soon</MenuItem>
            <MenuItem value="month">Due This Month</MenuItem>
            <MenuItem value="next_month">Due Next 30 Days</MenuItem>
            <MenuItem value="six_months">Due in Next 6 Month</MenuItem>
            <MenuItem value="all">All Tasks</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box
        sx={{
          width: { xxl: '20%', xl: '20%', lg: '35%', md: '40%' },
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
          justifyContent: 'flex-end',
        }}
      >
        <FindIcon
          setSearchQuery={setSearchQuery}
          searchQuery={searchQuery}
          myArr={myArr}
          projects={projects}
          setProjects={setProjects}
          myArr3={myArr3}
          newWithAssine={newWithAssine}
          currentUser={currentUser}
        />
        <img
          alt="list-icon"
          src={ListIco}
          onClick={() => setKanbanShow(false)}
          style={{ cursor: 'pointer' }}
        />
        <img
          alt="kanban-icon"
          src={kanBanIco}
          onClick={() => {
            setKanbanShow(true);
          }}
          style={{
            cursor: 'pointer',
          }}
        />
      </Box>
    </Box>
  );
}

function CustomCard({
  title,
  description,
  label,
  otherText,
  timeLeft,
  assignee,
}) {
  const Team = useSelector((state) => state.userTeam.team);
  const realAssingee =
    assignee && Array.isArray(Team.member) && Team.member.length
      ? Team.member.find((ele) => Number(ele.id) === Number(assignee))
      : false;
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div
      style={{
        backgroundColor: '#f2f2f2',
        borderRadius: '8px',
        padding: '10px 10px',
        margin: screenSize < 1300 ? '0 auto' : '5px',
        width:
          screenSize >= 1750
            ? '360px'
            : screenSize >= 1650 && screenSize <= 1749
              ? '320px'
              : screenSize >= 1300
                ? '240px'
                : screenSize < 1300
                  ? '190px'
                  : 'auto',
      }}
    >
      {title && (
        <div
          style={{
            backgroundColor: '#e0e0e0',
            borderStartStartRadius: '8px',
            borderStartEndRadius: '8px',
            padding: '5px 5px',
            fontWeight: 'bold',
            fontSize: '15px',
          }}
        >
          <p>{title}</p>
        </div>
      )}
      {description && (
        <div style={{ padding: '3px 3px', backgroundColor: '#f0f0f0' }}>
          {/* eslint-disable-next-line */}
          <p dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      )}

      {otherText && (
        <div
          style={{
            backgroundColor: '#e7deff',
            color: '#711fff',
            padding: '10px 10px',
            borderRadius: '8px',
            marginTop: '10px',
          }}
        >
          <p>{otherText}</p>
        </div>
      )}
      {label && (
        <div
          style={{
            padding: '8px 8px',
            backgroundColor: '#e0dede',
            width: '80%',
            borderRadius: '8px',
            marginTop: '10px',
            color: '#bb5805',
          }}
        >
          <span style={{ fontSize: '0.95rem', fontWeight: '600' }}>
            {' '}
            {label}
          </span>
        </div>
      )}
      {timeLeft && (
        <div
          style={{
            backgroundColor: '#e0dede',
            fontWeight: 'bold',
            width: '60%',
            borderRadius: '8px',
            marginTop: '10px',
            padding: '5px 5px',
          }}
        >
          {timeLeft}
        </div>
      )}
      {realAssingee ? (
        <Box
          sx={{
            cursor: 'pointer',
            position: 'absolute',
            bottom: '10px',
            right: '10px',
          }}
        >
          <Tooltip
            title={`Assinged to ${realAssingee.first_name} ${realAssingee.last_name}`}
          >
            <Avatar src={realAssingee.avatar} alt={realAssingee.first_name} />
          </Tooltip>
        </Box>
      ) : null}
    </div>
  );
}

function CustomLaneHeader({ title }) {
  return (
    <div
      style={{
        backgroundColor:
          title === 'Backlog'
            ? '#e0b4fa'
            : title === 'Need More Work'
              ? '#f0e4b9'
              : title === 'Inprogress'
                ? '#79d0ed'
                : title === 'Stuck'
                  ? '#ffbd85'
                  : title === 'Completed'
                    ? '#56d6a0'
                    : title === 'Submitted for Review'
                      ? '#e7deff'
                      : title === 'Archive'
                        ? '#51b7c9'
                        : null,
        padding: '10px 10px',
        borderStartStartRadius: '8px',
        borderStartEndRadius: '8px',
        borderBottom:
          title === 'Backlog'
            ? '4px solid #65099c'
            : title === 'Need More Work'
              ? '4px solid #c49a02'
              : title === 'Inprogress'
                ? '4px solid #0000FF'
                : title === 'Stuck'
                  ? '4px solid #bb5805'
                  : title === 'Completed'
                    ? '4px solid #075433'
                    : title === 'Submitted for Review'
                      ? '4px solid #711fff'
                      : title === 'Archive'
                        ? '2px solid #51b7c9'
                        : null,
      }}
    >
      <h5>{title}</h5>
    </div>
  );
}

function FindIcon({ searchQuery, setSearchQuery, setProjects, currentUser }) {
  const [searchField, setSearchField] = useState('button');

  const selector = useSelector((state) => state.projects.tasks);
  const nonFieldReturn =
    Array.isArray(selector) && selector.length
      ? selector.filter((tsk) =>
          tsk.assignee_task?.some(
            (ele) => Number(ele.assignee_user) === Number(currentUser.id),
          ),
        )
      : [];
  return searchField === 'button' ? (
    <Button
      className="search-btn"
      onClick={() => {
        setSearchField('input');
      }}
    >
      <ManageSearchIcon className="search-icon" />
    </Button>
  ) : (
    <AnimatePresence>
      <motion.div
        key="field"
        style={{
          background: '#FFFFFF',
          display: 'flex',
          alignItems: 'center',
          padding: '6px 8px',
          borderRadius: '6px',
          color: '#676B6B',
          cursor: 'pointer',
        }}
        initial={{ x: 10, opacity: 0, transition: { duration: 0.5 } }}
        animate={{ x: 0, opacity: 1, transition: { duration: 0.5 } }}
        exit={{ x: -10, opacity: 0, transition: { duration: 0.5 } }}
      >
        <SearchIcon
          style={{ curser: 'pointer' }}
          onClick={() => {
            setSearchField('button');
          }}
        />
        <DashedField
          id="standard-basic"
          variant="standard"
          sx={{ padding: '0px', width: '100%' }}
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            setProjects((prev) => {
              if (e.target.value) {
                const filteredSearch =
                  Array.isArray(prev) && prev.length > 0
                    ? prev.filter((item) =>
                        item.task_name
                          .toLowerCase()
                          .startsWith(searchQuery.toLowerCase()),
                      )
                    : [];
                return Array.isArray(filteredSearch) && filteredSearch.length
                  ? filteredSearch.map((ma) => ({ ...ma }))
                  : [];
              }
              return nonFieldReturn;
            });
          }}
        />
      </motion.div>
    </AnimatePresence>
  );
}

function TaskDetail2({ show, setShow, currentTarget }) {
  return (
    <Offcanvas
      show={show}
      onHide={() => {
        setShow(false);
      }}
      placement="bottom"
      backdrop
    >
      <Offcanvas.Header
        style={{
          position: 'relative',
        }}
      >
        <button
          type="button"
          aria-label="close-modal"
          className="close-modal"
          onClick={() => {
            setShow(false);
          }}
        />
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Step2Content
          currentTarget={{
            ...currentTarget,
            name: currentTarget.task_name,
          }}
          setShow={setShow}
        />
      </Offcanvas.Body>
    </Offcanvas>
  );
}

function MyTasks({ screenHeight }) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [show3, setShow3] = useState(false);
  const [kanbanShow, setKanbanShow] = useState(false);
  const [comments, setComments] = useState(null);

  const [currentTarget, setCurrentTarget] = useState({});

  const currentUser = useSelector((state) => state.userData.userData);
  const token = useSelector((state) => state.auth.user.access);
  const currentCompanies = useSelector((state) => state.Contacts.companies);
  const timeisKey = useSelector((state) => state.oppertunity.timetracking);
  const Team = useSelector((state) => state.userTeam.team);
  const new_channals = useSelector((state) => state.Stream.new_channels);
  const userData = useSelector((state) => state.userData.userData);
  const access_token = useSelector((item) => item.auth.user.access);

  useEffect(() => {
    dispatch(
      getQuickTask({
        access_token: token,
      }),
    );
  }, []);

  useEffect(() => {
    dispatch(
      getQuickTask({
        access_token: token,
      }),
    );
  }, [timeisKey, currentTarget, show]);

  const [isReady, setIsReady] = useState(false);
  const [myArr, setMyArr] = useState([]);

  const newWithAssine = myArr;

  const myTaskInitialApi = async () => {
    try {
      const response = await fetch(
        `${ENDPOINT}/api/project/task/usertasklist/`,
        {
          method: 'GET',
          headers: {
            Authorization: `JWT ${token}`,
            'Content-Type': 'application/json',
          },
        },
      );
      const data = await response.json();
      if (response.ok) {
        setMyArr(data);
        setIsReady(true);
      } else {
        setIsReady(true);
      }
    } catch (error) {
      setIsReady(true);
    }
  };

  useEffect(() => {
    myTaskInitialApi();
  }, []);

  useEffect(() => {
    myTaskInitialApi();
  }, [show]);

  const [taskChatShow, setTaskChatShow] = useState(false);
  const [taskChat, setTaskChat] = useState(null);

  const handleMyChatShow = async (task, comments) => {
    setTaskChatShow(true);
    setTaskChat(task);
    if (comments?.length) {
      // eslint-disable-next-line
      for (const task of comments) {
        try {
          const find = task?.read_message.some(
            (readMessage) => readMessage.read_by === userData.id,
          );
          if (!find) {
            // eslint-disable-next-line
            const response = await fetch(
              `${ENDPOINT}/api/chat/channel/message/read/create/`,
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `JWT ${access_token}`,
                },
                body: JSON.stringify({
                  message: task.id,
                  read_by: userData.id,
                }),
              },
            );
            if (!response.ok) {
              return;
            }
          }
          // eslint-disable-next-line
        } catch (error) {}
      }
      // eslint-disable-next-line
      const crtMessages = new_channals?.find((item) => item?.task === row.id);
      // eslint-disable-next-line
      const response = fetch(
        `${ENDPOINT}/api/chat/channel/detail/${crtMessages?.id}/`,
        {
          method: 'GET',
          headers: {
            Authorization: `JWT ${access_token}`,
            'Content-Type': 'application/json',
          },
        },
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          setComments(data?.message_in_channel);
          // Process the response data here
          dispatch(getMessages({ access_token }));
          dispatch(New_channels({ access_token }));
        })
        .catch(() => {});
    }
  };

  const [projects, setProjects] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const [args, setArgs] = useState('all2');
  const [timeParams, setTimeParams] = useState('all');

  let result = [];
  if (args === 'archive') {
    const value = ['archive', 'completed'];
    result =
      Array.isArray(newWithAssine) &&
      newWithAssine.filter((flt) => value.some((som) => som === flt.status));
  }
  if (args === 'all2') {
    const value = ['archive', 'completed'];
    result =
      Array.isArray(newWithAssine) &&
      newWithAssine.filter((item) => value.every((s) => item.status !== s));
  }
  if (args === 'backlog') {
    const value = ['backlog'];
    result =
      Array.isArray(newWithAssine) &&
      newWithAssine.filter((flt) => value.some((som) => som === flt.status));
  }

  const filteredMyArr =
    Array.isArray(newWithAssine) && newWithAssine.length > 0
      ? newWithAssine.filter((item) => item.task_type === args)
      : [];
  let myArr2 =
    args && args === 'archive'
      ? result
      : args === 'all2'
        ? result
        : args === 'backlog'
          ? result
          : filteredMyArr;

  if (args !== 'backlog') {
    myArr2 = myArr2.filter((flt) => flt.status !== 'backlog');
  }

  const filteredSearch =
    Array.isArray(myArr2) &&
    myArr2.length > 0 &&
    myArr2.filter((item) =>
      item.task_name.toLowerCase().startsWith(searchQuery.toLowerCase()),
    );
  const myArr3 = searchQuery ? filteredSearch : myArr2;

  useEffect(() => {
    setProjects(myArr3);
  }, [searchQuery]);

  useEffect(() => {
    setProjects(myArr3);
  }, [myArr]);

  const all_projects = useSelector((state) => state.projects.projects);

  const months = {
    '01': 'Jan',
    '02': 'Feb',
    '03': 'Mar',
    '04': 'Apr',
    '05': 'May',
    '06': 'Jun',
    '07': 'Jul',
    '08': 'Aug',
    '09': 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec',
  };
  const [fetchedComments, setFetchedComments] = useState({}); // Initialize as an empty object
  const memoizedChannals = useMemo(() => new_channals, [new_channals]);
  const memoizedArr3 = useMemo(() => myArr3, [myArr3]);
  // eslint-disable-next-line
  const [mentionsLength, setMentionsLength] = useState();
  const ChannelList = useSelector((state) => state.Stream.data);

  // Example of how you might set fetchedComments after fetching
  useEffect(() => {
    if (memoizedChannals && memoizedArr3) {
      const promises = myArr3.map((row) => {
        const crtMessages = new_channals.find((item) => item?.task === row.id);

        if (crtMessages) {
          return fetch(
            `${ENDPOINT}/api/chat/channel/detail/${crtMessages.id}/`,
            {
              method: 'GET',
              headers: {
                Authorization: `JWT ${access_token}`,
                'Content-Type': 'application/json',
              },
            },
          )
            .then((response) => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              return response.json();
            })
            .then((data) => ({
              taskId: row.id,
              comments: data.message_in_channel,
            }));
          // eslint-disable-next-line
        } else {
          return Promise.resolve({ taskId: row.id, comments: [] });
        }
      });

      Promise.all(promises)
        .then((results) => {
          const commentsMap = results.reduce((acc, { taskId, comments }) => {
            acc[taskId] = comments;
            return acc;
          }, {});
          setFetchedComments(commentsMap);
        })
        .catch(() => {});
    }
  }, [memoizedChannals, projects]);

  useEffect(() => {
    const regexPattern = new RegExp(
      `<span data-type="mention" class="deliverable-mention" data-id="@${userData.first_name}">@${userData.first_name}</span>`,
      'i',
    );

    const mentions = Object.values(fetchedComments)
      .flat()
      .filter((msg) => regexPattern.test(msg.message));

    setMentionsLength(mentions);
  }, [fetchedComments]);

  const columns = [
    {
      field: 'task_name',
      headerName: 'Task name',
      align: 'left',
      flex: 2.5,
      renderHeader: () => (
        <div
          className="tableHeader"
          style={{ padding: '0 0 0 1rem', width: '300px' }}
        >
          Task Name
        </div>
      ),
      renderCell: (params) => {
        const project_name =
          params.row.type !== null
            ? all_projects.find((item) => item.id === params.row.project)?.title
            : '';
        return (
          <div
            className="td"
            style={{
              padding: '0 0 0 1rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              position: 'relative',
              height: '100%',
              justifyContent: 'center',
              width: '100%',
            }}
            onClick={() => {
              setCurrentTarget(params?.row);
              setShow(true);
            }}
          >
            <Tooltip title={params.value}>{params.value}</Tooltip>
            {params.row.type !== 'quick_task' ? (
              <Tooltip title={project_name}>
                <Box
                  sx={{
                    color: '#711FFF',
                    position: 'absolute',
                    top: '50px',
                    left: '15px',
                    fontSize: '13px',
                  }}
                >
                  <SubdirectoryArrowRightRoundedIcon
                    sx={{ fontSize: '17px' }}
                  />{' '}
                  {project_name?.toUpperCase()}
                </Box>
              </Tooltip>
            ) : (
              ''
            )}
          </div>
        );
      },
    },
    {
      field: 'company',
      headerName: 'Company Name',
      flex: 2.2,
      renderHeader: () => <div className="tableHeader">Company Name</div>,
      renderCell: (params) => {
        const taskCompany = currentCompanies.find(
          (comp) => Number(comp.id) === Number(params.value),
        )?.name;

        const projectCompany = currentCompanies.find(
          (comp) =>
            Number(comp.id) ===
            Number(
              all_projects.find((item) => item.id === params.row.project)
                ?.company,
            ),
        )?.name;

        return (
          <div className="td">
            {params.value ? taskCompany : (projectCompany ?? '-------')}
          </div>
        );
      },
    },

    {
      field: 'assinger',
      headerName: 'Assigner',
      flex: 1.8,
      renderHeader: () => (
        <div className="tableHeader" onClick={() => {}}>
          Assignor
        </div>
      ),
      renderCell: (params) => (
        <div className="td">
          {' '}
          {params.value && Team
            ? Team.member
                .filter((membraan) => membraan.id === params.value)
                .map((mem) => (
                  <div
                    key={mem?.id ?? mem?.first_name}
                    className="dFlex"
                    style={{
                      gap: '0.5rem',
                      fontSize: '1rem',
                      fontWeight: '500',
                      fontFamily: 'Open Sans',
                    }}
                  >
                    <Avatar
                      src={mem.avatar}
                      alt={mem.first_name}
                      sx={{
                        width: '40px',
                        height: '40px',
                      }}
                    />{' '}
                    {mem.first_name} {mem.last_name}
                  </div>
                ))
            : '------'}
        </div>
      ),
    },
    {
      field: 'due_date',
      headerName: 'Due Date',
      flex: 1,
      renderHeader: () => (
        <div className="tableHeader" onClick={() => {}}>
          Due Date
        </div>
      ),
      renderCell: (params) =>
        params.row.due_date ? (
          <div className="td" style={{ fontSize: '1rem', fontWeight: 600 }}>
            {`${params.row.due_date.split('T')[0]},${
              params.row.due_date.split(',')[1]
            }`}
          </div>
        ) : (
          <div className="td" style={{ fontSize: '1rem', fontWeight: 600 }}>
            -------
          </div>
        ),
    },
    {
      field: 'tracked_time',
      headerName: 'Time Tracked',
      flex: 1.5,
      renderHeader: () => <div className="tableHeader">Time Tracked</div>,
      renderCell: (params) => {
        const totalDurationObtained =
          calculateTotalTimeObtained(params.row.time_tracking) || 0;

        return (
          <Tooltip title="Represents Your Actual / Your Assigned">
            <div className="td">
              <NetProgress
                status={params.row.status}
                obt={totalDurationObtained || 0}
                eta={params.row.assignee_hours || params.row.eta}
              />
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      align: 'right',
      flex: 1,
      renderHeader: () => (
        <div className="tableHeader" style={{ padding: '0 1rem 0 0' }}>
          Status
        </div>
      ),
      renderCell: (params) => (
        <div style={{ padding: '0 1rem 0 0' }}>
          <select
            className={
              params.row.status !== 'task_status'
                ? params.row.status
                : 'backlog'
            }
            onChange={async (e) => {
              setProjects((prev) => {
                const shallow_copy = [...prev.map((item) => ({ ...item }))];
                const int = shallow_copy.findIndex(
                  (ma) => Number(ma.id) === params.row.id,
                );
                shallow_copy[int].status = e.target.value;
                return shallow_copy;
              });

              const res = await fetch(
                `${ENDPOINT}/api/project/task/update/${params.row.id}/`,
                {
                  method: 'PUT',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `JWT ${token}`,
                  },
                  body: JSON.stringify({
                    task_name: params.row.task_name,
                    status: e.target.value,
                  }),
                },
              );
              if (res.ok) {
                myTaskInitialApi();
              }
            }}
            value={params.row.status}
          >
            <option value="backlog">Backlog</option>
            <option value="inprogress">In Progress</option>
            <option value="review">Submitted For Review</option>
            <option value="needs_more_work">Need more work</option>
            <option value="stuck">Stuck</option>
            <option value="completed">Completed</option>
            <option value="archive">Archived</option>
          </select>
        </div>
      ),
    },
    {
      field: 'Timer',
      headerName: 'Timer',
      flex: 1,
      renderHeader: () => (
        <div
          className="tableHeader"
          style={{
            display: 'flex',
            justifyContent: 'end',
            textAlign: 'end',
            width: '50px',
          }}
          onClick={() => {}}
        >
          Timer
        </div>
      ),
      renderCell: (params) => (
        <div
          className="td"
          style={{
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            width: '50px',
          }}
          onClick={() => {
            setCurrentTarget(params.row);
            setShow3(true);
          }}
        >
          {' '}
          <img
            src={Timer}
            alt="Timer"
            style={{ width: '28px', height: '28px' }}
          />
        </div>
      ),
    },
    {
      field: 'Comments',
      headerName: 'Comments',
      flex: 1,
      renderHeader: () => <div className="tableHeader">Comments</div>,
      renderCell: (params) => {
        // eslint-disable-next-line
        const comments = params.row.comments;
        const task = params.row;

        const hasUnreadMentions = comments?.some(
          (message) =>
            !message?.read_message?.some(
              (item) => item?.read_by === userData.id,
            ) &&
            new RegExp(
              `<span data-type="mention" class="deliverable-mention" data-id="@${userData.first_name}">@${userData.first_name}</span>`,
              'i',
            ).test(message.message),
        );

        const unreadMessagesCount = comments?.filter(
          (message) =>
            !message?.read_message?.some(
              (item) => item?.read_by === userData.id,
            ),
        )?.length;

        return (
          // eslint-disable-next-line
          <>
            {params.row.type === 'quick_task' ? (
              ''
            ) : (
              <Box
                onClick={() => handleMyChatShow(task, comments)}
                sx={{
                  padding: '7px 2px',
                  background: '#F8F9FA',
                  cursor: 'pointer',
                  gap: '10px',
                  borderRadius: '8px',
                  display: 'flex',
                  alignContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  justifyContent: 'space-between',
                  width: '70%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '50%',
                  }}
                >
                  <span
                    style={{
                      backgroundColor: unreadMessagesCount
                        ? hasUnreadMentions
                          ? '#00E6B8'
                          : '#E1D0FF'
                        : '#e3e3e3',
                      border: '2px solid #E9ECEF',
                      fontSize: '12px',
                      color: hasUnreadMentions ? '#fff' : '#222831',
                      padding: '1px 4px',
                      borderRadius: '50%',
                    }}
                  >
                    {Array.isArray(comments) && comments.length
                      ? comments?.filter(
                          (message) =>
                            !message?.read_message?.some(
                              (item) => item?.read_by === userData.id,
                            ),
                        )?.length
                      : 0}
                  </span>
                </Box>
                <Box
                  sx={{
                    borderLeft: '1px solid gray',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '60%',
                  }}
                >
                  <span
                    style={{
                      backgroundColor: '#e3e3e3',
                      color: '#222831',
                      border: '2px solid #E9ECEF',
                      fontSize: '12px',
                      padding: '1px 4px',
                      borderRadius: '50%',
                      marginLeft: '11px',
                    }}
                  >
                    {Array.isArray(comments) && comments.length
                      ? comments?.length
                      : 0}
                  </span>
                </Box>
              </Box>
            )}
          </>
        );
      },
    },
  ];

  // ? __________________________________________________________DECLARATION OF 'PARAMS:ROW'____________________________________________
  const row =
    Array.isArray(projects) && projects?.length
      ? projects?.map((item) => {
          const currentUserTotalTaskAssignedTime = item.assignee_task.filter(
            (item) => item.assignee_user === currentUser.id,
          )[0].hours;

          return {
            id: item.id,
            taskID: item.id,
            task_name: item.task_name,
            project: item.task_project,
            company: item.company,
            assignee_hours:
              Team && Team.member ? currentUserTotalTaskAssignedTime : null,
            assinger: Team && Team.member ? item.created_by.id : null,
            assignee_task:
              Array.isArray(item.assignee_task) && item.assignee_task.length
                ? item.assignee_task
                : [],
            assignee:
              Array.isArray(item.assignee_task) && item.assignee_task.length
                ? item.assignee_task
                : [],
            status: item.status,
            my_tasks: true,
            time_tracking_start: item.time_tracking_start,
            time_tracking_end: item.time_tracking_end,
            tracked_time: item.tracked_time,
            description: item.description,
            eta: item.estimated_hours,
            alt_description: item.alt_description,
            notes: item.notes,
            target_start: item.target_start,
            due_date: item.due_date
              ? `${months[item.due_date.split('-')[1]]} ${
                  item.due_date.split('-')[2]
                }, ${item.due_date.split('-')[0]}`
              : 0,
            time_remaing: Math.floor(
              (new Date(item.finish_date) - new Date()) / 1000,
            ),
            contact: item.contact,
            billable: item.billable,
            time_tracking: item.time_tracking.filter(
              (item) => item.created_by.id === currentUser.id,
            ),
            type: item.task_type,
            work_type: item.task_worktype ? item.task_worktype : [],
            str_worktype: item.work_type ? item.work_type : '',
            comments: fetchedComments[item?.id] || [], // Add comments here
          };
        })
      : false;

  const charShayo = [
    { dent: 'Backlog', identifier: 'backlog' },
    { dent: 'Inprogress', identifier: 'inprogress' },
    { dent: 'Stuck', identifier: 'stuck' },
    { dent: 'Completed', identifier: 'completed' },
  ];

  const handleCardMove = async (cardId, targetLaneId) => {
    const find = projects.find((item) => Number(item.id) === Number(cardId));
    const newStatus = charShayo.find((ele) => ele.dent === targetLaneId);

    setProjects((prev) => {
      const shallowCopy = prev.map((item) => ({ ...item }));
      const int = shallowCopy.findIndex((ele) => Number(ele.id) === find.id);
      shallowCopy[int].status = newStatus.identifier;
      return [...shallowCopy];
    });
    const resP = await fetch(
      `${ENDPOINT}/api/project/task/update/${find.id}/`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${token}`,
        },
        body: JSON.stringify({
          task_name: find.task_name,
          status: newStatus.identifier,
        }),
      },
    );
    if (!resP.ok) {
      return;
    }
    dispatch(getQuickTask({ access_token: token }));
  };

  const realData =
    Array.isArray(row) && row.length
      ? charShayo.map((status, index) => ({
          id: status.dent,
          title: status.dent,
          label: index,
          cards: row
            .filter((ele) => ele.status === status.identifier)
            .map((ca) => ({
              id: ca?.id,
              title: ca?.task_name ?? '',
              description: ca.description ? ca.description : '',
              label: ca.due_date
                ? `Due On ${ca.due_date.split('T')[0]} ${ca.due_date.split(',')[1]}`
                : 'No due date available',
              otherText: ca.company
                ? currentCompanies.find(
                    (sa) => Number(sa.id) === Number(ca.company),
                  )?.name
                : '',
              assignee: ca.assignee ? ca.assignee : false,
            })),
        }))
      : [];

  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [formData, setFormData] = useState({});

  const [show1, setShow1] = useState(false);
  const courses = useSelector((state) => state.NetNetU.courses).filter(
    (item) => item.course_type === 'help_videos',
  );
  const welcomeChapters = courses.flatMap((course) => {
    return course.chapters.filter((chapter) => chapter.title === 'My Tasks');
  });
  return isReady ? (
    !myArr || !Array.isArray(myArr) || !myArr.length ? (
      <section className="oppor-section">
        <div className="oppor-row">
          <div className="oppor-col">
            <h3 className="fstxtPro">My Tasks</h3>
            <img className="icon-sm" src={Dots} alt="dots" />
            <img className="icon-sm" src={Gear} alt="gear" />
            <p>Filters</p>
            <img
              alt="help-video-icon"
              src={helpVid}
              onClick={() => {
                setShow(true);
              }}
              style={{
                color: '#a6a6a6',
                marginLeft: '10px',
                width: '28px',
                height: '28px',
                cursor: 'pointer',
              }}
            />
          </div>
        </div>
        <section className="myCenter" style={{ height: screenHeight - 140 }}>
          <article className="box-txt">
            <h3 className="txtCenter">No Tasks yet</h3>
            <p className="txtCenter">All Your Tasks Would Appear Here!</p>
          </article>
        </section>
      </section>
    ) : (
      <Bounce left>
        <>
          <section className="oppor-section">
            <div className="oppor-row">
              <div className="oppor-col">
                <h3 className="fstxtPro">My Tasks</h3>
                <img className="icon-sm" src={Dots} alt="dots" />
                <img className="icon-sm" src={Gear} alt="gear" />
                <p>Filters</p>
                <img
                  alt="help-video-icon"
                  src={helpVid}
                  onClick={() => {
                    setShow1(true);
                  }}
                  style={{
                    color: '#a6a6a6',
                    marginLeft: '10px',
                    width: '28px',
                    height: '28px',
                    cursor: 'pointer',
                  }}
                />
              </div>
            </div>
            <Box
              sx={{
                width: '100%',
                minHeight: screenHeight - 0,
                maxHeight: screenHeight - 0,
              }}
            >
              <PrevBox
                timeParams={timeParams}
                setTimeParams={setTimeParams}
                args={args}
                setArgs={setArgs}
                projects={projects}
                setProjects={setProjects}
                myArr3={myArr3}
                newWithAssine={newWithAssine}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                currentUser={currentUser}
                myArr={myArr3}
                setKanbanShow={setKanbanShow}
              />

              {kanbanShow ? (
                <Box sx={{ width: '100%' }}>
                  <Board
                    data={{ lanes: realData }}
                    draggable={true}
                    handleDragEnd={handleCardMove}
                    style={{
                      backgroundColor: '#F4F5F7',
                      width: '100%',
                      borderRadius: '4px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                    laneStyle={{
                      background: '#e3e3e3',
                      margin: '10px',
                      borderRadius: '8px',
                      width:
                        screenSize >= 1650 && screenSize <= 1749
                          ? '340px'
                          : screenSize >= 1750
                            ? '380px'
                            : screenSize < 1300
                              ? '220px'
                              : '260px',
                    }}
                    components={{
                      LaneHeader: CustomLaneHeader,
                      Card: CustomCard,
                    }}
                  />
                </Box>
              ) : (
                <DataGrid
                  rows={row}
                  columns={columns}
                  rowHeight={80}
                  sx={{
                    minHeight: screenHeight - 225,
                    maxHeight: screenHeight - 225,
                    overflow: 'auto',
                    borderRadius: '8px',
                    '& .css-1kwdphh-MuiDataGrid-virtualScrollerContent': {
                      backgroundColor: '#fff',
                    },
                  }}
                />
              )}

              {taskChatShow ? (
                <MyTaskChat
                  setComments={setComments}
                  comments={comments}
                  setDelivChatShow={setTaskChatShow}
                  delivChatShow={taskChatShow}
                  deliverableChat={taskChat}
                  ChannelList={ChannelList}
                />
              ) : (
                ''
              )}
            </Box>
          </section>

          <TaskDetail
            formData={formData}
            currentTarget={currentTarget}
            show={show}
            setShow={setShow}
            setFormData={setFormData}
            setCurrentTarget={setCurrentTarget}
            token={token}
          />
          <TaskDetail2
            formData={formData}
            currentTarget={currentTarget}
            show={show3}
            setShow={setShow3}
            setFormData={setFormData}
            setCurrentTarget={setCurrentTarget}
            token={token}
          />
          <HelpVideo
            show={show1}
            setShow={setShow1}
            welcomeChapters={welcomeChapters}
          />
        </>
      </Bounce>
    )
  ) : (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        padding: '4rem 1rem',
      }}
    >
      <Skeleton
        sx={{ background: '#f0f0f0' }}
        variant="rounded"
        width="100%"
        height={80}
        animation="wave"
      />
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 2, 3, 3].map((item) => {
        return (
          <Skeleton
            sx={{ background: '#f0f0f0' }}
            variant="rounded"
            width="100%"
            height="40px"
            animation="wave"
            key={item}
          />
        );
      })}
    </Box>
  );
}

export default MyTasks;
