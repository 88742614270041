import { BsFillCircleFill } from "react-icons/bs";

const legened = ()=>{
    return <ul className="legend">
        <li>Key Indicators</li>
        <li><BsFillCircleFill style={{color: '#5ee690'}}/> Actual Hours (0-80%) of Estimated Hour</li>
        <li> <BsFillCircleFill style={{color: '#dffae9'}}/> Remaining/Estimated Hours</li>
        <li> <BsFillCircleFill style={{color: '#dc241c'}}/> Over Estimated Hours</li>
        <li> <BsFillCircleFill style={{color: '#f59825'}}/> Reached 81% of Estimated Hours</li>
    </ul>
}

export {legened}