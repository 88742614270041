import { useState } from 'react';

import { Box, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { PreLoading } from '../People/EditContact';
import { ENDPOINT } from '../../Opportunity/Redux/oppor';
import { reqImport, resImport } from '../ContactRedux';

function FourthStep({
  step,
  constructor,
  newParser,
  setIsImporting,
  isImporting,
}) {
  const dispatch = useDispatch();
  const access_token = useSelector((state) => state.auth.user.access);

  const [loading, setLoading] = useState(false);
  const [letData, setLetData] = useState({
    contact_type: '',
    tags: [],
  });

  const [tempStr, setTempStr] = useState('');

  /* eslint-disable */
  function checkValidKeys(arr) {
    const validKeys = ['First Name', 'Last Name', 'Company Name', 'Email'];
    for (let obj of arr) {
      for (let key of validKeys) {
        if (!(key in obj)) {
          return false;
        }
      }
    }
    return true;
  }

  const keyMap = {
    first_name: 'First Name',
    last_name: 'Last Name',
    email: 'Email',
    mobile_num: 'Mobile Number',
    title: 'Title',
    tags: 'Tags',
    description: 'Personal Description',
    website: '',
    company: 'Company Name',
    country: 'Person Country',
    state: 'Person State / Province',
    city: 'Person City',
    street: 'Person Address Line 1',
    street2: 'Person Address Line 2',
    zip: 'Person Zip',
    linkedin_url: 'Personal LinkedIn',
    facebook_url: 'Personal Facebook',
    twitter_url: 'Personal X', // Assuming this maps to "Personal X" in the original object
    instagram_url: 'Personal Instagram',
    company_email: 'Company Email 1',
    company_email2: 'Company Email 2',
    company_description: 'Company Description',
    company_address_zip: 'Company Zip Code',
    company_country: 'Company Country',
    company_city: 'Company City',
    company_state: 'Company State / Province',
    company_street: 'Company Address 1',
    company_street2: 'Company Address 2',
    company_instagram_url: 'Company Instagram',
    company_twitter_url: 'Company X', // Assuming this maps to "Company X" in the original object
    company_linkedin_url: 'Company Linkedin',
    company_facebook_url: 'Company Facebook',
    company_office_num: 'Office Telephone',
    company_industry: 'NAICS Industry',
    company_tags: 'Company Tags',
    company_type: 'Company Type',
    company_description: 'Company Description',
    address_zip: 'Person Zip / Postal Code',
    company_url: 'Company URL',
    company_email: 'Company Email 1',
    company_email2: 'Company Email 2',
  };

  // Function to transform keys of an object
  function transformKeys(obj, exchangedKeyMap) {
    const transformedObject = {};
    for (const key in obj) {
      if (exchangedKeyMap.hasOwnProperty(key)) {
        transformedObject[exchangedKeyMap[key]] = obj[key];
      } else {
        transformedObject[key] = obj[key];
      }
    }
    return transformedObject;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(reqImport(true));
    setIsImporting(true);
    const initalCheck = checkValidKeys(newParser);
    if (!initalCheck) {
      const Construct = constructor.map((item) => ({
        ...item,
        ...letData,
        tags: letData.tags ? letData.tags.join(',') : '',
      }));
      try {
        const res = await fetch(`${ENDPOINT}/api/contact/import/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${access_token}`,
          },
          body: JSON.stringify(
            Construct.map((item) => ({...item})),
          ),
        });
        const data = await res.json();
        if (data) {
          dispatch(resImport(data));
          if (!res.ok) {
            setIsImporting(false);
          } else {
            setIsImporting(false);
          }
        }
      } catch (error) {
        setIsImporting(false);
      }
    } else {
      const exchangedKeyMap = {};
      for (const key in keyMap) {
        if (keyMap.hasOwnProperty(key)) {
          exchangedKeyMap[keyMap[key]] = key;
        }
      }
      const transformedParser = newParser.map((obj) =>
        transformKeys(obj, exchangedKeyMap),
      );
      const res2 = await fetch(`${ENDPOINT}/api/contact/import/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${access_token}`,
        },
        body: JSON.stringify(
          transformedParser.map((item) => ({...item })),
        ),
      });
      const data2 = await res2.json();
      if (data2) {
        dispatch(resImport(data2));
        if (!res2.ok) {
          setLoading(false);
          setIsImporting(false);
        } else {
          setLoading(false);
          setIsImporting(false);
        }
      }
    }
  };
  /* eslint-enable */
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '2rem',
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '1rem',
          border: '1px solid #d9d9d9',
          backgroundColor: '#fcfcfc',
          width: '60%',
          borderRadius: '8px',
          padding: '0.44rem 0.88rem',
        }}
      >
        <h3 style={{ color: '#000' }} className="txtCenter txtSM">
          Types & Tags
        </h3>
        <p className="txtCenter initialParagraph txtESM">
          There are two ways to categorize people and companies in Net Net. The
          first is called “Types” and at the highest level, this is where you
          name them things like customer, subcontractor, prospect, etc. Big
          buckets if you will. Tags are more nuanced, they can be person, place,
          things, anything you want to group people under.
        </p>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          gap: '1rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            border: '1px solid #d9d9d9',
            borderRadius: '8px',
            margin: '0 auto',
            width: {
              sm: '100%',
              md: '80%',
              lg: '80%',
              xl: '100%',
            },
            alignItems: 'flex-start',
            backgroundColor: '#fff',
            padding: '0.87rem 1rem',
          }}
        >
          <div
            className="dFlex"
            style={{
              gap: '1rem',
              fontFamily: 'Open Sans',
              fontWeight: 600,
              fontSize: '1.1rem',
            }}
          >
            Type of Contact
          </div>

          <div
            style={{
              margin: '1rem 0',
              fontFamily: 'Open Sans',
              fontWeight: 500,
              fontSize: '1rem',
            }}
          >
            Select the type of Contact the user falls under.
          </div>
          <FormControl sx={{ width: '30%' }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              variant="outlined"
              value={letData.contact_type}
              onChange={(e) =>
                setLetData({
                  ...letData,
                  contact_type: e.target.value,
                })
              }
              sx={{
                outline: 'none',
                borderColor: '#711fff',
                '& .MuiSelect-select:focus': {
                  borderColor: '#711fff',
                },
                '& .MuiSelect-select:hover': {
                  outline: 'none',
                  borderColor: '#711fff',
                },
                '& .MuiSelect-select': {
                  borderColor: '#711fff',
                },
                '& .css-9xnun1-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                  {
                    borderColor: '#711fff',
                  },
              }}
            >
              <MenuItem value="type">Select Type</MenuItem>
              <MenuItem value="customer">Client</MenuItem>
              <MenuItem value="prospect">Prospect</MenuItem>
              <MenuItem value="subcontractor">Subcontractor</MenuItem>
              <MenuItem value="vendor">Vendor</MenuItem>
              <MenuItem value="partner">Partner</MenuItem>
              <MenuItem value="employee">Employee</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            border: '1px solid #d9d9d9',
            borderRadius: '8px',
            width: {
              sm: '100%',
              md: '80%',
              lg: '80%',
              xl: '100%',
            },
            margin: '0 auto',
            alignItems: 'flex-start',
            backgroundColor: '#fff',
            padding: '0.87rem 1rem',
          }}
        >
          <div className="dFlex">
            <h4
              style={{
                fontFamily: 'Open Sans',
                fontWeight: 600,
                fontSize: '1.1rem',
              }}
            >
              Tags
            </h4>
          </div>
          <div
            style={{
              margin: '1rem 0',
              fontFamily: 'Open Sans',
              fontWeight: 500,
              fontSize: '0.9rem',
            }}
          >
            Any Tags you apply here will be added to all the contacts uploaded
            during the import. You can use tags to organize your list and create
            unique segments after the import is complete.
          </div>
          <TextField
            sx={{
              width: '30%',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ADADAD',
                },
                '&:hover fieldset': {
                  borderColor: '#711fff',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#5a0bdf',
                },

                '&.Mui-error fieldset': {
                  borderColor: 'red',
                },

                '&.Mui-disabled fieldset': {
                  borderColor: 'grey',
                },
              },
              '& .MuiFormLabel-root': {
                color: '#03071e66',
              },
              '& .MuiFormLabel-root.Mui-focused': {
                color: '#5a0bdf',
              },
              '& .MuiFormLabel-root.Mui-error': {
                color: 'red',
              },
            }}
            variant="outlined"
            label="Enter Tags!"
            value={tempStr}
            onChange={(e) => setTempStr(e.target.value)}
            // eslint-disable-next-line
            onKeyDown={(e) => {
              if (e.key === 'Enter' && tempStr) {
                setLetData({
                  ...letData,
                  tags: [...letData.tags, tempStr],
                });
                setTempStr('');
              } else {
                return 0;
              }
            }}
          />
          {letData.tags ? (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                width: '100%',
                gap: '1rem',
                marginTop: '1rem',
              }}
            >
              {letData.tags.map((tag) => (
                <Box key={tag} className="greyTag">
                  {tag}{' '}
                  <button
                    className="cross noBorder"
                    type="button"
                    onClick={() => {
                      setLetData({
                        ...letData,
                        tags: letData.tags.filter((t) => t !== tag),
                      });
                    }}
                  >
                    x
                  </button>
                </Box>
              ))}
            </Box>
          ) : (
            false
          )}
        </Box>
      </Box>
      {loading && <PreLoading />}
      <Box
        sx={{
          position: 'fixed',
          bottom: '1px',
          width: { xl: '85%', lg: '80%', md: '98%' },
        }}
        className="AlBottom footer"
      >
        <ul className="footer-list">
          <li className={step === 2 ? 'footer-item thisStep' : 'footer-item'}>
            Import Files
          </li>
          <li className={step === 3 ? 'footer-item thisStep' : 'footer-item'}>
            Map Fields
          </li>
          <li className={step === 4 ? 'footer-item thisStep' : 'footer-item'}>
            Type
          </li>
          <li className={step === 5 ? 'footer-item thisStep' : 'footer-item'}>
            Exceptions
          </li>
        </ul>

        <div className="AlRight dd-import-group">
          {' '}
          <button
            type="button"
            className="smBtn"
            onClick={(e) => handleSubmit(e)}
            style={{ marginBottom: '1rem' }}
            disabled={isImporting}
          >
            {isImporting ? <i className="bx bx-loader-alt bx-spin" /> : 'NEXT'}
          </button>
        </div>
      </Box>
    </Box>
  );
}

export default FourthStep;
