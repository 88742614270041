import React from 'react';
import { useSelector } from 'react-redux';

import { TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { Box } from '@mui/system';

import moment from 'moment';

function TeamMemberGroupTableBody({ item, Projects }) {
  const currentCompanies = useSelector((state) => state.Contacts.companies);

  return (
    <TableBody>
      {item?.tasks?.length > 0 ? (
        Array.isArray(item?.tasks) &&
        item?.tasks?.length &&
        item?.tasks?.map((values) => {
          const userTrackingTimeWorkType = values.assignee_task.filter(
            (task) =>
              task.assignee_user === values.time_tracking[0]?.created_by?.id,
          )[0]?.assignee_work_type?.name; // * string.

          const start =
            Array.isArray(values.time_tracking) &&
            values.time_tracking.length &&
            values.time_tracking[values.time_tracking.length - 1]
              .time_tracking_start;
          const end =
            Array.isArray(values.time_tracking) &&
            values.time_tracking.length &&
            values.time_tracking[values.time_tracking.length - 1]
              .time_tracking_stop;

          const startDateTime = new Date(start);
          const endDateTime = new Date(end);

          const timeDifference = endDateTime - startDateTime;
          const hours = Math.floor(timeDifference / (1000 * 60 * 60));
          const minutes = Math.floor(
            (timeDifference % (1000 * 60 * 60)) / (1000 * 60),
          );

          const startTime = new Date(start).toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          });

          const endTime = new Date(end).toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          });

          const project_name =
            Array.isArray(Projects) && Projects.length && values?.task_project
              ? Projects.find((pro) => pro?.id === values?.task_project)?.title
              : '';

          const companyID =
            Projects?.find((project) => project.company === values?.company)
              ?.company ||
            values?.company ||
            '';

          const companyName =
            currentCompanies?.find((company) => company.id === companyID)
              ?.name ?? '----------';

          return (
            <TableRow
              key={values?.id}
              sx={{
                height: '92px',
                '& > *': { borderBottom: '1px solid #e5e5e5' },
              }}
            >
              <TableCell
                sx={{
                  fontWeight: 500,

                  '.css-175vu5o-MuiTableCell-root': {
                    fontSize: '14px !important',
                  },
                }}
              >
                <Box>
                  <Typography>{values?.task_name}</Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                    {project_name && (
                      <svg
                        style={{ marginRight: '10px' }}
                        width="9"
                        height="7"
                        viewBox="0 0 9 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path id="Vector 4" d="M1 0V6H9" stroke="black" />
                      </svg>
                    )}
                    <Typography
                      sx={{
                        color: '#5600EB',
                        fontSize: '12px',
                        width: '120px',
                        '&:hover': { color: 'black' },
                      }}
                    >
                      {project_name}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>

              <TableCell sx={{ width: '15%' }}>{companyName}</TableCell>

              <TableCell sx={{ minWidth: '360px', maxWidth: '360px' }}>
                <div
                  // eslint-disable-next-line
                  dangerouslySetInnerHTML={{
                    __html: values?.time_tracking[0]?.note ?? '',
                  }}
                />
              </TableCell>
              <TableCell sx={{ width: '15%' }}>
                {userTrackingTimeWorkType}
              </TableCell>
              <TableCell sx={{ minWidth: '160px' }}>
                {timeDifference === 0 ? (
                  <Typography> No Time Tracked </Typography>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '1rem',
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: '600',
                        }}
                      >
                        {startTime}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: '600',
                        }}
                      >
                        {endTime}
                      </Typography>
                    </Box>

                    <Typography
                      sx={{
                        fontWeight: '400',
                        fontSize: '12px',
                        color: '#686a78',
                      }}
                    >
                      {moment(values?.time_tracking[0]?.date || end).format(
                        'MMMM DD, yy',
                      ) !== 'January 01, 1970'
                        ? moment(values?.time_tracking[0]?.date ?? end).format(
                            'MMMM DD, yy',
                          )
                        : 'No Time Tracked'}
                    </Typography>
                  </Box>
                )}
              </TableCell>
              <TableCell sx={{ minWidth: '90px', fontSize: '14px' }}>
                <Box
                  sx={{
                    background: '#D2B8FF',
                    color: '#310085',
                    fontSize: '13px',
                    borderRadius: '6px',
                    fontWeight: 600,
                    padding: '2px 10px',
                  }}
                >
                  {`${hours}h:${minutes}m`}
                </Box>
              </TableCell>
            </TableRow>
          );
        })
      ) : (
        <TableRow>
          <TableCell colSpan={5} align="center">
            <p
              style={{
                width: '100%',
                textAlign: 'center',
                alignSelf: 'center',
              }}
            >
              No Entries
            </p>
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
}
export default TeamMemberGroupTableBody;
