import { Check, KeyboardArrowRight } from '@mui/icons-material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Alert,
  Typography,
  Avatar,
  AvatarGroup,
  TextField,
  Autocomplete,
  Popper,
  Popover,
  Tooltip,
  Skeleton,
} from '@mui/material';
import { Box } from '@mui/system';
import Shake from 'react-reveal/Shake';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Popup from 'reactjs-popup';
import { MdControlPointDuplicate } from 'react-icons/md';
import dayjs from 'dayjs';
import uuid from 'react-uuid';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
// eslint-disable-next-line
import { CSSTransition } from 'react-transition-group';

import moreIco from '../Opportunity/assets/moreIco.png';
import DeleteIcon from '../Contacts/assets/DeleteIcon.png';
import Test from '../Test';
import { New_channels } from '../Stream/streamRedux';
import SimpleDialog from '../SimpleDialog';
import editTask2 from '../Contacts/assets/editTask.png';
import { calculateTotalDurationObtained } from '../Projects/QuickTasks';
import { NetProgressProject } from '../../Componenets/ProgressBar';
import taskIcon from '../Opportunity/assets/taskIcon.svg';
import { NetNetCheckBox, PreLoader1 } from '../../Componenets/Misc';
import { ENDPOINT } from '../Opportunity/Redux/oppor';
import { getProjects, getQuickTask } from '../Projects/proRedux';

function Delete({ setSureDelete, currentTarget, getData }) {
  const access_token = useSelector((state) => state.auth.user.access);
  const [loading, setLoading] = useState(false);
  const handleCompletion = async () => {
    setLoading(true);
    if (currentTarget.title === 'Task') {
      const response = await fetch(
        `${ENDPOINT}/api/project/task/delete/${currentTarget.id}/`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `JWT ${access_token}`,
            'Content-Type': 'application/json',
          },
        },
      );
      if (response.ok) {
        getData();
        setSureDelete(false);
      }
    }
    if (currentTarget.title === 'Assignee') {
      const response = await fetch(
        `${ENDPOINT}/api/project/task/update/${currentTarget.requestID}/`,
        {
          method: 'PUT',
          headers: {
            Authorization: `JWT ${access_token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            delete_assignees: [currentTarget?.assignee_delete],
            delete_assignee_worktypes: [currentTarget.assignee_work_type?.id],
          }),
        },
      );
      if (response.ok) {
        getData();
        setSureDelete(false);
      }
    }
    if (currentTarget.title === 'Worktype') {
      const response = await fetch(
        `${ENDPOINT}/api/project/task/update/${currentTarget.requestID}/`,
        {
          method: 'PUT',
          headers: {
            Authorization: `JWT ${access_token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            delete_assignees: currentTarget?.assignee_Array,
            delete_assignee_worktypes: currentTarget?.worktype_Array,
          }),
        },
      );
      if (response.ok) {
        getData();
        setSureDelete(false);
      }
    }
  };
  const hasTimeTracking =
    Array.isArray(currentTarget?.time_tracking) &&
    currentTarget?.time_tracking.length
      ? currentTarget?.time_tracking?.filter((ele) => ele?.tracked_time_hours)
      : currentTarget?.hasTimeTracking;

  return (
    <section
      className="loading"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        backgroundColor: 'rgba(255,255,255,0.5)',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 999,
      }}
    >
      <Box
        sx={{
          width: '850px',
          height: '220px',
          background: 'rgba(241, 243, 245, 0.50)',
          padding: '2rem',
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: '95%',
            height: '95%',
            backgroundColor: 'rgba(255,255,255)',
            border: '1px solid #d9d9d9',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '8px',
            padding: '0.68rem 1rem',
            justifyContent: 'space-around',
            boxShadow: '2px 2px 6px 0px #D9D9D9',
            gap: '10px',
          }}
        >
          <p
            style={{
              fontSize: '0.9rem',
              fontWeight: '600',
              fontFamily: 'Open Sans, sans-serif',
              color: '#710FFF',
            }}
          >
            DELETE TASK
          </p>
          <p
            className="fstxt"
            style={{ width: '80%', textAlign: 'center', fontWeight: '400' }}
          >
            {(Array.isArray(hasTimeTracking) && hasTimeTracking.length) ||
            hasTimeTracking
              ? 'You can not delete tasks which have time logged to them'
              : `Are you sure you want to Delete this ${currentTarget?.title}`}
          </p>
          <Box
            sx={{
              display: 'flex',
              justifyContent:
                (Array.isArray(hasTimeTracking) && hasTimeTracking.length) ||
                hasTimeTracking
                  ? 'center'
                  : 'space-between',
              alignItems: 'center',
              width: '50%',
              margin: '0 auto',
            }}
          >
            {(Array.isArray(hasTimeTracking) && hasTimeTracking.length) ||
            hasTimeTracking ? null : (
              <button
                type="button"
                className="cancel-btn"
                onClick={() => setSureDelete(false)}
              >
                Cancel
              </button>
            )}
            <button
              type="button"
              style={{
                fontFamily: "'Neue Haas Display Pro', 'Open Sans', sans-serif",
                fontWeight: '500',
                backgroundColor:
                  (Array.isArray(hasTimeTracking) && hasTimeTracking.length) ||
                  hasTimeTracking
                    ? '#710FFF'
                    : '#cd0b4c',
                borderRadius: '5px',
                color: 'var(--white-100)',
                border: 'none',
                cursor: 'pointer',
                textAlign: 'center',
                fontSize: '1rem',
                padding: '0rem 2rem',
                height: '38px',
              }}
              onClick={
                (Array.isArray(hasTimeTracking) && hasTimeTracking.length) ||
                hasTimeTracking
                  ? () => setSureDelete(false)
                  : () => {
                      handleCompletion();
                    }
              }
            >
              {loading ? (
                <Box
                  sx={{ width: '30px', height: '30px', margin: '0rem 0.1rem' }}
                >
                  <PreLoader1 />
                </Box>
              ) : (Array.isArray(hasTimeTracking) && hasTimeTracking.length) ||
                hasTimeTracking ? (
                'Okay'
              ) : (
                'Delete'
              )}{' '}
            </button>
          </Box>
        </Box>
      </Box>
    </section>
  );
}

function Row({
  id,
  setRealComments,
  setShowName,
  showName,
  index,
  formData,
  dil,
  setSureDelete,
  setDeleteData,
  getData,
  types,
  row,
  setCurrentTarget,
  setShowOfTask,
  setDropDown,
  dropDown,
  setIsTyping,
  unique_id,
  setFormData,
  setDeliverable,
  dltingTask,
}) {
  const [openLink, setOpenLink] = useState(false);
  const dispatch = useDispatch();
  const [editTaskData, setEditTaskData] = useState({
    assignee_task: '',
    worktype: '',
    hours: null,
  });

  const access_token = useSelector((state) => state.auth.user.access);

  const switcher =
    row?.task_worktype &&
    Array.isArray(row?.task_worktype) &&
    row?.task_worktype.length > 1;

  const teamOptions = useSelector(
    (state) => state?.userTeam?.team?.member ?? [],
  );

  const [showEditDesc, setShowEditDesc] = useState(false);

  const [editTask, setEditTask] = useState(false);

  const totalAssignedHours =
    Array.isArray(row.assignee_task) && row.assignee_task.length
      ? row.assignee_task.reduce((total, acc) => total + acc.hours, 0)
      : 0;

  const worked_hour = row && calculateTotalDurationObtained(row?.time_tracking);

  const new_channals = useSelector((state) => state.Stream.new_channels);
  const [comments, setComments] = useState(null);
  const userData = useSelector((state) => state.userData.userData);
  const [commentLoader, setComentLoader] = useState(false);

  useEffect(() => {
    setComentLoader(true);
    if (new_channals && row) {
      const crtMessages = new_channals?.find((item) => item?.task === row?.id);
      // eslint-disable-next-line
      const response = fetch(
        `${ENDPOINT}/api/chat/channel/detail/${crtMessages?.id}/`,
        {
          method: 'GET',
          headers: {
            Authorization: `JWT ${access_token}`,
            'Content-Type': 'application/json',
          },
        },
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          setComments(data?.message_in_channel);
          setComentLoader(false);
          // Process the response data here
        })
        .catch(() => {
          setComentLoader(false);
        });
    }
  }, [new_channals, row]);

  const [mentionsLength, setMentionsLength] = useState();

  useEffect(() => {
    const regexPattern = new RegExp(
      `<span data-type="mention" class="deliverable-mention" data-id="@${userData.first_name}">@${userData.first_name}</span>`,
      'i',
    );

    const filtered_comments = comments?.filter(
      (message) =>
        !message?.read_message?.some((item) => item.read_by === userData?.id),
    );

    const shallowCopy = filtered_comments?.map((item) => ({ ...item }));
    const filteration = shallowCopy?.filter((msg) =>
      regexPattern.test(msg.message),
    );

    setMentionsLength(filteration);
  }, [comments]);

  const handleDeliverableShow = async () => {
    setShowOfTask(true);
    setIsTyping(true);
    setCurrentTarget(row);

    // Assuming `comments` is an array of tasks
    if (comments?.length) {
      // eslint-disable-next-line
      for (const task of comments) {
        try {
          const find = task?.read_message.some(
            (readMessage) => readMessage.read_by === userData.id,
          );
          if (!find) {
            // eslint-disable-next-line
            const response = await fetch(
              `${ENDPOINT}/api/chat/channel/message/read/create/`,
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `JWT ${access_token}`,
                },
                body: JSON.stringify({
                  message: task.id,
                  read_by: userData.id,
                }),
              },
            );
            if (!response.ok) {
              return; // Optionally handle the error
            }
          }
          // eslint-disable-next-line
        } catch (error) {}
      }
      const crtMessages = new_channals?.find((item) => item?.task === row.id);

      // eslint-disable-next-line
      const response = fetch(
        `${ENDPOINT}/api/chat/channel/detail/${crtMessages?.id}/`,
        {
          method: 'GET',
          headers: {
            Authorization: `JWT ${access_token}`,
            'Content-Type': 'application/json',
          },
        },
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          setComments(data?.message_in_channel);
          setComentLoader(false);
          // Process the response data here
        })
        .catch(() => {
          setComentLoader(false);
        });

      const crtMessages1 = new_channals
        ?.filter((item) => !item?.task && item.deliverable)
        .find((item) => item?.deliverable === crtMessages.deliverable);
      // eslint-disable-next-line
      const response1 = fetch(
        `${ENDPOINT}/api/chat/channel/detail/${crtMessages1?.id}/`,
        {
          method: 'GET',
          headers: {
            Authorization: `JWT ${access_token}`,
            'Content-Type': 'application/json',
          },
        },
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          setRealComments(data?.message_in_channel);
          // Process the response data here
        })
        .catch(() => {});
    }
  };

  const [error, setError] = useState(false);
  const [showStuff, setShowStuff] = useState(false);
  const worktypeMap = {};

  row.task_worktype.forEach((worktype) => {
    const { name, ...rest } = worktype;
    if (!worktypeMap[name]) {
      worktypeMap[name] = { ...rest, hours: worktype.hours, name };
    } else {
      worktypeMap[name].hours += worktype.hours;
    }
  });

  const uniqueWorktypes = Object.values(worktypeMap);
  const [html, setHtml] = useState(row?.description);
  const boxRef = useRef(null);

  const [btn, setbtn] = useState();
  const check = (e) => {
    setbtn(e);
  };
  const [task_name, setTask_name] = useState(row?.task_name);
  const [linkUrl, setLinkUrl] = useState('');

  const setLink = () => {
    const url = linkUrl.trim(); // Trim whitespace from the input URL
    if (url !== '') {
      btn.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run();
    }
    setOpenLink(false);
    setLinkUrl('');
  };

  return (
    <Draggable key={row?.id} draggableId={row?.task_name} index={index}>
      {(provided) => (
        <>
          <TableRow
            id={id}
            ref={provided.innerRef}
            // eslint-disable-next-line
            {...provided.draggableProps}
            // eslint-disable-next-line
            {...provided.dragHandleProps}
            onMouseOver={() => setShowStuff(true)}
            onMouseLeave={() => setShowStuff(false)}
            key={row?.id}
            sx={{
              position: 'relative',
              background: showStuff
                ? '#e5e5ea'
                : dropDown && dropDown === row?.id
                  ? '#e5e5ea'
                  : '#fff',
              color: dltingTask && dltingTask === row?.id ? '#fff' : '#000',
              height: '42px',
              '& > *': { borderBottom: '1px solid rgba(0,0,0,0.2)' },
            }}
          >
            <TableCell sx={{ padding: '5px' }}>
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  gap: '5px',
                  alignItems: 'left',
                }}
              >
                <DragIndicatorIcon
                  sx={{
                    opacity: showStuff ? '1' : 0,
                    transition: 'all 0.3s ease-in-out',
                    fontSize: '1.60rem',
                    fill: showStuff ? '#8D8F99' : '#fff',
                  }}
                />
                <div style={{ opacity: switcher ? 1 : 0 }}>
                  {dropDown && dropDown === row?.id ? (
                    <KeyboardArrowDownIcon
                      sx={{
                        transition: 'all 0.3s ease-in-out',
                        fill: '#323648',
                        cursor: 'pointer',
                      }}
                      onClick={() => setDropDown(false)}
                    />
                  ) : (
                    <KeyboardArrowRight
                      sx={{
                        transition: 'all 0.3s ease-in-out',
                        fill: '#323648',
                        cursor: 'pointer',
                      }}
                      onClick={() => setDropDown(row?.id)}
                    />
                  )}
                </div>
                <input
                  onChange={(e) => {
                    setTask_name(e.target.value);
                  }}
                  id="cypress-updateProjectTask-title-Input"
                  onBlur={async (e) => {
                    const res = await fetch(
                      `${ENDPOINT}/api/project/task/update/${row.id}/`,
                      {
                        method: 'PUT',
                        headers: {
                          'Content-Type': 'application/json',
                          Authorization: `JWT ${access_token}`,
                        },
                        body: JSON.stringify({
                          task_name: e.target.value,
                        }),
                      },
                    );

                    if (res.ok) {
                      const resGET = await fetch(
                        `${ENDPOINT}/api/project/detail/${unique_id}/`,
                        {
                          method: 'GET',
                          headers: {
                            'Content-Type': 'application/json',
                            Authorization: `JWT ${access_token}`,
                          },
                        },
                      );
                      const dataGET = await resGET.json();
                      if (resGET.ok) {
                        setFormData(dataGET);

                        if (
                          dataGET &&
                          Array.isArray(dataGET.deliverable_project) &&
                          dataGET.deliverable_project.length
                        ) {
                          setDeliverable(dataGET.deliverable_project);
                        }
                        dispatch(
                          getQuickTask({
                            access_token,
                          }),
                        );

                        dispatch(
                          getProjects({
                            access_token,
                          }),
                        );
                      }
                    }
                  }}
                  value={task_name ?? row.task_name}
                  type="text"
                  style={{
                    border: 'none',
                    outline: 'none',
                    width: '100%',
                    background: 'none',
                  }}
                  onMouseEnter={(e) =>
                    (e.target.style.borderBottom = '1px dashed gray')
                  } // Add border on hover
                  onMouseLeave={(e) =>
                    (e.target.style.borderBottom = '1px solid transparent')
                  } // Remove border when cursor leaves
                />
              </Box>
            </TableCell>

            <TableCell
              id="cypress-projectTask-description-pTag"
              onClick={() => setShowEditDesc(showEditDesc ? false : row?.id)}
              align="center"
              sx={{
                color: dltingTask && dltingTask === row.id ? '#fff' : '#000',
                padding: '5px',
                maxWidth: { xl: '150px', lg: '120px', md: '100px' },
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {html ? (
                <p
                  ref={boxRef}
                  onMouseEnter={(e) =>
                    (e.target.style.borderBottom = '1px dashed gray')
                  }
                  onMouseLeave={(e) =>
                    (e.target.style.borderBottom = '1px solid transparent')
                  }
                  style={{
                    cursor: 'pointer',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    maxHeight: '30px',
                    alignItems: 'center',
                  }}
                  // eslint-disable-next-line
                  dangerouslySetInnerHTML={{ __html: html }}
                />
              ) : (
                <p
                  ref={boxRef}
                  style={{
                    cursor: 'pointer',
                    whiteSpace: 'nowrap',
                    alignItems: 'center',
                  }}
                >
                  {' '}
                  -----{' '}
                </p>
              )}
            </TableCell>
            <TableCell sx={{ padding: '5px', width: '100px' }} align="center">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'center',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <AvatarGroup
                    max={3}
                    onClick={() => {
                      if (dropDown) {
                        setDropDown(false);
                      } else {
                        setDropDown(row?.id);
                      }
                    }}
                    sx={{
                      justifyContent: 'center',
                      textAlign: 'center',
                      paddingLeft: '1px',
                      borderBottom: '1px dashed gray',
                      '& .css-sxh3gq-MuiAvatar-root-MuiAvatarGroup-avatar': {
                        height: '21px',
                        width: '21px',
                        fontSize: '14px',
                      },
                      '& .css-rfuczr': {
                        height: '21px',
                        width: '21px',
                        fontSize: '14px',
                      },
                    }}
                  >
                    {Array.isArray(row?.assignee_task) &&
                    row.assignee_task.length
                      ? row.assignee_task.map((per) => {
                          const target = teamOptions.find(
                            (mem) =>
                              Number(mem?.id) === Number(per?.assignee_user),
                          );
                          return (
                            <Tooltip
                              key={target?.id}
                              title={
                                target?.first_name
                                  ? `${target?.first_name || ''} ${target?.last_name || ''}`
                                  : 'member'
                              }
                            >
                              <Avatar
                                key={target?.id} // Make sure to include a unique key for each avatar
                                sx={{ height: '20px', width: '20px' }}
                                src={
                                  target?.avatar
                                    ? target?.avatar
                                    : '/static/images/avatar/1.jpg'
                                }
                                alt={
                                  target?.first_name ? target.first_name : 'A'
                                }
                              />
                            </Tooltip>
                          );
                        })
                      : '------'}
                  </AvatarGroup>
                  <button
                    type="button"
                    onClick={() => {
                      setEditTask(!editTask);
                    }}
                    style={{
                      padding: '1px 5.5px',
                      borderRadius: '20px',
                      outline: 'none',
                      background: '#710FFF',
                      color: 'white',
                      fontSize: '12px',
                    }}
                  >
                    {' '}
                    +{' '}
                  </button>
                </Box>
              </Box>
            </TableCell>
            <TableCell
              align="center"
              sx={{
                color: dltingTask && dltingTask === row.id ? '#fff' : '#000',
                padding: '5px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexWrap: 'nowrap',
                  fontWeight: dropDown && dropDown === row?.id && 'bold',
                }}
              >
                {Array.isArray(uniqueWorktypes) && uniqueWorktypes.length
                  ? [uniqueWorktypes[0]].map((item) => (
                      <>
                        {item.name}{' '}
                        <p
                          style={{
                            fontWeight: 'bold',
                            color: '#710FFF',
                            fontSize: '12px',
                            overflow: 'hidden',
                          }}
                        >
                          {uniqueWorktypes.length - 1 > 0 &&
                            ` + ${uniqueWorktypes.length - 1} More`}
                        </p>
                      </>
                    ))
                  : '------'}
              </Box>
            </TableCell>
            <TableCell
              sx={{ padding: '5px', width: '15%', height: '100%' }}
              align="center"
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <NetProgressProject
                  eta={totalAssignedHours ?? 0}
                  obt={worked_hour ?? 0}
                />
              </Box>
            </TableCell>
            <TableCell
              align="center"
              width="10%"
              onClick={handleDeliverableShow}
              sx={{ padding: '3px', cursor: 'pointer' }}
            >
              {commentLoader ? (
                <Skeleton sx={{ width: '100%', height: '100%' }} />
              ) : (
                <Box
                  sx={{
                    padding: '7px 2px',
                    background: '#F8F9FA',
                    gap: '10px',
                    height: '100%',
                    borderRadius: '8px',
                    display: 'flex',
                    alignContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '50%',
                    }}
                  >
                    <span
                      style={{
                        backgroundColor: comments?.filter(
                          (message) =>
                            !message?.read_message?.some(
                              (item) => item?.read_by === userData.id,
                            ),
                        )?.length
                          ? mentionsLength?.length
                            ? '#00E6B8'
                            : '#E1D0FF'
                          : '#e3e3e3',
                        border: '2px solid #E9ECEF',
                        fontSize: '12px',
                        color: mentionsLength?.length ? '#fff' : '#222831',
                        padding: '1px 4px',
                        borderRadius: '50%',
                      }}
                    >
                      {Array.isArray(comments) && comments.length
                        ? comments?.filter(
                            (message) =>
                              !message?.read_message?.some(
                                (item) => item?.read_by === userData.id,
                              ),
                          )?.length
                        : 0}
                    </span>
                  </Box>
                  <Box
                    sx={{
                      borderLeft: '1px solid gray',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '60%',
                    }}
                  >
                    <span
                      style={{
                        backgroundColor: '#e3e3e3',
                        color: '#222831',
                        border: '2px solid #E9ECEF',
                        fontSize: '12px',
                        padding: '1px 4px',
                        borderRadius: '50%',
                        marginLeft: '11px',
                      }}
                    >
                      {Array.isArray(comments) && comments.length
                        ? comments?.length
                        : 0}
                    </span>
                  </Box>
                </Box>
              )}
            </TableCell>
            <TableCell
              sx={{ padding: '5px', width: '12%', height: '100%' }}
              align="center"
            >
              {' '}
              <select
                className={
                  row.status !== 'task_status' ? row.status : 'backlog'
                }
                onChange={async (e) => {
                  const res = await fetch(
                    `${ENDPOINT}/api/project/task/update/${row.id}/`,
                    {
                      method: 'PUT',
                      headers: {
                        'Content-Type': 'application/json',
                        Authorization: `JWT ${access_token}`,
                      },
                      body: JSON.stringify({
                        task_name: row.task_name,
                        status: e.target.value,
                      }),
                    },
                  );

                  if (res.ok) {
                    const resGET = await fetch(
                      `${ENDPOINT}/api/project/detail/${unique_id}/`,
                      {
                        method: 'GET',
                        headers: {
                          'Content-Type': 'application/json',
                          Authorization: `JWT ${access_token}`,
                        },
                      },
                    );
                    const dataGET = await resGET.json();
                    if (resGET.ok) {
                      setFormData(dataGET);
                      if (
                        dataGET &&
                        Array.isArray(dataGET.deliverable_project) &&
                        dataGET.deliverable_project.length
                      ) {
                        setDeliverable(dataGET.deliverable_project);
                      }
                      dispatch(
                        getQuickTask({
                          access_token,
                        }),
                      );

                      dispatch(
                        getProjects({
                          access_token,
                        }),
                      );
                    }
                  }
                }}
                value={row.status}
              >
                <option value="backlog">Backlog</option>
                <option value="inprogress">In Progress</option>
                <option value="review">Submitted For Review</option>
                <option value="needs_more_work">Need more work</option>
                <option value="stuck">Stuck</option>
                <option value="completed">Completed</option>
                <option value="archive">Archived</option>
              </select>{' '}
            </TableCell>
            <TableCell
              align="center"
              sx={{
                fontWeight: dropDown && dropDown === row?.id && 'bold',
                padding: '5px',
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  placeholder="Due Date"
                  value={row.due_date ? dayjs(row.due_date) : null}
                  inputFormat="MM-dd-yyyy"
                  onChange={async (newValue) => {
                    const res = await fetch(
                      `${ENDPOINT}/api/project/task/update/${row.id}/`,
                      {
                        method: 'PUT',
                        headers: {
                          'Content-Type': 'application/json',
                          Authorization: `JWT ${access_token}`,
                        },
                        body: JSON.stringify({
                          due_date: new Date(
                            new Date(newValue).getTime(),
                          ).toISOString(),
                        }),
                      },
                    );

                    if (res.ok) {
                      const resGET = await fetch(
                        `${ENDPOINT}/api/project/detail/${unique_id}/`,
                        {
                          method: 'GET',
                          headers: {
                            'Content-Type': 'application/json',
                            Authorization: `JWT ${access_token}`,
                          },
                        },
                      );
                      const dataGET = await resGET.json();
                      if (resGET.ok) {
                        setFormData(dataGET);
                        if (
                          dataGET &&
                          Array.isArray(dataGET.deliverable_project) &&
                          dataGET.deliverable_project.length
                        ) {
                          setDeliverable(dataGET.deliverable_project);
                        }
                        dispatch(
                          getQuickTask({
                            access_token,
                          }),
                        );
                        dispatch(
                          getProjects({
                            access_token,
                          }),
                        );
                      }
                    }
                  }}
                  sx={{
                    minWidth: '60px',
                    maxWidth: '150px',
                    height: '30px',
                    outline: 'none',
                    border: 'none',

                    '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                      {
                        outline: 'none',
                        borderColor: 'none',
                        border: 'none',
                      },
                    '& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused':
                      {
                        color: '#711FFF',
                      },
                    '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
                      {
                        borderColor: 'none',
                        border: 'none',
                        outline: 'none',
                      },
                    '& .MuiButtonBase-root MuiIconButton-root MuiIconButton-edgeEnd MuiIconButton-sizeMedium':
                      {
                        marginRight: '0px',
                        position: 'absolute',
                        borderRadius: '0px !important',
                        width: '100% !important',
                        height: '60px',
                      },
                    '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root': {
                      marginRight: '0px',
                      position: 'absolute',
                      borderRadius: '0px !important',
                      width: '100% !important',
                      height: '30px',
                      padding: '0px !important',
                    },
                    '& .MuiInputAdornment-root': {
                      position: 'absolute',
                      left: '-6px',
                      borderRadius: '0px',
                      width: '100%',
                    },
                    '& .MuiSvgIcon-root': {
                      display: 'none',
                      position: 'absolute',
                      right: '10px',
                    },
                    '& .MuiInputBase-root': {
                      width: '100%',
                      position: 'relative',
                    },
                    '& .MuiOutlinedInput-input': {
                      width: '100%',
                      height: '25px',
                      padding: '0px',
                      outline: 'none',
                      color: 'black',
                      marginLeft: '10px',
                    },
                    '& input::placeholder': {
                      fontSize: '12px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      outline: 'none !important',
                      border: 'none !important',
                      width: '100%',
                      height: '30px',
                      borderBottom: '1px dashed gray',
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      // eslint-disable-next-line
                      {...params}
                      sx={{
                        border: 'none',
                        borderBottom: '1px dashed lightgray',
                        '& .MuiOutlinedInput-root': {
                          border: 'none',
                          width: '280px',
                          outline: 'none',
                          padding: '0px',
                          '& fieldset': {
                            border: 'none',
                            borderBottom: '1px dashed gray',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                            borderBottom: '1px dashed gray',
                          },
                          '& input::placeholder': {
                            fontSize: '12px',
                          },
                          '&.Mui-error fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiFormLabel-root-MuiInputLabel-root .Mui-error': {
                          color: '#000',
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </TableCell>
            <TableCell sx={{ padding: '5px' }} align="center">
              <div className="td" style={{ justifyContent: 'center' }}>
                <Popup
                  trigger={
                    <button
                      type="button"
                      className="noBorder"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: '#b7b7b7',
                        padding: '6px',
                        borderRadius: '50%',
                        color:
                          formData.project_type === 'project' ? '#000' : '#fff',
                      }}
                    >
                      <img onClick={() => {}} src={moreIco} alt="more" />
                    </button>
                  }
                  position="right center"
                >
                  {formData.project_type === 'project' ? (
                    <ul className="popup">
                      <li
                        className="popupChildren LastChild"
                        onClick={async () => {
                          setSureDelete(true);
                          setDeleteData({
                            ...row,
                            title: 'Task',
                          });
                        }}
                        style={{ color: 'red' }}
                      >
                        <img
                          alt="delete icon"
                          src={DeleteIcon}
                          className="icon-sm"
                          style={{ marginLeft: '-5px' }}
                        />{' '}
                        Delete Selected
                      </li>
                    </ul>
                  ) : (
                    <ul
                      style={{
                        position: 'absolute',
                        top: '7px',
                        left: '-371px',
                        width: '400px',
                        backgroundColor: '#fff',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        zIndex: '1',
                        flexDirection: 'column',
                        border: '1px solid #ccc',
                        borderRadius: '8px',
                        padding: '1rem 0',
                      }}
                    >
                      <li className="popupChildren">
                        {' '}
                        <img
                          src={editTask2}
                          className="icon-sm"
                          alt="Edit Task"
                        />
                        Edit
                      </li>
                      <li className="popupChildren" onClick={() => {}}>
                        <MdControlPointDuplicate
                          style={{ marginRight: '5px', fontSize: '20px' }}
                        />{' '}
                        Duplicate
                      </li>
                      <li
                        className="popupChildren "
                        onClick={async () => {
                          setSureDelete(true);
                          setDeleteData({
                            ...row,
                            title: 'Task',
                          });
                        }}
                        style={{
                          color: 'red',
                          borderWidth: '80%',
                          borderRadius: '0px',
                        }}
                      >
                        <img
                          alt="Delete Icon"
                          src={DeleteIcon}
                          className="icon-sm"
                          style={{ marginLeft: '-5px' }}
                        />
                        Delete Selected
                      </li>
                    </ul>
                  )}
                </Popup>
              </div>
            </TableCell>
            <Popover
              id="cypress-updateProjectTask-Description-Editor"
              open={showEditDesc && showEditDesc === row?.id}
              anchorEl={boxRef.current}
              onClose={async () => {
                setShowEditDesc(false);
                const response = await fetch(
                  `${ENDPOINT}/api/project/task/update/${row?.id}/`,
                  {
                    method: 'PUT',
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `JWT ${access_token}`,
                    },
                    body: JSON.stringify({
                      task_name: row.task_name,
                      description: html,
                    }),
                  },
                );
                const data = await response.json();
                if (!response) {
                  Object.keys(data).forEach((key) => {
                    setError(data[key]);
                    setTimeout(() => {
                      setError(false);
                    }, 5000);
                  });
                } else {
                  const resGET = await fetch(
                    `${ENDPOINT}/api/project/detail/${unique_id}/`,
                    {
                      method: 'GET',
                      headers: {
                        'Content-Type': 'application/json',
                        Authorization: `JWT ${access_token}`,
                      },
                    },
                  );
                  const dataGET = await resGET.json();
                  if (resGET.ok) {
                    setFormData(dataGET);
                    if (
                      dataGET &&
                      Array.isArray(dataGET.deliverable_project) &&
                      dataGET.deliverable_project.length
                    ) {
                      setDeliverable(dataGET.deliverable_project);
                    }
                  }
                }
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <Box
                sx={{
                  borderRadius: '9px',
                  maxWidth: '768px',
                  minHeight: '170px',
                  maxHeight: 'auto',
                  overflowY: 'auto',
                  scrollbarWidth: 'none',
                }}
              >
                <Test
                  check={check}
                  html={html}
                  setHtml={setHtml}
                  setOpen={setOpenLink}
                  open={openLink}
                  onFocusFucntion={true}
                />
              </Box>
              <SimpleDialog
                open={openLink}
                setOpen={setOpenLink}
                linkUrl={linkUrl}
                setLinkUrl={setLinkUrl}
                setLink={setLink}
              />
            </Popover>
          </TableRow>

          {editTask && (
            <TableRow sx={{ position: 'relative' }}>
              <TableCell sx={{ padding: '5px' }} align="center" />
              <TableCell />
              <TableCell sx={{ padding: '5px' }} align="center">
                <Autocomplete
                  id="company"
                  options={teamOptions.map((item) => ({
                    id: item.id,
                    name: `${item.first_name} ${item.last_name}`,
                  }))}
                  // eslint-disable-next-line
                  PopperComponent={(props) => (
                    <Popper
                      // eslint-disable-next-line
                      {...props}
                      sx={{
                        overflowY: 'none', // Enable vertical scrolling
                        scrollbarColor: 'transparent transparent', // For Firefox
                        '&::-webkit-scrollbar': {
                          display: 'none',
                        },

                        // Styles for Microsoft Edge
                        '&::-ms-scrollbar': {
                          width: 0,
                          background: 'transparent transparent',
                        },
                      }}
                    />
                  )}
                  includeInputInList
                  multiple
                  value={
                    editTaskData.assignee_task
                      ? [editTaskData.assignee_task]
                      : []
                  } // Wrap the value in an array
                  onChange={(e, value) => {
                    if (value) {
                      if (value.length < 1) {
                        setEditTaskData({
                          ...editTaskData,
                          assignee_task: value[0],
                        });
                      } else {
                        setEditTaskData({
                          ...editTaskData,
                          assignee_task: value[value.length - 1],
                        });
                      }
                    } else {
                      setEditTaskData({
                        ...editTaskData,
                        assignee_task: [],
                      });
                    }
                  }}
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return option.name;
                  }}
                  sx={{
                    width: {
                      xl: editTaskData.assignee_task ? 'max-content' : '100%',
                      lg: editTaskData?.worktype ? 'max-content' : 150,
                      md: 100,
                    },
                  }}
                  renderOption={(props, option) => (
                    // eslint-disable-next-line
                    <li {...props}>{option.name}</li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      // eslint-disable-next-line
                      {...params}
                      placeholder={
                        editTaskData.assignee_task ? '' : 'Assignee(s)'
                      }
                      color="secondary"
                      sx={{
                        width: '100%',
                        '.MuiOutlinedInput-notchedOutline': {
                          border: 0,
                          borderBottom: '1px dashed gray',
                        },
                        '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                          {
                            border: 0,
                            borderBottom: '1px dashed gray',
                            display: 'flex',
                          },
                        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                          {
                            border: 0,
                            borderBottom: '1px dashed gray',
                          },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 0,
                            borderBottom: '1px dashed gray',
                          },
                          '&:hover fieldset': {
                            border: 0,
                            borderBottom: '1px dashed gray',
                          },
                          '&.Mui-focused fieldset': {
                            border: 0,
                            borderBottom: '1px dashed gray',
                          },
                          '& input::placeholder': {
                            fontSize: '12px',
                          },
                          '&.Mui-error fieldset': {
                            borderColor: 'red',
                          },
                        },
                        '& .MuiFormLabel-root': {
                          color: '#03071e66',
                        },
                        '& .MuiFormLabel-root.Mui-focused': {
                          color: '#5a0bdf',
                        },
                        '& .MuiFormLabel-root.Mui-error': {
                          color: 'red',
                        },
                      }}
                    />
                  )}
                />
              </TableCell>
              <TableCell sx={{ padding: '5px' }} align="center">
                <Autocomplete
                  id="company"
                  options={types}
                  // eslint-disable-next-line
                  PopperComponent={(props) => (
                    <Popper
                      // eslint-disable-next-line
                      {...props}
                      sx={{
                        overflowY: 'none', // Enable vertical scrolling
                        scrollbarColor: 'transparent transparent', // For Firefox
                        '&::-webkit-scrollbar': {
                          display: 'none',
                        },

                        // Styles for Microsoft Edge
                        '&::-ms-scrollbar': {
                          width: 0,
                          background: 'transparent transparent',
                        },
                      }}
                    />
                  )}
                  includeInputInList
                  multiple
                  value={editTaskData.worktype ? [editTaskData.worktype] : []} // Wrap the value in an array
                  onChange={(e, value) => {
                    if (value) {
                      if (value.length < 1) {
                        setEditTaskData({
                          ...editTaskData,
                          worktype: value[0],
                        });
                      } else {
                        setEditTaskData({
                          ...editTaskData,
                          worktype: value[value.length - 1],
                        });
                      }
                    } else {
                      setEditTaskData({
                        ...editTaskData,
                        worktype: [],
                      });
                    }
                  }}
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return option.name;
                  }}
                  sx={{
                    width: {
                      xl: editTaskData?.worktype ? 'max-content' : '100%',
                      lg: editTaskData?.worktype ? 'max-content' : 150,
                      md: 100,
                    },
                  }}
                  renderOption={(props, option) => (
                    // eslint-disable-next-line
                    <li {...props}>{option.name}</li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      // eslint-disable-next-line
                      {...params}
                      placeholder={editTaskData.worktype ? '' : 'Worktypes'}
                      color="secondary"
                      sx={{
                        width: '100%',
                        '.MuiOutlinedInput-notchedOutline': {
                          border: 0,
                          borderBottom: '1px dashed gray',
                        },
                        '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                          {
                            border: 0,
                            borderBottom: '1px dashed gray',
                          },
                        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                          {
                            border: 0,
                            borderBottom: '1px dashed gray',
                          },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 0,
                            borderBottom: '1px dashed gray',
                          },
                          '&:hover fieldset': {
                            border: 0,
                            borderBottom: '1px dashed gray',
                          },
                          '&.Mui-focused fieldset': {
                            border: 0,
                            borderBottom: '1px dashed gray',
                          },

                          '&.Mui-error fieldset': {
                            borderColor: 'red',
                          },
                          '& input::placeholder': {
                            fontSize: '12px',
                          },
                        },
                        '& .MuiFormLabel-root': {
                          color: '#03071e66',
                        },
                        '& .MuiFormLabel-root.Mui-focused': {
                          color: '#5a0bdf',
                        },
                        '& .MuiFormLabel-root.Mui-error': {
                          color: 'red',
                        },
                      }}
                    />
                  )}
                />
              </TableCell>
              <TableCell sx={{ padding: '5px' }} align="center">
                <TextField
                  type="number"
                  placeholder="Hours"
                  value={editTaskData?.hours}
                  onChange={(e) => {
                    setEditTaskData({
                      ...editTaskData,
                      hours: e.target.value,
                    });
                  }}
                  sx={{
                    width: '80%',
                    '& .MuiOutlinedInput-root': {
                      '& input::placeholder': {
                        fontSize: '12px',
                      },
                      '& fieldset': {
                        border: 'none',
                        borderBottom: '1px dashed gray',
                      },
                      '&:hover fieldset': {
                        borderBottom: '1px dashed gray',
                      },
                      '&.Mui-focused fieldset': {
                        borderBottom: '1px dashed gray',
                      },

                      '&.Mui-error fieldset': {
                        borderColor: 'red',
                      },
                    },
                    '& .MuiFormLabel-root': {
                      color: '#03071e66',
                    },
                    '& input::placeholder': {
                      fontSize: '12x',
                    },
                    '& .MuiFormLabel-root.Mui-focused': {
                      color: '#5a0bdf',
                    },
                    '& .MuiFormLabel-root.Mui-error': {
                      color: 'red',
                    },
                  }}
                />
              </TableCell>
              <TableCell sx={{ padding: '5px' }} align="center">
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '7px' }}>
                  <button
                    type="button"
                    aria-label="Update Task"
                    style={{
                      width: '23px',
                      height: '23px',
                      background: '#55cea1',
                      borderRadius: '50%',
                      color: '#fff',
                    }}
                    onClick={async () => {
                      if (editTaskData.worktype && editTaskData.assignee_task) {
                        const response = await fetch(
                          `${ENDPOINT}/api/project/task/update/${row?.id}/`,
                          {
                            method: 'PUT',
                            headers: {
                              Authorization: `JWT ${access_token}`,
                              'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                              task_worktypes: [
                                {
                                  id: editTaskData.worktype.id,
                                  hours: editTaskData.hours ?? 0,
                                },
                              ],
                              task_assignee: [
                                {
                                  assignee_user: editTaskData.assignee_task?.id,
                                  hours: editTaskData.hours ?? 0,
                                },
                              ],
                            }),
                          },
                        );
                        const data = await response.json();
                        if (!response.ok) {
                          Object.keys(data).forEach((key) => {
                            setError(data[key]);
                            setTimeout(() => {
                              setError(false);
                            }, 5000);
                          });
                        } else {
                          getData();
                          setEditTask(false);
                        }
                      } else {
                        setError(
                          !editTaskData.worktype && !editTaskData.assignee_task
                            ? 'Please Select Worktype and Assignee'
                            : !editTaskData.worktype &&
                                editTaskData.assignee_task
                              ? 'Please Select Worktype'
                              : 'Please Select Assignee',
                        );
                        setTimeout(() => {
                          setError(false);
                        }, 5000);
                      }
                    }}
                  >
                    <Check sx={{ fontSize: '14px' }} />
                  </button>
                  <button
                    type="button"
                    onClick={() => setEditTask(false)}
                    style={{
                      width: '23px',
                      height: '23px',
                      background: '#ed5959',
                      color: '#fff',
                      borderRadius: '50%',
                      textAlign: 'center',
                    }}
                  >
                    {' '}
                    X{' '}
                  </button>
                </Box>
              </TableCell>
              <TableCell />
              <TableCell />
              <TableCell />
              {error && (
                <Shake>
                  <p
                    style={{
                      position: 'absolute',
                      top: '0px',
                      left: '42%',
                      color: 'red',
                      fontSize: '12px',
                    }}
                  >
                    {error ?? 'Error'}
                  </p>{' '}
                </Shake>
              )}
            </TableRow>
          )}
          {dropDown &&
            dropDown === row?.id &&
            Array.isArray(row.task_worktype) &&
            row.task_worktype.length &&
            Array.isArray(uniqueWorktypes) &&
            uniqueWorktypes.length &&
            uniqueWorktypes.map((ent) => {
              const [changeHours, setChangeHours] = useState(false);
              const groupedByEntId =
                Array.isArray(row?.assignee_task) && row?.assignee_task.length
                  ? row?.assignee_task?.filter(
                      (hel) => hel.assignee_work_type?.name === ent?.name,
                    )
                  : [];

              const workTypeUsers = row.assignee_task
                .filter((item) => item.assignee_work_type.name === ent.name)
                .map((item) => item.assignee_user);

              const worked_hour =
                Array.isArray(dil) && dil.length
                  ? dil
                      .filter((tak) =>
                        tak.task_worktype.some((ele) => ele.name === ent.name),
                      )
                      .reduce(
                        (acc, tsk) =>
                          acc +
                          calculateTotalDurationObtained(
                            tsk.time_tracking.filter(
                              (tracking) =>
                                workTypeUsers.includes(
                                  tracking?.created_by?.id,
                                ) &&
                                tracking.task_id.task_name === row.task_name,
                            ),
                          ),
                        0,
                      )
                  : 0;

              // * Total Assigned Hours based on the 'assignee_task'.
              const totalAssignedHours = row.assignee_task
                .filter((item) => item.assignee_work_type.name === ent.name)
                .reduce((acc, item) => acc + item.hours, 0);

              const [total_hour, settotal_hour] = useState(ent?.hours);
              const [editWTT, setEditWTT] = useState(false);

              return (
                <>
                  <CSSTransition
                    key={uuid}
                    classNames="your-table-row-class"
                    timeout={600}
                  >
                    <TableRow
                      sx={{ background: '#e5e5ea', border: '2px solid #fff' }}
                    >
                      <TableCell sx={{ padding: '5px' }}> </TableCell>
                      <TableCell sx={{ padding: '5px' }}> </TableCell>

                      <TableCell
                        sx={{ padding: '5px', width: 'auto' }}
                        align="center"
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',

                            justifyContent: 'flex-end',
                            width: '100%',
                          }}
                        >
                          {row.assignee_task.length > 1 ? (
                            showName ? (
                              <KeyboardArrowDownIcon
                                sx={{ cursor: 'pointer' }}
                                onClick={() => setShowName(false)}
                              />
                            ) : (
                              <KeyboardArrowRight
                                id="cypress-updateProjectTask-arrowButton"
                                sx={{ cursor: 'pointer' }}
                                onClick={() => setShowName(ent?.name)}
                              />
                            )
                          ) : null}
                          <Box sx={{ width: '60%' }}>
                            <AvatarGroup
                              max={3}
                              sx={{
                                justifyContent: 'flex-end',
                                textAlign: 'center',
                                paddingLeft: '-1px',
                                '& .css-sxh3gq-MuiAvatar-root-MuiAvatarGroup-avatar':
                                  {
                                    height: '19px',
                                    width: '19px',
                                    fontSize: '14px',
                                  },
                                '& .css-rfuczr': {
                                  height: '19px',
                                  width: '19px',
                                  fontSize: '14px',
                                },
                              }}
                            >
                              {Array.isArray(groupedByEntId) &&
                                groupedByEntId.length &&
                                groupedByEntId.map((ava) => {
                                  const target = teamOptions.find(
                                    (team) => team?.id === ava?.assignee_user,
                                  );
                                  return (
                                    <Avatar
                                      key={target?.id}
                                      src={
                                        target?.avatar
                                          ? target?.avatar
                                          : '/static/images/avatar/1.jpg'
                                      }
                                      alt={target?.first_name}
                                      sx={{ height: '19px', width: '19px' }}
                                    />
                                  );
                                })}
                            </AvatarGroup>
                          </Box>
                        </div>
                      </TableCell>
                      <TableCell sx={{ padding: '5px' }} align="center">
                        {ent.name}
                      </TableCell>

                      <TableCell sx={{ padding: '5px' }} align="center">
                        {!editWTT ? (
                          <Box
                            sx={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                            onClick={() => {
                              // * Only allow editing if there is only one user assigned to that worktype.
                              if (workTypeUsers.length === 1) {
                                setEditWTT(true);
                              }
                            }}
                          >
                            <NetProgressProject
                              eta={totalAssignedHours ?? 0}
                              obt={worked_hour ?? 0}
                            />
                          </Box>
                        ) : (
                          <input
                            value={total_hour ?? 0}
                            onBlur={async () => {
                              const taskAssigneeId = row.assignee_task.find(
                                (assignee) =>
                                  assignee?.assignee_work_type.id === ent?.id,
                              ).id;

                              const response = await fetch(
                                `${ENDPOINT}/api/project/task/update/${row?.id}/`,
                                {
                                  method: 'PUT',
                                  headers: {
                                    Authorization: `JWT ${access_token}`,
                                    'Content-Type': 'application/json',
                                  },
                                  body: JSON.stringify({
                                    update_worktype_hours: [
                                      {
                                        existing_worktype_id: ent?.id,
                                        new_hours: Number(total_hour),
                                      },
                                    ],
                                    update_assignee_hours: [
                                      {
                                        existing_assignee_id: taskAssigneeId,
                                        new_hours: Number(total_hour),
                                      },
                                    ],
                                  }),
                                },
                              );
                              const data = await response.json();
                              if (!response.ok) {
                                Object.keys(data).forEach((key) => {
                                  setError(data[key]);
                                  setTimeout(() => {
                                    setError(false);
                                  }, 5000);
                                });

                                setEditWTT(false);
                              } else {
                                getData();
                                setEditTask(false);
                                setEditWTT(false);
                              }
                            }}
                            type="number"
                            onChange={(e) => settotal_hour(e.target.value)}
                            style={{
                              padding: '5px 0px',
                              border: 'none',
                              borderBottom: '1px dashed gray',
                              outline: 'none',
                              background: 'transparent',
                              width: '60%',
                              textAlign: 'center',
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell sx={{ padding: '5px' }} align="center">
                        {' '}
                      </TableCell>
                      <TableCell sx={{ padding: '5px' }} align="center">
                        {' '}
                      </TableCell>
                      <TableCell sx={{ padding: '5px' }} align="center">
                        {' '}
                      </TableCell>
                      <TableCell sx={{ padding: '5px' }} align="center">
                        <div
                          className="td"
                          style={{ justifyContent: 'center' }}
                        >
                          <Popup
                            trigger={
                              <button type="button" className="noBorder">
                                <img src={moreIco} alt="more" />
                              </button>
                            }
                            position="right center"
                          >
                            <ul className="popup">
                              <li
                                className="popupChildren LastChild"
                                onClick={async () => {
                                  setSureDelete(true);
                                  setDeleteData({
                                    ...ent,
                                    title: 'Worktype',
                                    hasTimeTracking: !!worked_hour,
                                    assignee_Array: groupedByEntId.map(
                                      (item) => item?.id,
                                    ),
                                    worktype_Array: row.task_worktype
                                      ?.filter((hh) =>
                                        groupedByEntId.some(
                                          (it) =>
                                            it.assignee_work_type.id === hh.id,
                                        ),
                                      )
                                      .map((item) => item?.id),
                                    requestID: row?.id,
                                  });
                                }}
                                style={{ color: 'red' }}
                              >
                                <img
                                  alt="Delete Icon"
                                  src={DeleteIcon}
                                  className="icon-sm"
                                  style={{ marginLeft: '-5px' }}
                                />{' '}
                                Delete Selected
                              </li>
                            </ul>
                          </Popup>
                        </div>
                      </TableCell>
                    </TableRow>
                  </CSSTransition>
                  {showName &&
                    showName === ent?.name &&
                    Array.isArray(groupedByEntId) &&
                    groupedByEntId.length &&
                    groupedByEntId.map((ava) => {
                      const target = teamOptions.find(
                        (team) => team.id === ava.assignee_user,
                      );

                      // ! Keep this for future reference. (Arthur & Santiago)
                      // console.info('DIL (for deliverable_task) ', dil);
                      // console.info('ENT (for work_type): ', ent);
                      // console.info('Ava (for assignee_task): ', ava);
                      // console.info(`Row.name ${row.task_name}`, row);

                      const currentUserTracking =
                        Array.isArray(dil) && dil.length
                          ? dil
                              .filter((tak) =>
                                tak.task_worktype.some(
                                  (ele) => ele.name === ent.name,
                                ),
                              )
                              .filter((tak) =>
                                tak.time_tracking.some(
                                  (ele) => ele?.created_by?.id === target?.id,
                                ),
                              )
                              .reduce(
                                (acc, tsk) =>
                                  acc +
                                  calculateTotalDurationObtained(
                                    tsk.time_tracking.filter(
                                      (tracking) =>
                                        tracking?.created_by?.id ===
                                          target?.id &&
                                        tracking.task_id.task_name ===
                                          row.task_name,
                                    ),
                                  ),
                                0,
                              )
                          : 0;

                      const [avaHours, setAvaHours] = useState(ava?.hours);
                      const [updateHours, setUpdateHours] = useState(false);

                      const handleUpdateHours = async () => {
                        const response = await fetch(
                          `${ENDPOINT}/api/project/task/update/${row?.id}/`,
                          {
                            method: 'PUT',
                            headers: {
                              Authorization: `JWT ${access_token}`,
                              'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                              update_assignee_hours: [
                                {
                                  existing_assignee_id: ava?.id,
                                  new_hours: Number(avaHours),
                                },
                              ],
                              update_worktype_hours: [
                                {
                                  existing_worktype_id:
                                    ava?.assignee_work_type?.id,
                                  new_hours: Number(avaHours),
                                },
                              ],
                            }),
                          },
                        );
                        const data = await response.json();
                        if (!response.ok) {
                          Object.keys(data).forEach((key) => {
                            setError(data[key]);
                            setTimeout(() => {
                              setError(false);
                            }, 5000);
                          });
                        } else {
                          getData();
                          setEditTask(false);
                          setChangeHours(false);
                        }
                      };

                      useEffect(() => {
                        if (updateHours) {
                          handleUpdateHours();
                        }
                      }, [updateHours]);

                      return (
                        <TableRow
                          key={target?.id}
                          sx={{
                            background: '#dddddd',
                            border: '2px solid #fff',
                            fontWeight: 'bold',
                          }}
                        >
                          <TableCell sx={{ padding: '5px' }} align="center" />
                          <TableCell sx={{ padding: '5px' }} align="center" />

                          <TableCell sx={{ padding: '5px' }} align="center">
                            <Box
                              sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  gap: '5px',
                                  alignItems: 'center',
                                  width: '73%',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                <img
                                  alt="Delete Icon"
                                  src={taskIcon}
                                  style={{ marginRight: '0px' }}
                                  className="icon-sm"
                                />
                                <Avatar
                                  src={
                                    target.avatar
                                      ? target.avatar
                                      : '/static/images/avatar/1.jpg'
                                  }
                                  alt={target.first_name}
                                  sx={{
                                    height: '19px',
                                    width: '19px',
                                    marginRight: '2px',
                                  }}
                                />
                                <p
                                  style={{
                                    fontWeight: 'bold',
                                    margin: '0px 0px',
                                  }}
                                >
                                  {`${target.first_name} ${target.last_name}`}
                                </p>
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell sx={{ padding: '5px' }} />
                          <TableCell align="center" sx={{ padding: '5px' }}>
                            {changeHours !== ava?.id ? (
                              <Box
                                onClick={() => setChangeHours(ava?.id)}
                                sx={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                <NetProgressProject
                                  eta={ava?.hours}
                                  obt={currentUserTracking ?? 0}
                                />
                              </Box>
                            ) : (
                              <input
                                value={avaHours ?? ava?.hours}
                                onBlur={() => setUpdateHours((prev) => !prev)}
                                type="number"
                                onChange={(e) => setAvaHours(e.target.value)}
                                style={{
                                  padding: '5px 0px',
                                  border: 'none',
                                  borderBottom: '1px dashed gray',
                                  outline: 'none',
                                  background: 'transparent',
                                  width: '60%',
                                  textAlign: 'center',
                                }}
                              />
                            )}
                          </TableCell>
                          <TableCell sx={{ padding: '5px' }} align="center" />
                          <TableCell sx={{ padding: '5px' }} align="center" />
                          <TableCell sx={{ padding: '5px' }} align="center" />
                          <TableCell sx={{ padding: '5px' }} align="center">
                            <div
                              className="td"
                              style={{ justifyContent: 'center' }}
                            >
                              <Popup
                                trigger={
                                  <button type="button" className="noBorder">
                                    <img src={moreIco} alt="more" />
                                  </button>
                                }
                                position="right center"
                              >
                                <ul className="popup">
                                  <li
                                    className="popupChildren LastChild"
                                    onClick={async () => {
                                      setSureDelete(true);
                                      setDeleteData({
                                        ...ava,
                                        title: 'Assignee',
                                        hasTimeTracking: !!currentUserTracking,
                                        assignee_delete: ava?.id,
                                        requestID: row?.id,
                                      });
                                    }}
                                    style={{ color: 'red' }}
                                  >
                                    <img
                                      alt="Delete Icon"
                                      src={DeleteIcon}
                                      className="icon-sm"
                                      style={{ marginLeft: '-5px' }}
                                    />{' '}
                                    Delete Selected
                                  </li>
                                </ul>
                              </Popup>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </>
              );
            })}
        </>
      )}
    </Draggable>
  );
}
function InnerTable({
  currentMonthIndex,
  realComments,
  setRealComments,
  task,
  workTypes,
  setShowOfTask,
  setCurrentTargetTask,
  setShowName,
  showName,
  formData,
  setAddTask,
  addTask,
  getData,
  access_token,
  ourWorkTypes,
  SetDltingTask,
  dltingTask,
  setFormData,
  setDropDown,
  dropDown,
  rows,
  setRows,
  currentDil,
  setIsTyping,
  id,
  setDeliverable,
  unique_id,
}) {
  const [openLink, setOpenLink] = useState(false);
  const dispatch = useDispatch();
  const teamOptions = formData?.project_subteam[0].members?.map((item) => ({
    name: `${item?.first_name || ''} ${item?.last_name || ''}`,
    id: item.id,
  }));

  const projectsFromGlobalState = useSelector(
    (state) => state.projects.projects,
  );

  const [taskData, setTaskData] = useState({
    task_name: '',
    description: '',
    due_date: '',
    task_project: id,
    task_deliverable: currentDil?.id,
    estimated_hours: '',
    task_worktypes: [],
    assignee_task: [],
    carry_forward_setting: 'carry_task',
  });

  const userWorkTypes = useSelector((state) => state.Misc.workTypes);
  const types =
    Array.isArray(userWorkTypes) && userWorkTypes.length
      ? userWorkTypes
          .filter((ee) => ourWorkTypes.some((ele) => ele.name === ee.name))
          .map((item) => ({
            name: item.name,
            id: item.id,
            hours: 0,
          }))
      : [];

  const [loading, setLoading] = useState(false);
  const [sureDelete, setSureDelete] = useState(false);
  const [deleteData, setDeleteData] = useState(false);
  const [html, setHtml] = useState('');
  const [showDesc, setShowDesc] = useState('');
  const [error, setError] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleDesCription = (event) => {
    setAnchorEl(event.currentTarget);
    setShowDesc(!showDesc);
  };

  const open = Boolean(anchorEl);
  const desId = open ? 'simple-popover' : undefined;
  const handleOnDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const items = Array.from(rows);
    const [reorderediItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderediItem);
    const updatedItems = items.map((item, index) => {
      return {
        ...item,
        sort_order: index + 1, // Assuming sort_order starts from 1
      };
    });

    const sorted = updatedItems.sort((a, b) => a.sort_order - b.sort_order);
    setRows(sorted);
    // eslint-disable-next-line
    for (var i = 0; i < updatedItems.length; i++) {
      // eslint-disable-next-line
      const response = await fetch(
        `${ENDPOINT}/api/project/task/update/${updatedItems[i]?.id}/`,
        {
          method: 'PUT',
          headers: {
            Authorization: `JWT ${access_token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            sort_order: updatedItems[i].sort_order,
          }),
        },
      );
      // eslint-disable-next-line
      const data = await response.json();
      if (!response.ok) {
        Object.keys(data).forEach((key) => {
          setError(data[key]);
          setTimeout(() => {
            setError(false);
          }, 5000);
        });
      }
    }
  };

  const [linkUrl, setLinkUrl] = useState('');

  const [btn, setbtn] = useState();

  const setLink = () => {
    const url = linkUrl.trim(); // Trim whitespace from the input URL
    // Update link only if the URL is not empty
    if (url !== '') {
      btn.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run();
    }
    setOpenLink(false);
    setLinkUrl('');
  };

  const check = (e) => {
    setbtn(e);
  };
  useEffect(() => {
    // Read from localStorage and initialize state
    const savedData = localStorage.getItem('taskData');
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      if (parsedData?.task_deliverable === currentDil?.id) {
        setHtml(parsedData?.description);
        setTaskData(parsedData);
      }
    }
  }, [currentDil.id]); // Add dependencies if necessary

  useEffect(() => {
    if (taskData.task_name.length) {
      // Only save taskData if it has meaningful content
      localStorage.setItem('taskData', JSON.stringify(taskData));
    }
  }, [taskData]);

  const handleClose = () => {
    setAnchorEl(null);
    setShowDesc(false);
    setTaskData((prevData) => ({
      ...prevData,
      description: html,
    }));
  };

  const handleChangeTask = (e) => {
    const { value } = e.target;
    setTaskData((prevData) => ({ ...prevData, task_name: value }));
  };

  const handleAutocompleteChange = (event, value) => {
    if (value) {
      const assignee_task = value?.map((option) => ({
        assignee_user: option.id,
        hours: 0,
        name: option.name,
      }));
      setTaskData((prevData) => ({
        ...prevData,
        assignee_task,
      }));
    } else {
      setTaskData((prevData) => ({
        ...prevData,
        assignee_task: [],
      }));
    }
  };

  const listOfRowsSortedByCreatedAtAndOrder =
    (Array.isArray(rows) &&
      rows.length &&
      rows.sort((a, b) => {
        if (a.sort_order === null && b.sort_order === null) {
          return new Date(a.created_at) - new Date(b.created_at);
        }
        if (a.sort_order === null) return 1;
        if (b.sort_order === null) return -1;

        return a.sort_order - b.sort_order;
      })) ??
    [];

  return (
    <Grid
      justifyContent="center"
      alignItems="center"
      sx={{
        m: '1rem 0',
        width: '100%',
        display: 'flex',
        position: 'relative',
      }}
    >
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="listOfRowsSortedByCreatedAtAndOrder">
          {(provided) => (
            <TableContainer
              component={Paper}
              // eslint-disable-next-line
              {...provided.droppableProps}
              ref={provided.innerRef}
              sx={{
                width: '100%',
                border: '1px solid #adadad',
                borderRadius: '8px',
              }}
            >
              {Array.isArray(rows) && rows.length ? (
                <Table aria-label="collapsible table">
                  <TableHead className="table-head">
                    <TableRow>
                      <TableCell
                        style={{ fontWeight: 'bold', color: 'gray' }}
                        className="th-cell"
                        align="start"
                      >
                        Tasks
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: 'bold', color: 'gray' }}
                        className="th-cell"
                        align="center"
                      >
                        Description
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: 'bold', color: 'gray' }}
                        className="th-cell"
                        align="center"
                      >
                        Assignee(s)
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: 'bold', color: 'gray' }}
                        className="th-cell"
                        align="center"
                      >
                        Work Types
                      </TableCell>

                      <TableCell
                        style={{ fontWeight: 'bold', color: 'gray' }}
                        className="th-cell"
                        align="center"
                      >
                        Hours
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: 'bold', color: 'gray' }}
                        className="th-cell"
                        align="center"
                      >
                        Comments
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: 'bold', color: 'gray' }}
                        className="th-cell"
                        align="center"
                      >
                        Status
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: 'bold', color: 'gray' }}
                        className="th-cell"
                        align="center"
                      >
                        Due Date
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: 'bold', color: 'gray' }}
                        className="th-cell"
                        align="center"
                      >
                        {}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listOfRowsSortedByCreatedAtAndOrder.map((row, index) => (
                      <Row
                        id="Cypress-Input-test"
                        check={check}
                        realComments={realComments}
                        setRealComments={setRealComments}
                        currentDil={currentDil}
                        task={task}
                        workTypes={workTypes}
                        index={index}
                        key={uuid()}
                        mergeRow={rows}
                        row={row}
                        setRows={setRows}
                        setCurrentTarget={setCurrentTargetTask}
                        setShowOfTask={setShowOfTask}
                        dropDown={dropDown}
                        setDropDown={setDropDown}
                        setIsTyping={setIsTyping}
                        unique_id={unique_id}
                        setFormData={setFormData}
                        setDeliverable={setDeliverable}
                        SetDltingTask={SetDltingTask}
                        dltingTask={dltingTask}
                        ourWorkTypes={ourWorkTypes}
                        types={types}
                        getData={getData}
                        setSureDelete={setSureDelete}
                        sureDelete={sureDelete}
                        setDeleteData={setDeleteData}
                        deleteData={deleteData}
                        dil={rows}
                        formData={formData}
                        setShowName={setShowName}
                        showName={showName}
                        teamOptionsForUpdateTask={teamOptions}
                      />
                    ))}

                    {provided.placeholder}

                    {!addTask && (
                      <TableRow sx={{ height: '92px', paddingLeft: '10px' }}>
                        <TableCell>
                          <div
                            style={{
                              borderBottom: '1px dashed #000',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setAddTask(true);
                            }}
                          >
                            Add a New Task
                          </div>
                        </TableCell>
                        <TableCell> </TableCell>
                        <TableCell> </TableCell>
                        <TableCell> </TableCell>
                        <TableCell> </TableCell>
                        <TableCell> </TableCell>
                        <TableCell> </TableCell>
                      </TableRow>
                    )}
                    {sureDelete && (
                      <Delete
                        setSureDelete={setSureDelete}
                        currentTarget={deleteData}
                        getData={getData}
                      />
                    )}
                  </TableBody>
                </Table>
              ) : (
                !addTask && (
                  <>
                    <Alert severity="info" sx={{ width: '100%' }}>
                      There are no Tasks for this Deliverable
                    </Alert>

                    <TableRow sx={{ height: '92px' }}>
                      <TableCell>
                        <div
                          style={{
                            borderBottom: '1px dashed #000',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setAddTask(true);
                          }}
                        >
                          Add a New Task
                        </div>
                      </TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  </>
                )
              )}
              {addTask && (
                <TableRow sx={{ position: 'relative', overflowX: 'auto' }}>
                  <TableCell>
                    <TextField
                      id="cypress-projectTaskCreate-taskName-inputfiled"
                      placeholder="Task Name"
                      value={taskData?.task_name}
                      onChange={(e) => handleChangeTask(e)}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                            borderBottom: '1px dashed gray',
                          },
                          '&:hover fieldset': {
                            borderBottom: '1px dashed gray',
                          },
                          '&.Mui-focused fieldset': {
                            borderBottom: '1px dashed gray',
                          },

                          '&.Mui-error fieldset': {
                            borderColor: 'red',
                          },
                          '& input::placeholder': {
                            fontSize: '12px',
                          },
                        },
                        '& .MuiFormLabel-root': {
                          color: '#03071e66',
                        },
                        '& .MuiFormLabel-root.Mui-focused': {
                          color: '#5a0bdf',
                        },
                        '& .MuiFormLabel-root.Mui-error': {
                          color: 'red',
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      maxWidth: { xl: '250px', lg: '120px', md: '100px' },
                      maxHeight: '40px',
                    }}
                  >
                    <Box onClick={handleDesCription} sx={{ cursor: 'pointer' }}>
                      {html ? (
                        <Typography
                          aria-describedby={desId}
                          sx={{
                            borderBottom: '1px dashed gray',
                            padding: '20px 0px',
                            maxHeight: '43px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            minWidth: {
                              xl: '200px',
                              lg: '110px',
                              md: '100px',
                            },
                          }}
                          dangerouslySetInnerHTML={{ __html: html }}
                        />
                      ) : (
                        <TextField
                          aria-describedby={desId}
                          autoFocus={false}
                          placeholder="Description"
                          sx={{
                            minWidth: {
                              xl: '200px',
                              lg: '110px',
                              md: '100px',
                            },
                            '& input': {
                              caretColor: 'transparent',
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                border: 'none',
                                borderBottom: '1px dashed gray',
                                cursor: 'none',
                                caretColor: 'transparent',
                              },
                              '&:hover fieldset': {
                                borderBottom: '1px dashed gray',
                              },
                              '&.Mui-focused fieldset': {
                                borderBottom: '1px dashed gray',
                                cursor: 'none',
                                caretColor: 'transparent',
                              },

                              '&.Mui-error fieldset': {
                                borderColor: 'red',
                              },
                              '& input::placeholder': {
                                fontSize: '12px',
                              },
                            },
                            '& .MuiFormLabel-root': {
                              color: '#03071e66',
                            },
                            '& .MuiFormLabel-root.Mui-focused': {
                              color: '#5a0bdf',
                            },
                            '& .MuiFormLabel-root.Mui-error': {
                              color: 'red',
                            },
                          }}
                        />
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Autocomplete
                      id="company"
                      options={teamOptions}
                      // eslint-disable-next-line
                      PopperComponent={(props) => (
                        <Popper
                          // eslint-disable-next-line
                          {...props}
                          sx={{
                            overflowY: 'none', // Enable vertical scrolling
                            scrollbarColor: 'transparent transparent', // For Firefox
                            '&::-webkit-scrollbar': {
                              display: 'none',
                            },

                            // Styles for Microsoft Edge
                            '&::-ms-scrollbar': {
                              width: 0,
                              background: 'transparent transparent',
                            },
                          }}
                        />
                      )}
                      includeInputInList
                      multiple
                      value={
                        Array.isArray(taskData?.assignee_task) &&
                        taskData?.assignee_task?.length
                          ? taskData?.assignee_task
                          : []
                      }
                      onChange={(e, value) =>
                        handleAutocompleteChange(e, value)
                      }
                      getOptionLabel={(option) => {
                        if (typeof option === 'string') {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option.name;
                      }}
                      sx={{
                        width: {
                          xl:
                            Array.isArray(taskData?.assignee_task) &&
                            taskData?.assignee_task.length
                              ? 'max-content'
                              : 180,
                          lg:
                            Array.isArray(taskData.assignee_task) &&
                            taskData.assignee_task.length
                              ? 'max-content'
                              : 180,
                          md: 100,
                        },
                      }}
                      renderOption={(props, option) => (
                        // eslint-disable-next-line
                        <li {...props}>{option.name}</li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          // eslint-disable-next-line
                          {...params}
                          placeholder={
                            Array.isArray(taskData?.assignee_task) &&
                            taskData?.assignee_task.length
                              ? ''
                              : 'Assignee(s)'
                          }
                          color="secondary"
                          sx={{
                            width: '100%',
                            '.MuiOutlinedInput-notchedOutline': {
                              border: 0,
                              borderBottom: '1px dashed gray',
                            },
                            '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                              {
                                border: 0,
                                borderBottom: '1px dashed gray',
                              },
                            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                              {
                                border: 0,
                                borderBottom: '1px dashed gray',
                              },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                border: 0,
                                borderBottom: '1px dashed gray',
                              },
                              '& input::placeholder': {
                                fontSize: '12px',
                              },
                              '&:hover fieldset': {
                                border: 0,
                                borderBottom: '1px dashed gray',
                              },
                              '&.Mui-focused fieldset': {
                                border: 0,
                                borderBottom: '1px dashed gray',
                              },

                              '&.Mui-error fieldset': {
                                borderColor: 'red',
                              },
                            },
                            '& .MuiFormLabel-root': {
                              color: '#03071e66',
                            },
                            '& .MuiFormLabel-root.Mui-focused': {
                              color: '#5a0bdf',
                            },
                            '& .MuiFormLabel-root.Mui-error': {
                              color: 'red',
                            },
                          }}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <Autocomplete
                      id="company"
                      options={types}
                      // eslint-disable-next-line
                      PopperComponent={(props) => (
                        <Popper
                          // eslint-disable-next-line
                          {...props}
                          sx={{
                            overflowY: 'none', // Enable vertical scrolling
                            scrollbarColor: 'transparent transparent', // For Firefox
                            '&::-webkit-scrollbar': {
                              display: 'none',
                            },

                            // Styles for Microsoft Edge
                            '&::-ms-scrollbar': {
                              width: '90%',
                              background: 'transparent transparent',
                            },
                          }}
                        />
                      )}
                      includeInputInList
                      multiple
                      value={
                        Array.isArray(taskData?.task_worktypes) &&
                        taskData?.task_worktypes.length
                          ? taskData?.task_worktypes
                          : []
                      }
                      onChange={(e, value) => {
                        if (value) {
                          if (value.length < 1) {
                            setTaskData({
                              ...taskData,
                              task_worktypes: [
                                {
                                  id: value[0]?.id,
                                  hours: 0,
                                  name: value[0]?.name,
                                },
                              ],
                            });
                          } else {
                            setTaskData({
                              ...taskData,
                              task_worktypes: [
                                {
                                  id: value[value.length - 1]?.id,
                                  hours: 0,
                                  name: value[value.length - 1]?.name,
                                },
                              ],
                            });
                          }
                        } else {
                          setTaskData({
                            ...taskData,
                            task_worktypes: [],
                          });
                        }
                      }}
                      getOptionLabel={(option) => {
                        if (typeof option === 'string') {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option.name;
                      }}
                      sx={{
                        width: {
                          xl:
                            Array.isArray(taskData.task_worktypes) &&
                            taskData.task_worktypes.length
                              ? 'max-content'
                              : 180,
                          lg:
                            Array.isArray(taskData.task_worktypes) &&
                            taskData.task_worktypes.length
                              ? 'max-content'
                              : 180,
                          md: 100,
                        },
                      }}
                      renderOption={(props, option) => (
                        // eslint-disable-next-line
                        <li {...props}>{option.name}</li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          // eslint-disable-next-line
                          {...params}
                          placeholder={
                            Array.isArray(taskData.task_worktypes) &&
                            taskData.task_worktypes.length
                              ? ''
                              : 'Work Types'
                          }
                          color="secondary"
                          sx={{
                            width: '100%',
                            '.MuiOutlinedInput-notchedOutline': {
                              border: 0,
                              borderBottom: '1px dashed gray',
                            },
                            '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                              {
                                border: 0,
                                borderBottom: '1px dashed gray',
                              },
                            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                              {
                                border: 0,
                                borderBottom: '1px dashed gray',
                              },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                border: 0,
                                borderBottom: '1px dashed gray',
                              },
                              '&:hover fieldset': {
                                border: 0,
                                borderBottom: '1px dashed gray',
                              },
                              '&.Mui-focused fieldset': {
                                border: 0,
                                borderBottom: '1px dashed gray',
                              },

                              '&.Mui-error fieldset': {
                                borderColor: 'red',
                              },
                              '& input::placeholder': {
                                fontSize: '12px',
                              },
                            },
                            '& .MuiFormLabel-root': {
                              color: '#03071e66',
                            },
                            '& .MuiFormLabel-root.Mui-focused': {
                              color: '#5a0bdf',
                            },
                            '& .MuiFormLabel-root.Mui-error': {
                              color: 'red',
                            },
                          }}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      id="cypress-projectTaskCreate-hours-inputfiled"
                      placeholder="Hours"
                      value={taskData?.estimated_hours}
                      onChange={(e) => {
                        setTaskData({
                          ...taskData,
                          estimated_hours: e.target.value,
                        });
                      }}
                      sx={{
                        width: '70px',
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                            borderBottom: '1px dashed gray',
                          },
                          '&:hover fieldset': {
                            borderBottom: '1px dashed gray',
                          },
                          '&.Mui-focused fieldset': {
                            borderBottom: '1px dashed gray',
                          },

                          '&.Mui-error fieldset': {
                            borderColor: 'red',
                          },
                          '& input::placeholder': {
                            fontSize: '12px',
                          },
                        },
                        '& .MuiFormLabel-root': {
                          color: '#03071e66',
                        },
                        '& .MuiFormLabel-root.Mui-focused': {
                          color: '#5a0bdf',
                        },
                        '& .MuiFormLabel-root.Mui-error': {
                          color: 'red',
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        placeholder="Due Date"
                        inputFormat="MM-dd-yyyy"
                        value={
                          taskData?.due_date ? dayjs(taskData?.due_date) : null
                        }
                        onChange={(newValue) => {
                          setTaskData({
                            ...taskData,
                            due_date: new Date(
                              new Date(newValue).getTime(),
                            ).toISOString(),
                          });
                        }}
                        sx={{
                          width: '150px',
                          height: '60px',
                          outline: 'none',
                          border: 'none',

                          '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                            {
                              outline: 'none',
                              borderColor: 'none',
                              border: 'none',
                            },
                          '& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused':
                            {
                              color: '#711FFF',
                            },
                          '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
                            {
                              borderColor: 'none',
                              border: 'none',
                              outline: 'none',
                              borderBottom: '1px dashed gray',
                            },
                          '& .MuiButtonBase-root MuiIconButton-root MuiIconButton-edgeEnd MuiIconButton-sizeMedium':
                            {
                              marginRight: '0px',
                              position: 'absolute',
                              borderRadius: '0px !important',
                              width: '100% !important',
                              height: '60px',
                            },
                          '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root':
                            {
                              marginRight: '0px',
                              position: 'absolute',
                              borderRadius: '0px !important',
                              width: '100% !important',
                              height: '50px',
                              borderBottom: '1px dashed gray',
                            },
                          '& .MuiInputAdornment-root': {
                            position: 'absolute',
                            top: '28px',
                            left: '-6px',
                            borderRadius: '0px',
                            width: '100%',
                          },
                          '& .MuiSvgIcon-root': {
                            display: 'none',
                            position: 'absolute',
                            right: '10px',
                          },
                          '& .MuiInputBase-root': {
                            width: '100%',
                            position: 'relative',
                          },
                          '& .MuiOutlinedInput-input': {
                            width: '100%',
                            height: '50px',
                            padding: '0px',
                            outline: 'none',
                            color: 'black',
                            marginLeft: '10px',
                          },
                          '& input::placeholder': {
                            fontSize: '12px',
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            outline: 'none !important',
                            border: 'none !important',
                            width: '100%',

                            height: '50px',
                            borderBottom: '1px dashed gray',
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            // eslint-disable-next-line
                            {...params}
                            sx={{
                              border: 'none',
                              borderBottom: '1px dashed lightgray',
                              '& .MuiOutlinedInput-root': {
                                border: 'none',
                                width: '280px',
                                outline: 'none',
                                padding: '0px',
                                '& fieldset': {
                                  border: 'none',
                                  borderBottom: '1px dashed gray',
                                },
                                '&:hover fieldset': {
                                  border: 'none',
                                  borderBottom: '1px dashed gray',
                                },
                                '& input::placeholder': {
                                  fontSize: '12px',
                                },
                                '&.Mui-error fieldset': {
                                  border: 'none',
                                },
                              },
                              '& .MuiFormLabel-root-MuiInputLabel-root .Mui-error':
                                {
                                  color: '#000',
                                },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </TableCell>
                  {formData?.project_type === 'retainer' && (
                    <TableCell>
                      <Box
                        className="dFlex"
                        sx={{
                          gap: '0.5rem',
                          fontSize: '0.8rem',
                          fontWeight: 600,
                          mt: '2.3rem',
                          width: '95px',
                          height: '23px',
                        }}
                      >
                        <NetNetCheckBox
                          onChange={(e) => {
                            setTaskData({
                              ...taskData,
                              carry_forward_setting: e.target.checked
                                ? 'repeat_task'
                                : 'carry_task',
                            });
                          }}
                        />{' '}
                        <span>Repeat Task</span>
                      </Box>
                    </TableCell>
                  )}

                  <TableCell>
                    <Box sx={{ display: 'flex', gap: '5px' }}>
                      <button
                        type="button"
                        disabled={loading}
                        onClick={async () => {
                          if (!taskData.task_name) {
                            setError('Task Name is Required');

                            setTimeout(() => {
                              setError(false);
                            }, 5000);
                            return;
                          }

                          if (!taskData.task_worktypes) {
                            setError('Worktype is Required');

                            setTimeout(() => {
                              setError(false);
                            }, 5000);
                            return;
                          }
                          const currentYear = new Date().getFullYear();

                          // Create a new date for the first day of the month by adding 1 to the month index
                          const targetStartDate = new Date(
                            currentYear,
                            currentMonthIndex + 1,
                            1,
                          );
                          setLoading(true);

                          const currentProjectCompanyID =
                            (Array.isArray(projectsFromGlobalState) &&
                              projectsFromGlobalState.find(
                                (project) => project.id === id,
                              )?.company) ??
                            null;

                          const response = await fetch(
                            `${ENDPOINT}/api/project/task/create/`,
                            {
                              method: 'POST',
                              headers: {
                                Authorization: `JWT ${access_token}`,
                                'Content-Type': 'application/json',
                              },
                              body: JSON.stringify(
                                taskData.due_date
                                  ? {
                                      ...taskData,
                                      due_date: new Date(
                                        new Date(taskData?.due_date).setDate(
                                          new Date(taskData.due_date).getDate(),
                                        ),
                                      ).toISOString(),
                                      description: html,
                                      task_deliverable: currentDil?.id,
                                      target_start: targetStartDate
                                        .toISOString()
                                        .split('T')[0],
                                      task_project: Number(id),
                                      estimated_hours: taskData.estimated_hours,
                                      task_worktypes:
                                        taskData.task_worktypes.map((wt) => ({
                                          ...wt,
                                          hours: taskData.estimated_hours,
                                        })),
                                      task_status: 'Backlog',
                                      company: currentProjectCompanyID,
                                      task_type:
                                        formData?.project_type === 'retainer'
                                          ? 'project_task_retainer'
                                          : 'project_task',
                                      task_assignee: taskData.assignee_task.map(
                                        (el) => ({
                                          ...el,
                                          hours: taskData.estimated_hours,
                                        }),
                                      ),
                                    }
                                  : {
                                      ...taskData,
                                      task_deliverable: currentDil?.id,
                                      target_start: targetStartDate
                                        .toISOString()
                                        .split('T')[0],
                                      task_project: id,
                                      description: html,
                                      estimated_hours: taskData.estimated_hours,
                                      task_status: '',
                                      company: currentProjectCompanyID,
                                      task_type:
                                        formData?.project_type === 'retainer'
                                          ? 'project_task_retainer'
                                          : 'project_task',
                                      task_assignee: taskData.assignee_task.map(
                                        (el) => ({
                                          ...el,
                                          hours: taskData.estimated_hours,
                                        }),
                                      ),
                                      task_worktypes:
                                        taskData.task_worktypes.map((wt) => ({
                                          ...wt,
                                          hours: taskData.estimated_hours,
                                        })),
                                    },
                              ),
                            },
                          );
                          const data = await response.json();
                          if (!response.ok) {
                            Object.keys(data).forEach((key) => {
                              setError(data[key]);
                              setTimeout(() => {
                                setError(false);
                              }, 5000);
                            });
                            setLoading(false);
                          } else {
                            getData();
                            localStorage.removeItem('taskData');
                            setAddTask(false);
                            setTaskData({
                              task_name: '',
                              description: '',
                              due_date: '',
                              task_project: id,
                              task_deliverable: currentDil?.id,
                              estimated_hours: '',
                              task_worktypes: [],
                              assignee_task: [],
                            });
                            dispatch(
                              New_channels({
                                access_token,
                              }),
                            );
                            setLoading(false);
                          }
                        }}
                        style={{
                          padding: '1rem 1.5rem',
                          color: '#fff',
                          background: '#0cdeb4',
                          borderRadius: '5px',
                        }}
                      >
                        {loading ? (
                          <Box sx={{ width: '25px', height: '25px' }}>
                            {' '}
                            <PreLoader1 />
                          </Box>
                        ) : (
                          'Save'
                        )}
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          localStorage.removeItem('taskData');
                          setAddTask(false);
                        }}
                        style={{
                          padding: '1rem 1.5rem',
                          color: '#fff',
                          background: '#ed5959',
                          borderRadius: '5px',
                        }}
                      >
                        Cancel
                      </button>
                    </Box>
                  </TableCell>
                  {error && (
                    <Shake>
                      {' '}
                      <p
                        style={{
                          position: 'absolute',
                          top: '0px',
                          left: '40%',
                          color: 'red',
                          fontSize: '12px',
                        }}
                      >
                        {' '}
                        {error && error}
                      </p>
                    </Shake>
                  )}
                </TableRow>
              )}
            </TableContainer>
          )}
        </Droppable>
      </DragDropContext>
      {showDesc && (
        <Popover
          id={desId}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            style: { borderRadius: '8px' },
          }}
        >
          <Box
            sx={{
              borderRadius: '9px',
              maxWidth: '768px',
              minHeight: '170px',
              maxHeight: 'auto',
              overflowY: 'auto',
              scrollbarWidth: 'none',
            }}
          >
            <Test
              check={check}
              open={openLink}
              setOpen={setOpenLink}
              html={html}
              setHtml={setHtml}
              setShowDesc={setShowDesc}
            />
          </Box>
        </Popover>
      )}
      <SimpleDialog
        open={openLink}
        setOpen={setOpenLink}
        linkUrl={linkUrl}
        setLinkUrl={setLinkUrl}
        setLink={setLink}
      />
    </Grid>
  );
}

export default React.memo(InnerTable);
