import { Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

function EditIconButton({ toolTipTitle, width, onClick }) {
  return (
    <Tooltip title={toolTipTitle || 'Edit'}>
      <EditIcon
        sx={{
          fill: '#8d8e8e',
          width: width || '20px',
          margin: '0 9px',
          transition: 'fill 0.2s',
          '&:hover': {
            fill: '#310085',
          },
        }}
        onClick={onClick}
      />
    </Tooltip>
  );
}

export default EditIconButton;
