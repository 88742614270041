import { Grid, List, ListItem, Typography, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { ProgressBar } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getVariant } from '../Projects/ProjectUtility';

function VarPro({ meta, dil }) {
  const calculateTotalDurationObtained = (timeTrackingArray) => {
    let totalDuration = 0;

    if (Array.isArray(timeTrackingArray) && timeTrackingArray.length > 0) {
      totalDuration = timeTrackingArray
        .filter((ele) => ele.tracked_time_hours)
        .reduce((acc, item) => {
          const hours = Number(item.tracked_time_hours.split(':')[0]);
          return acc + hours;
        }, 0);
    }

    return totalDuration;
  };
  const worked_hour =
    Array.isArray(dil.task_deliverable) && dil.task_deliverable.length
      ? dil.task_deliverable
          .filter((tak) =>
            tak.task_worktype.some((ele) => ele.name === meta.name),
          )
          .reduce(
            (acc, tsk) =>
              acc + calculateTotalDurationObtained(tsk.time_tracking),
            0,
          )
      : 0;
  const total_hour = meta.hours ? meta.hours : 2;
  return (
    <div
      className="progress-container"
      style={{ width: '60%', borderRight: '1.5px dashed #d9d9d9' }}
    >
      <ProgressBar
        sx
        now={Math.round(worked_hour)}
        variant={getVariant(worked_hour, total_hour, 'date')}
        min={0}
        max={total_hour}
      />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <span className="span">{Math.round(worked_hour)}h</span>
        <span className="span">{Math.round(total_hour)}h</span>
      </Box>
    </div>
  );
}

function TimeSheet({ dil }) {
  const task = dil;

  const calculateTotalDurationObtained = (timeTrackingArray) => {
    let totalDuration = 0;

    if (Array.isArray(timeTrackingArray) && timeTrackingArray.length > 0) {
      totalDuration = timeTrackingArray
        .filter((ele) => ele.tracked_time_hours)
        .reduce((acc, item) => {
          const hours = Number(item.tracked_time_hours.split(':')[0]);
          return acc + hours;
        }, 0);
    }

    return totalDuration;
  };

  const total_hour = task.worktype.reduce((acc, item) => {
    return acc + Number(item.hours);
  }, 0);
  const worked_hour =
    task.task_deliverable &&
    Array.isArray(task.task_deliverable) &&
    task.task_deliverable.length
      ? Math.round(
          task.task_deliverable.reduce((acc, item) => {
            return (
              acc + Number(calculateTotalDurationObtained(item.time_tracking))
            );
          }, 0),
        )
      : 0;

  const currentDate = moment();
  const endDate = moment(dil.deliverable_enddate);

  const getToday = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  };

  const start = dil?.deliverable_startdate
    ? new Date(dil?.deliverable_startdate?.split('T')[0]).getTime()
    : new Date().getTime();
  const end = dil?.deliverable_enddate
    ? new Date(dil?.deliverable_enddate?.split('T')[0]).getTime()
    : new Date().getTime();
  const today = getToday().getTime();
  const progressDate = (today - start) / (1000 * 3600 * 24);
  const totalDate = (end - start) / (1000 * 3600 * 24);
  return (
    <Grid
      item
      container
      direction="column"
      alignItems="flex-start"
      xl={6}
      lg={6}
      md={6}
      sm={6}
      sx={{ gap: '1rem' }}
    >
      <div className="summery">
        <div className="inner-summery">
          <span className="span">Level of Effort(LOE)</span>
          <ProgressBar
            sx
            now={worked_hour}
            className={getVariant(worked_hour, total_hour, 'hour')}
            min={0}
            max={total_hour}
          />

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '200px',
              gap: '1rem',
            }}
          >
            {worked_hour}hr
            <i>/</i>
            {total_hour}hr
          </div>
        </div>
      </div>

      <div className="summery">
        <div className="inner-summery">
          <span className="span">Timeline</span>
          <ProgressBar
            sx
            now={progressDate}
            className={getVariant(progressDate, totalDate, 'hour')}
            min={0}
            max={totalDate}
          />

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '200px',
              gap: '1rem',
            }}
          >
            {currentDate.format('MMM DD, YYYY')}
            {'  '} / {'  '}
            {endDate.format('MMM DD, YYYY')}
          </div>
        </div>
      </div>
    </Grid>
  );
}

function WorkTypeProgress({ dil }) {
  const [ourWorkTypes, setOurWorkTypes] = useState([]);
  const userWorkTypes = useSelector((state) => state.Misc.workTypes);
  useEffect(() => {
    if (
      Array.isArray(dil.deliverable_worktype) &&
      dil.deliverable_worktype.length
    )
      if ('name' in dil.deliverable_worktype[0]) {
        setOurWorkTypes(dil.deliverable_worktype);
      } else {
        // eslint-disable-next-line
        if (Array.isArray(userWorkTypes) && userWorkTypes.length) {
          const newWorkTypes = dil.deliverable_worktype.map((item) => {
            const merger = userWorkTypes.find(
              (ele) => Number(ele.id) === Number(item.id),
            );
            return {
              ...item,
              ...merger,
            };
          });
          setOurWorkTypes(newWorkTypes);
        }
      }
  }, [userWorkTypes, dil]);

  return Array.isArray(ourWorkTypes) && ourWorkTypes.length ? (
    <Grid
      container
      item
      direction="column"
      alignItems="flex-start"
      xl={5}
      lg={5}
      md={5}
      sm={5}
    >
      <List sx={{ width: '100%' }}>
        <Typography
          sx={{
            fontFamily: 'Open Sans',
            fontSize: '1rem',
            color: '#711fff',
            fontWeight: '600',
          }}
        >
          WORK TYPE PROGRESS
        </Typography>
        {ourWorkTypes.map((item) => {
          return (
            <ListItem
              key={item?.name}
              sx={{ justifyContent: 'space-between', width: '100%' }}
            >
              {item.name ? item.name : ''}
              <VarPro meta={item} dil={dil} />
            </ListItem>
          );
        })}
      </List>
    </Grid>
  ) : null;
}

function DesignOne({ dil }) {
  return (
    <Grid
      container
      alignItems="flex-start"
      justifyContent="space-between"
      sx={{
        padding: '1.88rem 0.44rem',
        border: '1px solid #d9d9d9',
        borderRadius: '8px',
      }}
    >
      <TimeSheet dil={dil} />
      <WorkTypeProgress dil={dil} />
    </Grid>
  );
}

function DesignTwo({ title, worked_hour, total_hour }) {
  return (
    <div className="container2">
      <div style={{}}>
        <div className="part2">
          <span className="design-title">{title}</span>
          <div className="design-progess">
            <div className="progress-container">
              <ProgressBar
                sx
                now={worked_hour}
                className={getVariant(worked_hour, total_hour, 'date')}
                min={0}
                max={total_hour}
              />
              <span className="span">{worked_hour} h</span>
            </div>
            <span className="span">
              {worked_hour}:00<i>/</i>
              {total_hour}:00
            </span>
          </div>
        </div>
        <span className="span" style={{ float: 'right' }}>
          {' '}
          - - - Assigned time for thsi task
        </span>
      </div>
    </div>
  );
}
export { DesignTwo };
export default DesignOne;
