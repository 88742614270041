import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Alert from '@mui/material/Alert';
import { NetNetCheckBox } from '../../../Componenets/Misc';
import { PreLoading } from '../People/EditContact';

function MapFields({
  item,
  num,
  setConstructor,
  setIsError,
  setErrMsg,
  newParser,
  setMapped,
  mapped,
  columns,
}) {
  const filter = createFilterOptions();
  const mapOptions = [
    { name: 'Skip Column', inputValue: 'skip_col', cat: 'Skip' },
    { name: 'First Name', inputValue: 'first_name', cat: 'People' },
    { name: 'Last Name', inputValue: 'last_name', cat: 'People' },
    { name: 'Email', inputValue: 'email', cat: 'People' },
    { name: 'Mobile Number', inputValue: 'mobile_num', cat: 'People' },
    { name: 'Job Title', inputValue: 'title', cat: 'People' },
    { name: 'Country', inputValue: 'country', cat: 'People' },
    { name: 'State', inputValue: 'state', cat: 'People' },
    { name: 'Description', inputValue: 'description', cat: 'People' },
    { name: 'City', inputValue: 'city', cat: 'People' },
    { name: 'Zip Code ', inputValue: 'address_zip', cat: 'People' },
    { name: 'Tags', inputValue: 'tags', cat: 'People' },
    { name: 'Address 1', inputValue: 'street', cat: 'People' },
    { name: 'Address 2', inputValue: 'street2', cat: 'People' },
    { name: 'LinkedIn', inputValue: 'linkedin_url', cat: 'People' },
    { name: 'Facebook', inputValue: 'facebook_url', cat: 'People' },
    { name: 'X', inputValue: 'twitter_url', cat: 'People' },
    { name: 'Instagram', inputValue: 'instagram_url', cat: 'People' },
    { name: 'Notes', inputValue: 'notes', cat: 'People' },
    {
      name: 'Type',
      inputValue: 'contact_type',
      cat: 'People',
    },
    { name: 'Company Name', inputValue: 'company', cat: 'Company' },

    {
      name: 'Company Title',
      inputValue: 'company_title',
      cat: 'Company',
    },
    {
      name: 'Company Tags',
      inputValue: 'company_tags',
      cat: 'Company',
    },
    { name: 'NAICS Industry', inputValue: 'company_industry', cat: 'Company' },
    { name: 'Company Type', inputValue: 'company_type', cat: 'Company' },
    { name: 'Office Number', inputValue: 'company_office_num', cat: 'Company' },
    {
      name: 'Company Status',
      inputValue: 'company_status',
      cat: 'Company',
    },
    { name: 'Notes', inputValue: 'notes', cat: 'Company' },

    { name: 'Company URL', inputValue: 'company_url', cat: 'Company' },

    {
      name: 'Company LinkedIn',
      inputValue: 'company_linkedin_url',
      cat: 'Company',
    },
    {
      name: 'Company Facebook ',
      inputValue: 'company_facebook_url',
      cat: 'Company',
    },
    {
      name: 'Company X',
      inputValue: 'company_twitter_url',
      cat: 'Company',
    },
    {
      name: 'Company Instagram',
      inputValue: 'company_instagram_url',
      cat: 'Company',
    },
    {
      name: 'Company Address 1',
      inputValue: 'company_street',
      cat: 'Company',
    },
    {
      name: 'Company Address 2',
      inputValue: 'company_street2',
      cat: 'Company',
    },
    {
      name: 'Company State',
      inputValue: 'company_state',
      cat: 'Company',
    },
    {
      name: 'Company City',
      inputValue: 'company_city',
      cat: 'Company',
    },
    { name: 'Company Country', inputValue: 'company_country', cat: 'Company' },
    {
      name: 'Company Zip',
      inputValue: 'company_address_zip',
      cat: 'Company',
    },
    {
      name: 'Company Description',
      inputValue: 'company_description',
      cat: 'Company',
    },
    {
      name: 'Company Email',
      inputValue: 'company_email',
      cat: 'Company',
    },
    {
      name: 'Company Email2',
      inputValue: 'company_email2',
      cat: 'Company',
    },
  ];

  const initialNetProps = {
    first_name: '',
    last_name: '',
    email: '',
    mobile_num: '',
    title: '',
    tags: '',
    description: '',
    website: '',
    company: '',
    country: '',
    state: '',
    city: '',
    street: '',
    street2: '',
    zip: '',
    contact_type: '',
    linkedin_url: '',
    facebook_url: '',
    twitter_url: '',
    instagram_url: '',
    company_email: '',
    company_email2: '',
    company_description: '',
    company_address_zip: '',
    company_country: '',
    company_city: '',
    company_state: '',
    company_street: '',
    company_street2: '',
    company_instagram_url: '',
    company_twitter_url: '',
    company_linkedin_url: '',
    company_facebook_url: '',
    company_office_num: '',
    company_industry: '',
    company_tags: '',
    company_type: '',
    company_title: '',
    company_url: '',
  };
  const [netProps, setNetProps] = useState({ ...initialNetProps });

  useEffect(() => {
    if (mapped) {
      setIsError(false);
    } else {
      setIsError(true);
      setErrMsg(
        'Please ensure all the fields have been mapped to their crossponding NetNet property.',
      );
    }
  });

  return (
    <Autocomplete
      key={num}
      id="company"
      options={mapOptions}
      defaultValue={
        ['First Name', 'Last Name', 'Email', 'Company Name'].every((value) =>
          columns.includes(value),
        )
          ? item === 'First Name'
            ? 'First Name'
            : item === 'Last Name'
              ? 'Last Name'
              : item === 'Company Name'
                ? 'Company Name'
                : item === 'Email'
                  ? 'Email'
                  : item === 'Mobile Number'
                    ? 'Mobile Number'
                    : item === 'Personal LinkedIn'
                      ? 'LinkedIn'
                      : item === 'Personal X'
                        ? 'X'
                        : item === 'Person Address Line 1'
                          ? 'Address 1'
                          : item === 'Person Address Line 2'
                            ? 'Address 2'
                            : item === 'Person City'
                              ? 'City'
                              : item === 'Person State / Province'
                                ? 'State'
                                : item === 'Person Zip / Postal Code'
                                  ? 'Zip Code'
                                  : item === 'Person Country'
                                    ? 'Country'
                                    : item === 'Personal Instagram'
                                      ? 'Instagram'
                                      : item === 'Personal Facebook'
                                        ? 'Facebook'
                                        : item === 'Title'
                                          ? 'Job Title'
                                          : item === 'Personal Description'
                                            ? 'Description'
                                            : item === 'Office Telephone'
                                              ? 'Office Number'
                                              : item === 'Company Address 1'
                                                ? 'Company Address 1'
                                                : item ===
                                                    'Company State / Province'
                                                  ? 'Company State'
                                                  : item === 'Company Address 2'
                                                    ? 'Company Address 2'
                                                    : item === 'Company City'
                                                      ? 'Company City'
                                                      : item ===
                                                          'Company Zip Code'
                                                        ? 'Company Zip'
                                                        : item ===
                                                            'Company Country'
                                                          ? 'Company Country'
                                                          : item ===
                                                              'NAICS Industry'
                                                            ? 'NAICS Industry'
                                                            : item ===
                                                                'Company Linkedin'
                                                              ? 'Company Linkedin'
                                                              : item ===
                                                                  'Company X'
                                                                ? 'Company X'
                                                                : item ===
                                                                    'Company Instagram'
                                                                  ? 'Company Instagram'
                                                                  : item ===
                                                                      'Company URL'
                                                                    ? 'Company URL'
                                                                    : item ===
                                                                        'Company Facebook'
                                                                      ? 'Company Facebook'
                                                                      : item ===
                                                                          'Company Description'
                                                                        ? 'Company Description'
                                                                        : item ===
                                                                            'Company Type'
                                                                          ? 'Company Type'
                                                                          : ''
          : ''
      }
      groupBy={(option) => option.cat}
      includeInputInList
      onChange={(e, value) => {
        setMapped(true);
        setNetProps({ ...initialNetProps });
        const myValues = newParser.map((ele) => ele[item]);
        const find = newParser.map((va, index) => {
          if (value.inputValue in netProps) {
            netProps[value.inputValue] = myValues[index];
            return {
              ...netProps,
            };
          }
          if ('contact_dynamicfield' in netProps) {
            return {
              ...netProps,
              contact_dynamicfield: [
                {
                  dynamicfield_charfield: [
                    netProps.contact_dynamicfield[0].dynamicfield_charfield,
                    {
                      field_name: value.inputValue,
                      value: myValues[index],
                    },
                  ],
                },
              ],
            };
          }
          return {
            ...netProps,
            contact_dynamicfield: [
              {
                dynamicfield_charfield: [
                  {
                    field_name: value.inputValue,
                    value: myValues[index],
                  },
                ],
              },
            ],
          };
        });
        if (value.inputValue === 'skip_col') {
          // eslint-disable-next-line
          for (let i = 0; i < find.length; i++) {
            find[i][item] = '';
          }
        }

        setConstructor((prev) => {
          if (prev.length < 1) {
            return find;
          }

          const newArr = prev.map((c, index) => {
            const objB = { ...find[index] };
            Object.keys(objB).forEach((key) => {
              if (!objB[key]) {
                delete objB[key];
              }
            });
            const update = {
              ...c,
              ...objB,
            };

            return update;
          });
          return newArr;
        });
      }}
      freeSolo
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        return option.name;
      }}
      style={{ width: 300, marginTop: '0.7rem' }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        if (params.inputValue !== '') {
          filtered.push({
            inputValue: params.inputValue,
            name: `Add New Column"${params.inputValue}"`,
          });
        }
        return filtered;
      }}
      // eslint-disable-next-line
      renderOption={(props, option) => <li {...props}>{option.name}</li>}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line
          {...params}
          label="Select Net Net Property"
          variant="standard"
          color="secondary"
          InputProps={{
            ...params.InputProps,
            style: { width: 300 },
          }}
        />
      )}
    />
  );
}

function ImportTHead() {
  return (
    <TableHead
      sx={{
        height: '90.12px',
      }}
    >
      <TableRow>
        <TableCell align="left">
          <div className="tcell">Column Header From File</div>
        </TableCell>
        <TableCell align="left">
          <div className="tcell">Preview Information</div>
        </TableCell>
        <TableCell align="left">
          <div className="tcell">Net Net property</div>
        </TableCell>
        <TableCell align="right">
          <div className="tcell">Don&apos;t Overwrite Existing Values</div>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

function ThirdStep({
  step,
  setStep,
  newParser,
  constructor,
  setConstructor,
  setNewParser,
}) {
  const [miniLoading, setMiniLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const [column, setColumn] = useState([]);
  const [mapped, setMapped] = useState(false);
  useEffect(() => {
    // Set the sorted column names as the state variable
    setColumn(Object.keys(newParser[0]));
  }, [mapped]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMiniLoading(true);
    setStep(4);
  };
  const [pageHeight, setPageHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setPageHeight(window.innerHeight);
    };

    // Add event listener to update page height on resize
    window.addEventListener('resize', handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '2rem',
        height: `${pageHeight - 180}px`,
        overflow: 'auto',
      }}
    >
      {miniLoading && <PreLoading />}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '60%',
          backgroundColor: '#fcfcfc',
          borderRadius: '8px',
          padding: '0.5rem 0.80rem',
          border: '1px solid #d9d9d9',
        }}
      >
        <h3
          style={{
            color: '#000',
          }}
          className="txtCenter txtSM bd600"
        >
          Map Columns in your file to People Properties
        </h3>
        <p className="txtCenter initialParagraph txtESM">
          Each column header below should be mapped to a People property in Net
          Net. Some of these have already been mapped based on their names.
          Anything that hasn&apos;t been mapped yet can be manually mapped to a
          People property with the dropdown menu. You can always create a new
          property with the dropdown menu. You can always create a new property
          or choose “Don&apos;t import column”
        </p>
      </Box>
      {isError && <Alert severity="info">{errMsg}</Alert>}
      <Box sx={{ width: '100%' }}>
        <TableContainer
          component={Paper}
          sx={{
            width: '100%',
            backgroundColor: '#fff',
            marginBottom: '70px',
          }}
        >
          <Table aria-label="simple table">
            <ImportTHead />
            <TableBody>
              {column.map((item, num) => (
                <TableRow
                  key={item}
                  sx={{
                    '&:nth-of-type(odd)': {
                      backgroundColor: '#e9ecef',
                    },
                    height: '110px',
                  }}
                  hover
                >
                  <TableCell>
                    <div
                      className="fstxt"
                      style={{ textTransform: 'capitalize' }}
                    >
                      {item === 'First Name'
                        ? 'First Name'
                        : item === 'Last Name'
                          ? 'Last Name'
                          : item === 'Email'
                            ? 'Email'
                            : item}
                    </div>
                  </TableCell>

                  <TableCell>
                    {newParser.length > 3
                      ? newParser.slice(0, 3).map((ele) => (
                          <div key={ele[item]} className="fstxt">
                            {ele[item]}
                          </div>
                        ))
                      : newParser.map((ins) => (
                          <div key={ins[item]} className="fstxt">
                            {ins[item]}
                          </div>
                        ))}
                  </TableCell>
                  <TableCell align="left">
                    <MapFields
                      constructor={constructor}
                      setConstructor={setConstructor}
                      item={item}
                      num={num}
                      setIsError={setIsError}
                      setErrMsg={setErrMsg}
                      newParser={newParser}
                      setNewParser={setNewParser}
                      mapped={mapped}
                      setMapped={setMapped}
                      columns={column}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <NetNetCheckBox defaultChecked />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box
        sx={{
          position: 'fixed',
          bottom: '1px',
          width: { xl: '85%', lg: '80%', md: '98%' },
        }}
        className="AlBottom footer"
      >
        <ul className="footer-list">
          <li className={step === 2 ? 'footer-item thisStep' : 'footer-item'}>
            Import Files
          </li>
          <li className={step === 3 ? 'footer-item thisStep' : 'footer-item'}>
            Map Fields
          </li>
          <li className={step === 4 ? 'footer-item thisStep' : 'footer-item'}>
            Type
          </li>
          <li className={step === 5 ? 'footer-item thisStep' : 'footer-item'}>
            Exceptions
          </li>
        </ul>

        <div className="AlRight dd-import-group">
          {' '}
          <button
            type="button"
            className="cancel-btn alRight fsESM border"
            onClick={() => setStep(2)}
          >
            Back
          </button>
          <Tooltip title={isError ? 'Please First Map All The Fields ' : ''}>
            <button
              type="button"
              className="smBtn"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Next
            </button>
          </Tooltip>
        </div>
      </Box>
    </Box>
  );
}

export default ThirdStep;
