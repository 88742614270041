import React, { useCallback, useState, useEffect } from 'react';

import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Document, Page } from '@react-pdf/renderer';
import uuid from 'react-uuid';

import { Delete, Edit, MoreHoriz } from '@mui/icons-material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import GridViewIcon from '@mui/icons-material/GridView';
import { Box, Grid, Typography } from '@mui/material';

import { formatMoneyString } from '../estSteps/SixthStep';
import { ENDPOINT, getPaymentTerms, getTermAndCondtion } from '../Redux/oppor';
import Test from '../../Test';
import {
  calculateReduction2,
  calculateReduction3,
  calculateTotalNet,
  sumPriceToCustomer,
  calculatePercentage,
} from './Fifth';
import { NetNetSwitch } from '../../../Componenets/Misc';

const ccri = {
  color: '#03071E',
  fontFamily: 'Open Sans',
  fontSize: '0.75rem',
  fontWeight: '400',
  paddingLeft: '25px',
  paddingRight: '50px',
  textAlign: 'justify',
};

const spri = {
  color: '#03071E',
  fontFamily: 'Open Sans',
  fontSize: '0.85rem',
  fontWeight: '700',
  letterSpacing: '-0.3px',
};

function CulminationCol({ formData, setFormData }) {
  const access_token = useSelector((state) => state.auth.user.access);
  const [editEst, setEditEst] = useState(false);
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}
    >
      <Typography
        sx={{
          color: '#03071E',
          fontFamily: 'Open Sans',
          fontSize: '22px',
          fontWeight: '700',
          padding: '1rem',
          paddingTop: '0rem',
          paddingBottom: '0.25rem',
          display: 'flex',
          alignItems: 'center',
        }}
        className="fstxt"
      >
        Estimate No{' '}
        {!editEst ? (
          <Typography
            onMouseEnter={(e) => {
              e.target.style.borderBottom = '1px dashed rgba(0,0,0,1)';
            }}
            onMouseLeave={(e) => {
              e.target.style.borderBottom = '1px dashed rgba(0,0,0,0)';
            }}
            sx={{ fontSize: '22px', fontWeight: '700' }}
            onClick={() => {
              setEditEst(true);
            }}
          >
            {' '}
            {formData?.estimate_number
              ? `#${formData?.estimate_number}`
              : '#'}{' '}
          </Typography>
        ) : (
          <input
            onBlur={async () => {
              setEditEst(false);

              if (formData.estimate_number) {
                const shallowCopy = {
                  ...formData,
                };

                delete shallowCopy.work_type;
                delete shallowCopy.estimate_hardcost;
                const res = await fetch(
                  `${ENDPOINT}/api/opportunity/update/estimate/${formData.id}/`,
                  {
                    method: 'PUT',
                    headers: {
                      Authorization: `JWT ${access_token}`,
                      'Content-Type': 'application/json',
                    },
                    body:
                      Array.isArray(formData.collaborator) &&
                      formData.collaborator.length
                        ? JSON.stringify({
                            ...shallowCopy,
                            estimate_number: formData.estimate_number,
                            collaborator: formData?.collaborator?.map(
                              (item) => item?.id,
                            ),
                          })
                        : JSON.stringify({
                            ...shallowCopy,
                            estimate_number: formData.estimate_number,
                          }),
                  },
                );

                // eslint-disable-next-line
                const data = await res.json();
                if (!res.ok) {
                  setEditEst(false);
                } else {
                  setEditEst(false);
                }
              }
            }}
            onChange={(e) =>
              setFormData({
                ...formData,
                estimate_number: e.target.value,
              })
            }
            value={formData.estimate_number ? formData.estimate_number : ''}
            style={{
              border: 'none',
              outline: 'none',
              background: '#fff',
              width: '120px',
              textAlign: 'end',
            }}
          />
        )}
      </Typography>
    </Box>
  );
}

function IntroCol({ filteredComp, person, currentDate }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <Typography sx={{ ...ccri, margin: '1rem, 0' }}>{currentDate}</Typography>
      <Typography sx={ccri} style={{ marginTop: '10px' }}>
        {filteredComp ? filteredComp?.name : ''}
      </Typography>
      <Typography sx={ccri}>
        {person && person.first_name ? person.first_name : ''}{' '}
        {person && person.last_name ? person.last_name : ''}
      </Typography>

      <Typography sx={ccri}>
        {filteredComp ? filteredComp?.street : ''}{' '}
      </Typography>
      <Typography sx={ccri}>
        {filteredComp ? filteredComp?.street2 : ''}{' '}
      </Typography>
      <Typography sx={{ ...ccri, paddingBottom: '50px' }}>
        {filteredComp && filteredComp.city ? filteredComp?.city : ''}{' '}
        {filteredComp && filteredComp.state ? ` , ${filteredComp?.state}` : ''}{' '}
        {filteredComp && filteredComp.address_zip
          ? filteredComp?.address_zip
          : ''}
      </Typography>
    </Box>
  );
}

function IntroRow({ formData, setFormData, currentDate }) {
  const comp = useSelector((state) => state.Contacts.companies);
  const people = useSelector((state) => state.Contacts.persons);
  const person = people.find((item) => item.id === formData.contact);
  const filteredComp = comp.find((item) => item.id === formData.company);

  const [est_num, setEst_num] = useState('#11001');

  return (
    <Grid
      sx={{ display: 'flex' }}
      item
      container
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <IntroCol
        filteredComp={filteredComp}
        person={person}
        currentDate={currentDate}
      />
      <CulminationCol
        est_num={est_num}
        setEst_num={setEst_num}
        setFormData={setFormData}
        formData={formData}
      />
    </Grid>
  );
}

function MileStones({
  realPaymentTerms,
  realTermAndContitions,
  realstate,
  setRealState,
  paymentTerms,
  termAndContitions,
  globleTotalCost,
  dileverable,
  formData,
  pic,
  estimate_hardcost,
  currentTarget,
  isTyping,
  setIsTyping,
  dispatch,
  setFormData,
  setShowDescription,
  setEditSection,
  editSection,
  setIsDetOpen,
  hideIcons,
}) {
  const access_token = useSelector((state) => state.auth.user.access);
  const [data, setData] = useState([
    {
      id: 'Summary',
      contentHead: 'Summary',
      contentPara: formData.description ? formData.description : null,
    },

    {
      id: 'DELIVERABLES',
      contentHead: 'Deliverables',
      deliverables: dileverable,
    },
    {
      id: 'Pricing',
      contentHead: 'PRICING',
      tableHeads: [
        {
          one: 'Deliverable',
          two: 'Subtotal',
          three: 'FED Taxes',
          four: 'NGO Discount',
          five: 'Total',
        },
      ],

      tableData: dileverable,
      tableSubTotal: 'SUBTOTAL',
      hardCostSub: 'SUBTOTAL',
      hardCostHeads: ['Hard Costs', 'Total'],
      hardCostData: estimate_hardcost,
      totalCostSub: 'TOTAL',

      TotalCostData: globleTotalCost,
    },
    {
      id: 'paymentTerms',
      contentHead: 'Payment Terms',

      contentPara: realPaymentTerms.id ? realPaymentTerms?.payment_terms : '',
      isEditing: false,
      paymentTermsID: null,
    },

    {
      id: 'timeline',
      contentHead: 'TIMELINE',
      timeLinePic: pic ?? null,
      estimateStartDate: formData.est_startdate
        ? moment(formData.est_startdate).format('DD MMM, YYYY')
        : null,
      estimateEndDate: formData.est_enddate
        ? moment(formData.est_enddate).format('DD MMM, YYYY')
        : 'No End Date',
    },
    {
      id: 'conditionsTerms',

      contentHead: 'Terms and Conditions',
      contentPara: realTermAndContitions.id
        ? realTermAndContitions?.terms_conditions
        : '',
      isEditing: false,
      conditionTermsId: null,
    },

    {
      id: 'acceptance',
      contentHead: 'ACCEPTANCE',

      clientPara: (
        <p
          style={{
            display: 'flex',
            marginTop: '15px',
            marginBottom: '10px',
            pageBreakInside: 'avoid',
            whiteSpace: 'nowrap',
          }}
        >
          <b>THE UNDERSIGNED CLIENT,&nbsp;</b> does hereby accept and execute
          this Job Order on the date set forth herein.
        </p>
      ),

      clientName: (
        <p style={{ paddingTop: '20px', fontFamily: 'Open Sans' }}>
          Name ________________________________________________________________
        </p>
      ),
      clientSign: (
        <p style={{ paddingTop: '20px', fontFamily: 'Open Sans' }}>
          Signature ____________________________________________________________
        </p>
      ),
      clientTittle: (
        <p style={{ paddingTop: '20px', fontFamily: 'Open Sans' }}>
          Title
          __________________________________________________________________
        </p>
      ),
      clientDate: (
        <p style={{ paddingTop: '20px', fontFamily: 'Open Sans' }}>
          Date
          __________________________________________________________________
        </p>
      ),
    },
  ]);

  useEffect(() => {
    let updatedData = null;
    updatedData = data?.map((item) => {
      const section = formData?.section?.find(
        (section) => section?.section_id === item?.id,
      );
      if (section) {
        return {
          ...item,
          sort_order: section?.sort_order,
          section_uniqueid: section?.id,
        };
      }
      return item;
    });

    updatedData.sort((a, b) => (a.sort_order || 0) - (b.sort_order || 0));
    // Update the state with the new data array
    setRealState(updatedData);
  }, [formData.section]);

  useEffect(() => {
    if (realPaymentTerms?.id || realTermAndContitions.id) {
      const updatedData = realstate?.map((item) => {
        if (item.id === 'paymentTerms') {
          return {
            ...item,
            contentPara: realPaymentTerms?.payment_terms,
          };
        }
        if (item.id === 'conditionsTerms') {
          return {
            ...item,
            contentPara: realTermAndContitions?.terms_conditions,
          };
        }
        return item;
      });

      setRealState(updatedData);
    }
  }, [realPaymentTerms, realTermAndContitions]);

  useEffect(() => {
    if (realstate?.length || realstate !== null) {
      let updatedData = realstate?.length ? [...realstate] : [...data];
      if (Array.isArray(formData.section) && formData.section.length) {
        formData?.section.forEach((section, index) => {
          const sectionId = `section ${index}`;

          const sectionIndex = updatedData.findIndex(
            (ele) => ele?.id === sectionId,
          );

          if (sectionIndex !== -1) {
            // Update existing section
            updatedData[sectionIndex] = {
              ...updatedData[sectionIndex],
              contentHead: section.section_title,
              contentPara: section.section_description,
              sort_order: section.sort_order,
              request: section?.id,
            };
          } else {
            // Add new section
            updatedData.push({
              id: sectionId,
              contentHead: section.section_title,
              contentPara: section.section_description,
              sort_order: section.sort_order,
              request: section?.id,
            });
          }
        });

        // Remove any empty objects that might have been introduced
        updatedData = updatedData.filter((item) => item.id && item.contentHead);

        // Move the acceptance object to the end if it exists
        const acceptanceIndex = updatedData.findIndex(
          (ele) => ele?.id === 'acceptance',
        );
        if (acceptanceIndex !== -1) {
          const acceptanceObject = updatedData.splice(acceptanceIndex, 1)[0];

          // Sort the updatedData based on sort_order before adding the acceptance object
          updatedData.sort((a, b) => (a.sort_order || 0) - (b.sort_order || 0));

          // Add the acceptance object at the end
          updatedData.push(acceptanceObject);
        } else {
          // Sort the updatedData based on sort_order if there is no acceptance object
          updatedData.sort((a, b) => (a.sort_order || 0) - (b.sort_order || 0));
        }
        setRealState(updatedData);
      }
    }
  }, [formData, formData.section, editSection]);

  const onDragEnd = async (result, realstate, setRealState) => {
    if (!result.destination) {
      return;
    }
    const shallow_copy = { ...formData };
    const itemsCopy = Array.from(realstate);

    const [reorderedItem] = itemsCopy.splice(result.source.index, 1);
    itemsCopy.splice(result.destination.index, 0, reorderedItem);
    const updatedItems = itemsCopy.map((item, index) => {
      return {
        ...item,
        sort_order: index + 1, // Assuming sort_order starts from 1
      };
    });

    setRealState(updatedItems);
    updatedItems.forEach((item, index) => {
      // eslint-disable-next-line
      realstate[index].sort_order = item.sort_order;
    });

    const updatedItemsIdsAndSortOrders = updatedItems.map((item) => ({
      id: item.section_uniqueid ? item.section_uniqueid : item.request,
      section_id: item.id,
      sort_order: item.sort_order,
    }));

    delete shallow_copy.deliverable;
    delete shallow_copy.subteam_estimate;
    delete shallow_copy.work_type;
    delete shallow_copy.estimate_hardcost;

    const res = await fetch(
      `${ENDPOINT}/api/opportunity/update/estimate/${formData.id}/`,
      {
        method: 'PUT',
        headers: {
          Authorization: `JWT ${access_token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(
          Array.isArray(formData.collaborator) && formData.collaborator.length
            ? {
                ...shallow_copy,
                estimate_sections: updatedItemsIdsAndSortOrders?.map((item) => {
                  return {
                    id: item.id,
                    section_id: item.section_id,
                    sort_order: item.sort_order,
                  };
                }),
              }
            : {
                ...shallow_copy,
                estimate_sections: updatedItemsIdsAndSortOrders?.map((item) => {
                  return {
                    id: item.id,
                    section_id: item.section_id,
                    sort_order: item.sort_order,
                  };
                }),
              },
        ),
      },
    );

    if (res.ok) {
      const resGet = await fetch(
        `${ENDPOINT}/api/opportunity/estimate/${formData?.unique_id}/`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${access_token}`,
          },
        },
      );
      const dataGet = await resGet.json();
      if (resGet.ok) {
        dataGet?.section.forEach((item, index) => {
          // eslint-disable-next-line
          realstate[index] = item;
        });
        setFormData(dataGet);
      }
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElCondition, setAnchorEl2Condition] = React.useState(null);
  const open = Boolean(anchorEl);
  const openCondition = Boolean(anchorElCondition);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickCondition = (event) => {
    setAnchorEl2Condition(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseCondition = () => {
    setAnchorEl2Condition(null);
  };

  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const [itemSection, setItemSection] = useState();
  const handleClick2 = (event, item) => {
    setAnchorEl2(event.currentTarget);
    setItemSection(item);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const [paymentTerms_title, setPaymentTerms_title] = useState('');
  const [termAndContitions_title, setTermAndCondition_title] = useState('');

  const handleEditDescription = (item, index) => {
    const newData = [...realstate];
    if (item.terms_conditions) {
      newData[index].isEditing = false;
      setTermAndCondition_title(item?.title);
      newData[index].contentPara = item.terms_conditions;
      newData[index].conditionTermsId = item.id;
      newData[index].isEditing = true;
      setData(newData);
      setIsTyping(true);
    } else if (item.payment_terms) {
      newData[index].isEditing = false;
      setPaymentTerms_title(item?.title);
      newData[index].contentPara = item.payment_terms;
      newData[index].paymentTermsID = item.id;
      newData[index].isEditing = true;
      setData(newData);
      setIsTyping(true);
    } else {
      newData[item].isEditing = false;
      if (newData[item].id === 'paymentTerms') {
        setPaymentTerms_title(realPaymentTerms.title);
        newData[item].paymentTermsID = formData.payment_terms_selected
          ? formData.payment_terms_selected
          : null;
      }
      if (newData[item].id === 'conditionsTerms') {
        setTermAndCondition_title(realTermAndContitions.title);
        newData[item].conditionTermsId = formData.terms_conditions_selected
          ? formData.terms_conditions_selected
          : null;
      }
      setData(newData);
      setIsTyping(true);
      newData[item].isEditing = true;
    }
  };

  const handleNameChange = (index, value) => {
    const newData = [...realstate];

    if (newData[index]?.id === 'paymentTerms') {
      setPaymentTerms_title(value);
    } else if (newData[index]?.id === 'conditionsTerms') {
      setTermAndCondition_title(value);
    }
  };

  const handleDescriptionChange = (index, event) => {
    const newData = [...realstate];

    newData[index].contentPara = event;

    setData(newData);
    setIsTyping(true);
  };

  useEffect(() => {
    if (isTyping === false) {
      setPaymentTerms_title('');
      setTermAndCondition_title('');
    }
  }, [isTyping]);

  const handleAddConditions = async (index, item) => {
    const newData = [...realstate];
    setIsTyping(false);
    if (item.id === 'paymentTerms') {
      const shallow_copy = { ...formData };
      delete shallow_copy.work_type;
      delete shallow_copy.estimate_hardcost;

      newData[index].isEditing = false;

      const res2 = await fetch(
        `${ENDPOINT}/api/opportunity/create/estimate/paymentterms/`,
        {
          method: 'POST',
          headers: {
            Authorization: `JWT ${access_token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            title: paymentTerms_title ?? paymentTerms_title,
            payment_terms: item.contentPara,
          }),
        },
      );
      const terms = await res2.json();
      if (!res2.ok) {
        return;
      }

      const res3 = await fetch(
        `${ENDPOINT}/api/opportunity/update/estimate/${currentTarget.id}/`,
        {
          method: 'PUT',
          headers: {
            Authorization: `JWT ${access_token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...shallow_copy,
            payment_terms_selected: terms?.id,
          }),
        },
      );

      const terms2 = await res3.json();
      if (res3.ok) {
        setFormData(terms2);
      }

      const resGet = await fetch(
        `${ENDPOINT}/api/opportunity/estimate/${formData?.unique_id}/`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${access_token}`,
          },
        },
      );
      const dataGet = await resGet.json();
      if (resGet.ok) {
        setFormData(dataGet);
      }
    } else if (item.id === 'conditionsTerms') {
      const shallow_copy = { ...formData };
      delete shallow_copy.work_type;
      delete shallow_copy.estimate_hardcost;
      newData[index].isEditing = false;

      const res2 = await fetch(
        `${ENDPOINT}/api/opportunity/create/estimate/termsconditions/`,
        {
          method: 'POST',
          headers: {
            Authorization: `JWT ${access_token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            title: termAndContitions_title ?? termAndContitions_title,
            terms_conditions: item.contentPara ?? item.contentPara,
          }),
        },
      );
      const terms = await res2.json();
      const res3 = await fetch(
        `${ENDPOINT}/api/opportunity/update/estimate/${currentTarget.id}/`,
        {
          method: 'PUT',
          headers: {
            Authorization: `JWT ${access_token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...shallow_copy,
            terms_conditions_selected: terms?.id,
          }),
        },
      );
      // eslint-disable-next-line
      const terms2 = await res3.json();
      if (res3.ok) {
        setFormData(terms);
      }

      const resGet = await fetch(
        `${ENDPOINT}/api/opportunity/estimate/${formData?.unique_id}/`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${access_token}`,
          },
        },
      );
      const dataGet = await resGet.json();
      if (resGet.ok) {
        setFormData(dataGet);
      }
    }
  };

  const handleSaveDescription = async (index, id) => {
    const newData = [...realstate];
    setIsTyping(false);
    if (id === 'paymentTerms') {
      newData[index].isEditing = false;

      if (newData[index].paymentTermsID) {
        const res2 = await fetch(
          `${ENDPOINT}/api/opportunity/update/estimate/paymentterms/${newData[index].paymentTermsID}/`,
          {
            method: 'PUT',
            headers: {
              Authorization: `JWT ${access_token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              title: paymentTerms_title ?? paymentTerms_title,
              payment_terms: newData[index].contentPara,
            }),
          },
        );

        const terms = await res2.json();
        if (res2.ok) {
          setFormData(terms);
          dispatch(getPaymentTerms({ access_token }));
        }

        const resGet = await fetch(
          `${ENDPOINT}/api/opportunity/estimate/${formData?.unique_id}/`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `JWT ${access_token}`,
            },
          },
        );
        const dataGet = await resGet.json();
        if (resGet.ok) {
          setFormData(dataGet);
        }
      }

      if (newData[index].paymentTermsID) {
        const shallow_copy = { ...formData };
        delete shallow_copy.work_type;
        delete shallow_copy.estimate_hardcost;

        const res2 = await fetch(
          `${ENDPOINT}/api/opportunity/update/estimate/${currentTarget.id}/`,
          {
            method: 'PUT',
            headers: {
              Authorization: `JWT ${access_token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              ...shallow_copy,
              payment_terms_selected: newData[index]?.paymentTermsID,
            }),
          },
        );

        const terms = await res2.json();
        if (res2.ok) {
          setFormData(terms);
        }
        const resGet = await fetch(
          `${ENDPOINT}/api/opportunity/estimate/${formData?.unique_id}/`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `JWT ${access_token}`,
            },
          },
        );
        const dataGet = await resGet.json();
        if (resGet.ok) {
          setFormData(dataGet);
        }
      }
    } else if (id === 'conditionsTerms') {
      newData[index].isEditing = false;

      if (newData[index]?.conditionTermsId) {
        const res2 = await fetch(
          `${ENDPOINT}/api/opportunity/update/estimate/termsconditions/${newData[index]?.conditionTermsId}/`,
          {
            method: 'PUT',
            headers: {
              Authorization: `JWT ${access_token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              title: termAndContitions_title ?? termAndContitions_title,
              terms_conditions: newData[index].contentPara,
            }),
          },
        );

        const terms = await res2.json();
        if (res2.ok) {
          setFormData(terms);
          dispatch(getTermAndCondtion({ access_token }));
        }

        const resGet = await fetch(
          `${ENDPOINT}/api/opportunity/estimate/${formData?.unique_id}/`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `JWT ${access_token}`,
            },
          },
        );
        const dataGet = await resGet.json();
        if (resGet.ok) {
          setFormData(dataGet);
        }
      }
      if (newData[index].conditionTermsId) {
        const shallow_copy = { ...formData };
        delete shallow_copy.work_type;
        delete shallow_copy.estimate_hardcost;

        const res2 = await fetch(
          `${ENDPOINT}/api/opportunity/update/estimate/${currentTarget.id}/`,
          {
            method: 'PUT',
            headers: {
              Authorization: `JWT ${access_token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              ...shallow_copy,
              terms_conditions_selected: newData[index]?.conditionTermsId,
            }),
          },
        );

        const terms = await res2.json();
        if (res2.ok) {
          setFormData(terms);
        }
        const resGet = await fetch(
          `${ENDPOINT}/api/opportunity/estimate/${formData?.unique_id}/`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `JWT ${access_token}`,
            },
          },
        );
        const dataGet = await resGet.json();
        if (resGet.ok) {
          setFormData(dataGet);
        }
      }
    }
  };

  const handleEditSection = () => {
    setIsDetOpen(false);
    setShowDescription(true);
    handleClose2();
    setEditSection({
      text: itemSection.contentHead,
      description: itemSection.contentPara,
      id: itemSection?.request,
    });
  };

  const noop = () => {};

  return (
    <Box sx={{ width: '100%' }}>
      <DragDropContext
        onDragEnd={(result) => onDragEnd(result, realstate, setRealState)}
      >
        <Droppable droppableId="droppable">
          {(provided) => (
            <Grid
              item
              container
              direction="column"
              sx={{ gap: '1rem' }}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {realstate?.map((item, index) => {
                return (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <Box
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          backgroundColor: '#fff',
                          width: '100%',
                        }}
                      >
                        <Typography style={{ display: 'flex' }} sx={spri}>
                          {' '}
                          {item.contentHead && !hideIcons ? (
                            <DragIndicatorIcon
                              sx={{ fontSize: '1.2rem', color: 'gray' }}
                            />
                          ) : null}{' '}
                          {item.contentHead}{' '}
                          {item?.request && (
                            <>
                              <MoreHoriz
                                sx={{ marginLeft: '10px', cursor: 'pointer' }}
                                onClick={(e) => handleClick2(e, item)}
                              />
                              <Menu
                                id="demo-positioned-menu"
                                aria-labelledby="demo-positioned-button"
                                anchorEl={anchorEl2}
                                open={open2}
                                onClose={handleClose2}
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                              >
                                <MenuItem
                                  onClick={async () => {
                                    const res = await fetch(
                                      `${ENDPOINT}/api/opportunity/delete/estimate/section/${itemSection?.request}/`,
                                      {
                                        method: 'DELETE',
                                        headers: {
                                          'Content-Type': 'application/json',
                                          Authorization: `JWT ${access_token}`,
                                        },
                                      },
                                    );

                                    if (res.ok) {
                                      handleClose2();
                                      const resGet = await fetch(
                                        `${ENDPOINT}/api/opportunity/estimate/${formData?.unique_id}/`,
                                        {
                                          method: 'GET',
                                          headers: {
                                            'Content-Type': 'application/json',
                                            Authorization: `JWT ${access_token}`,
                                          },
                                        },
                                      );
                                      const dataGet = await resGet.json();

                                      setFormData(dataGet);

                                      if (resGet.ok) {
                                        const Data1 = Array.isArray(realstate)
                                          ? realstate.filter(
                                              (flt) =>
                                                flt &&
                                                flt.id &&
                                                !flt.id.includes('section'),
                                            )
                                          : [];
                                        const updatedData = [...Data1];

                                        if (
                                          Array.isArray(dataGet.section) &&
                                          dataGet.section.length
                                        ) {
                                          dataGet.section.forEach(
                                            (section, index) => {
                                              const sectionId = `section ${index}`;
                                              const sectionIndex =
                                                updatedData.findIndex(
                                                  (ele) =>
                                                    ele?.id === sectionId,
                                                );

                                              if (sectionIndex !== -1) {
                                                // Update existing section
                                                updatedData[sectionIndex] = {
                                                  id: sectionId,
                                                  contentHead:
                                                    section.section_title,
                                                  contentPara:
                                                    section.section_description,
                                                  request: section?.id,
                                                };
                                              } else {
                                                // Add new section
                                                updatedData.push({
                                                  id: sectionId,
                                                  contentHead:
                                                    section.section_title,
                                                  contentPara:
                                                    section.section_description,
                                                  request: section?.id,
                                                });
                                              }
                                            },
                                          );

                                          // Sort the updatedData based on sort_order
                                          updatedData.sort(
                                            (a, b) =>
                                              (a.sort_order || 0) -
                                              (b.sort_order || 0),
                                          );

                                          // Move the acceptance object to the end if it exists
                                          const acceptanceIndex =
                                            updatedData.findIndex(
                                              (ele) => ele?.id === 'acceptance',
                                            );
                                          if (acceptanceIndex !== -1) {
                                            const acceptanceObject =
                                              updatedData.splice(
                                                acceptanceIndex,
                                                1,
                                              )[0];
                                            updatedData.push(acceptanceObject);
                                          }

                                          setRealState(updatedData);
                                        }
                                      }
                                    }
                                  }}
                                  sx={{
                                    fontSize: '12px',
                                    color: '#e92525',
                                    gap: '5px',
                                  }}
                                >
                                  <Delete sx={{ color: '#e92525' }} /> Delete
                                </MenuItem>

                                <MenuItem
                                  onClick={handleEditSection}
                                  sx={{
                                    fontSize: '12px',
                                    color: 'gray',
                                    gap: '5px',
                                  }}
                                >
                                  {' '}
                                  <Edit /> Edit{' '}
                                </MenuItem>
                              </Menu>
                            </>
                          )}
                          {item.id === 'paymentTerms' ? (
                            <Box>
                              {!hideIcons && (
                                <BorderColorIcon
                                  sx={{
                                    fontSize: '15px',
                                    color: 'GrayText',
                                    marginLeft: '1rem',
                                    marginBottom: '5px',
                                  }}
                                  onClick={
                                    item.isEditing
                                      ? noop
                                      : typeof handleClick === 'function'
                                        ? handleClick
                                        : noop
                                  }
                                />
                              )}
                              <Menu
                                id="demo-positioned-menu"
                                aria-labelledby="demo-positioned-button"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                              >
                                <MenuItem
                                  onClick={() => {
                                    handleEditDescription(index);
                                    handleClose();
                                  }}
                                  sx={{
                                    fontSize: '12px',
                                    color: 'gray',
                                    gap: '5px',
                                  }}
                                >
                                  <BorderColorIcon fontSize="12px" />{' '}
                                  {formData?.payment_terms_selected
                                    ? 'Edit'
                                    : 'Create'}
                                </MenuItem>
                                {paymentTerms?.map((item) => {
                                  return (
                                    <MenuItem
                                      key={uuid()}
                                      onClick={() => {
                                        handleEditDescription(item, index);
                                        handleClose();
                                      }}
                                      sx={{
                                        fontSize: '12px',
                                        color: 'gray',
                                        gap: '5px',
                                      }}
                                    >
                                      {' '}
                                      <GridViewIcon fontSize="12px" />
                                      {item.title ? item.title : 'No Name'}
                                    </MenuItem>
                                  );
                                })}
                              </Menu>
                            </Box>
                          ) : null}
                          {item.id === 'conditionsTerms' ? (
                            <Box>
                              {!hideIcons && (
                                <BorderColorIcon
                                  sx={{
                                    fontSize: '15px',
                                    color: 'GrayText',
                                    marginLeft: '1rem',
                                    marginBottom: '5px',
                                  }}
                                  onClick={
                                    item.isEditing
                                      ? noop
                                      : typeof handleClickCondition ===
                                          'function'
                                        ? handleClickCondition
                                        : noop
                                  }
                                />
                              )}
                              <Menu
                                id="demo-positioned-menu"
                                aria-labelledby="demo-positioned-button"
                                anchorEl={anchorElCondition}
                                open={openCondition}
                                onClose={handleCloseCondition}
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                              >
                                <MenuItem
                                  onClick={() => {
                                    handleEditDescription(index);
                                    handleCloseCondition();
                                  }}
                                  sx={{
                                    fontSize: '12px',
                                    color: 'gray',
                                    gap: '5px',
                                  }}
                                >
                                  <BorderColorIcon fontSize="12px" />{' '}
                                  {formData.terms_conditions_selected
                                    ? 'Edit'
                                    : 'Create'}
                                </MenuItem>
                                {termAndContitions?.map((item) => {
                                  return (
                                    <MenuItem
                                      key={item?.title}
                                      onClick={() => {
                                        handleEditDescription(item, index);
                                        handleCloseCondition();
                                      }}
                                      sx={{
                                        fontSize: '12px',
                                        color: 'gray',
                                        gap: '5px',
                                      }}
                                    >
                                      {' '}
                                      <GridViewIcon fontSize="12px" />
                                      {item?.title ? item?.title : 'No Name'}
                                    </MenuItem>
                                  );
                                })}
                              </Menu>
                            </Box>
                          ) : null}
                        </Typography>
                        <Typography style={{ width: '100%' }} sx={ccri}>
                          {item.isEditing === true ? (
                            <Box
                              sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  width: '100%',
                                  marginBottom: '1rem', // Add some space between the name field and the editor
                                }}
                              >
                                <input
                                  type="text"
                                  placeholder="Enter name"
                                  value={
                                    item.id === 'paymentTerms'
                                      ? paymentTerms_title
                                      : termAndContitions_title
                                  }
                                  onChange={(event) =>
                                    handleNameChange(index, event.target.value)
                                  }
                                  style={{
                                    padding: '0.5rem',
                                    border: '1px solid #ccc',
                                    borderRadius: '4px',
                                    marginBottom: '1rem',
                                    marginTop: '1rem',
                                    width: '100%',
                                    outline: 'none',
                                  }}
                                />
                              </Box>
                              <Box
                                sx={{
                                  height: '85%',
                                  display: 'flex',
                                  flexDirection: 'column-reverse',
                                }}
                              >
                                <Test
                                  bottom={true}
                                  html={item?.contentPara}
                                  setHtml={(event) =>
                                    handleDescriptionChange(index, event)
                                  }
                                />
                              </Box>
                              <Box sx={{ display: 'flex', width: '100%' }}>
                                <button
                                  type="button"
                                  style={{
                                    width: '100%',
                                    opacity:
                                      (item?.conditionTermsId ||
                                        item?.paymentTermsID) &&
                                      item.contentPara.length > 0
                                        ? 1
                                        : 0.5,
                                  }}
                                  disabled={
                                    !(
                                      item?.conditionTermsId ||
                                      item?.paymentTermsID
                                    ) || item.contentPara.length === 0
                                  }
                                  className="create-btn"
                                  onClick={() =>
                                    handleSaveDescription(index, item.id)
                                  }
                                >
                                  Save
                                </button>
                                <button
                                  type="button"
                                  style={{ marginLeft: '1rem', width: '100%' }}
                                  className="create-btn"
                                  onClick={() =>
                                    handleAddConditions(index, item)
                                  }
                                >
                                  Create
                                </button>
                              </Box>
                            </Box>
                          ) : item?.request ? (
                            <p
                              // eslint-disable-next-line
                              dangerouslySetInnerHTML={{
                                __html: item.contentPara,
                              }}
                            />
                          ) : (
                            <p
                              // eslint-disable-next-line
                              dangerouslySetInnerHTML={{
                                __html: item?.contentPara,
                              }}
                            />
                          )}
                        </Typography>
                        <Typography sx={ccri}>{item.clientPara}</Typography>
                        <Typography sx={ccri}>{item.clientName}</Typography>
                        <Typography sx={ccri}>{item.clientSign}</Typography>
                        <Typography sx={ccri}>{item.clientTittle}</Typography>
                        <Typography sx={ccri}>{item.clientDate}</Typography>

                        {item.timeLinePic ? (
                          <>
                            <Box display="flex" alignItems="center">
                              <Typography
                                style={{
                                  color: '#03071E',
                                  fontFamily: 'Open Sans',
                                  fontSize: '12px',
                                  fontWeight: '400',
                                  padding: '0px',
                                  paddingLeft: '25px',
                                  paddingTop: '0.75rem',
                                  paddingBottom: '0.25rem',
                                }}
                              >
                                Estimated Start:
                              </Typography>
                              <Typography
                                style={{
                                  color: '#03071E',
                                  fontFamily: 'Open Sans',
                                  fontSize: '12px',
                                  fontWeight: 'bold',
                                  fontStyle: 'italic',
                                  padding: '0px',
                                  paddingLeft: '5px',
                                  paddingTop: '0.75rem',
                                  paddingBottom: '0.25rem',
                                }}
                              >
                                {item.estimateStartDate}
                              </Typography>
                            </Box>
                            <Box display="flex" alignItems="center">
                              <Typography
                                style={{
                                  color: '#03071E',
                                  fontFamily: 'Open Sans',
                                  fontSize: '12px',
                                  padding: '0px',
                                  paddingLeft: '25px',
                                  paddingTop: '0.25rem',
                                  paddingBottom: '0.25rem',
                                }}
                              >
                                Estimated Completion:
                              </Typography>
                              <Typography
                                style={{
                                  color: '#03071E',
                                  fontFamily: 'Open Sans',
                                  fontSize: '12px',
                                  fontWeight: 'bold',
                                  fontStyle: 'italic',
                                  padding: '0px',
                                  paddingLeft: '5px',
                                  paddingTop: '0.25rem',
                                  paddingBottom: '0.25rem',
                                }}
                              >
                                {item.estimateEndDate}
                              </Typography>
                            </Box>
                            <img
                              alt="timeline-pic"
                              src={item.timeLinePic}
                              width="97%"
                              height="450px"
                              style={{
                                paddingLeft: '25px',
                                marginTop: '10px',
                                marginBottom: '',
                              }}
                            />
                          </>
                        ) : (
                          <Typography sx={ccri}>{item.timeLinePic}</Typography>
                        )}

                        <ul>
                          {Array.isArray(item.deliverables) &&
                          item.deliverables.length
                            ? item.deliverables.map((nestedItem) => (
                                <li
                                  key={`${item.id}-${nestedItem.deliverable_name}`}
                                >
                                  <u
                                    style={{
                                      color: '#03071E',
                                      fontFamily: 'Open Sans',
                                      fontSize: '0.75rem',
                                      fontWeight: '400',
                                      textUnderlinePosition: 'under',
                                      textDecoration: 'underline dotted',
                                      padding: '0px',
                                      marginLeft: '-12px',
                                      paddingTop: '1rem',
                                      paddingBottom: '1rem',
                                    }}
                                  >
                                    {nestedItem.deliverable_name}
                                  </u>
                                  <p
                                    style={{
                                      color: '#03071E',
                                      fontFamily: 'Open Sans',
                                      fontSize: '0.75rem',
                                      fontWeight: '400',
                                      padding: '0px',
                                      marginRight: '12px',
                                      marginLeft: '-12px',
                                      marginTop: '1rem',
                                      paddingBottom: '1rem',
                                    }}
                                  >
                                    <div
                                      // eslint-disable-next-line
                                      dangerouslySetInnerHTML={{
                                        __html: nestedItem.description,
                                      }}
                                    />
                                  </p>
                                </li>
                              ))
                            : null}
                        </ul>
                        <div>
                          {Array.isArray(item.sections) && item.sections.length
                            ? item.sections.map((section) => {
                                return (
                                  <Box
                                    key={section?.section_title}
                                    sx={{ margin: '10px 0px' }}
                                  >
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                      }}
                                    >
                                      <DragIndicatorIcon
                                        sx={{
                                          fontSize: '1.2rem',
                                          color: 'gray',
                                        }}
                                      />
                                      <Typography sx={spri}>
                                        {section.section_title}
                                      </Typography>
                                      <MoreHoriz
                                        sx={{
                                          marginLeft: '10px',
                                          cursor: 'pointer',
                                        }}
                                        onClick={handleClick2}
                                      />
                                      <Menu
                                        id="demo-positioned-menu"
                                        aria-labelledby="demo-positioned-button"
                                        anchorEl={anchorEl2}
                                        open={open2}
                                        onClose={handleClose2}
                                        anchorOrigin={{
                                          vertical: 'top',
                                          horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                          vertical: 'top',
                                          horizontal: 'left',
                                        }}
                                      >
                                        <MenuItem
                                          onClick={async () => {
                                            const res = await fetch(
                                              `${ENDPOINT}/api/opportunity/delete/estimate/section/${section.id}/`,
                                              {
                                                method: 'DELETE',
                                                headers: {
                                                  'Content-Type':
                                                    'application/json',
                                                  Authorization: `JWT ${access_token}`,
                                                },
                                              },
                                            );

                                            if (res.ok) {
                                              handleClose2();
                                              const resGet = await fetch(
                                                `${ENDPOINT}/api/opportunity/estimate/${formData?.unique_id}/`,
                                                {
                                                  method: 'GET',
                                                  headers: {
                                                    'Content-Type':
                                                      'application/json',
                                                    Authorization: `JWT ${access_token}`,
                                                  },
                                                },
                                              );
                                              const dataGet =
                                                await resGet.json();
                                              if (!resGet.ok) {
                                                return;
                                              }
                                              if (dataGet) {
                                                setFormData(dataGet);
                                              }
                                            }
                                          }}
                                          sx={{
                                            fontSize: '12px',
                                            color: '#e92525',
                                            gap: '5px',
                                          }}
                                        >
                                          <Delete sx={{ color: '#e92525' }} />{' '}
                                          Delete
                                        </MenuItem>

                                        <MenuItem
                                          onClick={() => {
                                            setShowDescription(true);
                                            handleClose2();

                                            setEditSection({
                                              text: item.contentHead,
                                              description: item.contentPara,
                                              id: item.id,
                                            });
                                          }}
                                          sx={{
                                            fontSize: '12px',
                                            color: 'gray',
                                            gap: '5px',
                                          }}
                                        >
                                          {' '}
                                          <Edit /> Edit{' '}
                                        </MenuItem>
                                      </Menu>
                                    </Box>
                                  </Box>
                                );
                              })
                            : null}
                        </div>
                        <table
                          style={{
                            width: '95%',
                            marginLeft: '20px',
                            borderCollapse: 'collapse',
                          }}
                        >
                          <thead>
                            {Array.isArray(item.tableHeads) &&
                            item.tableHeads.length
                              ? item.tableHeads.map((header) => (
                                  <tr key={uuid()}>
                                    <th
                                      style={{
                                        border: '1px solid gray',
                                        padding: '8px',
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        color: 'black',
                                        width: '14.4%',
                                      }}
                                    >
                                      {header.one}
                                    </th>
                                    {item?.tableData?.some((dil) =>
                                      dil?.deliverable_multiplier?.some(
                                        (vis) => vis.visible,
                                      ),
                                    ) && (
                                      <th
                                        style={{
                                          border: '1px solid gray',
                                          padding: '8px',
                                          fontSize: '14px',
                                          fontWeight: 'bold',
                                          color: 'black',
                                          width: '14.4%',
                                        }}
                                      >
                                        {header.two}
                                      </th>
                                    )}
                                    {item?.tableData &&
                                    item?.tableData[0]
                                      ?.deliverable_multiplier ? (
                                      // eslint-disable-next-line
                                      <>
                                        {item?.tableData[0]
                                          .deliverable_multiplier
                                          ? item.tableData[0].deliverable_multiplier.map(
                                              (v) => {
                                                return (
                                                  v.visible && (
                                                    <th
                                                      style={{
                                                        border:
                                                          '1px solid gray',
                                                        padding: '8px',
                                                        fontSize: '14px',
                                                        fontWeight: 'bold',
                                                        color: 'black',
                                                        width: '14.4%',
                                                      }}
                                                    >
                                                      {' '}
                                                      {v.label}{' '}
                                                    </th>
                                                  )
                                                );
                                              },
                                            )
                                          : null}
                                      </>
                                    ) : null}
                                    <th
                                      style={{
                                        border: '1px solid gray',
                                        padding: '8px',
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        color: 'black',
                                        width: '14.4%',
                                      }}
                                    >
                                      {header.five}
                                    </th>
                                  </tr>
                                ))
                              : null}
                          </thead>

                          <tbody>
                            {Array.isArray(item?.tableData) &&
                            item?.tableData.length
                              ? item?.tableData.map((tableItem) => (
                                  <tr
                                    key={uuid()}
                                    style={{
                                      border: '1px solid gray',
                                      padding: '8px',
                                      color: '#000',
                                      height: '50px',
                                    }}
                                  >
                                    <td
                                      style={{
                                        border: '1px solid gray',
                                        padding: '8px',
                                        width: '200px',
                                      }}
                                    >
                                      {tableItem?.deliverable_name}
                                    </td>
                                    {tableItem?.deliverable_multiplier?.find(
                                      (vis) => vis.visible,
                                    ) && (
                                      <td
                                        style={{
                                          border: '1px solid gray',
                                          padding: '8px',
                                          textAlign: 'end',
                                        }}
                                      >
                                        {' '}
                                        {tableItem?.user_price &&
                                          `$${calculateReduction3(
                                            tableItem?.deliverable_multiplier,
                                            Number(tableItem?.user_price),
                                          )
                                            ?.toFixed(2)
                                            ?.toString()
                                            ?.replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ',',
                                            )}`}
                                      </td>
                                    )}
                                    {tableItem.deliverable_multiplier ? (
                                      // eslint-disable-next-line
                                      <>
                                        {Array.isArray(
                                          tableItem.deliverable_multiplier,
                                        ) &&
                                        tableItem.deliverable_multiplier.length
                                          ? tableItem.deliverable_multiplier.map(
                                              (veed) => {
                                                return (
                                                  veed.visible && (
                                                    <td
                                                      style={{
                                                        border:
                                                          '1px solid gray',
                                                        padding: '8px',
                                                        textAlign: 'end',
                                                      }}
                                                    >
                                                      {veed?.rate}%
                                                      <p
                                                        style={{
                                                          fontSize: '10px',
                                                          color: 'GrayText',
                                                        }}
                                                      >
                                                        {`${veed?.operation || ''}$${((tableItem?.user_price ?? 0) * ((veed?.rate ?? 0) / 100)).toFixed(2)}`}
                                                      </p>
                                                    </td>
                                                  )
                                                );
                                              },
                                            )
                                          : null}
                                      </>
                                    ) : null}
                                    <td
                                      style={{
                                        border: '1px solid gray',
                                        padding: '8px',
                                        textAlign: 'end',
                                      }}
                                    >
                                      $
                                      {Array.isArray(
                                        tableItem?.deliverable_multiplier,
                                      ) &&
                                      tableItem?.deliverable_multiplier?.length
                                        ? calculateReduction2(
                                            tableItem?.deliverable_multiplier,
                                            Number(tableItem?.user_price),
                                          )
                                            ?.toFixed(2)
                                            ?.toString()
                                            ?.replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ',',
                                            )
                                        : tableItem?.user_price &&
                                          tableItem?.user_price
                                            ?.toFixed(2)
                                            ?.toString()
                                            ?.replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ',',
                                            )}
                                    </td>
                                  </tr>
                                ))
                              : null}
                            {item.tableSubTotal ? (
                              <tr
                                style={{
                                  border: '1px solid gray',
                                  fontSize: '14px',
                                  fontWeight: 'bold',
                                  color: 'black',
                                  height: '50px',
                                }}
                              >
                                <td
                                  style={{
                                    border: '1px solid gray',
                                    padding: '8px',
                                    width: '36%',
                                  }}
                                >
                                  {item.tableSubTotal}
                                </td>
                                {item.tableData.some((dil) =>
                                  dil?.deliverable_multiplier?.some(
                                    (vis) => vis.visible,
                                  ),
                                ) ? (
                                  <td aria-label="column" />
                                ) : null}
                                {item.tableData &&
                                item.tableData[0]?.deliverable_multiplier ? (
                                  // eslint-disable-next-line
                                  <>
                                    {item.tableData[0]?.deliverable_multiplier
                                      ? item.tableData[0]?.deliverable_multiplier.map(
                                          (v) => {
                                            return (
                                              v.visible && (
                                                <td aria-label="column" />
                                              )
                                            );
                                          },
                                        )
                                      : null}
                                  </>
                                ) : null}
                                <td
                                  style={{
                                    textAlign: 'end',
                                    padding: '8px',
                                    width: '14.4%',
                                  }}
                                >
                                  ${' '}
                                  {item.tableData[0]?.deliverable_multiplier
                                    ? (
                                        calculatePercentage(dileverable) +
                                        calculateTotalNet(dileverable)
                                      )
                                        .toFixed(2)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                    : calculateTotalNet(dileverable)
                                        .toFixed(2)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                </td>
                              </tr>
                            ) : null}
                          </tbody>
                        </table>

                        <table
                          style={{
                            width: '95%',
                            marginLeft: '20px',
                            borderCollapse: 'collapse',
                          }}
                        >
                          <thead>
                            <tr>
                              {Array.isArray(item.hardCostHeads) &&
                              Array.isArray(item.hardCostData) &&
                              item.hardCostData.length &&
                              item.hardCostHeads.length
                                ? item.hardCostHeads.map((header) => (
                                    <th
                                      style={{
                                        border: '1px solid gray',
                                        padding: '8px',
                                        color: 'black',
                                        width: '14.4%',
                                      }}
                                      key={header}
                                    >
                                      {header}
                                    </th>
                                  ))
                                : null}
                            </tr>
                          </thead>

                          <tbody>
                            {Array.isArray(item.hardCostData) &&
                            item?.hardCostData.length
                              ? item?.hardCostData?.map((costItem) => (
                                  <tr
                                    key={uuid()}
                                    style={{
                                      border: '1px solid gray',
                                      padding: '8px',
                                      height: '50px',
                                      color: 'black',
                                    }}
                                  >
                                    <td
                                      style={{
                                        border: '1px solid gray',
                                        padding: '8px',
                                        width: '36%',
                                      }}
                                    >
                                      {costItem?.hardcost_label ?? ''}
                                    </td>
                                    <td
                                      style={{
                                        border: '1px solid gray',
                                        padding: '8px',
                                        textAlign: 'end',
                                      }}
                                    >
                                      $
                                      {costItem?.price_to_customer
                                        ? costItem?.price_to_customer
                                            ?.toFixed(2)
                                            ?.toString()
                                            .replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ',',
                                            )
                                        : ''}
                                    </td>
                                  </tr>
                                ))
                              : null}
                            {item.hardCostSub &&
                            Array.isArray(item.hardCostData) &&
                            item.hardCostData.length ? (
                              <tr
                                style={{
                                  border: '1px solid gray',
                                  fontSize: '14px',
                                  fontWeight: 'bold',
                                  height: '50px',
                                  color: 'black',
                                }}
                              >
                                <td
                                  style={{
                                    border: '1px solid gray',
                                    padding: '8px',
                                  }}
                                >
                                  {item.hardCostSub}
                                </td>

                                <td
                                  style={{
                                    border: '1px solid gray',
                                    padding: '8px',
                                    textAlign: 'end',
                                  }}
                                >
                                  {sumPriceToCustomer(estimate_hardcost)
                                    ? formatMoneyString(
                                        `${sumPriceToCustomer(
                                          estimate_hardcost,
                                        )}`,
                                      )
                                    : '$0'}
                                </td>
                              </tr>
                            ) : null}
                            {item?.totalCostSub && (
                              <tr
                                style={{
                                  border: '1px solid gray',
                                  fontSize: '14px',
                                  fontWeight: 'bold',
                                  height: '70px',
                                  color: 'black',
                                }}
                              >
                                <td
                                  style={{
                                    border: '1px solid gray',
                                    padding: '8px',
                                    width: '50%',
                                    fontSize: '20px',
                                    fontWeight: 700,
                                  }}
                                >
                                  {item?.totalCostSub}
                                </td>

                                <td
                                  style={{
                                    border: '1px solid gray',
                                    padding: '8px',
                                    textAlign: 'end',
                                    fontSize: '20px',
                                    fontWeight: 700,
                                    width: '20%',
                                  }}
                                >
                                  {globleTotalCost !== undefined
                                    ? formatMoneyString(`${globleTotalCost}`)
                                    : '$0'}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </Box>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
}

function Final({
  globleTotalCost,
  setRealState,
  realstate,
  formData,
  imageVersion,
  dileverable,
  GI,
  pic,
  estimate_hardcost,
  setDeliverable,
  setFormData,
  setShowDescription,
  showDescription,
  setEditSection,
  componentRef,
  setIsDetOpen,
  isDetOpen,
  hideIcons,
}) {
  const estimates = useSelector((state) => state.oppertunity.estimates);
  const payment_terms = useSelector((state) => state.oppertunity.paymentTerms);
  const termAndContitions = useSelector(
    (state) => state.oppertunity.termAndContitions,
  );
  const [realPaymentTerms, setRealPaymentTerms] = useState({});
  const [realTermAndContitions, setrealTermAndCondition] = useState({});

  const setRealPaymentTermsCallback = useCallback(
    (paymentTerm) => {
      if (paymentTerm !== undefined) {
        setRealPaymentTerms(paymentTerm ?? {});
      }
    },
    [setRealPaymentTerms],
  );

  const setRealTermsAndConditionCallback = useCallback(
    (termConditions) => {
      if (termConditions !== undefined) {
        setrealTermAndCondition(termConditions ?? {});
      }
    },
    [setrealTermAndCondition],
  );

  useEffect(() => {
    const paymentTerm = payment_terms?.find(
      (item) => item?.id === formData?.payment_terms_selected,
    );
    setRealPaymentTermsCallback(paymentTerm);

    const termCondition = termAndContitions?.find(
      (item) => item?.id === formData?.terms_conditions_selected,
    );
    setRealTermsAndConditionCallback(termCondition);
  }, [
    formData,
    payment_terms,
    termAndContitions,
    realTermAndContitions,
    realPaymentTerms,
  ]);

  const dispatch = useDispatch();

  const currentTarget =
    Array.isArray(estimates) && estimates.length
      ? estimates.find((est) => est.title === formData?.title)
      : false;
  const access_token = useSelector((state) => state.auth.user.access);
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    dispatch(getPaymentTerms({ access_token }));
    dispatch(getTermAndCondtion({ access_token }));
  }, [dispatch]);

  const currentDate = moment().format('DD MMM, YYYY');
  const user = useSelector(
    (state) => state.userData?.userData ?? { first_name: '', last_name: '' },
  );

  return (
    <Grid container justifyContent="center">
      {Array.isArray(dileverable[0]?.deliverable_multiplier) &&
      dileverable[0]?.deliverable_multiplier.length ? (
        <Box sx={{ position: 'relative', left: '75%' }}>
          <Box
            sx={{
              position: 'absolute',
              top: '20%',
              left: '100%',
              paddingLeft: '5px',
              borderRadius: '10px',
              width: '150px',
              border: '1px solid #d9d9d9',
              borderLeft: 'none',
            }}
          >
            {dileverable[0].deliverable_multiplier.map((item, index) => (
              <Box
                key={item?.id}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <NetNetSwitch
                  checked={item.visible ? item.visible : false}
                  onChange={() => {
                    const updatedDeliverable = [...dileverable];
                    updatedDeliverable.map((v) => {
                      // eslint-disable-next-line
                      return (v.deliverable_multiplier[index].visible =
                        !v.deliverable_multiplier[index].visible);
                    });
                    setDeliverable(updatedDeliverable);
                  }}
                />
                <p style={{ fontSize: '12px', fontWeight: 'bold' }}>
                  {item.label}
                </p>
              </Box>
            ))}
          </Box>
        </Box>
      ) : null}

      <Grid
        item
        container
        xl={9}
        lg={9}
        md={9}
        sm={9}
        direction="column"
        alignItems="flex-start"
        sx={{
          backgroundColor: '#fff',
          padding: imageVersion ?? '4rem 3rem',
          border: '1px solid #d9d9d9',
          borderRadius: '2px',
        }}
      >
        {imageVersion?.length ? (
          <img alt="version" src={imageVersion} width="100%" height="100%" />
        ) : (
          <Document style={{ width: '100%' }}>
            <Page ref={componentRef}>
              <IntroRow
                access_token={access_token}
                currentDate={currentDate}
                user={user}
                formData={formData}
                setFormData={setFormData}
              />
              <MileStones
                realPaymentTerms={realPaymentTerms}
                realstate={realstate}
                setRealState={setRealState}
                realTermAndContitions={realTermAndContitions}
                paymentTerms={payment_terms}
                termAndContitions={termAndContitions}
                globleTotalCost={globleTotalCost}
                formData={formData}
                dileverable={dileverable}
                GI={GI}
                pic={pic}
                estimate_hardcost={estimate_hardcost}
                setDeliverable={setDeliverable}
                currentTarget={currentTarget}
                isTyping={isTyping}
                setIsTyping={setIsTyping}
                dispatch={dispatch}
                setFormData={setFormData}
                setShowDescription={setShowDescription}
                showDescription={showDescription}
                setEditSection={setEditSection}
                componentRef={componentRef}
                setIsDetOpen={setIsDetOpen}
                isDetOpen={isDetOpen}
                hideIcons={hideIcons}
              />
            </Page>
          </Document>
        )}
      </Grid>
    </Grid>
  );
}

export default Final;
