import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Shake from 'react-reveal/Shake';
import Slide from 'react-reveal/Slide';
// eslint-disable-next-line
import PropTypes from 'prop-types';
import { Avatar, Grid, Typography } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import { getCompanies, getPersons } from '../ContactRedux';
import defaultCompany from '../assets/defaultCompany.png';
import { ENDPOINT } from '../../Opportunity/Redux/oppor';
import { PreLoader1 } from '../../../Componenets/Misc';

import Test from '../../Test';

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ width: '100%', textAlign: 'center' }}>
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        {/* eslint-disable-next-line */}
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {/* eslint-disable-next-line */}
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

function EmployeeView({ showEmployee, setShowEmployee, currentTarget }) {
  const [listEmploye, setListEmployee] = useState();
  return currentTarget ? (
    <Offcanvas
      show={showEmployee}
      onHide={() => setShowEmployee(false)}
      scroll={false}
      backdrop
      placement="start"
    >
      <Offcanvas.Header closeButton>
        <p className="sub-heading">Employees </p>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <TextField
          id="outlined-basic"
          label="Search"
          variant="standard"
          color="secondary"
          onChange={(e) => {
            setListEmployee(
              currentTarget.company_contacts.filter((employee) =>
                employee.first_name
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase()),
              ),
            );
          }}
          style={{ width: '80%' }}
        />

        <ul
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '1rem',
            marginTop: '2rem',
          }}
        >
          {listEmploye &&
            listEmploye.map((employee) => (
              <li className="fullTab" key={employee?.first_name}>
                <Avatar>{employee.first_name[0]}</Avatar> {'  '}{' '}
                {employee.first_name} {'  '}
              </li>
            ))}
        </ul>
      </Offcanvas.Body>
    </Offcanvas>
  ) : (
    0
  );
}

function SureDeleteContact({ setSureDelete, selected }) {
  const dispatch = useDispatch();
  const access_token = useSelector((state) => state.auth.user.access);

  const [loading, setLoading] = useState(false);
  const [word, setWord] = useState('');
  const [error, setError] = useState(false);
  // eslint-disable-next-line
  const handleDelete = () => {
    if (word === 'delete') {
      if (!selected) {
        return false;
      }
      const meta = {
        access_token,
      };
      setLoading(true);
      selected.forEach(async (person) => {
        const res = await fetch(`${ENDPOINT}/api/contact/delete/${person}/`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${meta.access_token}`,
          },
        });

        if (!res.ok) {
          throw new Error(`Error deleting ${person}`);
        } else {
          // eslint-disable-next-line
          if (res) {
            setLoading(false);
            setSureDelete(false);
          }
        }
        return 1;
      });
      dispatch(getPersons(meta));
    } else {
      setError('Incorrect Spelling');
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  };
  const [secondWarn, setsecondWarn] = useState(false);

  return (
    <section
      className="loading"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        backgroundColor: 'rgba(255,255,255,0.5)',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 999,
      }}
    >
      <Box
        sx={{
          width: '500px',
          height: '150px',
          backgroundColor: 'rgba(255,255,255)',
          border: '1px solid #d9d9d9',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '8px',
          padding: '0.68rem 1rem',
        }}
      >
        {secondWarn ? (
          <>
            <p className="fstxt">
              Please type word &lsquo;delete&lsquo; to delete all the contacts.
            </p>
            <TextField
              id="standard-basic"
              label="Type Delete"
              color="secondary"
              variant="standard"
              type="text"
              value={word}
              onChange={(e) => {
                setWord(e.target.value.toLowerCase());
              }}
              sx={{
                marginTop: '10px',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '6px',
                  height: '44px',
                  backgroundColor: '#F1F3F5',

                  '& fieldset': {
                    borderColor: '#fff',
                  },
                  '&:hover fieldset': {
                    borderColor: '#711fff',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#711fff',
                  },
                },
              }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <button type="button" onClick={() => setSureDelete(false)}>
                Cancel
              </button>
              <button
                type="button"
                className="netDanger-btn"
                onClick={() => {
                  if (word === 'delete') {
                    handleDelete();
                  } else {
                    setsecondWarn(false);
                  }
                }}
              >
                {loading ? (
                  <i className="bx bx-loader-alt bx-spin" />
                ) : (
                  'Delete'
                )}{' '}
              </button>
            </Box>
          </>
        ) : (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <button
                type="button"
                className="cancel-btn"
                onClick={() => setSureDelete(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="netDanger-btn"
                onClick={() => {
                  setsecondWarn(true);
                }}
              >
                Delete
              </button>
            </Box>
            <section
              className="loading"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100%',
                backgroundColor: 'rgba(255,255,255,0.5)',
                position: 'fixed',
                top: 0,
                left: 0,
                zIndex: 999,
              }}
            >
              <Slide bottom>
                <Box
                  sx={{
                    width: '850px',
                    height: '320px',
                    background: 'rgba(241, 243, 245, 0.50)',
                    padding: '2rem',
                    borderRadius: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Box
                    sx={{
                      width: '95%',
                      height: '95%',
                      backgroundColor: 'rgba(255,255,255)',
                      border: '1px solid #d9d9d9',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      borderRadius: '8px',
                      padding: '0.68rem 1rem',
                      justifyContent: 'space-around',
                      boxShadow: '2px 2px 6px 0px #D9D9D9',
                      gap: '10px',
                    }}
                  >
                    <p
                      style={{
                        fontSize: '0.9rem',
                        fontWeight: '600',
                        fontFamily: 'Open Sans, sans-serif',
                        color: 'red',
                      }}
                    >
                      DELETE {selected.length > 1 ? 'CONTACTS' : 'CONTACT'}
                    </p>
                    <p
                      className="fstxt"
                      style={{
                        width: '80%',
                        margin: '0 auto',
                        textAlign: 'center',
                        fontWeight: '400',
                      }}
                    >
                      Are You Sure You want to Delete{' '}
                      {selected.length > 1 ? 'These Contacts' : 'This Contact'}{' '}
                      Removing{' '}
                      {selected.length > 1 ? 'These Contacts' : 'This Contact'}{' '}
                      will remove all associated data.
                    </p>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <p className="fstxt" style={{ fontWeight: '400' }}>
                        Please type the word &ldquo;DELETE&ldquo; and click the
                        Delete button
                      </p>
                      <Box
                        sx={{
                          width: '40%',
                          borderRadius: '8px',
                          padding: '1rem 1.1rem',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          height: '65px',
                        }}
                      >
                        <TextField
                          value={word}
                          variant="standard"
                          onChange={(e) =>
                            setWord(e.target.value.toLowerCase())
                          }
                          placeholder="Delete"
                          sx={{
                            width: '100%',
                            '& .MuiInputBase-input': {
                              fontSize: '1rem',
                              fontFamily: 'Open Sans',
                              fontWeight: '500',
                              color: '#000000',
                              textAlign: 'center',
                            },
                            '& .MuiInput-underline:before': {
                              borderBottom: '1px dashed #000000',
                            },
                            '& .MuiInput-underline:after': {
                              borderBottom: '1px dashed #000000',
                            },
                            '& .MuiInput-underline:hover:not(.Mui-disabled):before':
                              {
                                borderBottom: '1px dashed #000000',
                              },
                          }}
                        />
                      </Box>
                      {error && (
                        <Shake>
                          <p style={{ color: 'red' }}>{error}</p>
                        </Shake>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '50%',
                        margin: '0 auto',
                      }}
                    >
                      <button
                        type="button"
                        className="cancel-btn"
                        onClick={() => setSureDelete(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        style={{
                          fontFamily:
                            "'Neue Haas Display Pro', 'Open Sans', sans-serif",
                          fontWeight: '500',
                          backgroundColor: word ? '#cd0b4c' : '#f99f9f',
                          borderRadius: '5px',
                          color: 'var(--white-100)',
                          border: 'none',
                          cursor: 'pointer',
                          textAlign: 'center',
                          fontSize: '1rem',
                          padding: '0rem 2rem',
                          height: '38px',
                        }}
                        onClick={handleDelete}
                        disabled={!word}
                      >
                        {loading ? (
                          <Box
                            sx={{
                              width: '30px',
                              height: '30px',
                              margin: '0rem 0.1rem',
                            }}
                          >
                            <PreLoader1 />
                          </Box>
                        ) : (
                          'Delete'
                        )}{' '}
                      </button>
                    </Box>
                  </Box>
                </Box>
              </Slide>
            </section>
          </>
        )}
      </Box>
    </section>
  );
}

function ContactDelete({ setSureDelete, customParams, handleRowClassName }) {
  const access_token = useSelector((state) => state.auth.user.access);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const handleDelete = async () => {
    if (!customParams) {
      return false;
    }
    const meta = {
      access_token,
    };
    setLoading(true);

    handleRowClassName();
    const res = await fetch(
      `${ENDPOINT}/api/contact/delete/${customParams.row.id}/`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${meta.access_token}`,
        },
      },
    );
    if (res.ok) {
      dispatch(getPersons(meta));
      setLoading(false);
      setSureDelete(false);
    }
    return 1;
  };

  return (
    <section
      className="loading"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        backgroundColor: 'rgba(255,255,255,0.5)',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 99999999999,
      }}
    >
      <Slide bottom>
        <Box
          sx={{
            width: '500px',
            height: '130px',
            backgroundColor: 'rgba(255,255,255)',
            border: '1px solid #d9d9d9',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '8px',
            padding: '0.68rem 1rem',
          }}
        >
          <p
            className="fstxt"
            style={{ textAlign: 'center', marginTop: '.3rem' }}
          >
            Are You Sure You want to Delete This Contact? Removing This Contact
            will remove all associated data.
          </p>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '75%',
              marginTop: '1rem',
            }}
          >
            <button
              type="button"
              className="cancel-btn"
              onClick={() => setSureDelete(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              style={{ background: loading && '#FA2D23' }}
              className="netDanger-btn"
              onClick={() => handleDelete()}
            >
              {loading ? (
                <Box
                  sx={{ width: '21px', height: '21px', margin: '0rem 0.7rem' }}
                >
                  <PreLoader1 />
                </Box>
              ) : (
                'Delete'
              )}{' '}
            </button>
          </Box>
        </Box>
      </Slide>
    </section>
  );
}

function CompanyDelete({ setSureDelete, customParams }) {
  const access_token = useSelector((state) => state.auth.user.access);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const handleDeleteComp = async () => {
    if (!customParams) {
      return false;
    }
    const meta = {
      access_token,
    };
    setLoading(true);
    const res = await fetch(
      `${ENDPOINT}/api/contact/company/delete/${customParams.id}/`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${meta.access_token}`,
        },
      },
    );
    if (!res.ok) {
      throw new Error(`Error deleting ${customParams.id}`);
    } else {
      dispatch(getCompanies(meta));
      dispatch(getPersons(meta));
      setLoading(false);
      setSureDelete(false);
    }
    return 1;
  };

  return (
    <section
      className="loading"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        backgroundColor: 'rgba(255,255,255,0.5)',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 99999999999,
      }}
    >
      <Slide bottom>
        <Box
          sx={{
            width: '500px',
            height: '130px',
            backgroundColor: 'rgba(255,255,255)',
            border: '1px solid #d9d9d9',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '8px',
            padding: '0.68rem 1rem',
          }}
        >
          <p
            className="fstxt"
            style={{ textAlign: 'center', marginTop: '.3rem' }}
          >
            Are You Sure You want to Delete This Company? Removing This Company
            will remove all associated data.
          </p>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '75%',
              marginTop: '1rem',
            }}
          >
            <button
              type="button"
              className="cancel-btn"
              onClick={() => setSureDelete(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              style={{ background: loading && '#FA2D23' }}
              className="netDanger-btn"
              onClick={() => handleDeleteComp()}
            >
              {loading ? (
                <Box
                  sx={{ width: '21px', height: '21px', margin: '0rem 0.7rem' }}
                >
                  <PreLoader1 />
                </Box>
              ) : (
                'Delete'
              )}{' '}
            </button>
          </Box>
        </Box>
      </Slide>
    </section>
  );
}

function CompanySureDelete({ setSureDelete, selected }) {
  const dispatch = useDispatch();
  const access_token = useSelector((state) => state.auth.user.access);
  const [loading, setLoading] = useState(false);
  const [secondWarn, setsecondWarn] = useState(false);
  const [word, setWord] = useState('');
  const [error, setError] = useState(false);
  // eslint-disable-next-line
  const handleDelete = () => {
    if (word === 'delete') {
      if (!selected) {
        return false;
      }
      const meta = {
        access_token,
      };
      setLoading(true);
      selected.forEach(async (company) => {
        const res = await fetch(
          `${ENDPOINT}/api/contact/company/delete/${company}/`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `JWT ${meta.access_token}`,
            },
          },
        );
        if (!res.ok) {
          setLoading(false);
          throw new Error(`Error deleting ${company}`);
        } else {
          // eslint-disable-next-line
          if (res) {
            setLoading(false);
            setSureDelete(false);
          }
        }
        return 1;
      });
      dispatch(getCompanies(meta));
    } else {
      setError('Incorrect Spelling');
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  };

  return (
    <section
      className="loading"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        backgroundColor: 'rgba(255,255,255,0.5)',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 999,
      }}
    >
      <Box
        sx={{
          width: '500px',
          height: '150px',
          backgroundColor: 'rgba(255,255,255)',
          border: '1px solid #d9d9d9',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '8px',
          padding: '0.68rem 1rem',
        }}
      >
        {secondWarn ? (
          <>
            <p className="fstxt">
              Please type word &apos;delete&apos; to delete all the companies.
            </p>
            <TextField
              id="standard-basic"
              label="Type Delete"
              color="secondary"
              variant="standard"
              type="text"
              value={word}
              onChange={(e) => {
                setWord(e.target.value.toLowerCase());
              }}
              sx={{
                marginTop: '10px',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '6px',
                  height: '44px',
                  backgroundColor: '#F1F3F5',

                  '& fieldset': {
                    borderColor: '#fff',
                  },
                  '&:hover fieldset': {
                    borderColor: '#711fff',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#711fff',
                  },
                },
              }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <button type="button" onClick={() => setSureDelete(false)}>
                Cancel
              </button>
              <button
                type="button"
                className="netDanger-btn"
                onClick={() => {
                  if (word === 'delete') {
                    handleDelete();
                  } else {
                    setsecondWarn(false);
                  }
                }}
              >
                {loading ? (
                  <Box
                    sx={{
                      width: '30px',
                      height: '30px',
                      margin: '0rem 0.1rem',
                    }}
                  >
                    <PreLoader1 />
                  </Box>
                ) : (
                  'Delete'
                )}{' '}
              </button>
            </Box>
          </>
        ) : (
          <Box>
            <section
              className="loading"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100%',
                backgroundColor: 'rgba(255,255,255,0.5)',
                position: 'fixed',
                top: 0,
                left: 0,
                zIndex: 999,
              }}
            >
              <Slide bottom>
                <Box
                  sx={{
                    width: '850px',
                    height: '320px',
                    background: 'rgba(241, 243, 245, 0.50)',
                    padding: '2rem',
                    borderRadius: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Box
                    sx={{
                      width: '95%',
                      height: '95%',
                      backgroundColor: 'rgba(255,255,255)',
                      border: '1px solid #d9d9d9',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      borderRadius: '8px',
                      padding: '0.68rem 1rem',
                      justifyContent: 'space-around',
                      boxShadow: '2px 2px 6px 0px #D9D9D9',
                      gap: '10px',
                    }}
                  >
                    <p
                      style={{
                        fontSize: '0.9rem',
                        fontWeight: '600',
                        fontFamily: 'Open Sans, sans-serif',
                        color: 'red',
                      }}
                    >
                      DELETE {selected.length > 1 ? 'COMPANIES' : 'COMPANY'}
                    </p>
                    <p
                      className="fstxt"
                      style={{
                        width: '80%',
                        margin: '0 auto',
                        textAlign: 'center',
                        fontWeight: '400',
                      }}
                    >
                      Are You Sure You want to Delete{' '}
                      {selected.length > 1 ? 'These Companies' : 'This Company'}{' '}
                      Removing{' '}
                      {selected.length > 1 ? 'These Companies' : 'This Company'}{' '}
                      will remove all associated data.
                    </p>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <p className="fstxt" style={{ fontWeight: '400' }}>
                        Please type the word &quot;DELETE&quot; and click the
                        Delete button
                      </p>
                      <Box
                        sx={{
                          width: '40%',
                          borderRadius: '8px',
                          padding: '1rem 1.1rem',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          height: '65px',
                        }}
                      >
                        <TextField
                          value={word}
                          variant="standard"
                          onChange={(e) =>
                            setWord(e.target.value.toLowerCase())
                          }
                          placeholder="Delete"
                          sx={{
                            width: '100%',
                            '& .MuiInputBase-input': {
                              fontSize: '1rem',
                              fontFamily: 'Open Sans',
                              fontWeight: '500',
                              color: '#000000',
                              textAlign: 'center',
                            },
                            '& .MuiInput-underline:before': {
                              borderBottom: '1px dashed #000000',
                            },
                            '& .MuiInput-underline:after': {
                              borderBottom: '1px dashed #000000',
                            },
                            '& .MuiInput-underline:hover:not(.Mui-disabled):before':
                              {
                                borderBottom: '1px dashed #000000',
                              },
                          }}
                        />
                      </Box>
                      {error && (
                        <Shake>
                          <p style={{ color: 'red' }}>{error}</p>
                        </Shake>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '50%',
                        margin: '0 auto',
                      }}
                    >
                      <button
                        type="button"
                        className="cancel-btn"
                        onClick={() => setSureDelete(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        style={{
                          fontFamily:
                            "'Neue Haas Display Pro', 'Open Sans', sans-serif",
                          fontWeight: '500',
                          backgroundColor: word ? '#cd0b4c' : '#f99f9f',
                          borderRadius: '5px',
                          color: 'var(--white-100)',
                          border: 'none',
                          cursor: 'pointer',
                          textAlign: 'center',
                          fontSize: '1rem',
                          padding: '0rem 2rem',
                          height: '38px',
                        }}
                        onClick={handleDelete}
                        disabled={!word}
                      >
                        {loading ? (
                          <Box
                            sx={{
                              width: '30px',
                              height: '30px',
                              margin: '0rem 0.1rem',
                            }}
                          >
                            <PreLoader1 />
                          </Box>
                        ) : (
                          'Delete'
                        )}{' '}
                      </button>
                    </Box>
                  </Box>
                </Box>
              </Slide>
            </section>
          </Box>
        )}
      </Box>
    </section>
  );
}

export { SureDeleteContact, ContactDelete, CompanySureDelete, CompanyDelete };

function CompanyModal({
  currentTarget,
  show,
  setShow,
  companyTarget,
  setCompanyTarget,
  setCurrentTarget,
}) {
  const navigate = useNavigate();
  const [showEmployee, setShowEmployee] = useState(false);
  const access_token = useSelector((state) => state.auth.user.access);
  const [value, setValue] = useState('Notes');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClose = () => setShow(false);
  const dispatch = useDispatch();

  const fetchData = async () => {
    try {
      const res = await fetch(
        `${ENDPOINT}/api/contact/detail/${currentTarget.id}/`,
        {
          method: 'GET',
          headers: {
            Authorization: `JWT ${access_token}`,
            'Content-Type': 'application/json',
          },
        },
      );

      if (res.ok) {
        const data = await res.json();
        setCurrentTarget(data);
      }
      // eslint-disable-next-line
    } catch (error) {}
  };

  const [html, setHtml] = useState('');

  const [btn, setbtn] = useState();
  const check = (e) => {
    setbtn(e);
  };
  const send_Note = async (e) => {
    // eslint-disable-next-line
    if (e.shiftKey) {
    } else if (e.key === 'Enter') {
      setHtml('');
      btn?.editor?.commands.clearContent();
      const res = await fetch(`${ENDPOINT}/api/contact/note/create/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${access_token}`,
        },
        body: JSON.stringify({
          company_note: companyTarget.id,
          note: html,
        }),
      });
      if (res.ok) {
        const resGet = await fetch(
          `${ENDPOINT}/api/contact/company/detail/${companyTarget.unique_id}/`,
          {
            method: 'GET',
            headers: {
              Authorization: `JWT ${access_token}`,
              'Content-Type': 'application/json',
            },
          },
        );

        if (resGet.ok) {
          const dataGet = await resGet.json();
          setCompanyTarget(dataGet);
          fetchData();
        }
      }
      dispatch(getCompanies({ access_token }));
    }
  };

  return companyTarget ? (
    <>
      <Offcanvas show={show} onHide={handleClose} placement="bottom ">
        <Offcanvas.Header>
          {/* eslint-disable-next-line */}
          <button
            type="button"
            className="close-modal"
            onClick={() => setShow(false)}
          />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <section
            className="modal-Header"
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              padding: '0 1.24rem',
            }}
          >
            <article
              className="companyHeader"
              style={{ display: 'flex', gap: '0.8rem' }}
            >
              <Avatar src={defaultCompany} sx={{ width: 80, height: 80 }} />
              <div className="dFlex col-md-6">
                <h3
                  className="fstxtPro noPad"
                  style={{ textTransform: 'capitalize' }}
                >
                  {companyTarget.name}{' '}
                </h3>
                <h3 className="specialTxT noPad">{companyTarget.email}</h3>
              </div>
            </article>
            <button
              type="button"
              className="btninC"
              onClick={() => {
                // eslint-disable-next-line
                if (['unique_id' in companyTarget]) {
                  navigate(`/Company_Detail/${companyTarget.unique_id}`);
                }
              }}
            >
              Go to Company
            </button>
          </section>
          <section
            className="modal-Body"
            style={{ display: 'flex', gap: '0.74rem', width: '100%' }}
          >
            <section className="info" style={{ flex: '0.6' }}>
              <Grid container style={{ padding: '0 1.24rem' }}>
                <Grid item xs={12} sm={6}>
                  <article className="col">
                    <h3 className="sub-heading">Info</h3>
                    <div className="MUI-box-in-CC">
                      <span>Type</span>
                      <input
                        type="text"
                        className="mui-In-CC"
                        value={companyTarget.company_type ?? ''}
                        disabled
                      />
                    </div>

                    <div className="MUI-box-in-CC">
                      <span>Office Num</span>
                      <input
                        type="text"
                        className="mui-In-CC"
                        value={companyTarget.office_num}
                        disabled
                      />
                    </div>
                    <div className="MUI-box-in-CC">
                      <span>State</span>
                      <input
                        type="text"
                        className="mui-In-CC"
                        value={companyTarget.state}
                        disabled
                      />
                    </div>
                    <div className="MUI-box-in-CC">
                      <span>Linked In</span>
                      <input
                        type="text"
                        className="mui-In-CC"
                        value={companyTarget.linkedin_url}
                        disabled
                      />
                    </div>
                    <div className="MUI-box-in-CC">
                      <span>Facebook</span>
                      <input
                        type="text"
                        className="mui-In-CC"
                        value={companyTarget.facebook_url}
                        disabled
                      />
                    </div>

                    <div
                      className="MUI-box-in-CC"
                      style={{ minWidth: '70px', maxHeight: 'auto' }}
                    >
                      <span>Tags</span>
                      <Box
                        className="tagBox"
                        sx={{
                          display: 'flex',
                          gap: '1rem',
                          flexDirection: 'row',
                          flexWrap: 'wrap',
                        }}
                      >
                        {companyTarget.tags
                          ? companyTarget.tags.split(',').map((item) => (
                              <span key={item} className="tag">
                                {item}
                              </span>
                            ))
                          : null}
                      </Box>
                    </div>
                  </article>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <article
                    className="col"
                    style={{
                      marginTop: '42px',
                    }}
                  >
                    <div className="MUI-box-in-CC">
                      <span>Web Address</span>
                      <input
                        type="text"
                        className="mui-In-CC"
                        value={
                          Array.isArray(companyTarget.company_url) &&
                          companyTarget.company_url.length
                            ? companyTarget.company_url[0].domain_name
                            : ''
                        }
                        disabled
                      />
                    </div>
                    <div className="MUI-box-in-CC">
                      <span>City</span>
                      <input
                        type="text"
                        className="mui-In-CC"
                        value={companyTarget.city}
                        disabled
                      />
                    </div>
                    <div className="MUI-box-in-CC">
                      <span>Country</span>
                      <input
                        type="text"
                        className="mui-In-CC"
                        value={companyTarget.country}
                        disabled
                      />
                    </div>
                    <div className="MUI-box-in-CC">
                      <span>X</span>
                      <input
                        type="text"
                        className="mui-In-CC"
                        value={companyTarget.twitter_url}
                        disabled
                      />
                    </div>

                    <div className="MUI-box-in-CC">
                      <span>Instagram</span>
                      <input
                        type="text"
                        className="mui-In-CC"
                        value={companyTarget.instagram_url}
                        disabled
                      />
                    </div>
                  </article>
                </Grid>
              </Grid>
            </section>
            <section
              className="Tab-System"
              style={{ flex: '0.6', height: '500px' }}
            >
              <Box sx={{ width: '100%' }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="secondary tabs example"
                >
                  <Tab value="Notes" label="Notes" />
                </Tabs>
              </Box>
              <Grid
                item
                container
                direction="column"
                alignItems="flex-start"
                sx={{
                  padding: '0 2rem',
                }}
              >
                {value === 'Notes' && (
                  <Grid
                    item
                    container
                    direction="column"
                    xs={12}
                    justifyContent="space-between"
                  >
                    <List
                      sx={{
                        width: '100%',
                        height: '400px',
                        overflowY: 'auto',
                      }}
                    >
                      {companyTarget.company_note &&
                      companyTarget.company_note.length > 0 ? (
                        companyTarget.company_note.map((item) => (
                          <>
                            <ListItem alignItems="flex-start">
                              <ListItemAvatar>
                                <Avatar
                                  alt={item.created_by.first_name}
                                  src={`${ENDPOINT}/${item.created_by.avatar}`}
                                />
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  <Typography
                                    variant="subtitle1"
                                    sx={{
                                      fontWeight: 'bold',
                                      fontFamily: 'Open Sans',
                                      fontSize: '1rem',
                                    }}
                                  >
                                    {item.created_by.first_name}
                                  </Typography>
                                }
                                secondary={
                                  <Typography
                                    sx={{
                                      color: '#03071E',
                                      fontSize: '14px',
                                    }}
                                    variant="subtitle2"
                                    dangerouslySetInnerHTML={{
                                      __html: item.note,
                                    }}
                                  />
                                }
                              />
                            </ListItem>
                            <Divider
                              component="li"
                              sx={{
                                paddingLeft: '2.25%',
                                paddingRight: '2.25%',
                              }}
                            />
                          </>
                        ))
                      ) : (
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontWeight: 'bold',
                            paddingLeft: '5%',
                            paddingRight: '5%',
                          }}
                        >
                          No Notes
                        </Typography>
                      )}
                    </List>
                    <Grid item sx={{ width: '100%' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                          alignItems: 'end',
                        }}
                      >
                        <section
                          className="editorNoteContacts px-2 pt-1 mb-2 rounded"
                          onKeyDown={send_Note}
                        >
                          <Box sx={{ height: '150px', overflow: 'hidden' }}>
                            <Test
                              setHtml={setHtml}
                              html={html}
                              check={check}
                              auto_focus={false}
                            />
                          </Box>
                        </section>
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </section>
          </section>
        </Offcanvas.Body>
      </Offcanvas>
      <EmployeeView
        showEmployee={showEmployee}
        setShowEmployee={setShowEmployee}
        currentTarget={currentTarget}
      />
    </>
  ) : (
    0
  );
}

export default CompanyModal;
