/* eslint-disable */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import '../Deliverable/deliverable.css';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Tooltip,
  Typography,
  Grid,
  TextField,
  Autocomplete,
  List,
  ListItem,
  Skeleton,
} from '@mui/material';
import { useEffect, useState, useMemo } from 'react';
import { ProgressBar } from 'react-bootstrap';
import InnerTable from '../Deliverable/expandedtable';
import { EditorState } from 'draft-js';
import moment from 'moment';
import Offcanvas from 'react-bootstrap/Offcanvas';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { stateToHTML } from 'draft-js-export-html';
import uuid from 'react-uuid';
import { ENDPOINT } from '../Opportunity/Redux/oppor';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { NetProgressProjectLOE } from '../../Componenets/ProgressBar';
import EditIconButton from '../../Componenets/atoms/EditIconButton';
import Test from '../Test';

import { calculateTotalDurationObtained } from './QuickTasks';
import { set } from 'date-fns';

const VarPro = ({ meta, dil }) => {
  const workTypeUsers =
    dil.row.flatMap((task) =>
      task.assignee_task
        .filter((item) => item.assignee_work_type.name === meta.name)
        .map((item) => item.assignee_user),
    ) || [];

  const worked_hour =
    Array.isArray(dil.task_deliverable) && dil.task_deliverable.length
      ? dil.task_deliverable
          .filter((tak) =>
            tak.task_worktype.some((ele) => ele.name === meta.name),
          )
          .reduce(
            (acc, tsk) =>
              acc +
              calculateTotalDurationObtained(
                tsk.time_tracking.filter((tracking) =>
                  workTypeUsers.includes(tracking?.created_by?.id),
                ),
              ),
            0,
          )
      : 0;

  // * Extract hours (integer part).
  const hoursObt = Math.floor(worked_hour);
  // * Extract minutes.
  const minutesObt = Math.floor((worked_hour - hoursObt) * 60);

  // * Format hours and minutes with leading zeros if necessary.
  const formattedHoursObt = hoursObt < 10 ? `0${hoursObt}` : hoursObt;
  const formattedMinutesObt = minutesObt < 10 ? `0${minutesObt}` : minutesObt;

  // * Create the formatted string.
  const totalWorkedHourPerWorkType = `${formattedHoursObt}:${formattedMinutesObt}`;

  const total_hour = meta.hours ? meta.hours : 0.2;

  const calculateTotalAssigned = (worktype) => {
    let totalDuration = 0;

    if (Array.isArray(worktype) && worktype.length > 0) {
      totalDuration = worktype.reduce((acc, item) => {
        const hours = Number(item.hours);
        return acc + hours;
      }, 0);
    }

    return totalDuration;
  };

  const assignedCalc =
    Array.isArray(dil?.task_deliverable) && dil.task_deliverable.length
      ? dil.task_deliverable.filter((who) =>
          who.task_worktype.some((mo) => mo.name === meta.name),
        )
      : [];

  const totalAssigned =
    Array.isArray(assignedCalc) && assignedCalc.length
      ? assignedCalc.reduce((acc, task) => {
          // Filter task_worktype array based on meta.name
          let filteredWorkTypes = task.task_worktype.filter(
            (worktype) => worktype.name === meta.name,
          );

          // Calculate total assigned hours for the filtered worktypes
          let totalHoursForWorkTypes =
            calculateTotalAssigned(filteredWorkTypes);

          // Add to the accumulator
          return acc + totalHoursForWorkTypes;
        }, 0)
      : 0;

  return (
    <div style={{ width: '60%' }}>
      <div
        className="progress-container"
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: '30%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '2px',
          }}
        >
          <Tooltip title={'Actual'}>
            <span className="span">{totalWorkedHourPerWorkType}</span>
          </Tooltip>
        </Box>
        <ProgressBar id="outerbar">
          <ProgressBar
            id="innerbar"
            style={{
              background: getVariantWorktype(worked_hour, total_hour, 'date'),
            }}
            now={Math.round(worked_hour)}
            min={0}
            max={total_hour ? total_hour : 0}
            key={1}
          />
        </ProgressBar>
        <Box
          sx={{
            width: '30%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '2px',
          }}
        >
          <Tooltip title={'Estimated'}>
            <span className="span">{Math.round(total_hour)}:00</span>
          </Tooltip>
        </Box>
      </div>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <Tooltip title={'Assigned'}>{Math.round(totalAssigned)}h</Tooltip>
      </Box>
    </div>
  );
};

const DetailView = ({
  setShow, // Prop FUnc
  show, // Prop Bool
  p, // p = the deliverable that's detail u want
  dileverable, // dileverable array
  setDeliverable, // func to set delieverable array
  formData, // u know it
  setIsDetOpen,
  index2,
  selectedView,
  setSelectedView,
  setIsTyping,
  setFormData,
  expanded,
}) => {
  const handleClose = () => {
    setShow(false);
  };

  const [editor, setEditor] = useState(() => {
    EditorState.createEmpty().getCurrentContent();
  });
  const [fieldEmpty, setFieldEmpty] = useState(editor);
  const fieldText = (e) => {
    setEditor(e);
    setHtml(stateToHTML(e.getCurrentContent()));
  };

  const customOptions = dileverable.filter(
    (item) => item.deliverable_name !== p.deliverable_name,
  );

  const [mem, setMem] = useState({
    description: '',
    deliverable_dependency: '',
  });

  const val = moment(formData?.target_start);
  const val2 = () => {
    const index = dileverable.findIndex(
      (ent) => Number(ent.id) === Number(p.id),
    );
    return moment(dileverable[index]?.deliverable_enddate) ?? moment();
  };

  const currentDil = dileverable.find((item) => item.id === selectedView.id);
  function calculateTotalWeeks(deliverableStartDate, deliverableEndDate) {
    const startDate = new Date(deliverableStartDate);
    const endDate = new Date(deliverableEndDate);

    // Calculate the time difference in milliseconds
    const timeDifference = endDate - startDate;

    // Convert milliseconds to weeks
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    // Calculate the number of weeks (5-day weeks) based on the days difference
    const weeksDifference = Math.ceil(daysDifference / 7);

    return weeksDifference;
  }
  const [object, setObject] = useState({});
  const access_token = useSelector((item) => item.auth.user.access);
  const [deliverName, setDeliverName] = useState();
  const [html, setHtml] = useState(currentDil?.internal_note ?? '<p></p>');
  const [htmlDesc, setHtmlDesc] = useState(
    currentDil?.description ?? '<p></p>',
  );
  const [openLink, setOpenLink] = useState(false);

  return (
    <Offcanvas
      show={show && show === p?.id ? true : false}
      onHide={handleClose}
      placement="bottom "
    >
      <Offcanvas.Header>
        <button
          className="close-modal"
          onClick={() => {
            setShow(false);
            setIsTyping(expanded ? true : false);
            setSelectedView();
          }}
        />
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Grid container direction="column" alignItems="flex-start">
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            xl={12}
            lg={12}
            md={12}
            sm={12}
          >
            <Box
              sx={{
                width: '96%',
                fontSize: '1.5rem',
                fontWeight: '600',
                fontFamily: 'Open Sans',
                textAlign: 'left',
                padding: { xl: '0.50rem 4.5rem', lg: '0.50rem .2rem' },
              }}
            >
              <TextField
                variant="standard"
                value={deliverName ?? currentDil?.deliverable_name}
                sx={{
                  width: { xl: 280, lg: 200, md: 150 },
                  fontSize: '1.5rem',
                  '& .MuiInputBase-input': {
                    fontSize: '1.5rem',
                    fontFamily: 'Open Sans',
                    fontWeight: '600',
                    color: '#000000',
                  },

                  '& .MuiInput-underline:before': {
                    borderBottom: '1px dashed #fff',
                  },
                  '& .MuiInput-underline:after': {
                    borderBottom: '1px dashed #000000',
                  },
                  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                    borderBottom: '1px dashed #000000',
                  },
                }}
                inputProps={{ style: { fontSize: 26 } }} // font size of input text
                onChange={(e) => {
                  setDeliverName(e.target.value);
                }}
              />
            </Box>
            <Grid
              item
              container
              sx={{ width: '100%', gap: '1rem' }}
              justifyContent="center"
              alignItem="center"
            >
              <Grid item xl={5} lg={4.1} md={11}>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '8px',
                    border: '1px solid #d9d9d9',
                    padding: '0.44rem 0.88rem',
                    gap: '1rem',
                  }}
                >
                  <p
                    style={{
                      fontSize: '1rem',
                      fontWeight: '600',
                      fontFamily: 'Open Sans',
                    }}
                    onClick={() => {}}
                  >
                    Details
                  </p>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '0.5rem',
                      backgroundColor: '#f2ebff',
                      borderRadius: '8px',
                      padding: '0.44rem 0.88rem',
                    }}
                  >
                    <Box className="myJam">
                      <span className="modalSpan" style={{ color: 'GrayText' }}>
                        Duration{' '}
                        <InfoOutlinedIcon
                          sx={{ color: '#4dfad2', fontSize: '14px' }}
                        />
                      </span>
                      <Box
                        sx={{
                          width: '300px',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          color="secondary"
                          placeholder="Enter Value"
                          defaultValue={calculateTotalWeeks(
                            currentDil.deliverable_startdate,
                            currentDil.deliverable_enddate,
                          )}
                          sx={{
                            width: '60%',
                            outline: 'none',
                            borderTopLeftRadius: '10px',
                            borderStartStartRadius: '10px',
                            backgroundColor: 'white',
                            textAlign: 'center',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                border: '1px solid #f2ebff',
                              },
                              '&:hover fieldset': {
                                border: '1px solid #711fff',
                              },
                              '&.Mui-focused fieldset': {
                                border: '1px solid #5a0bdf',
                              },
                              '&.Mui-error fieldset': {
                                border: '1px solid #f2ebff',
                              },
                            },
                            '& .MuiFormLabel-root': {
                              color: '#03071e66',
                            },
                            '& .MuiFormLabel-root.Mui-focused': {
                              color: '#5a0bdf',
                            },
                            '& .MuiFormLabel-root.Mui-error': {
                              color: 'red',
                            },
                          }}
                          type={'number'}
                          InputProps={{
                            inputProps: { min: 1 },
                          }}
                          onChange={(e) => {
                            const start = moment(
                              currentDil?.deliverable_startdate?.split('T')[0],
                            ).toDate();
                            const end = moment(start)
                              .add(Number(e.target.value) * 7, 'days')
                              .toDate();
                            setObject({
                              ...object,
                              deliver_id: currentDil.id,
                              enddate: end,
                            });
                          }}
                        />
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            backgroundColor: '#f2f2f2',
                            width: '40%',
                            color: 'GrayText',
                            borderTopRightRadius: '10px',
                            borderEndEndRadius: '10px',
                            height: '45px',
                          }}
                        >
                          <span
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <ArrowDropUpIcon sx={{ marginBottom: '-15px' }} />
                            <ArrowDropDownIcon />
                          </span>
                          <Typography sx={{ fontSize: '14px' }}>
                            Week
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="myJam">
                      <span className="modalSpan" style={{ color: 'GrayText' }}>
                        Dependency{' '}
                        <InfoOutlinedIcon
                          sx={{ color: '#4dfad2', fontSize: '15px' }}
                        />
                      </span>
                      <Autocomplete
                        id="company"
                        options={customOptions}
                        defaultValue={
                          currentDil.deliverable_dependency &&
                          dileverable.find(
                            (item) =>
                              item.id === currentDil.deliverable_dependency,
                          )
                        }
                        getOptionLabel={(option) => option.deliverable_name}
                        onChange={(_, value) => {
                          if (value) {
                            setObject({
                              ...object,
                              deliver_id: currentDil.id,
                              dependency: value.id,
                            });
                          } else {
                            setObject({
                              ...object,
                              deliver_id: currentDil.id,
                              dependency: 'not',
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            color="secondary"
                            InputProps={{
                              ...params.InputProps,
                              style: { width: 250 },
                            }}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xl={6} lg={7.7} md={11}>
                <Box
                  sx={{
                    width: '100%',
                    backgroundColor: 'white',
                    padding: '0.88rem 1rem',
                    borderRadius: '8px',
                    border: '1px solid #d9d9d9',
                    minHeight: { xl: '170px', lg: '160px', md: '154px' },
                  }}
                >
                  <span
                    style={{
                      fontWeight: '600',
                      fontFamily: 'Open Sans',
                    }}
                  >
                    Description
                  </span>
                  <Box
                    sx={{
                      height: '85%',
                      display: 'flex',
                      flexDirection: 'column-reverse',
                    }}
                  >
                    {' '}
                    <Test
                      open={openLink}
                      setOpen={setOpenLink}
                      bottom={true}
                      html={htmlDesc}
                      setHtml={setHtmlDesc}
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid item xl={12} lg={12} md={12}>
                <Box
                  sx={{
                    width: { xl: '93%', lg: '98.5%', md: '98%', sm: '90%' },
                    backgroundColor: '#fafafa',
                    padding: '0.88rem 1rem',
                    borderRadius: '8px',
                    margin: '0 auto',
                    minHeight: '145px',
                  }}
                >
                  <span
                    style={{
                      fontWeight: '600',
                      fontFamily: 'Open Sans',
                    }}
                  >
                    Internal Notes
                  </span>
                  <Box
                    sx={{
                      height: '85%',
                      display: 'flex',
                      flexDirection: 'column-reverse',
                    }}
                  >
                    {' '}
                    <Test
                      open={openLink}
                      setOpen={setOpenLink}
                      bottom={true}
                      html={html}
                      setHtml={setHtml}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid
              item
              sx={{
                width: { xl: '90%', lg: '98%', md: '98%' },
                height: 'auto',
                padding: '10px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <button className="cancel-btn" onClick={() => setShow(false)}>
                  Cancel
                </button>
                <button
                  className="create-btn"
                  onClick={async () => {
                    const ind = dileverable.findIndex(
                      (item) => item.id === currentDil.id,
                    );
                    const shallow_copy = dileverable.map((item) =>
                      Object.assign({}, item),
                    );
                    shallow_copy[ind].deliverable_enddate = object.enddate
                      ? new Date(object.enddate).toISOString()
                      : shallow_copy[ind].deliverable_enddate;
                    shallow_copy[ind].deliverable_dependency = object.dependency
                      ? object.dependency === 'not'
                        ? null
                        : object.dependency
                      : shallow_copy[ind].deliverable_dependency;
                    shallow_copy[ind].description =
                      htmlDesc ?? shallow_copy[ind].description;
                    shallow_copy[ind].deliverable_name = deliverName
                      ? deliverName
                      : dileverable[ind].deliverable_name;
                    shallow_copy[ind].internal_note =
                      html ?? shallow_copy[ind].internal_note;
                    const shallowCopy = {
                      ...formData,
                    };

                    delete shallowCopy.work_type;
                    delete shallowCopy.estimate_hardcost;

                    const res = await fetch(
                      `${ENDPOINT}/api/project/update/${formData.id}/`,
                      {
                        method: 'PUT',
                        headers: {
                          Authorization: `JWT ${access_token}`,
                          'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                          ...shallowCopy,

                          type: 'project',
                          project_deliverable: shallow_copy?.map(
                            (item, index) => {
                              delete item.deliverable_multiplier;
                              delete item.task_deliverable;

                              const newDate =
                                new Date(item.deliverable_enddate) -
                                new Date(item.deliverable_startdate);
                              const durationInDays =
                                newDate / (24 * 60 * 60 * 1000);
                              const startDateOfDil =
                                item?.deliverable_dependency
                                  ? dileverable.find(
                                      (dil) =>
                                        dil.id === item?.deliverable_dependency,
                                    ).deliverable_enddate
                                  : item.deliverable_startdate;
                              const endDateOfDil = item?.deliverable_dependency
                                ? new Date(
                                    new Date(startDateOfDil).setDate(
                                      new Date(startDateOfDil).getDate() +
                                        durationInDays,
                                    ),
                                  ).toISOString()
                                : item.deliverable_enddate;
                              return {
                                ...item,
                                deliver_id: item.id,
                                deliverable_dependency:
                                  item.deliverable_dependency,
                                deliverable_enddate: endDateOfDil,
                                deliverable_startdate: startDateOfDil,
                                deliverable_worktype:
                                  item.deliverable_worktype.map((wtt) => ({
                                    ...wtt,
                                    id: wtt.id,
                                    hours: wtt.hours ? Number(wtt.hours) : null,
                                  })),
                              };
                            },
                          ),
                        }),
                      },
                    );
                    const data = await res.json();
                    if (!res.ok) {
                      return;
                    } else {
                      const resGet = await fetch(
                        `${ENDPOINT}/api/project/detail/${formData?.unique_id}/`,
                        {
                          method: 'GET',
                          headers: {
                            'Content-Type': 'application/json',
                            Authorization: `JWT ${access_token}`,
                          },
                        },
                      );
                      const dataGet = await resGet.json();
                      if (!resGet.ok) {
                        return;
                      }
                      setFormData(dataGet);

                      if (
                        dataGet &&
                        Array.isArray(dataGet.deliverable_project) &&
                        dataGet.deliverable_project.length
                      ) {
                        setDeliverable(dataGet.deliverable_project);
                        setShow(false);
                        setIsTyping(expanded ? true : false);
                      }
                    }
                  }}
                >
                  Save
                </button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

const getVariant = (progress, total, type) => {
  let progressInPrecent = (progress * 100) / total;
  if (progressInPrecent <= 50 && progressInPrecent >= 0)
    return type == 'hour' ? 'light-success' : 'success';
  else if (progressInPrecent < 100 && progressInPrecent >= 51) return 'warning';
  else return 'danger';
};

const getVariantWorktype = (progress, total, type) => {
  let progressInPrecent = (progress * 100) / total;
  if (progressInPrecent <= 50 && progressInPrecent >= 0)
    return type == 'hour' ? '#5FCEA8' : '#5FCEA8';
  else if (progressInPrecent < 100 && progressInPrecent >= 51) return '#F59825';
  else return '#FA2D23';
};

const CustomRow = ({
  status,
  expanded,
  task,
  dileverable,
  setExpanded,
  setShow,
  show,
  tasks,
  setDetOpen,
  setIsDetOpen,
  setDeliverable,
  formData,
  setFormData,
  setIsTyping,
  isTyping,
  index,
  setSelectedView,
  selectedView,
  unique_id,
  dropDown,
  setDropDown,
  setGetAvatar,
  setId,
  id,
  value1,
  setValue1,
  setDateRange,
  dateRange,
  SetDltingTask,
  dltingTask,
  access_token,
  getData,
  addTask,
  setAddTask,
  showDesc,
  setShowDesc,
  html,
  setHtml,
  showName,
  setShowName,
  setDueDate,
  currentMonthIndex,
  setDelivChatShow,
  delivChatShow,
  deliverableChat,
  setDeliverableChat,
  ChannelList,
  setShowOfTask,
  showofTask,
  setCurrentTargetTask,
  currentTargetTask,
  setComments,
  comments,
  commentLoader,
  setComentLoader,
}) => {
  useEffect(() => {
    setIsDetOpen(!show);
  }, [show]);

  const total_hour =
    Array.isArray(task.worktype) && task.worktype.length
      ? task.worktype.reduce((acc, item) => {
          return acc + Number(item.hours);
        }, 0)
      : 1;

  const worked_hour =
    task.task_deliverable &&
    Array.isArray(task.task_deliverable) &&
    task.task_deliverable.length
      ? task.task_deliverable.reduce((acc, item) => {
          return (
            acc + Number(calculateTotalDurationObtained(item.time_tracking))
          );
        }, 0)
      : 0;

  const percentageOfDeliverableCompletion = (
    (worked_hour / total_hour) *
    100
  ).toFixed(2);

  // * Extract hours (integer part).
  const hoursObt = Math.floor(worked_hour);
  // * Extract minutes.
  const minutesObt = Math.floor((worked_hour - hoursObt) * 60);

  // * Format hours and minutes with leading zeros if necessary.
  const formattedHoursObt = hoursObt < 10 ? `0${hoursObt}` : hoursObt;
  const formattedMinutesObt = minutesObt < 10 ? `0${minutesObt}` : minutesObt;

  // * Create the formatted string.
  const totalWorkedHourPerWorkType = `${formattedHoursObt}:${formattedMinutesObt}`;

  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const dil = task;

  const currentDate = moment(dil?.startdate);
  const endDate = moment(dil?.due_date);

  const getToday = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  };

  const start = dil?.startdate
    ? new Date(dil?.startdate?.split('T')[0]).getTime()
    : new Date().getTime();
  const end = dil?.due_date
    ? new Date(dil?.due_date?.split('T')[0]).getTime()
    : new Date().getTime();
  const today = getToday().getTime();
  const progressDate = (today - start) / (1000 * 3600 * 24);
  const totalDate = (end - start) / (1000 * 3600 * 24);

  const userWorkTypes = useSelector((state) => state.Misc.workTypes);

  const ourWorkTypes = (() => {
    if (Array.isArray(dil.worktype) && dil.worktype.length) {
      if ('name' in dil.worktype[0]) {
        return dil.worktype;
      } else {
        if (Array.isArray(userWorkTypes) && userWorkTypes.length) {
          const newWorkTypes = dil.worktype.map((item) => {
            const merger = userWorkTypes.find(
              (ele) => Number(ele.id) === Number(item.id),
            );
            return {
              ...item,
              ...merger,
            };
          });
          return newWorkTypes;
        }
      }
    }
    // Default case if none of the conditions are met
    return [];
  })();

  const userData = useSelector((state) => state.userData.userData);
  const [mentionsLength, setMentionsLength] = useState();
  const [deliverableLength, setDeliverableLength] = useState();

  const handleDeliverableShow = async () => {
    setDelivChatShow(true);
    setDeliverableChat(task);
    setIsTyping(true);

    // Assuming `comments` is an array of tasks
    for (const task of comments) {
      try {
        const find = task?.read_message.some(
          (readMessage) => readMessage.read_by === userData.id,
        );
        if (!find) {
          const response = await fetch(
            `${ENDPOINT}/api/chat/channel/message/read/create/`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${access_token}`,
              },
              body: JSON.stringify({
                message: task.id,
                read_by: userData.id,
              }),
            },
          );
          if (!response.ok) {
            const errorData = await response.json();
            return; // Optionally handle the error
          }
          const data = await response.json();
        }
      } catch (error) {}
    }

    const crtMessages = new_channals
      ?.filter((item) => item?.deliverable && !item?.task)
      .find((item) => item?.deliverable === task.id);
    const response = fetch(
      `${ENDPOINT}/api/chat/channel/detail/${crtMessages?.id}/`,
      {
        method: 'GET',
        headers: {
          Authorization: `JWT ${access_token}`,
          'Content-Type': 'application/json',
        },
      },
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setComments(data?.message_in_channel);
        setComentLoader(false);

        // Process the response data here
      })
      .catch((error) => {
        setComentLoader(false);
      });
  };

  useEffect(() => {
    const regexPattern = new RegExp(
      `<span data-type="mention" class="deliverable-mention" data-id="@${userData.first_name}">@${userData.first_name}</span>`,
      'i',
    );

    const filtered_comments = comments?.filter(
      (message) =>
        !message?.read_message?.some((item) => item.read_by === userData?.id),
    );
    const shallowCopy = filtered_comments?.map((item) => ({ ...item }));
    const filteration = shallowCopy?.filter((msg) =>
      regexPattern.test(msg.message),
    );
    setMentionsLength(filteration);
  }, [comments]);

  useEffect(() => {
    const regexPattern1 = new RegExp(
      `<span\\s+data-type="mention"\\s+class="deliverable-mention"\\s+data-id="${task?.title}">~${task?.title}</span>`,
      'i',
    );
    const filtered_comments1 = comments?.filter(
      (message) =>
        !message?.read_message?.some((item) => item.read_by === userData?.id),
    );
    const tempArray = filtered_comments1?.map((item) => ({ ...item }));
    const filteration1 = tempArray?.filter((msg) =>
      regexPattern1.test(msg.message),
    );
    if (filteration1.length) {
      setDeliverableLength(filteration1);
    }
  }, [comments]);

  const [rows, setRowsForCurrentMonth] = useState(task?.row || []);

  const rowsForCurrentMonth = (() => {
    const hasArchive =
      status.length && status?.some((item) => item?.value === 'archive');
    const activeRows = hasArchive
      ? rows
      : rows?.filter((item) => item?.status !== 'archive');

    if (formData?.project_type === 'retainer') {
      return activeRows?.filter((row) => {
        if (row?.carry_forward_setting === 'carry_task') {
          const rowDate = new Date(row?.due_date);
          const rowMonthIndex = rowDate.getUTCMonth(); // Use getUTCMonth() to ensure UTC is used
          return rowMonthIndex === currentMonthIndex;
        } else {
          return true;
        }
      });
    } else {
      return activeRows?.slice().sort((a, b) => a.sort_order - b.sort_order);
    }
  })();

  const new_channals = useSelector((state) => state.Stream.new_channels);

  const deliverableTitle = (task) => (
    <Tooltip title={task.title}>
      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          flexWrap: 'wrap',
          textDecoration: 'none',
          fontSize: { xl: '1rem', md: '0.9rem' },
          fontWeight: '600',
          overflow: 'hidden',
          width: '120px',
          height: '42px',
          color: '#310085',
        }}
      >
        {task.title}
      </Typography>
    </Tooltip>
  );

  return (
    <Accordion
      expanded={expanded === task.id}
      disableGutters={true}
      elevation={0}
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
      }}
      key={uuid()}
    >
      <AccordionSummary
        id={task.id}
        sx={{
          width: '100%',
          height: '60px',
          '&MuiAccordionSummary-content': {
            justifyContent: 'space-between',
          },
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '25%',
              height: '42px',
              alignItems: 'center',
            }}
          >
            {expanded !== task.id ? (
              <div
                style={{
                  display: 'flex',
                  width: '225px',
                  height: '42px',
                  alignItems: 'center',
                }}
                onClick={() => {
                  if (expanded === task.id) {
                    setExpanded(false);
                    setAddTask(false);
                  } else {
                    setExpanded(task.id);
                  }
                  setGetAvatar(task.task_deliverable);
                  setComentLoader(true);
                  const savedData = localStorage.getItem('taskData');
                  if (savedData) {
                    const parsedData = JSON.parse(savedData);
                    if (parsedData.task_deliverable === task.id) {
                      setAddTask(true);
                    }
                  }

                  if (new_channals && task) {
                    const crtMessages = new_channals
                      ?.filter((item) => item?.deliverable && !item?.task)
                      .find((item) => item?.deliverable === task.id);
                    const response = fetch(
                      `${ENDPOINT}/api/chat/channel/detail/${crtMessages?.id}/`,
                      {
                        method: 'GET',
                        headers: {
                          Authorization: `JWT ${access_token}`,
                          'Content-Type': 'application/json',
                        },
                      },
                    )
                      .then((response) => {
                        if (!response.ok) {
                          throw new Error('Network response was not ok');
                        }
                        return response.json();
                      })
                      .then((data) => {
                        setComments(data?.message_in_channel);
                        setComentLoader(false);

                        // Process the response data here
                      })
                      .catch((error) => {
                        setComentLoader(false);
                      });
                  }
                }}
              >
                <KeyboardArrowRightIcon
                  id="cypress-updateProjectTask-arrowButton"
                  sx={{
                    height: '30px',
                    width: '35px',
                    fontWeight: '500',
                    color: '#310085',
                  }}
                />

                {deliverableTitle(task)}
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  width: '225px',
                  height: '42px',
                  alignItems: 'center',
                }}
                onClick={() => {
                  if (expanded === task.id) {
                    setExpanded(false);
                    setAddTask(false);
                  } else {
                    setExpanded(task.id);
                  }
                  setGetAvatar(task.task_deliverable);
                }}
              >
                <ExpandMoreIcon
                  sx={{
                    height: '30px',
                    width: '35px',
                    fontWeight: '500',
                    color: '#310085',
                  }}
                />

                {deliverableTitle(task)}
              </div>
            )}

            <EditIconButton
              toolTipTitle="Edit Deliverable"
              width="20px"
              onClick={() => {
                setSelectedView(task);
                setShow(show ? false : task?.id);
                setIsTyping(true);
              }}
            />

            <button
              variant="success"
              className="btn-task"
              style={{ height: '42px' }}
            >
              <span className="number-of-task">
                {Array.isArray(rowsForCurrentMonth) &&
                rowsForCurrentMonth.length
                  ? rowsForCurrentMonth.length
                  : 0}
              </span>{' '}
              Tasks
            </button>
          </div>

          <div
            className="span-due"
            style={{
              width: '17%',
              height: '42px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              padding: '7px 10px',
            }}
          >
            <div
              onClick={() => {
                setId(task);
                setIsTyping(true);
                setValue1(null);
                setDueDate(dayjs(task.due_date));
              }}
              style={{ borderBottom: '1px dashed gray', width: '100%' }}
            >
              DUE ON{' '}
              {task.due_date
                ? new Date(task.due_date)
                    .toLocaleDateString('en-US', options)
                    .toUpperCase()
                : ''}
            </div>
          </div>
          {expanded !== task?.id ? (
            <Box
              sx={{
                boxSizing: 'border-box',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '7px',
                height: '42px',
                border: '1px solid #D9D9D9',
                borderRadius: '8px',
                width: '36%',
                backgroundColor: '#F8F9FA',
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  padding: '7px 8px',
                  background: '#F8F9FA',
                  gap: '10px',
                  height: '100%',
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '12px',
                    width: '100%',
                  }}
                  className="span"
                >
                  Level of Effort (LOE)
                </Typography>
                <ProgressBar
                  sx
                  now={worked_hour}
                  className={getVariant(worked_hour, total_hour, 'hour')}
                  min={0}
                  max={total_hour}
                />

                <Typography
                  className="span"
                  sx={{
                    fontWeight: '600',
                    width: '100%',
                    textAlign: 'center',
                  }}
                >
                  {totalWorkedHourPerWorkType} <i>/</i> {Math.round(total_hour)}
                  :00
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                boxSizing: 'border-box',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '7px',
                height: '42px',
                borderRadius: '8px',
                width: '340px',
                backgroundColor: '#F8F9FA',
              }}
            >
              {commentLoader ? (
                <Skeleton sx={{ width: '100%', height: '100%' }} />
              ) : (
                <Box
                  onClick={handleDeliverableShow}
                  sx={{
                    alignItems: 'center',
                    padding: '7px 8px',
                    background: '#F8F9FA',
                    gap: '10px',
                    height: '100%',
                    borderRadius: '8px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <span
                      style={{
                        backgroundColor: comments?.filter(
                          (message) =>
                            !message?.read_message?.some(
                              (item) => item?.read_by === userData.id,
                            ),
                        )?.length
                          ? mentionsLength?.length
                            ? '#00E6B8'
                            : '#E1D0FF'
                          : '#e3e3e3',
                        border: '2px solid #E9ECEF',
                        fontSize: '12px',
                        marginLeft: '4px',
                        color: mentionsLength?.length ? '#fff' : '#222831',
                        padding: '1px 6px',
                        borderRadius: '50%',
                      }}
                    >
                      {Array.isArray(comments) && comments.length
                        ? comments?.filter(
                            (message) =>
                              !message?.read_message?.some(
                                (item) => item?.read_by === userData.id,
                              ),
                          )?.length
                        : 0}
                    </span>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: 600,
                        marginLeft: '4px',
                      }}
                      className="span"
                    >
                      {' '}
                      Unread{' '}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      borderLeft: '1px solid gray',
                      paddingLeft: '36px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <span
                      style={{
                        backgroundColor: '#e3e3e3',
                        color: '#222831',
                        border: '2px solid #E9ECEF',
                        fontSize: '12px',
                        marginLeft: '5px',
                        padding: '1px 6px',
                        borderRadius: '50%',
                      }}
                    >
                      {Array.isArray(comments) && comments.length
                        ? comments?.length
                        : 0}
                    </span>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: 600,
                        marginLeft: '4px',
                      }}
                      className="span"
                    >
                      {' '}
                      COMMENTS{' '}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          )}

          <Box
            sx={{
              width: '1px',
              height: '48px',
              backgroundColor: '#c9c9c9',
              borderRadius: '8px',
              marginLeft: '10px',
            }}
          />

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '0.12rem 1.44rem',
              fontWeight: '600',
              fontSize: '0.8rem',
              color: '#711fff',
              height: '42px',
              width: '15%',
              borderRadius: '8px',
              textAlign: 'center',
            }}
          >
            DELIVERABLE COMPLETION: {percentageOfDeliverableCompletion || 0}%
          </Box>
        </Box>
      </AccordionSummary>

      {show && (
        <DetailView
          expanded={expanded}
          p={task}
          show={show}
          setShow={setShow}
          dileverable={dileverable}
          setDeliverable={setDeliverable}
          setIsDetOpen={setIsDetOpen}
          setIsTyping={setIsTyping}
          index2={index}
          setSelectedView={setSelectedView}
          selectedView={selectedView}
          formData={formData}
          setFormData={setFormData}
        />
      )}

      <AccordionDetails>
        <hr />
        <Grid
          container
          alignItems="flex-start"
          justifyContent="space-between"
          sx={{
            padding: '1.88rem 0.44rem',
            border: '1px solid #d9d9d9',
            borderRadius: '8px',
          }}
        >
          <Grid
            item
            container
            direction="column"
            alignItems="flex-start"
            xl={6}
            lg={6}
            md={6}
            sm={6}
            sx={{ gap: '1rem' }}
          >
            <div className="summery">
              <div className="inner-summery">
                <span
                  style={{ color: '#711fff', fontWeight: 'bold' }}
                  className="span"
                >
                  Level of Effort(LOE)
                </span>
                <NetProgressProjectLOE
                  eta={Math.round(total_hour)}
                  obt={worked_hour ?? 0} //Math.round(worked_hour ?? 0)}
                />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    gap: '0rem',
                  }}
                >
                  Hours
                </div>
              </div>
            </div>

            <div className="summery">
              <div style={{ padding: '15px' }} className="inner-summery">
                <span
                  style={{ color: '#711fff', fontWeight: 'bold' }}
                  className="span"
                >
                  Timeline
                </span>
                <NetProgressProjectLOE
                  eta={Math.round(totalDate)}
                  obt={Math.round(progressDate ?? 0)}
                  startDate={currentDate.format('MMM DD')}
                  endDate={endDate.format('MMM DD')}
                />

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    gap: '0rem',
                  }}
                >
                  Due
                </div>
              </div>
            </div>
          </Grid>
          <Box
            sx={{
              width: '1px',
              backgroundColor: 'rgba(0,0,0,0.4)',
              height: '116px',
            }}
          />
          <Grid
            container
            item
            direction="column"
            alignItems="flex-start"
            xl={5}
            lg={5}
            md={5}
            sm={5}
          >
            <List sx={{ width: '100%' }}>
              <Typography
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '1rem',
                  color: '#711fff',
                  fontWeight: '600',
                }}
              >
                WORK TYPE PROGRESS
              </Typography>
              {ourWorkTypes.map((item, index) => {
                return (
                  <ListItem
                    key={index}
                    sx={{
                      padding: 0,
                      paddingBottom: '8px',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    {item.name ? item.name : ''}
                    <VarPro meta={item} dil={dil} />
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        </Grid>
        <InnerTable
          rows={rowsForCurrentMonth}
          setRows={setRowsForCurrentMonth}
          currentMonthIndex={currentMonthIndex}
          realComments={comments}
          setRealComments={setComments}
          task={task}
          workTypes={ourWorkTypes}
          deliverable={tasks}
          proId={task.pro_id}
          setDetOpen={setDetOpen}
          setIsDetOpen={setIsDetOpen}
          currentDil={task}
          setIsTyping={setIsTyping}
          setProjectFormData={setFormData}
          setDeliverable={setDeliverable}
          unique_id={unique_id}
          id={formData.id}
          setDropDown={setDropDown}
          dropDown={dropDown}
          setFormData={setFormData}
          SetDltingTask={SetDltingTask}
          dltingTask={dltingTask}
          ourWorkTypes={ourWorkTypes}
          access_token={access_token}
          getData={getData}
          setAddTask={setAddTask}
          addTask={addTask}
          formData={formData}
          setShowDesc={setShowDesc}
          showDesc={showDesc}
          html={html}
          setHtml={setHtml}
          setShowName={setShowName}
          showName={showName}
          setShowOfTask={setShowOfTask}
          showofTask={showofTask}
          setCurrentTargetTask={setCurrentTargetTask}
          currentTargetTask={currentTargetTask}
        />
      </AccordionDetails>
    </Accordion>
  );
};

const CreateAccordion = ({
  status,
  tasks,
  dileverable,
  setDeliverable,
  args,
  setDetOpen,
  setIsDetOpen,
  formData,
  setFormData,
  setIsTyping,
  isTyping,
  unique_id,
  id,
  setId,
  SetDltingTask,
  dltingTask,
  access_token,
  getData,
  setAddTask,
  addTask,
  show,
  setShow,
  setDueDate,
  currentMonthIndex,
  setDelivChatShow,
  delivChatShow,
  deliverableChat,
  setDeliverableChat,
  ChannelList,
  setShowOfTask,
  showofTask,
  comments,
  setComments,
  setCurrentTargetTask,
  currentTargetTask,
}) => {
  const [expanded, setExpanded] = useState(false);

  const [selectedView, setSelectedView] = useState();
  const [dropDown, setDropDown] = useState(false);
  const [getAvatar, setGetAvatar] = useState();
  const [showName, setShowName] = useState(false);
  const [value1, setValue1] = useState([null, null]);
  const [showDesc, setShowDesc] = useState(false);
  const [dateRange, setDateRange] = useState(false);
  const [html, setHtml] = useState(false);
  const [commentLoader, setComentLoader] = useState(false);

  return (
    <>
      {Array.isArray(tasks) && tasks?.length
        ? tasks?.map((task, index) => (
            <CustomRow
              status={status}
              key={uuid()}
              task={task}
              expanded={expanded}
              setExpanded={setExpanded}
              setShow={setShow}
              show={show}
              setShowDesc={setShowDesc}
              showDesc={showDesc}
              tasks={tasks}
              dileverable={dileverable}
              setDetOpen={setDetOpen}
              setIsDetOpen={setIsDetOpen}
              setDeliverable={setDeliverable}
              formData={formData}
              setFormData={setFormData}
              setIsTyping={setIsTyping}
              isTyping={isTyping}
              index={index}
              setSelectedView={setSelectedView}
              selectedView={selectedView}
              unique_id={unique_id}
              setDropDown={setDropDown}
              dropDown={dropDown}
              setGetAvatar={setGetAvatar}
              value1={value1}
              setValue1={setValue1}
              id={id}
              setId={setId}
              dateRange={dateRange}
              setDateRange={setDateRange}
              SetDltingTask={SetDltingTask}
              dltingTask={dltingTask}
              access_token={access_token}
              getData={getData}
              setAddTask={setAddTask}
              addTask={addTask}
              html={html}
              setHtml={setHtml}
              setShowName={setShowName}
              showName={showName}
              setDueDate={setDueDate}
              currentMonthIndex={currentMonthIndex}
              setDelivChatShow={setDelivChatShow}
              delivChatShow={delivChatShow}
              deliverableChat={deliverableChat}
              setDeliverableChat={setDeliverableChat}
              ChannelList={ChannelList}
              setShowOfTask={setShowOfTask}
              showofTask={showofTask}
              setCurrentTargetTask={setCurrentTargetTask}
              currentTargetTask={currentTargetTask}
              setComments={setComments}
              comments={comments}
              commentLoader={commentLoader}
              setComentLoader={setComentLoader}
            />
          ))
        : null}
    </>
  );
};
export { getVariant, CreateAccordion };
