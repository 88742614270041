import React, { useState, useEffect } from 'react';

import Offcanvas from 'react-bootstrap/Offcanvas';
import { Box, Typography } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ENDPOINT } from '../../pages/Opportunity/Redux/oppor';

export default function HelpVideo({ show, setShow }) {
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const lastSegment = pathSegments[pathSegments.length - 1];
  const access_token = useSelector((state) => state.auth.user.access);

  const [helpVideosScreen, setHelpVideosScreen] = useState();
  const [selectedVideoScreen, setselectedVideoScreen] = useState({});

  const getVideosScreen = async () => {
    const response = await fetch(
      `${ENDPOINT}/api/netnetu/help-video-screen-list/`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${access_token}`,
        },
      },
    );

    const data = await response.json();
    if (data) {
      setHelpVideosScreen(data);
    }

    return data;
  };

  useEffect(() => {
    getVideosScreen();
  }, []);

  const extractVideoId = (url) => {
    const match = url?.match(
      // eslint-disable-next-line
      /(?:\?v=|\/embed\/|\/\d\d\/|\/vi\/|\/v\/|https:\/\/www.youtube.com\/watch\?v=|https:\/\/youtu.be\/)([^#\&\?]*).*/,
    );
    return match && match[1].length === 11 ? match[1] : false;
  };

  // Function to construct the thumbnail URL
  const constructThumbnailUrl = (videoId, quality = 'maxresdefault') => {
    return `https://img.youtube.com/vi/${videoId}/${quality}.jpg`;
  };

  // eslint-disable-next-line
  function handleRenderThumbnail(youtubeURL) {
    const videoId = extractVideoId(youtubeURL);
    if (videoId) {
      const thumbnailUrl = constructThumbnailUrl(videoId, 'maxresdefault');
      return thumbnailUrl;
    }
  }

  const [updatedData, setGetUpdatedData] = useState();

  useEffect(() => {
    const getUpdatedData =
      Array.isArray(helpVideosScreen) &&
      helpVideosScreen.length &&
      helpVideosScreen?.find((item) => item?.title === lastSegment);
    setGetUpdatedData(getUpdatedData);
    const firstVideoInScreen = getUpdatedData;
    setselectedVideoScreen(
      firstVideoInScreen?.help_video?.length &&
        firstVideoInScreen?.help_video[0],
    );
  }, [helpVideosScreen, lastSegment]);

  const formatMinutes = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const seconds = 0;

    return `${hours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <Offcanvas
      show={show}
      onHide={() => setShow(false)}
      scroll={false}
      backdrop
      placement="bottom"
      style={{
        height: 'max-content',
        zIndex: 99999999,
        maxHeight: '700px',
      }}
    >
      <button
        type="button"
        aria-label="Close Modal"
        className="close-modal"
        onClick={() => setShow(false)}
      />
      <Offcanvas.Header>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '95%',
            margin: '0 auto',
          }}
        >
          <Typography
            sx={{
              fontFamily: "'Open Sans', sans-serif",
              fontWeight: '600',
              fontSize: '2rem',
            }}
          >
            Lesson
          </Typography>
        </Box>
      </Offcanvas.Header>

      <Offcanvas.Body>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            gap: '1rem',
          }}
        >
          <Box sx={{ width: '65%' }}>
            {selectedVideoScreen ? (
              <Box>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    position: 'relative',
                    background: 'black',
                  }}
                >
                  <iframe
                    title=" "
                    src={selectedVideoScreen.youtube_link}
                    frameBorder="8"
                    style={{
                      borderRadius: '10px',
                      width: '1200px',
                      height: '450px',
                    }}
                  />
                </Box>

                <Box sx={{ width: '95%', margin: '0 auto' }}>
                  <Typography
                    sx={{
                      fontFamily: "'Open Sans', sans-serif",
                      fontWeight: '600',
                      fontSize: '1.5rem',
                      color: '#000',
                      marginTop: '1.5rem',
                    }}
                  >
                    {selectedVideoScreen.title}
                  </Typography>

                  <p
                    // eslint-disable-next-line
                    dangerouslySetInnerHTML={{
                      __html: selectedVideoScreen?.description,
                    }}
                  />
                </Box>
              </Box>
            ) : // );
            // })
            null}
          </Box>

          <Box sx={{ width: '32%' }}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                background: '#f7f7f7',
                maxHeight: '420px',
                minHeight: '420px',
                overflowY: 'auto',
                borderRadius: '8px',
                gap: '1rem',
              }}
            >
              <Box sx={{ width: '85%', margin: '0 auto' }}>
                <Typography
                  sx={{
                    fontFamily: "'Open Sans', sans-serif",
                    fontWeight: '600',
                    fontSize: '1.5rem',
                    color: '#711fff',
                    marginTop: { xl: '1.5rem', lg: '1rem', md: '1rem' },
                  }}
                >
                  SEE ALSO ON {updatedData?.title}
                </Typography>
                {Array.isArray(updatedData?.help_video) &&
                updatedData?.help_video?.length
                  ? updatedData?.help_video
                      ?.filter((item) => item.id !== selectedVideoScreen.id)
                      .map((item) => (
                        <React.Fragment key={item?.id ?? item?.youtube_link}>
                          <Box
                            sx={{
                              width: '100%',
                              height: '120px',
                              position: 'relative',
                              borderStartEndRadius: '8px',
                              borderStartStartRadius: '8px',
                              backgroundcolor: 'black',
                              backgroundImage: `url(${handleRenderThumbnail(
                                item?.youtube_link,
                              )})`,
                              backgroundSize: 'cover',
                              backgroundPosition: 'center',
                            }}
                          >
                            <iframe
                              title=" "
                              src={item.youtube_link}
                              frameBorder="8"
                              style={{
                                borderRadius: '10px',
                                width: '100%',
                                height: '180px',
                              }}
                            />
                            <p
                              style={{
                                position: 'absolute',
                                bottom: '1px',
                                right: '1px',
                                padding: '3px 5px',
                                backgroundColor: 'rgba(0,0,0,0.7)',
                                color: '#fff',
                                zIndex: 9999,
                                borderRadius: '5px',
                              }}
                            >
                              {formatMinutes(item.duration)}
                            </p>
                          </Box>
                          <Box
                            sx={{
                              width: '100%',
                              height: '90px',
                              padding: '10px 13px',
                              background: '#f2ebff',
                              borderEndEndRadius: '8px',
                              borderEndStartRadius: '8px',
                            }}
                          >
                            <p
                              onClick={() => setselectedVideoScreen(item)}
                              style={{
                                fontWeight: 'bold',
                                fontSize: '18px',
                                color: '#000',
                              }}
                            >
                              {item.title}
                            </p>
                            <p
                              style={{
                                fontSize: '13px',
                                color: 'GrayText',
                              }}
                              // eslint-disable-next-line
                              dangerouslySetInnerHTML={{
                                __html: item.description,
                              }}
                            />
                          </Box>
                        </React.Fragment>
                      ))
                  : null}
              </Box>
            </Box>
            <Box
              sx={{
                padding: '1rem 1.5rem',
                display: 'flex',
                alignItems: 'center',
                background: '#f2ebff',
                marginTop: '1rem',
                width: 'fit-content',
                borderRadius: '8px',
                gap: '1rem',
              }}
            >
              <SchoolIcon />{' '}
              <Typography sx={{ fontSize: '16px', fontFamily: 'Open Sans' }}>
                Visit Lessons
              </Typography>
            </Box>
          </Box>
        </Box>
      </Offcanvas.Body>
    </Offcanvas>
  );
}
