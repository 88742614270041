import { React, useEffect, useState } from 'react';
import { Alert, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Dots from '../assets/Dots.png';
import Gear from '../assets/Gear.png';
import { ENDPOINT } from '../../Opportunity/Redux/oppor';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import {
  NetNetSwitch,
} from '../../../Componenets/Misc';
import Slider from '@mui/material/Slider';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import { ViewMode } from 'gantt-task-react';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { getTemplates } from '../../User/redux';
import { useDispatch } from 'react-redux';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Shake from 'react-reveal/Shake';
import PFirst from './PFirst';
import PThird from './PThird';
import PFourth from './PFourth';
import PFifth, { ActiveProjectAlert } from './PFifth';
import helpVid from '../../Opportunity/assets/Section_Help-idle.svg'
import dayjs from 'dayjs';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { PreLoading } from '../../Contacts/People/EditContact';
import { getProjects } from '../proRedux';
import NewProjectHelpVideos from './NewProjectHelpvideos';

const step = [
  {
    label: 'Summary',
  },

  // {
  //   label: 'Collaboration',
  // },
  {
    label: 'Level of Efforts',
  },
  {
    label: 'Timeline',
  },

  {
    label: 'Net Net',
  },
];

const VerticalLinearSteppe = ({ setSteps, steps, isDetOpen, formData }) => {

  const [activeStep, setActiveStep] = useState(0);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [show, setShow] = useState(true);
  const [showChatEst, setShowChatEst] = useState(false);

  let stepsNums = 0

  return isDetOpen ? (
    <Box sx={{ zIndex: 99 }}>
      <Box
        className="firstMenu"
        sx={{
          backgroundColor: 'white',
          padding: '1rem 0rem',
          borderRadius: '10px',
          boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.5);',
          display: 'flex',
          jusitfyContent: 'center',
          position: 'fixed',
          top: '17%',
          opacity: show ? 1 : 0,
          zIndex: show ? 99 : 1,
          transition: 'opacity 0.3s ease-in-out',
          height: show ? 'auto' : '0',
        }}
      >
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          sx={{ margin: '0 auto', width: '90%' }}
        >
          {step.map((step, index) => {
            if (formData.project_type && formData?.project_type === 'retainer') {
              if (index === 2) {
                return;
              }
            }
            return (
              <Step key={step.label}>
                <button
                  onClick={() => {
                    if (index + 1 <= steps) {
                      setSteps(index + 1);
                    }
                  }}
                  style={{
                    gap: 10,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    borderRadius: '10px',
                    border: 'none',
                    backgroundColor:
                      steps === index + 1
                        ? '#cbb6fc'
                        : steps > index + 1
                          ? '#7323fa'
                          : '#f6f5f7',
                    height: '40px',
                    cursor: 'pointer',
                    paddingLeft: '8px',
                  }}
                >
                  <CheckIcon
                    style={{
                      color:
                        steps === index + 1
                          ? '#af8dfc'
                          : steps > index + 1
                            ? 'white'
                            : '#efe8ff',
                    }}
                  />
                  <Typography
                    style={{
                      backgroundColor: 'white',
                      borderRadius: '20px',
                      padding: '1px 3px',
                      fontSize: '12px',
                    }}
                  >
                    {++stepsNums}
                  </Typography>
                  <Typography
                    sx={{
                      color:
                        steps === index + 1
                          ? '#171717'
                          : steps > index + 1
                            ? 'white'
                            : '#575656',
                      fontSize: '11.5px',
                      fontWeight: '500',
                    }}
                  >
                    {step.label}
                  </Typography>
                </button>
              </Step>
            )

          })}
        </Stepper>
      </Box>
      <Box
        sx={{
          marginLeft: '5px',
          position: 'fixed',
          top: '17%',
        }}
        className="secondMenu"
      >
        <p
          onClick={() => setShow(!show)}
          style={{
            cursor: 'pointer',
            writingMode: 'vertical-rl',
            textOrientation: 'revert-layer',
            padding: '15px 2px',
            backgroundColor: show ? '#ac7cfc' : '#c6a7fa',
            borderStartEndRadius: screenSize > 1200 && '12px',
            borderTopRightRadius: screenSize > 1200 && '12px',
            borderEndEndRadius: screenSize < 1200 && '12px',
            borderEndStartRadius: screenSize < 1200 && '12px',
            color: show ? 'white' : 'black',
            letterSpacing: '0.1rem',
          }}
        >
          PROJECT INFO
        </p>
        <Box
          sx={{
            padding: '15px 2px',
            fontSize: '10px',
            color: 'white',
            backgroundColor: '#ac7cfc',
            borderStartStartRadius: screenSize < 1200 && '12px',
            borderTopRightRadius: screenSize > 1200 && '12px',
            borderEndEndRadius: screenSize > 1200 && '12px',
            borderEndStartRadius: screenSize < 1200 && '12px',
            marginTop: '5px',
            width: screenSize < 1200 ? '23px' : '27px',
            cursor: 'pointer',
          }}
          onClick={() => setShowChatEst(!showChatEst)}
        >
          <TextsmsOutlinedIcon color="white" />
        </Box>
      </Box>
    </Box>
  ) : null
};

const EstHeader = ({
  dileverable,
  setDeliverable,
  formData,
  openCanva,
  setOpenCanva,
  screenSize,
  showAllTasks,
  setShowAllTasks,
  setShowAllDescription,
  showAllDescription
}) => (
  <Box
    style={{
      border: '1px solid #D9D9D9',
      padding: '0.7rem .5rem',
      borderRadius: '8px',
      width: { xl: '42%', lg: '50%', sm: '55%', md: '60%' },
      backgroundColor: '#e7deff',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      height: '100px',
      marginRight: '1.3rem',
      boxShadow: '1px 1px 1.5px rgba(0, 0, 0, 0.3)',
    }}
  >
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: '10px',
        justifyContent: 'start',
        alignItems: 'center',
        gap: '.8rem',
      }}
    >
      <button
        className="netnetc-btn-act2"
        onClick={(e) => {
          e.preventDefault();
          setOpenCanva(true);
        }}
      >
        Deliverable Templates
      </button>
      <Box sx={{ marginLeft: '30px' }}>
      </Box>
    </Box>{' '}
    <Box
      style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }}
    >
      <NetNetSwitch onChange={() => {
        setShowAllTasks(!showAllTasks)
      }} label={screenSize < 720 ? 'Tasks' : 'Show all Tasks'} />
      <NetNetSwitch
        onChange={(e) => {
          if (showAllDescription) {
            setShowAllDescription(false)

          }
          else {
            setShowAllDescription(true)

          }
        }}
        label={screenSize < 720 ? 'Description' : 'Show all Description '}
      />
    </Box>
  </Box>
);

const DilTemplate = ({
  dileverable,
  setDeliverable,
  setOpenCanva,
  openCanva,
  formData,
  setFormData,
  setSelectedWorkType
}) => {
  const [tableData, setTableData] = useState([]);
  const access_token = useSelector((state) => state.auth.user.access);
  const dispatch = useDispatch();
  const realTemplates = useSelector((state) => state.Misc.mainTemplates);
  useEffect(() => {
    dispatch(getTemplates({ access_token }));
  }, []);


  useEffect(() => {
    if (Array.isArray(realTemplates) && realTemplates.length) {
      setTableData(realTemplates);
    }
  }, [tableData]);

  const [selected, setSelected] = useState([]);

  function removeDuplicateNames(array) {
    const uniqueNamesMap = new Map();

    array.forEach(item => {
      const { name, hours, id } = item;
      if (!uniqueNamesMap.has(name)) {
        uniqueNamesMap.set(name, { name, hours, id });
      }
    });
    return Array.from(uniqueNamesMap.values());
  }

  return (
    <Offcanvas
      placement="bottom"
      show={openCanva}
      onHide={() => setOpenCanva(false)}
    >
      <Offcanvas.Header>
        <button
          className="close-modal"
          onClick={() => setOpenCanva(false)}
        ></button>{' '}
      </Offcanvas.Header>

      <Offcanvas.Body>
        <Grid container direction="column">
          <Grid item container justifyContent={'flex-end'} alignItems="center">
            <button
              style={{
                background: '#c6a5ff',
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
                borderRadius: '8px',
                position: 'absolute',
                top: '7%',
                right: '20px',
                paddingRight: '20px',
                marginTop: "1rem"
              }}
              className="noBorder"
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                  padding: '0.88rem 1.44rem',
                  borderRight: '1px solid #000',
                  color: 'black',
                  margintop: "1rem"
                }}
                onClick={async () => {
                  const allDeliverables = dileverable.concat(selected);
                  const consolidated = allDeliverables.map((deliverable) => {
                    const existingWorktypeNames = deliverable.deliverable_worktype.map(
                      (worktype) => worktype.name
                    );

                    const missingWorktypes = allDeliverables
                      .filter((otherDeliverable) => otherDeliverable !== deliverable)
                      .flatMap((otherDeliverable) =>
                        otherDeliverable.deliverable_worktype
                          .filter((worktype) => !existingWorktypeNames.includes(worktype.name))
                          .map((missingWorktype) => ({
                            name: missingWorktype.name,
                            hours: 0, // Set hours to zero for missing worktypes,
                            id: missingWorktype.id
                          }))
                      );

                    const updatedWorktypes = [
                      ...deliverable.deliverable_worktype,
                      ...missingWorktypes,
                    ];

                    return { ...deliverable, deliverable_worktype: updatedWorktypes };
                  });

                  // Iterate through initialDeliverables and remove duplicate names

                  const updatedDeliverables = consolidated.map(deliverable => ({
                    ...deliverable,
                    deliverable_worktype: removeDuplicateNames(deliverable.deliverable_worktype).sort(),

                  }));

                  var i = 0;
                  for (i; i < dileverable.length; i++) {
                    const response = await fetch(
                      `${ENDPOINT}/api/project/deliverable/delete/${dileverable[i].id}/`,
                      {
                        method: 'DELETE',
                        headers: {
                          Authorization: `JWT ${access_token}`,
                          'Content-Type': 'application/json',
                        },
                      }
                    );

                    if (response.ok) {

                    }
                    else {
                      const data = await response.json()

                      return
                    }
                  }
                  updatedDeliverables.forEach(deliverable => {
                    const deliverableWorktypes = deliverable.deliverable_worktype;

                    deliverable.task_deliverable?.forEach(taskDeliverable => {
                      const existingWorktypes = new Set(taskDeliverable.task_worktype.map(worktype => worktype.name));

                      deliverableWorktypes.forEach(deliverableWorktype => {
                        if (!existingWorktypes.has(deliverableWorktype.name)) {
                          const newTaskWorktype = {
                            name: deliverableWorktype.name,
                            id: deliverableWorktype.id,
                            hours: null,
                            // Other properties from deliverable_worktype that you want to include
                          };

                          // Add the new task worktype to the task worktypes array in the current task_deliverable
                          taskDeliverable.task_worktype.push(newTaskWorktype);

                          // Add the worktype name to the existing worktypes set
                          existingWorktypes.add({ name: deliverableWorktype.name, hours: '' });
                        }
                      });
                    });
                  });



                  for (var j = 0; j < updatedDeliverables.length; j++) {
                    delete updatedDeliverables[j].deliverable_dependency

                    const resCreate = await fetch(`${ENDPOINT}/api/project/deliverable/create/`, {

                      method: 'POST',
                      headers: {
                        Authorization: `JWT ${access_token}`,
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify([{
                        ...updatedDeliverables[j],
                        deliverable_project: updatedDeliverables[j].deliverable_project ?? Number(formData?.id),

                        deliverable_name: updatedDeliverables[j].deliverable_name,
                        description: updatedDeliverables[j].description ?? "",
                        sort_order: j + 1,
                        deliverable_worktype: updatedDeliverables[j].deliverable_worktype.map((i, n) => {
                          return {
                            name: i.name,
                            id: i.id,
                            hours: i.hours ? i.hours : null,
                          };
                        }).sort((a, b) =>
                          a?.name?.localeCompare(b.name)
                        ),
                        task_deliverable: Array.isArray(updatedDeliverables[j].task_deliverable) && updatedDeliverables[j].task_deliverable.length ?
                          updatedDeliverables[j].task_deliverable.map((tsk) => {
                            return (
                              {
                                name: tsk.task_name ? tsk.task_name : tsk.name,
                                task_worktype: tsk.task_worktype.map((wt_tsk) => {
                                  return (
                                    {
                                      id: wt_tsk.id,
                                      name: wt_tsk.name,
                                      hours: wt_tsk.hours ? wt_tsk.hours : 0,
                                    }
                                  )
                                }).sort((a, b) =>
                                  a?.name?.localeCompare(b.name)
                                ),
                              }
                            )
                          }) : [],
                        deliverable_startdate: formData.target_start ? formData.target_start : new Date().toISOString(),
                        deliverable_enddate: formData.target_start ? new Date(new Date(formData.target_start).setDate(new Date(formData.target_start).getDate() + 7)) : new Date(new Date().setDate(new Date().getDate() + 7)).toISOString(),
                      }]),

                    })

                    const data = await resCreate.json();
                    if (!resCreate.ok) {

                      return;
                    }
                    else {

                    }
                  }
                  const resGet = await fetch(`${ENDPOINT}/api/project/detail/${formData?.unique_id}/`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `JWT ${access_token}`,
                    },
                  });
                  const dataGet = await resGet.json();
                  if (!resGet.ok) {
                  }
                  setFormData(dataGet)
                  if (
                    dataGet &&
                    Array.isArray(dataGet.deliverable_project) &&
                    dataGet.deliverable_project.length
                  ) {
                    setDeliverable(dataGet.deliverable_project);
                  }
                  setOpenCanva(false)
                }}
              >
                <ContentCopyIcon sx={{ marginRight: "4px" }} />
                Add To Project
              </Box>
              <Box>
                {Array.isArray(selected) && selected.length
                  ? selected.length + ' ' + 'Selected'
                  : null}
              </Box>
            </button>
          </Grid>
          <Grid item container spacing={5}>
            {Array.isArray(tableData) && tableData.length ? (
              tableData.map((e) => {
                const disrapancy = selected.findIndex(
                  (ie) => Number(ie?.id) === Number(e?.deliverable[0]?.id)
                );
                return (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={3}
                    xxl={2}
                    onClick={() => {
                      if (disrapancy !== -1) {
                        setSelected((prev) =>
                          prev.filter((item) => !prev[disrapancy])
                        );
                      } else {
                        setSelected([...selected, e.deliverable[0] ?? e.deliverable[0]]);
                      }
                    }}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'white',
                        borderRadius: '8px',
                        padding: '1rem',
                        alignItems: 'center',
                        border: '1px solid lightGray',
                        borderTop: 0,
                        borderLeft: 0,
                        cursor: 'pointer',
                      }}
                    >
                      <ul
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: '0.6rem',
                          marginBottom: '1rem',
                          backgroundColor:
                            disrapancy !== -1 ? '#c6a5ff' : '#f1f3f5',
                          borderRadius: '8px',
                          padding: '0.5rem',
                        }}
                      >
                        <li
                          style={{
                            paddingLeft: '0px',
                            marginBottom: '0px',
                          }}
                        >
                          <h5
                            style={{
                              marginBottom: '0px',
                            }}
                            key={e?.id}
                          >
                            {' '}
                            {e?.name}
                          </h5>
                          <p
                            style={{
                              fontSize: '12px',
                              marginBottom: '0px',
                              color: '#8743fd',
                            }}
                          >
                            {' '}
                            {e?.deliverable[0]?.task_deliverable?.length} Tasks
                          </p>
                        </li>
                      </ul>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-around',
                          backgroundColor: '#e4ddf0',
                          borderRadius: ' 8px',
                          height: '2rem',
                          width: '7rem',
                          color: 'black',
                        }}
                      >
                        <AccessTimeOutlinedIcon
                          style={{
                            fontSize: '1.3rem',
                          }}
                        />
                        <div>
                          <span
                            style={{
                              fontSize: '0.9rem',
                            }}
                          >
                            {' '}
                            {e.deliverable[0]?.hours}
                          </span>{' '}
                          <span
                            style={{
                              fontSize: '0.9rem',
                            }}
                          >
                            Hours{' '}
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          backgroundColor: '#f8f9fa',
                          borderRadius: '8px',
                          padding: '4px',
                          marginTop: '1rem',
                        }}
                      >
                        <h6
                          style={{
                            marginBottom: '0px',
                          }}
                        >
                          Description:
                        </h6>
                        <p
                          style={{
                            marginBottom: '0px',
                          }}
                          dangerouslySetInnerHTML={{
                            __html: e?.deliverable[0]?.description ? e?.deliverable[0]?.description : '<p> </p>',
                          }}
                        ></p>
                      </div>
                    </Box>
                  </Grid>
                );
              })
            ) : (
              <Grid
                container
                item
                sx={{ height: '100%', width: '100%', margin: '1rem 0' }}
                direction="column"
                alignItems={'center'}
                justifyContent="center"
              >
                <Alert severity="info">You do not have any Templates. </Alert>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

const NewProject = ({ projectDetail, setProjectDetail, screenHeight }) => {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [cache, setCache] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [steps, setSteps] = useState(1);
  const [tracker, setTracker] = useState(0);
  const [openCanva, setOpenCanva] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
  });

  const [tic, setTic] = useState(0);
  const access_token = useSelector((state) => state.auth.user.access);
  const [pic, setPic] = useState(null);
  const [view, setView] = useState('Month');

  const [temp, setTemp] = useState();

  const setErrorState = ({ error, message }) => {
    setError(error);
    setErrorMessage(message);
  };

  const handleThirdStep = () => {
    if (
      formData.deliverable_project &&
      formData.deliverable_project.length &&
      formData.deliverable_project.every((delivery) => {
        return (
          Array.isArray(delivery.deliverable_worktype) &&
          delivery.deliverable_worktype.length > 0
        );
      })
    ) {
      setSteps(formData.project_type === 'project' ? 3 : 4)
    } else {
      setErrorState({
        error: true,
        message:
          Array.isArray(formData.deliverable_project) && formData.deliverable_project.length
            ? 'Level of effort must be defined'
            : 'At least one Deliverable is required for an Estimate',
      });
    }
  };

  const handleFourthStep = async () => {
    setSteps(4);
  };

  const dispatch = useDispatch()
  const projects = useSelector((state) => state.projects.projects)

  useEffect(() => {
    const fetchData = async () => {

      const unique_id = projects?.find((est) => est?.title === formData?.title)?.unique_id;

      if (unique_id && !projectDetail) {
        try {
          const res = await fetch(`${ENDPOINT}/api/project/detail/${unique_id}/`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `JWT ${access_token}`,
            },
          });
          const data = await res.json();
          if (!res.ok) {

          }
          if (data && Array.isArray(data.deliverable_project) && data.deliverable_project.length) {
            setDeliverable(data.deliverable_project);
          }

          setFormData(data)
          setPreLoading(false);

          Array.isArray(formData.collaborators) && formData.collaborators.length
            ? setSteps(2)
            : setSteps(2);

        } catch (error) {
        }
      }
    };

    // Immediately fetch data when the component mounts
    fetchData()

  }, [projects]);


  const handleFirstStep = async () => {

    if (!formData.title) {
      setError(true);
      setErrorMessage('Estimate Name is Required.');
    }
    else if (!formData.work_type) {
      setError(true);
      setErrorMessage('Please Specify Work Types.');
    }
    else {
      setPreLoading(true)
      const JamDataa = {
        ...formData,
        project_deliverable: Array.isArray(dileverable) && dileverable.length ? dileverable.map((ti) => ({
          ...ti,
          loe_price: 0,
          user_price: 0,
          deliverable_multiplier: [],
          deliverable_worktype: ti.deliverable_worktype.map((wtt) => ({
            ...wtt,
            hours: wtt?.hours ? Number(wtt?.hours) : 0,
          })),
          task_deliverable: ti?.task_deliverable
            ? ti.task_deliverable.map((tk) => ({
              ...tk,
              task_name: tk?.name,
              task_worktypes: tk?.task_worktype.map((twt) => ({
                ...twt,
                hours: twt?.hours ? Number(twt?.hours) : 0,
              })),
            }))
            : [],
        })) : [],
        project_worktype: formData?.work_type.map((item, ind) => ({
          id: item.id,
          hours: 0,
        }))
      };

      delete JamDataa.work_type
      const response = await fetch(`${ENDPOINT}/api/project/create/`, {
        method: 'POST',
        headers: {
          Authorization: `JWT ${access_token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...JamDataa,
          target_start: JamDataa.target_start ? JamDataa.target_start : new Date().toISOString(),
          finish_date: JamDataa.finish_date ? JamDataa.finish_date : null,
          status: 'inactive',
          creation_wizard_completed: false,
        }),
      });
      const data = await response.json();
      if (!response.ok) {
        if (data?.title?.['Title Error']) {
          for (let i in data.title) {
            let msg = data[i]
            setErrorState({
              error: true,
              message: data && data?.title ? data?.title?.[i] : 'Could not Activate Your Project.',
            });
          }
        }
        else if (data?.['create project']){

          for (let i in data) {
            let msg = data[i]
            setAlertMessage(msg)
          }
          SetAlertShow(true)
        }
        else {
          for (let i in data) {

            let msg = data[i]
            setErrorState({
              error: true,
              message: data && msg ? msg : 'Could not Activate Your Project.',
            });
          }
        }
        setPreLoading(false)
        return
      }
      else {
        setSteps(2)
        dispatch(getProjects({ access_token }))
        setError(false);
        setErrorMessage('');
      }
    }
  };

  const handleFirstStepUpdate = async () => {

    const shallowCopy = {
      ...formData,
      project_worktype: formData.work_type.map((item, ind) => ({
        id: item.id,
        hours: 0,
      }))
    }
    delete shallowCopy.work_type

    const dileverableCopy = dileverable.map(item => ({ ...item }));

    const res = await fetch(
      `${ENDPOINT}/api/project/update/${formData.id}/`,
      {
        method: 'PUT',
        headers: {
          Authorization: `JWT ${access_token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...shallowCopy,
          company: shallowCopy.company,
          contact: shallowCopy.contact,
          members: Array.isArray(shallowCopy.members) && shallowCopy.members.length ? shallowCopy.members.map((item) => (item)) : [],
          target_start: shallowCopy.est_start_date ? shallowCopy.est_start_date : shallowCopy.target_start ? shallowCopy.target_start : new Date().toISOString(),
          finish_date: shallowCopy.est_finish_date ? shallowCopy.est_finish_date : shallowCopy.finish_date ? shallowCopy.finish_date : null,
          project_deliverable: Array.isArray(dileverableCopy) && dileverableCopy.length
            ? dileverableCopy.map((item, index) => {
              delete item.task_deliverable

              return {
                ...item,
                deliver_id: item.id,
                deliverable_worktype: item.deliverable_worktype.map((wtt, ind) => ({
                  ...wtt,
                  id: wtt.id ? wtt.id : '',
                  hours: wtt.hours ? wtt.hours : null,
                })),
              }
            })
            : [],
        }),

      }
    );
    const data = await res.json()
    if (!res.ok) {
      if (data?.title?.['Title Error']) {
        for (let i in data.title) {
          let msg = data[i]
          setErrorState({
            error: true,
            message: data && data?.title ? data?.title?.[i] : 'Could not Activate Your Project.',
          });
        }
      }
      else if (data?.['create project']){

        for (let i in data) {
          let msg = data[i]
          setAlertMessage(msg)
        }
        SetAlertShow(true)
      }
      else {
        for (let i in data) {
          let msg = data[i]
          setErrorState({
            error: true,
            message: data && msg ? msg : 'Could not Activate Your Project.',
          });
        } }
      return
    }
    else {
      setSteps(2)
    }
  }

  const handleSecondStep = () => {
    setSteps(3);
  };

  useEffect(() => {
    if (error) {

      setTimeout(() => {
        setErrorState({ error: false, message: '' });
      }, 3000);
    }
  }, [error]);


  const handleSubmit = () => {
    if (steps === 1) {
      !formData?.unique_id ? handleFirstStep() : handleFirstStepUpdate()
      // setSteps(3)
    }
    //  else if (steps === 2) {
    //   handleSecondStep();
    // } 
    else if (steps === 2) {
      handleThirdStep();
    }
    // else if (steps === 3) {
    //   handleThirdStep();
    // }
    else if (steps === 3) {
      handleFourthStep();
    }
    //  else if (steps === 4) {
    //   handleFourthStep();
    // }
  };

  const [dileverable, setDeliverable] = useState([]);
  const [showDescription, setShowDescription] = useState(false);
  const [companySelected, setCompanySelected] = useState(null);
  const [estimate_hardcost, setEstHardCost] = useState([]);

  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const start =
    formData && formData.target_start && formData.target_start?.endsWith('Z')
      ? formData.target_start.split('T')
      : null;
  const end =
    formData && formData.finish_date && formData.finish_date.endsWith('Z')
      ? formData.finish_date.split('T')
      : null;



  const [value1, setValue1] = useState([null, null]);
  const [day, setDay] = useState('This Month')
  const [dateRange, setDateRange] = useState(false);

  const handleDateRange = (v) => {
    setDateRange(v)

  }

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
    setValue1([null, null])
    setDateRange(false)
  };

  const handleClickDay = (v, i) => {
    setDay(v)

    if (v === 'Today') {
      const today = dayjs();
      setDateRange([today,])
    }
    else if (v === 'Yesterday') {
      const today = dayjs();
      setDateRange([today.subtract(1, 'day'),])
    }
    else if (v === 'This Week') {
      const today = dayjs();
      setDateRange([today.startOf('week'),])
    }
    else if (v === 'Past 2 Week') {
      const today = dayjs();
      const prevWeek = today.subtract(14, 'day');
      setDateRange([prevWeek.startOf('week'),]);

    }
    else if (v === 'This Month') {
      const today = dayjs();
      setDateRange([today.startOf('month'),])
    }
    else if (v === 'Last Month') {
      const today = dayjs();
      const startOfNextMonth = today.startOf('month').subtract(1, 'month');
      setDateRange([startOfNextMonth,]);

    }
    else if (v === 'This Year') {
      const today = dayjs();
      setDateRange([today.startOf('year'),])

    }
    else if (v === 'Last Year') {
      const today = dayjs();
      setDateRange([today.startOf('year').subtract(1, 'year'),])
    }
  }

  async function handleRangeChange() {
    const shallowCopy = {
      ...formData,
    };
    delete shallowCopy.work_type;
    delete shallowCopy.estimate_hardcost;


    const res = await fetch(
      `${ENDPOINT}/api/project/update/${formData.id}/`,
      {
        method: 'PUT',
        headers: {
          Authorization: `JWT ${access_token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...shallowCopy,
          target_start: endOrStart === 'start' ? new Date(new Date(dateRange)).toISOString() : formData?.target_start,
          finish_date: endOrStart === 'end' ? new Date(new Date(dateRange)).toISOString() : formData?.finish_date,
          project_deliverable: dileverable?.map(
            (item, index) => {

              const durationInMilliseconds = new Date(item.deliverable_enddate) - new Date(item.deliverable_startdate)
              const deliverable_duration = durationInMilliseconds / (24 * 60 * 60 * 1000)
              const dynamicChange = new Date(item.deliverable_startdate) - new Date(formData.target_start)
              const dynamic_duration = dynamicChange / (24 * 60 * 60 * 1000)
              delete item.deliverable_multiplier
              delete item.task_deliverable

              return {
                ...item,
                deliver_id: item.id,


                deliverable_startdate: endOrStart === 'start' ? new Date(new Date(dateRange).setDate(new Date(dateRange).getDate() + dynamic_duration)).toISOString() : item.deliverable_startdate,
                deliverable_enddate: endOrStart === 'start' ? new Date(new Date(dateRange).setDate(new Date(dateRange).getDate() + dynamic_duration + deliverable_duration)).toISOString() : item.deliverable_enddate,
                deliverable_worktype: item.deliverable_worktype.map(
                  (wtt) => ({
                    ...wtt,
                    hours: wtt.hours ? Number(wtt.hours) : null,
                  })
                ),
              }
            }),

        }),
      }
    );
    const data = await res.json()
    if (!res.ok) {

      return
    }
    else {
      const resGet = await fetch(`${ENDPOINT}/api/project/detail/${formData?.unique_id}/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${access_token}`,
        },
      });
      const dataGet = await resGet.json();
      if (!resGet.ok) {

        return;
      }
      setFormData(dataGet)
      setValue1(dateRange);
      setDateRange(false)
      if (
        dataGet &&
        Array.isArray(dataGet.deliverable_project) &&
        dataGet.deliverable_project.length
      ) {
        setDeliverable(dataGet.deliverable_project)
      }
    }
  }

  const [tat, setTat] = useState(0);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [preLoading, setPreLoading] = useState(false)



  useEffect(() => {
    const fetchData = async () => {
      if (projectDetail) {
        setPreLoading(true);
        try {
          const resGet = await fetch(`${ENDPOINT}/api/project/detail/${projectDetail?.unique_id}/`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `JWT ${access_token}`,
            },
          });
          if (!resGet.ok) {
            throw new Error('Error at Getting Estimates');
          }
          const dataGet = await resGet.json();
          setFormData(dataGet);
          setPreLoading(false);
          if (
            dataGet &&
            Array.isArray(dataGet.deliverable_project) &&
            dataGet.deliverable_project.length
          ) {
            setDeliverable(dataGet.deliverable_project);
          }
        } catch (error) {

          setPreLoading(false);
        }
      }
    };

    fetchData();

  }, [projectDetail]);

  const [selectedWorkType, setSelectedWorkType] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [selectedCollabarators, setSelectedCollabarators] = useState([]);
  const [isDetOpen, setIsDetOpen] = useState(true);
  const [AlertShow, SetAlertShow] = useState(false)
  const [AlertMessage, setAlertMessage] = useState('')
  const [showAllTasks, setShowAllTasks] = useState()
  const [endOrStart, setEndOrStart] = useState()
  const [show, setShow] = useState(false)
  const [showAllDescription, setShowAllDescription] = useState(false)
  return !preLoading ? (
    <>
      {
        AlertShow &&
        <ActiveProjectAlert SetAlertShow={SetAlertShow} AlertMessage={AlertMessage} setAlertMessage={setAlertMessage} />
      }
      <section className="oppor-section5">
        <VerticalLinearSteppe
          setSteps={setSteps}
          steps={steps}
          formData={formData}
          isDetOpen={isDetOpen}
        />
        <div className="oppor-row" style={{ width: '100%' }}>
          <div>
            <div className="oppor-col">
              <h3 className="fstxtPro">New Job</h3>
              <img className="icon-sm" src={Dots} alt="dots" />
              <img className="icon-sm" src={Gear} alt="gear" />
              <p style={{ fontSize: '13px', color: 'GrayText' }}>Filters</p>
              <img src={helpVid} onClick={() => setShow(true)} style={{ color: '#a6a6a6', marginLeft: '10px', width: '28px', height: '28px', cursor: 'pointer' }} />
            </div>
            {steps > 1 ? (
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                  alignItems: 'center',
                  justifyContent: 'start',
                }}
              >
                <KeyboardReturnIcon sx={{ transform: 'scaleX(-1)' }} />
                <p style={{ color: '#9257fa' }}>{formData.title}</p>
                {companySelected ? (
                  <p
                    style={{
                      padding: '5px 10px',
                      backgroundColor: '#ededed',
                      color: 'GrayText',
                      borderRadius: '8px',
                    }}
                  >
                    {companySelected}
                  </p>
                ) : null}
              </div>
            ) : null}
          </div>
          <div style={{ width: '60%' }}>
            {steps === 2 ? (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'end',
                }}
              >
                <EstHeader
                  dileverable={dileverable}
                  setDeliverable={setDeliverable}
                  formData={formData}
                  openCanva={openCanva}
                  setOpenCanva={setOpenCanva}
                  screenSize={screenSize}
                  setShowAllTasks={setShowAllTasks}
                  showAllTasks={showAllTasks}
                  setShowAllDescription={setShowAllDescription}
                  showAllDescription={showAllDescription}
                />
              </div>
            ) : null}

            {steps === 3 ? (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'end',
                }}
              >
                <Box
                  sx={{
                    border: '1px solid #D9D9D9',
                    padding: '0.7rem .5rem',
                    borderRadius: '8px',
                    width: { xl: screenSize < 1650 && screenSize > 1450 ? '59%' : '50%', lg: '75%', md: '80%', sm: '85%' },
                    backgroundColor: '#e7deff',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: '100px',
                    marginRight: '1.3rem',
                    boxShadow: '1px 1px 1.5px rgba(0, 0, 0, 0.3)',
                  }}
                >
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: '10px',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      color: 'gray',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        fontSize: '16px',
                        alignItems: 'center',
                        width: '80%',
                      }}
                    >

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px',
                          width: '100%',
                          justifyContent: 'space-around',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '5px',
                          }}
                        >
                          <CalendarMonthOutlinedIcon
                            sx={{
                              cursor: 'pointer',
                              fontSize: {
                                xl: '25px',
                                lg: '22px',
                                md: '20px',
                                sm: '20px',
                              },
                            }}
                            onClick={() => {
                              setDateRange(formData.target_start ? dayjs(formData.target_start) : new Date())
                              setEndOrStart('start')
                              setValue1(null)
                            }}
                          />
                          <Typography
                            sx={{
                              fontSize: {
                                xl: '14px',
                                lg: '13px',
                                md: '12px',
                                sm: '11px',
                              },
                            }}
                          >
                            Start:{' '}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: {
                                xl: '14px',
                                lg: '13px',
                                md: '12px',
                                sm: '11px',
                              },
                            }}
                            style={{ marginTop: '0px', fontWeight: 'normal' }}
                          >
                            {start && start[0]
                              ? new Date(start[0])?.toLocaleDateString('en-US', options)
                              : ''}
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '5px',
                          }}
                        >
                          <CalendarMonthOutlinedIcon
                            sx={{
                              cursor: 'pointer',
                              fontSize: {
                                xl: '25px',
                                lg: '22px',
                                md: '20px',
                                sm: '20px',
                              },
                            }}
                            onClick={() => {
                              setDateRange(formData.finish_date ? dayjs(formData.finish_date) : new Date())
                              setEndOrStart('end')
                              setValue1(null)
                            }}
                          />
                          <Typography
                            sx={{
                              fontSize: {
                                xl: '14px',
                                lg: '13px',
                                md: '12px',
                                sm: '11px',
                              },
                            }}
                          >
                            End:{' '}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: {
                                xl: '14px',
                                lg: '13px',
                                md: '12px',
                                sm: '11px',
                              },
                            }}
                            style={{ marginTop: '0px', fontWeight: 'normal' }}
                          >
                            {end && end[0]
                              ? new Date(end[0])?.toLocaleDateString('en-US', options)
                              : ''}
                          </Typography>
                        </div>
                      </div>

                      {
                        !value1 && (
                          <Box sx={{ position: 'absolute', top: '90%', right: 0, zIndex: '99999', background: '#fff' }}>
                            <Box align='center' sx={{ width: 'auto', height: 'auto', px: '30px', display: { xs: 'block', md: 'flex' }, }}>
                              <Box align='left' sx={{ backgroundColor: '#F8F9FA', width: { xs: '100%', md: '200px' }, p: 1, pb: 3, my: 4, borderRadius: 2 }}>
                                {
                                  ['Today', 'Yesterday', 'This Week', 'Past 2 Week', 'This Month', 'Last Month', 'This Year', 'Last Year'].map((v, i) => {
                                    return (
                                      <Typography key={i} onClick={() => handleClickDay(v, i)} sx={day === v ?
                                        { fontSize: '16px', fontWeight: 400, cursor: 'pointer', color: '#711FFF', my: 3, ml: 2 }
                                        :
                                        { fontSize: '16px', fontWeight: 400, cursor: 'pointer', color: '#03071E', my: 3, ml: 2 }
                                      }>{v}</Typography>
                                    )
                                  })
                                }

                              </Box>
                              <Box sx={{ mx: 4, my: 4 }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DateCalendar
                                    value={dayjs(dateRange)}
                                    onChange={(newValue) => handleDateRange(newValue?.$d)}
                                    sx={{
                                      '& .css-10wpov9-MuiTypography-root ': {
                                        fontWeight: '700 !important'
                                      },
                                      '& .css-cyzddl-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition:not(.MuiDateRangeCalendar-dayDragging) .MuiDateRangePickerDay-dayOutsideRangeInterval': {
                                        color: '#03071E !important',
                                        fontSize: '16px !important',
                                        fontWeight: 500,
                                      },
                                      '& .css-1gbl7yn-MuiDateRangePickerDay-root': {
                                        backgroundColor: '#310085 !important',
                                        color: 'white !important',
                                        borderRadius: '0px !important'
                                      },
                                      '& .css-1e841vg-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected': {
                                        backgroundColor: '#711FFF !important',
                                        color: "white !important",
                                        borderRadius: '0px !important',
                                        fontSize: '16px !important',
                                        fontWeight: 500
                                      },
                                      '& .css-1ckjanr-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected': {
                                        backgroundColor: '#711FFF !important',
                                        color: "white !important",
                                        borderRadius: '0px !important',
                                        fontSize: '16px !important',
                                        fontWeight: 500
                                      },
                                      '& .css-1u23akw-MuiButtonBase-root-MuiPickersDay-root.Mui-selected': {
                                        backgroundColor: '#711FFF !important',
                                        color: "white !important",
                                        borderRadius: '0px !important',
                                        fontSize: '16px !important',
                                        fontWeight: 500
                                      },
                                      '& .css-2ko3hu-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day': {
                                        backgroundColor: '#310085 !important',
                                        color: "white !important",
                                        opacity: 'inherit !important',
                                        borderRadius: '0px',
                                        border: 'none !important',
                                        fontSize: '16px !important',
                                        fontWeight: 500
                                      },
                                      '& .css-1ku4sqv-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day': {
                                        backgroundColor: '#711FFF !important',
                                        color: "white !important",
                                        borderRadius: '0px !important',
                                        fontSize: '16px !important',
                                        fontWeight: 500
                                      },
                                      '& .css-ahwqre-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:not(.Mui-selected)': {
                                        backgroundColor: '#310085 !important',
                                        color: "#e569db !important",
                                        borderRadius: '0px !important',
                                        fontSize: '16px !important',
                                        fontWeight: 500
                                      },
                                      '& .css-jef1b6-MuiDateRangeCalendar-container:not(:last-of-type)': {
                                        borderRight: 'none !important'
                                      },
                                      '& .css-3wduhr-Mu.iDateRangeCalendar-root': {
                                        flexDirection: 'column !important',
                                      },


                                      '& .css-grqin-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day': {
                                        color: 'inherit',
                                        fontSize: '16px !important',
                                        opacity: 'inherit',
                                        fontWeight: 500
                                      },
                                      '& .css-1kex3oi-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:not(.Mui-selected)': {
                                        backgroundColor: 'inherit',
                                        color: "black !important",
                                        borderRadius: '50%',
                                        border: "1px solid gray",
                                        fontSize: '16px',
                                        fontWeight: 500
                                      },
                                      '& .MuiTypography-subtitle1': {
                                        color: " #03071E !important",
                                        fontSize: '16px',
                                        fontWeight: 700
                                      },
                                      '& .MuiDayCalendar-weekDayLabel': {
                                        color: " #03071ECC !important",
                                        fontSize: '16px',
                                        fontWeight: 400
                                      },
                                      '& .MuiSvgIcon-fontSizeInherit': {
                                        backgroundColor: 'black',
                                        color: 'white',
                                        borderRadius: '50%'
                                      },
                                      '& .MuiPickersDay-today': {
                                        backgroundColor: !dateRange && '#711FFF',
                                        color: !dateRange && 'white',
                                        borderRadius: !dateRange && '0px',
                                        fontSize: !dateRange && '16px',
                                        fontWeight: !dateRange && 500

                                      },
                                      '& .css-grqin-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected': {
                                        backgroundColor: '#711FFF',
                                        color: 'white',
                                        borderRadius: '0px',
                                        fontSize: '16px',
                                        fontWeight: 500
                                      },
                                      '& .css-gtjfra-MuiDateRangePickerDay-root': {
                                        backgroundColor: '#310085',
                                        color: 'white !important',
                                        borderRadius: '0px',
                                        fontSize: '16px',
                                        fontWeight: 500
                                      },
                                      '& .css-1i2r8k1-MuiDateRangePickerDay-root': {
                                        backgroundColor: '#310085',
                                        color: 'white !important',
                                        borderRadius: '0px',
                                        fontSize: '16px',
                                        fontWeight: 500
                                      },
                                    }}

                                  />
                                </LocalizationProvider>

                                <Box sx={{ display: 'flex', my: 4 }}>
                                  <Box sx={{ display: 'flex', mr: 4 }}>
                                    <Box sx={{ width: "20px", height: "20px", backgroundColor: "#711FFF", borderRadius: 2, mr: 2 }}></Box>
                                    <Typography sx={{ fontWeight: 400, fontSize: '14px' }}>Start Date and End Date</Typography>
                                  </Box>
                                  <Box sx={{ display: 'flex' }}>
                                    <Box sx={{ width: "20px", height: "20px", backgroundColor: "#310085", borderRadius: 2, mr: 2 }}></Box>
                                    <Typography sx={{ fontWeight: 400, fontSize: '14px' }}>Date Range</Typography>
                                  </Box>
                                </Box>

                              </Box>
                              <Box align='' my={3} sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "" }} >
                                <Box align='left' mt={4}>
                                  <Box align='left'>
                                    <Typography sx={{ color: '#03071E99', fontSize: '16px', fontWeight: 400 }}>Start Date</Typography>
                                    <Typography sx={{ color: '#03071E', fontSize: '16px', fontWeight: 400 }}> {endOrStart === 'start' ? new Date(dateRange).toLocaleDateString('en-US', options) : start && start[0]
                                      ? new Date(start[0])?.toLocaleDateString('en-US', options)
                                      : ''}</Typography>
                                  </Box>
                                  <Box sx={{ mt: 2 }}>
                                    <Typography sx={{ color: '#03071E99', fontSize: '16px', fontWeight: 400 }}>End Date</Typography>
                                    <Typography sx={{ color: '#03071E', fontSize: '16px', fontWeight: 400 }}> {endOrStart === 'end' ? new Date(dateRange).toLocaleDateString('en-US', options) : end && end[0]
                                      ? new Date(end[0])?.toLocaleDateString('en-US', options)
                                      : ''}</Typography>
                                  </Box>
                                </Box>


                                <Box align='right'>
                                  <Button variant='text' sx={{ textDecoration: 'inherit', color: 'black', width: { xs: 'auto', lg: '100px' }, mr: 1 }} onClick={handleClose}>Cancel</Button>
                                  <Button variant='contained' sx={{
                                    textDecoration: 'inherit', backgroundColor: "#711FFF", width: { xs: 'auto', lg: '100px' }, mr: 1,
                                    '&:hover': {
                                      backgroundColor: "#711FFF"
                                    }
                                  }}
                                    onClick={() => {
                                      handleRangeChange()
                                    }}
                                  >Update</Button>
                                </Box>

                              </Box>

                            </Box>
                          </Box>

                        )
                      }

                    </div>
                  </Box>{' '}
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                      color: 'gray',
                    }}
                  >
                    <p
                      style={{
                        fontSize: '25px',
                        marginTop: '0px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        if (view === 'Week') {
  
                          setView('Year');
                        } else if (view === 'Year') {
                          setView('Month');
                          setView('Week');
                        }
                      }}
                    >
                      -
                    </p>
                    <Slider
                      aria-label="Temperature"
                      defaultValue={0}
                      value={
                        view === 'Week' ? 0 : view === 'Month' ? 1 : view === 'Year' ? 2 : 4
                      }

                      valueLabelDisplay="auto"
                      valueLabelFormat={(value) => {
                        const label = ['Week', 'Month', 'Year']
                        return label[value]
                      }}
                      step={1}
                      min={0}
                      max={2}
                      sx={{
                        color: 'gray',
                        width: { xl: '40%', lg: '40%', md: '35%', sm: '33%' },
                      }}
                      onChange={(e) => {

                        if (e.target.value === 0) {
                          setView('Week');
                        }
                        if (e.target.value === 1) {
                          setView('Month');
                        }
                        if (e.target.value === 2) {
                          setView('Year');
                        }

                      }}
                    />{' '}
                    <p
                      style={{
                        fontSize: '25px',
                        marginTop: '0px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        if (view === ViewMode.Week) {
                          setView(ViewMode.Month);
                        } else if (view === ViewMode.Year) {
                          setView(ViewMode.Day);
                        }
                        else if (view === ViewMode.Month) {
                          setView(ViewMode.Year);
                        }
                        else {
                          setView(ViewMode.Week);
                        }
                      }}
                    >
                      +
                    </p>
                  </Box>
                </Box>
              </div>
            ) : null}
          </div>
        </div>

        <section
          className={steps < 6 ? 'container-psU-p5' : null}
          style={
            steps !== 6 ? { padding: '2rem 1.5rem', position: 'relative' } : {}
          }
        >
          {steps === 1 && (
            <PFirst
              setCompanySelected={setCompanySelected}
              formData={formData}
              setFormData={setFormData}
              dileverable={dileverable}
              selectedWorkType={selectedWorkType}
              setSelectedWorkType={setSelectedWorkType}
              setDeliverable={setDeliverable}
              setSelectedCollabarators={setSelectedCollabarators}
              selectedCollabarators={selectedCollabarators}
              setSelectedTeam={setSelectedTeam}
              selectedTeam={selectedTeam}
              screenHeight={screenHeight}
            />
          )}
        

          {steps === 2 && (
            <PThird
              formData={formData}
              setFormData={setFormData}
              steps={steps}
              dileverable={dileverable}
              setDeliverable={setDeliverable}
              setErrorState={setErrorState}
              access_token={access_token}
              setIsDetOpen={setIsDetOpen}
              showAllTasks={showAllTasks}
              showAllDescription={showAllDescription}
              screenHeight={screenHeight}
            />
          )}

          {/* {steps === 3 && (
            <PThird
              formData={formData}
              setFormData={setFormData}
              steps={steps}
              dileverable={dileverable}
              setDeliverable={setDeliverable}
              setErrorState={setErrorState}
              access_token={access_token}
              setIsDetOpen={setIsDetOpen}
              showAllTasks={showAllTasks}
              showAllDescription={showAllDescription}
              screenHeight={screenHeight}
            />
          )} */}

          {steps === 3 && (
            <PFourth
              formData={formData}
              setFormData={setFormData}
              temp={temp}
              setTemp={setTemp}
              setPic={setPic}
              view={view}
              setView={setView}
              dileverable={dileverable}
              setDeliverable={setDeliverable}
              access_token={access_token}
            />
          )}

          {/* {steps === 4 && (
            <PFourth
              formData={formData}
              setFormData={setFormData}
              temp={temp}
              setTemp={setTemp}
              setPic={setPic}
              view={view}
              setView={setView}
              dileverable={dileverable}
              setDeliverable={setDeliverable}
              access_token={access_token}
            />
          )} */}

          {steps === 4 && (
            <PFifth
              formData={formData}
              setFormData={setFormData}
              cache={cache}
              dileverable={dileverable}
              setDeliverable={setDeliverable}
              estimate_hardcost={estimate_hardcost}
              setEstHardCost={setEstHardCost}
              setTracker={setTracker}
              setTat={setTat}
              tat={tat}
              setTic={setTic}
              setErrorState={setErrorState}
            />
          )}

          {/* {steps === 5 && (
            <PFifth
              formData={formData}
              setFormData={setFormData}
              cache={cache}
              dileverable={dileverable}
              setDeliverable={setDeliverable}
              estimate_hardcost={estimate_hardcost}
              setEstHardCost={setEstHardCost}
              setTracker={setTracker}
              setTat={setTat}
              tat={tat}
              setTic={setTic}
              setErrorState={setErrorState}
            />
          )} */}
        </section>

        {error && (
          <Box sx={{
            position: 'absolute',
            bottom: { xl: '12%', lg: '14%', md: '15%', sm: '9%' },
            width: '98%',
            zIndex: 999
          }}>
            <Shake><Alert

              severity="info"
            >
              {errorMessage}
            </Alert></Shake>
          </Box>
        )}

        {steps < 5 && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '1rem 2.2rem',
              backgroundColor: '#fff',
              height: { xl: '90px', lg: '120px' },
              marginBottom: { xl: '.5rem', lg: '1rem' },
            }}
          >
            <button
              className="cancel-btn"
              onClick={() =>
                steps == 1
                  ? navigate('/')
                  : setSteps((prev) => {
                    if (prev === 3 && !formData.collaborators) {
                      return 1;
                    }
                    return prev - 1;
                  })
              }
            >
              {steps == 1 ? 'Cancel' : 'Back'}
            </button>
            <Box>
              <button disabled={steps > 3 ? true : false} className="create-btn" onClick={handleSubmit}>
                {steps < 7 ? 'Next' : 'Finish'}
              </button>
            </Box>
          </Box>
        )}
      </section>
      <DilTemplate
        dileverable={dileverable}
        setDeliverable={setDeliverable}
        setOpenCanva={setOpenCanva}
        openCanva={openCanva}
        formData={formData}
        setFormData={setFormData}
        setSelectedWorkType={setSelectedWorkType}
      />
      <NewProjectHelpVideos show={show} setShow={setShow} steps={steps} />
    </>)
    :
    (<PreLoading />)

};

export default NewProject;
