import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Chip, Box, Popper } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import { ENDPOINT } from '../../Opportunity/Redux/oppor';
import Dots from '../assets/Dots.png';
import Gear from '../assets/Gear.png';
import '../contacts.css';
import { NetnetField } from '../../../Componenets/NetField';
import { getPersons } from '../ContactRedux';
import { PreLoading } from './EditContact';
import { PreLoader1, myCountries } from '../../../Componenets/Misc';

function DynamicField({
  formData,
  setFormData,
  addColumn,
  setAddColumn,
  column,
  setColumn,
}) {
  return (
    <Backdrop
      open={addColumn}
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Box
        sx={{
          width: 400,
          height: 400,
          backgroundColor: 'white',
          borderRadius: '5px',
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <span className="modalSpan">
          Add your own custom field to this contact
        </span>
        <NetnetField
          label="Field Name"
          value={column.field_name}
          onChange={(e) => setColumn({ ...column, field_name: e.target.value })}
        />
        <NetnetField
          label="Field Value"
          value={column.value}
          onChange={(e) => setColumn({ ...column, value: e.target.value })}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <button
            type="button"
            className="cancel-btn"
            onClick={() => {
              setColumn({
                field_name: '',
                value: '',
              });
              setAddColumn(false);
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="create-btn"
            onClick={() => {
              setAddColumn(false);
              setFormData({
                ...formData,
                contact_dynamicfield: [
                  {
                    dynamicfield_charfield: [
                      ...formData.contact_dynamicfield[0]
                        .dynamicfield_charfield,
                      {
                        field_name: column.field_name,
                        value: column.value,
                      },
                    ],
                  },
                ],
              });
            }}
          >
            Apply
          </button>
        </Box>
      </Box>
    </Backdrop>
  );
}

function BasicInformation({ formData, setFormData }) {
  const [addColumn, setAddColumn] = useState(false);
  const [column, setColumn] = useState({
    field_name: '',
    value: '',
  });
  const [showAdd, setShowAdd] = useState(false);

  return (
    <div className="col col-NP-NC">
      <h3 className="sub-title">Basic Information</h3>
      <Box
        className="df-r-j-c-gp-5"
        style={{ width: 'auto', maxWidth: '500px' }}
      >
        <span className="modalSpan">First Name</span>
        <NetnetField
          id="FirstName"
          label="Enter First Name"
          variant="outlined"
          className="MUI-P6-M5"
          value={formData.first_name}
          onChange={(e) =>
            setFormData({ ...formData, first_name: e.target.value })
          }
          color="secondary"
        />
      </Box>
      <Box
        className="df-r-j-c-gp-5"
        style={{ width: 'auto', maxWidth: '500px' }}
      >
        <span className="modalSpan">Last Name</span>

        <NetnetField
          id="LastName"
          label="Enter Last Name"
          variant="outlined"
          className="MUI-P6-M5"
          value={formData.last_name}
          onChange={(e) =>
            setFormData({ ...formData, last_name: e.target.value })
          }
          color="secondary"
        />
      </Box>
      <Box
        className="df-r-j-c-gp-5"
        style={{ width: 'auto', maxWidth: '500px' }}
      >
        <span className="modalSpan">Email</span>
        <NetnetField
          id="Email"
          label="Enter Email"
          variant="standard"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          color="secondary"
          style={{ width: 300 }}
          type="email"
        />
      </Box>
      <Box
        className="df-r-j-c-gp-5"
        style={{ width: 'auto', maxWidth: '500px' }}
      >
        <span className="modalSpan">Mobile Number</span>

        <NetnetField
          id="MobileNumber"
          label="Mobile Number"
          variant="standard"
          type="tel"
          value={formData.mobile_num}
          onChange={(e) =>
            setFormData({ ...formData, mobile_num: e.target.value })
          }
          color="secondary"
          style={{ width: 300 }}
          helperText="Please Enter a valid Mobile Number"
        />
      </Box>
      <DynamicField
        formData={formData}
        setFormData={setFormData}
        showAdd={showAdd}
        setShowAdd={setShowAdd}
        column={column}
        setColumn={setColumn}
        addColumn={addColumn}
        setAddColumn={setAddColumn}
      />
    </div>
  );
}

function BussniessInformation({ formData, setFormData }) {
  const [addColumn, setAddColumn] = useState(false);
  const [column, setColumn] = useState({
    field_name: '',
    value: '',
  });
  const [showAdd, setShowAdd] = useState(false);
  const currentCompanies = useSelector((state) => state.Contacts.companies);
  const companyOptions = currentCompanies.map((company) => ({
    name: company.name,
    id: company.id,
  }));
  const filter = createFilterOptions();

  const types = ['Customer', 'Former Customer', 'Lead', 'Partner', 'Prospect'];
  return (
    <div className="col col-NP-NC">
      <h3 className="sub-title">Business Information</h3>
      <Box
        className="df-r-j-c-gp-5"
        style={{ width: 'auto', maxWidth: '500px' }}
      >
        <span className="modalSpan">Company</span>
        <Autocomplete
          id="company"
          options={companyOptions}
          // eslint-disable-next-line
          PopperComponent={(props) => (
            <Popper
              {...props}
              sx={{
                overflowY: 'none',
                scrollbarColor: 'transparent transparent',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
                '&::-ms-scrollbar': {
                  width: 0,
                  background: 'transparent transparent',
                },
              }}
            />
          )}
          includeInputInList
          freeSolo
          onChange={(e, value) => {
            setFormData({
              ...formData,
              company: value.inputValue ? value.inputValue : value.name,
            });
          }}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option;
            }
            if (option.inputValue) {
              return option.inputValue;
            }
            return option.name;
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            if (params.inputValue !== '') {
              filtered.push({
                inputValue: params.inputValue,
                name: `Add "${params.inputValue}"`,
              });
            }
            return filtered;
          }}
          sx={{
            width: '100%',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#f2f2f5 !important',
              },
              '&.Mui-disabled fieldset': {
                borderColor: '#f2f2f5',
              },
            },
          }}
          renderOption={(props, option) => <li {...props}>{option.name}</li>}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Enter Company Name"
              variant="outlined"
              value={formData.company}
              color="secondary"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'white',
                  },
                  '&:hover fieldset': {
                    borderColor: '#711fff',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#5a0bdf',
                  },

                  '&.Mui-error fieldset': {
                    borderColor: 'red',
                  },
                },
                '& .MuiFormLabel-root': {
                  color: '#03071e66',
                },
                '& .MuiFormLabel-root.Mui-focused': {
                  color: '#5a0bdf',
                },
                '& .MuiFormLabel-root.Mui-error': {
                  color: 'red',
                },
              }}
              InputProps={{
                ...params.InputProps,
                style: { width: '100%' },
              }}
            />
          )}
        />
      </Box>
      <Box
        className="df-r-j-c-gp-5"
        style={{ width: 'auto', maxWidth: '500px' }}
      >
        <span className="modalSpan">Title</span>

        <NetnetField
          id="EnterTitle"
          label="Enter Title"
          disabled={!formData.company}
          variant="standard"
          className="MUI-P6-M5"
          value={formData.title}
          onChange={(e) => setFormData({ ...formData, title: e.target.value })}
          color="secondary"
          style={{ width: 300 }}
        />
      </Box>
      <Box
        className="df-r-j-c-gp-5"
        style={{ width: 'auto', maxWidth: '500px' }}
      >
        <span className="modalSpan">Type</span>
        <Autocomplete
          id="country"
          includeInputInList
          // eslint-disable-next-line
          PopperComponent={(props) => (
            <Popper
              {...props}
              sx={{
                overflowY: 'none',
                scrollbarColor: 'transparent transparent',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
                '&::-ms-scrollbar': {
                  width: 0,
                  background: 'transparent transparent',
                },
              }}
            />
          )}
          onChange={(e, value) => {
            setFormData({ ...formData, contact_type: value });
          }}
          options={types}
          getOptionLabel={(option) => option}
          sx={{
            width: '100%',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#f2f2f5 !important',
              },
              '&.Mui-disabled fieldset': {
                borderColor: '#f2f2f5',
              },
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Enter Type"
              variant="outlined"
              value={formData.contact_type}
              color="secondary"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'white',
                  },
                  '&:hover fieldset': {
                    borderColor: '#711fff',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#5a0bdf',
                  },

                  '&.Mui-error fieldset': {
                    borderColor: 'red',
                  },

                  '&.Mui-disabled fieldset': {
                    borderColor: 'grey',
                  },
                },
                '& .MuiFormLabel-root': {
                  color: '#03071e66',
                },
                '& .MuiFormLabel-root.Mui-focused': {
                  color: '#5a0bdf',
                },
                '& .MuiFormLabel-root.Mui-error': {
                  color: 'red',
                },
              }}
              InputProps={{
                ...params.InputProps,
                style: { width: '100%' },
              }}
            />
          )}
        />
      </Box>
      <DynamicField
        formData={formData}
        setFormData={setFormData}
        showAdd={showAdd}
        setShowAdd={setShowAdd}
        column={column}
        setColumn={setColumn}
        addColumn={addColumn}
        setAddColumn={setAddColumn}
      />
    </div>
  );
}

function AddressInformation({ formData, setFormData }) {
  const [addColumn, setAddColumn] = useState(false);
  const [column, setColumn] = useState({
    field_name: '',
    value: '',
  });
  const [showAdd, setShowAdd] = useState(false);
  return (
    <div className="col col-NP-NC">
      <h3 className="sub-title">Residence Information</h3>

      <Box
        className="df-r-j-c-gp-5"
        style={{ width: 'auto', maxWidth: '500px' }}
      >
        <span className="modalSpan">Address Line 1</span>

        <NetnetField
          id="Address1"
          label="Enter Address"
          variant="standard"
          value={formData.street}
          onChange={(e) => setFormData({ ...formData, street: e.target.value })}
          color="secondary"
          style={{ width: 300 }}
        />
      </Box>
      <Box
        className="df-r-j-c-gp-5"
        style={{ width: 'auto', maxWidth: '500px' }}
      >
        <span className="modalSpan">Address Line 2</span>

        <NetnetField
          id="Address2"
          label="Enter Address"
          variant="standard"
          value={formData.street2}
          onChange={(e) =>
            setFormData({ ...formData, street2: e.target.value })
          }
          color="secondary"
          style={{ width: 300 }}
        />
      </Box>

      <Box
        className="df-r-j-c-gp-5"
        style={{ width: 'auto', maxWidth: '500px' }}
      >
        <span className="modalSpan">City</span>

        <NetnetField
          id="EnterCity"
          label="Enter City"
          variant="standard"
          value={formData.city}
          color="secondary"
          onChange={(e) => setFormData({ ...formData, city: e.target.value })}
          style={{ width: 300 }}
        />
      </Box>
      <Box
        className="df-r-j-c-gp-5"
        style={{ width: 'auto', maxWidth: '500px' }}
      >
        <span className="modalSpan">State / Province</span>

        <NetnetField
          id="EnterState"
          label="Enter State"
          variant="standard"
          value={formData.state}
          color="secondary"
          onChange={(e) => setFormData({ ...formData, state: e.target.value })}
          style={{ width: 300 }}
        />
      </Box>
      <Box
        className="df-r-j-c-gp-5"
        style={{ width: 'auto', maxWidth: '500px' }}
      >
        <span className="modalSpan">
          {formData.country === 'United States'
            ? 'Zip Code '
            : 'Zip / Postal Code '}
        </span>

        <NetnetField
          id="EnterZip"
          label="Enter Zip Code"
          color="secondary"
          variant="standard"
          style={{ width: 300 }}
          value={formData.address_zip}
          onChange={(e) =>
            setFormData({ ...formData, address_zip: e.target.value })
          }
        />
      </Box>
      <Box
        className="df-r-j-c-gp-5"
        style={{ width: 'auto', maxWidth: '500px' }}
      >
        <span className="modalSpan">Country</span>

        <Autocomplete
          id="country"
          includeInputInList
          onChange={(e, value) => {
            setFormData({ ...formData, country: value });
          }}
          // eslint-disable-next-line
          PopperComponent={(props) => (
            <Popper
              {...props}
              sx={{
                overflowY: 'none',
                scrollbarColor: 'transparent transparent',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
                '&::-ms-scrollbar': {
                  width: 0,
                  background: 'transparent transparent',
                },
              }}
            />
          )}
          options={myCountries}
          getOptionLabel={(option) => option}
          sx={{
            width: '100%',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#f2f2f5 !important',
              },
              '&.Mui-disabled fieldset': {
                borderColor: '#f2f2f5 !important',
              },
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Enter Country"
              variant="outlined"
              value={formData.country}
              color="secondary"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'white',
                  },
                  '&:hover fieldset': {
                    borderColor: '#711fff',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#5a0bdf',
                  },

                  '&.Mui-error fieldset': {
                    borderColor: 'red',
                  },

                  '&.Mui-disabled fieldset': {
                    borderColor: 'grey',
                  },
                },
                '& .MuiFormLabel-root': {
                  color: '#03071e66',
                },
                '& .MuiFormLabel-root.Mui-focused': {
                  color: '#5a0bdf',
                },
                '& .MuiFormLabel-root.Mui-error': {
                  color: 'red',
                },
              }}
              InputProps={{
                ...params.InputProps,
                style: { width: '100%' },
              }}
            />
          )}
        />
      </Box>
      <DynamicField
        formData={formData}
        setFormData={setFormData}
        showAdd={showAdd}
        setShowAdd={setShowAdd}
        column={column}
        setColumn={setColumn}
        addColumn={addColumn}
        setAddColumn={setAddColumn}
      />
    </div>
  );
}

function SocialMedia({ formData, setFormData }) {
  const [addColumn, setAddColumn] = useState(false);
  const [column, setColumn] = useState({
    field_name: '',
    value: '',
  });
  const [showAdd, setShowAdd] = useState(false);

  return (
    <div className="col col-NP-NC">
      <h3 className="sub-title">Social Media</h3>
      <Box
        className="df-r-j-c-gp-5"
        style={{ width: 'auto', maxWidth: '500px' }}
      >
        <span className="modalSpan">Linkedin</span>

        <NetnetField
          id="EnterUrlLinkedin"
          label="Enter URL"
          type="url"
          variant="standard"
          value={formData.linkedin_url}
          onChange={(e) =>
            setFormData({ ...formData, linkedin_url: e.target.value })
          }
          color="secondary"
          style={{ width: 300 }}
        />
      </Box>
      <Box
        className="df-r-j-c-gp-5"
        style={{ width: 'auto', maxWidth: '500px' }}
      >
        <span className="modalSpan"> X </span>

        <NetnetField
          id="EnterUrlX"
          label="Enter URL"
          variant="standard"
          type="url"
          value={formData.twitter_url}
          onChange={(e) =>
            setFormData({ ...formData, twitter_url: e.target.value })
          }
          color="secondary"
          style={{ width: 300 }}
        />
      </Box>
      <Box
        className="df-r-j-c-gp-5"
        style={{ width: 'auto', maxWidth: '500px' }}
      >
        <span className="modalSpan">Instagram</span>

        <NetnetField
          id="EnterUrlInsta"
          label="Enter URL"
          variant="standard"
          type="url"
          value={formData.instagram_url}
          onChange={(e) =>
            setFormData({ ...formData, instagram_url: e.target.value })
          }
          color="secondary"
          style={{ width: 300 }}
        />
      </Box>
      <Box
        className="df-r-j-c-gp-5"
        style={{ width: 'auto', maxWidth: '500px' }}
      >
        <span className="modalSpan">Facebook</span>

        <NetnetField
          id="EnterUrlFacebook"
          label="Enter URL"
          variant="standard"
          type="url"
          value={formData.facebook_url}
          onChange={(e) =>
            setFormData({ ...formData, facebook_url: e.target.value })
          }
          color="secondary"
          style={{ width: 300 }}
        />
      </Box>
      <DynamicField
        formData={formData}
        setFormData={setFormData}
        showAdd={showAdd}
        setShowAdd={setShowAdd}
        column={column}
        setColumn={setColumn}
        addColumn={addColumn}
        setAddColumn={setAddColumn}
      />
    </div>
  );
}

function Tags({ formData, setFormData }) {
  const handleDelete = (chipToDelete) => {
    setFormData({
      ...formData,
      tags: formData.tags.filter((chip) => chip !== chipToDelete),
    });
  };

  const persons = useSelector((item) => item.Contacts.persons).filter(
    (item) => item.tags,
  );
  const flattenedTags = persons
    .map((person) => person.tags.split(',').map((tag) => tag.trim()))
    .flat();

  const uniqueTagsArray = [];
  const seenTags = new Set();
  // eslint-disable-next-line
  for (const tag of flattenedTags) {
    if (!seenTags.has(tag)) {
      seenTags.add(tag);
      uniqueTagsArray.push(tag);
    }
  }

  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);

  return (
    <div className="col col-NP-NC">
      <h3 className="sub-title">Tags</h3>
      <Box
        className="df-r-j-c-gp-5"
        style={{ width: '49%', maxWidth: '500px' }}
      >
        <span className="modalSpan">Tags</span>

        <Autocomplete
          id="company"
          options={uniqueTagsArray}
          // eslint-disable-next-line
          PopperComponent={(props) => (
            <Popper
              {...props}
              sx={{
                overflowY: 'none',
                scrollbarColor: 'transparent transparent',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
                '&::-ms-scrollbar': {
                  width: 0,
                  background: 'transparent transparent',
                },
              }}
            />
          )}
          defaultValue={inputValue}
          value={inputValue}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              setFormData({
                ...formData,
                tags: [...formData.tags, e.target.value],
              });
              setInputValue('');
              e.target.value = '';
            }
          }}
          getOptionLabel={(option) => option}
          style={{ width: '100%' }}
          sx={{
            width: '100%',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#f2f2f5 !important',
              },
              '&.Mui-disabled fieldset': {
                borderColor: '#f2f2f5',
              },
            },
          }}
          renderOption={(props, option) => <li {...props}>{option}</li>}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Add Tags"
              variant="outlined"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              inputRef={inputRef}
              color="secondary"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'white',
                  },
                  '&:hover fieldset': {
                    borderColor: '#711fff',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#5a0bdf',
                  },
                  '&.Mui-error fieldset': {
                    borderColor: 'red',
                  },
                },
                '& .MuiFormLabel-root': {
                  color: '#03071e66',
                },
                '& .MuiFormLabel-root.Mui-focused': {
                  color: '#5a0bdf',
                },
                '& .MuiFormLabel-root.Mui-error': {
                  color: 'red',
                },
              }}
              InputProps={{
                ...params.InputProps,
                style: { width: '100%' },
              }}
            />
          )}
        />
      </Box>
      <div
        className="input-groups"
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          marginTop: '10px',
          marginLeft: '10px',
          height: '100px',
          overflow: 'auto',
        }}
      >
        {formData.tags.map((tag) => (
          <Chip
            key={tag}
            label={tag}
            color="secondary"
            variant="outlined"
            style={{ backgroundColor: '#f5f5f5' }}
            onDelete={() => handleDelete(tag)}
          />
        ))}
      </div>
    </div>
  );
}

function NewPerson({ updateInnerNetNetTabs }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentCompanies = useSelector((state) => state.Contacts.companies);
  const currentPersons = useSelector((state) => state.Contacts.persons);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    company: '',
    title: '',
    country: '',
    street: '',
    state: '',
    city: '',
    address_zip: '',
    linkedin_url: '',
    twitter_url: '',
    facebook_url: '',
    instagram_url: '',
    mobile_num: '',
    office_phone: '',
    tags: [],
    street2: '',
    contact_dynamicfield: [
      {
        dynamicfield_charfield: [],
      },
    ],
  });
  const [id, setID] = useState(false);
  const unique_id = id
    ? currentPersons.find((item) => item.id === id)?.unique_id
    : false;
  const [loading, setLoading] = useState(false);
  const access_token = useSelector((state) => state.auth.user.access);
  useEffect(() => {
    if (unique_id) {
      navigate(`/Person_Detail/${unique_id}`);
      updateInnerNetNetTabs('Person Detail', `/Person_Detail/${unique_id}`);
      setLoading(false);
    }
  }, [id, unique_id]);

  const [loadingBtnIcon, setLoadingBtnIcon] = useState(false);
  const handleSubmition = async (e) => {
    const CompanyCreate = `${ENDPOINT}/api/contact/company/create/`;
    const ContactCreate = `${ENDPOINT}/api/contact/create/`;
    const alreadyExists = currentPersons.find(
      (p) => p.email === formData.email,
    );
    e.preventDefault();
    if (formData.first_name === '') {
      setError(true);
      setErrorMessage('First Name Is Required');
    } else if (formData.company) {
      setLoadingBtnIcon(true);
      navigate('/People');
      updateInnerNetNetTabs('People', '/People');
      const searchCompany = currentCompanies.filter(
        (item) => item.name === formData.company,
      );

      if (searchCompany.length > 0) {
        setFormData({
          ...formData,
          company: searchCompany[0].id,
        });
        const firstResponse = await fetch(ContactCreate, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${access_token}`,
          },
          body: JSON.stringify({
            ...formData,
            company: searchCompany[0].id,
            tags: formData.tags ? formData.tags.join(',') : '',
          }),
        });
        const data = await firstResponse.json();

        if (!firstResponse.ok) {
          setError(true);
          setLoadingBtnIcon(false);
          Object.keys(data).forEach((key) => {
            setErrorMessage(data[key]);
          });
        } else {
          dispatch(getPersons({ access_token }));
          setID(data.id);
          setLoading(true);
        }
      } else {
        const secondary = await fetch(CompanyCreate, {
          method: 'POST',
          headers: {
            Authorization: `JWT ${access_token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: formData.company,
          }),
        });

        const secondarydata = await secondary.json();
        if (!secondary.ok) {
          setError(true);
          setLoadingBtnIcon(false);
          Object.keys(secondarydata).forEach((key) => {
            setErrorMessage(
              secondarydata[key].Error
                ? secondarydata[key].Error
                : (Object.values(secondarydata) ?? 'Oops Something Went Wrong'),
            );
          });
        } else {
          const finalResponse = await fetch(ContactCreate, {
            method: 'POST',
            headers: {
              Authorization: `JWT ${access_token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              ...formData,
              company: secondarydata.id,
              tags: formData.tags ? formData.tags.join(',') : '',
            }),
          });
          const netData = await finalResponse.json();
          if (!finalResponse.ok) {
            setError(true);
            setLoadingBtnIcon(false);
            Object.keys(netData).forEach((key) => {
              setErrorMessage(
                netData[key].Error
                  ? netData[key].Error
                  : (Object.values(netData) ?? 'Oops Something Went Wrong'),
              );
            });
          } else {
            navigate('/People');
            updateInnerNetNetTabs('People', '/People');
            setLoadingBtnIcon(false);
            dispatch(getPersons({ access_token }));
            setID(netData.id);
            setLoading(true);
          }
        }
      }
    } else if (alreadyExists) {
      setError(true);
      setErrorMessage('A Contact with this email already exists');
    } else {
      const response = await fetch(ContactCreate, {
        method: 'POST',
        headers: {
          Authorization: `JWT ${access_token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          tags: formData.tags ? formData.tags.join(',') : '',
        }),
      });
      const Fdata = await response.json();
      if (!response.ok) {
        setError(true);
        Object.keys(Fdata).forEach((key) => {
          setErrorMessage(
            Fdata[key].Error ? Fdata[key].Error : 'Oops Something Went Wrong',
          );
        });
      } else {
        dispatch(getPersons({ access_token }));
        setLoading(true);
        setID(Fdata.id);
        updateInnerNetNetTabs('People', '/People');
        navigate('/People');
      }
    }
  };

  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return !loading ? (
    <div
      className="oppor-section2"
      style={{ width: screenSize < 600 ? screenSize : 'auto' }}
    >
      <div className="oppor-row">
        <div className="oppor-col">
          <h3 className="fstxtPro">New Person</h3>
          <img className="icon-sm" src={Dots} alt="dots" />
          <img className="icon-sm" src={Gear} alt="gear" />
        </div>
      </div>
      <section
        className="grid-system"
        style={{
          scrollbarWidth: 'thin',
          scrollbarColor: 'transparent',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <div className="row">
          <BasicInformation formData={formData} setFormData={setFormData} />
          <BussniessInformation formData={formData} setFormData={setFormData} />
        </div>
        <div className="row">
          <AddressInformation formData={formData} setFormData={setFormData} />
          <SocialMedia formData={formData} setFormData={setFormData} />
        </div>

        <div className="row">
          <Tags formData={formData} setFormData={setFormData} />
        </div>
      </section>
      {error && <p className="error">{errorMessage}</p>}
      <div
        className="alCenter"
        style={{ marginBottom: screenSize < 600 && '2.5rem' }}
      >
        <button
          type="button"
          className="cancel-btn"
          onClick={() => {
            navigate('/People');
            updateInnerNetNetTabs('People', '/People');
          }}
        >
          Cancel
        </button>
        {loadingBtnIcon ? (
          // eslint-disable-next-line
          <button className="create-btn" type="button">
            <Box width="27px" height="27px" sx={{ px: 0.2 }}>
              <PreLoader1 />
            </Box>
          </button>
        ) : (
          <button
            type="button"
            className="create-btn"
            onClick={handleSubmition}
          >
            {' '}
            Save{' '}
          </button>
        )}
      </div>
    </div>
  ) : (
    <PreLoading />
  );
}
export default NewPerson;
