import { Grid } from '@mui/material';

import PlayCircleIcon from '@mui/icons-material/PlayCircle';

function VideoDemo({ duration, video, lesson }) {
  const extractVideoId = (url) => {
    const match = url?.match(
      // eslint-disable-next-line
      /(?:\?v=|\/embed\/|\/\d\d\/|\/vi\/|\/v\/|https:\/\/www.youtube.com\/watch\?v=|https:\/\/youtu.be\/)([^#\&\?]*).*/,
    );
    return match && match[1].length === 11 ? match[1] : false;
  };

  const constructThumbnailUrl = (videoId) => {
    return `https://img.youtube.com/vi/${videoId}/default.jpg`;
  };

  // Handle rendering the thumbnail
  const handleRenderThumbnail = (youtubeURL) => {
    const videoId = extractVideoId(youtubeURL);

    if (videoId) {
      const thumbnailUrl = constructThumbnailUrl(videoId);
      return thumbnailUrl;
    }
    return 0;
  };
  return (
    <Grid
      container
      direction="column"
      alignItems="flex-start"
      sx={{ padding: '2px 7px' }}
    >
      <Grid
        item
        sx={{
          backgroundImage: `url(${handleRenderThumbnail(video)})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          width: '235px',
          height: '135px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          borderRadius: '8px',
        }}
      >
        <div
          className="duration"
          style={{
            alignSelf: 'self-end',
            marginTop: '-38px',
            marginRight: '8px',
            backgroundColor: 'rgba(232, 231, 230,0.8)',
            padding: '0.3rem',
            color: '#000',
          }}
        >
          {duration}
        </div>
        <PlayCircleIcon
          sx={{
            fontSize: 50,
            color: '#711fff',
          }}
          onClick={() => window.open(video, '_blank')}
        />
      </Grid>
      <Grid
        container
        item
        direction="column"
        alignItems="flex-start"
        textAlign="start"
      >
        <p
          style={{
            margin: '8px 0 0 0',
            fontWeight: 'bold',
            fontSize: '16px',
            color: '#000',
            width: '88%',
          }}
        >
          {lesson.title ? lesson.title : 'No Tittle'}
        </p>
        <p className="m-0000">Beginner - Advance</p>
      </Grid>
    </Grid>
  );
}

export default VideoDemo;
