import { createAsyncThunk } from '@reduxjs/toolkit';
import { async } from 'q';

const GET_OPPORTUNITIES = 'netnet/Redux/GET_OPPERTUNITIES';
const CREATE_OPPORTUNITY = 'netnet/Redux/CREATE_OPPORTUNITY';
const CONVERT_OPPORTUNITY = 'netnet/Redux/CONVERT_OPPORTUNITY';
const GET_ESTIMATES = 'netnet/Redux/GET_ESTIMATES';
const STORE_NUMBER = 'netnet/Redux/STORE_NUMBER';
const GET_TIMETRACKING = 'netnet/Redux/GET_TIMETRACKING'
const GET_PAYMENTTERMS = 'netnet/Redux/GET_PAYMENTTERMS'
const GET_TERMSANDCONDITION = 'netnet/Redux/GET_TERMSANDCONDITION'
const TIMELINE_PIC = 'netnet/Redux/TIMELINE_PIC'

export const ORIGIN = process.env.REACT_APP_FRONTEND_URL;
export const ENDPOINT = process.env.REACT_APP_BASE_API_URL;

const getTimeTracking = createAsyncThunk(GET_TIMETRACKING, async (meta) => {
  const response = await fetch(`${ENDPOINT}/api/project/task/timetracking/list/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `JWT ${meta.access_token}`,
    },
  });
  const data = await response.json();
  if (!response.ok) {
    console.log('rejected@timeTracking', data);
  } else {
    return data;
  }
})

const getOpportunity = createAsyncThunk(GET_OPPORTUNITIES, async (meta) => {
  const response = await fetch(`${ENDPOINT}/api/opportunity/opportunitylist/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `JWT ${meta.access_token}`,
    },
  });
  const data = await response.json();
  if (!response.ok) {
    console.log('rejected@getEstimates', data);
  } else {
    return data;
  }
});

const storeStep = createAsyncThunk(STORE_NUMBER, async (number) => {
  // This async thunk can be used to store a number in the state.
  return number;
});

const storeTimeLinePic = createAsyncThunk(TIMELINE_PIC, async (timelinePic)=>{
  return timelinePic
})

const getEstimates = createAsyncThunk(GET_ESTIMATES, async (meta, thunkAPI) => {
  const res = await fetch(`${ENDPOINT}/api/opportunity/estimate/list/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `JWT ${meta.access_token}`,
    },
  });
  const data = await res.json();
  if (!res.ok) {
    console.log('Error at Getting Estimates', data);
    thunkAPI.rejectWithValue(data);
  }
  return data;
});

const createOpportunity = createAsyncThunk(CREATE_OPPORTUNITY, async (meta) => {
  const response = await fetch(`${ENDPOINT}/api/opportunity/create/`, {
    Authorization: `JWT ${meta.access_token}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(meta.payload),
  });
  const data = await response.json();
  if (!response.ok) {
    console.log('rejected@createEstimate', data);
  } else {
    console.log('success@createEstimate', data);
  }
});



const getPaymentTerms = createAsyncThunk(GET_PAYMENTTERMS, async (meta) => {
  const response = await fetch(`${ENDPOINT}/api/opportunity/paymenterms/template/list/`, {
    method: 'GET',
    headers: {
      Authorization: `JWT ${meta.access_token}`,
      'Content-Type': 'application/json',
    },
  })
  const data = await response.json()
  if (!response.ok) {
    console.log("rejected@getPaymentTerms", data)
  }
  return data
})

const getTermAndCondtion = createAsyncThunk(GET_TERMSANDCONDITION, async (meta) => {
  const response = await fetch(`${ENDPOINT}/api/opportunity/tearmsconditions/template/list/`, {
    method: 'GET',
    headers: {
      Authorization: `JWT ${meta.access_token}`,
      'Content-Type': 'application/json',
    },
  })
  const data = await response.json()
  if (!response.ok) {
    console.log("rejected@getPaymentTerms", data)
  }
  return data
})


const convertOpportunity = createAsyncThunk(
  CONVERT_OPPORTUNITY,
  async (beta, thunkAPI) => {
    const response = await fetch(
      `${ENDPOINT}/api/opportunity/update/${beta.payload.id}/`,
      {
        method: 'PUT',
        headers: {
          Authorization: `JWT ${beta.access_token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(beta.payload),
      }
    );
    const data = await response.json();
    if (!response.ok) {
      thunkAPI.rejectWithValue(data);
    } else {
      console.log('success@convertEstimate', data);
    }
  }
);

export { getOpportunity, createOpportunity, convertOpportunity, getEstimates, storeStep, getTimeTracking, getPaymentTerms, getTermAndCondtion, storeTimeLinePic };

const initialState = {
  opportunites: [],
  projects: [],
  estimates: [],
  paymentTerms: [],
  termAndContitions: [],
  loading: false,
  error: null,
};

const opporReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_OPPORTUNITIES}/pending`:
      return {
        ...state,
        loading: true,
      };
    case `${GET_OPPORTUNITIES}/fulfilled`:
      return {
        ...state,
        loading: false,
        opportunites: action.payload,
      };
    case `${GET_OPPORTUNITIES}/rejected`:
      return {
        ...state,
        loading: false,
        error: action.payload,
        opportunites: [],
      };

    case `${GET_ESTIMATES}/pending`:
      return {
        ...state,
        loading: true,
      };
    case `${GET_ESTIMATES}/fulfilled`:
      return {
        ...state,
        loading: false,
        estimates: action.payload,
      };
    case `${GET_ESTIMATES}/rejected`:
      return {
        ...state,
        loading: false,
        error: action.payload,
        estimates: [],
      };
    case `${GET_PAYMENTTERMS}/pending`:
      return {
        ...state,
        loading: true,
      };
    case `${GET_PAYMENTTERMS}/fulfilled`:
      return {
        ...state,
        loading: false,
        paymentTerms: action.payload,
      };
    case `${GET_PAYMENTTERMS}/rejected`:
      return {
        ...state,
        loading: false,
        error: action.payload,
        paymentTerms: [],
      };
    case `${GET_TERMSANDCONDITION}/pending`:
      return {
        ...state,
        loading: true,
      };
    case `${GET_TERMSANDCONDITION}/fulfilled`:
      return {
        ...state,
        loading: false,
        termAndContitions: action.payload,
      };
    case `${GET_TERMSANDCONDITION}/rejected`:
      return {
        ...state,
        loading: false,
        error: action.payload,
        termAndContitions: [],
      };
    case `${STORE_NUMBER}/fulfilled`:
      return {
        ...state,
        loading: false,
        storedNumber: action.payload, // Add a new field 'storedNumber' to the state
      };

      case `${TIMELINE_PIC}/fulfilled`:
        return {
          ...state,
          loading: false,
          timelinePic: action.payload, // Add a new field 'storedNumber' to the state
        };

    case `${GET_TIMETRACKING}/fulfilled`:
      return {
        ...state,
        loading: false,
        timetracking: action.payload, // Add a new field 'storedNumber' to the state
      };
    default:
      return state;
  }
};
export default opporReducer;
