import React, { useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { ArrowBack } from '@mui/icons-material';
import Bounce from 'react-reveal/Bounce';

import helpVid from '../Opportunity/assets/Section_Help-idle.svg';
import InitalizeParam from './InitailzeParam';
import ParamControl from './ParamControl';
import ReportScreenHelp from './ReportScreenHelp';
import ReportSubScreenHelp from './ReportSubScreenHelp';
// import QuickTasks from './QuickTask';
import Estimate from './Estimate';
import Time from './Time';
// import Team from './Team';
import Sales from './Sales';

// ! 'Quick Tasks' and 'Team' reports are commented out for now. (Marc's request - 21-10-2024).

function Reports({ screenHeight }) {
  const [show, setShow] = useState(false);
  const url = useLocation();
  const path = url?.pathname?.split('/');
  const navigate = useNavigate();
  return (
    <Bounce left>
      <section className="oppor-section5">
        <div className="oppor-row">
          <div className="oppor-col">
            <div
              title={
                Array.isArray(path) &&
                path.length &&
                path[2] &&
                'Go back to reports page.'
              }
              onClick={() => {
                navigate('/reports');
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.8rem',
                cursor:
                  Array.isArray(path) && path.length && path[2] && 'pointer',
              }}
            >
              {Array.isArray(path) && path.length && path[2] && (
                <ArrowBack sx={{ fontSize: '2rem', color: '#a6a6a6' }} />
              )}
              <h3 className="fstxtPro">Reports</h3>
            </div>
            <img
              alt=""
              src={helpVid}
              onClick={() => {
                setShow(true);
              }}
              style={{
                color: '#a6a6a6',
                marginLeft: '10px',
                width: '28px',
                height: '28px',
                cursor: 'pointer',
              }}
            />
          </div>

          {Array.isArray(path) && path.length && path[2] && (
            <ParamControl report={path[2]} />
          )}
        </div>
        <section
          style={{
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            borderRadius: '8px',
            padding: '0.1rem 0.1rem',
            minHeight: screenHeight - 155,
            maxHeight: screenHeight - 155,
            overflow: 'auto',
          }}
        >
          <Routes>
            <Route path="/" element={<InitalizeParam />} />
            <Route path="/time-report" element={<Time />} />
            <Route path="/estimate-report" element={<Estimate />} />
            <Route path="/sales-report" element={<Sales />} />
            {/* <Route path="/reports/team-report" element={<Team />} />
            <Route path="/reports/quick-tasks-report" element={<QuickTasks />} /> */}
          </Routes>

          {Array.isArray(path) && path.length && path[2] ? (
            <ReportSubScreenHelp
              show={show}
              setShow={setShow}
              params={
                path[2] && path[2] === 'QuickTasks'
                  ? 'quick_task'
                  : path[2]?.toLocaleLowerCase()
              }
            />
          ) : (
            <ReportScreenHelp show={show} setShow={setShow} />
          )}
        </section>
      </section>
    </Bounce>
  );
}

export default Reports;
