import React, { useEffect, useState } from 'react';

import {
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  CardMedia,
  Tabs,
  Tab,
  List,
  Alert,
  ListItem,
  Avatar,
} from '@mui/material';
import { Box } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import CloseIcon from '@mui/icons-material/Close';

import { Divider } from 'rsuite';
import moment from 'moment/moment';
import Slide from 'react-reveal/Slide';

import { useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { ENDPOINT } from '../../pages/Opportunity/Redux/oppor';

function HelpCard() {
  const [currentPage, setCurrentPage] = useState();
  const url = window.location.href;
  useEffect(() => {
    const page = url.split('/')[3];
    setCurrentPage(page ? page.replace(/_/g, ' ') : 'Me');
  }, [url]);

  const courses = useSelector((state) => state.NetNetU.courses).filter(
    (item) => item.course_type === 'help_videos',
  );
  const myCourses = courses.filter((course) =>
    course.chapters.some((chapter) => chapter.title === currentPage),
  );

  return (
    <Grid>
      <Box sx={{ display: 'flex', padding: '20px 2rem' }}>
        <Box sx={{ display: 'flex' }}>
          <Typography
            variant="h4"
            color="text.primary"
            sx={{ fontWeight: 'bold' }}
          >
            Help
          </Typography>
          <Typography
            variant="h4"
            color="text.secondary"
            sx={{ marginLeft: '10px', position: 'relative' }}
          >
            {currentPage ?? ''}
          </Typography>
          <Box
            sx={{
              position: 'absolute',
              top: '20',
              right: '0',
              paddingRight: '50px',
            }}
          >
            <SearchIcon sx={{ marginRight: '10px', fontSize: '2rem' }} />
            <HelpOutlineIcon sx={{ fontSize: '2rem' }} />
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0rem 2rem',
          alignItems: 'center',
        }}
      >
        <Box>
          <Typography
            variant="h6"
            sx={{ fontWeight: 'bold', marginBottom: '10px' }}
          >
            Sections
          </Typography>
          <Box
            sx={{
              display: 'flex',
              width: '300px',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Typography variant="body1" sx={{ color: '#cb02cb' }}>
                Chapter 1
              </Typography>
              <Typography variant="body1" sx={{ color: '#cb02cb' }}>
                Chapter 2
              </Typography>
              <Typography variant="body1" sx={{ color: '#cb02cb' }}>
                Chapter 3
              </Typography>
              <Typography variant="body1" sx={{ color: '#cb02cb' }}>
                Chapter 4
              </Typography>
            </Box>
            <Box sx={{ paddingRight: '20px' }}>
              <Typography variant="body1">6 videos</Typography>
              <Typography variant="body1">6 videos</Typography>
              <Typography variant="body1">6 videos</Typography>
              <Typography variant="body1">6 videos</Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {myCourses.map((codex) =>
            codex.chapters.map((item) =>
              item.lessons.map((video) => {
                return (
                  <Card
                    key={video.id}
                    sx={{
                      width: 300,
                      height: 220,
                      mx: 3,
                      border: '1.5px solid #00e1b3',
                      backgroundColor: '#e5fffa',
                    }}
                  >
                    <CardContent>
                      <Box sx={{ position: 'relative' }}>
                        <CardMedia
                          src={video.video}
                          component="iframe"
                          title="video"
                          height="80"
                          frameborder="0"
                          allow="autoplay; encrypted-media"
                          allowfullscreen
                          sx={{
                            cursor: 'pointer',
                            width: '60%',
                            margin: '0 auto',
                          }}
                        />
                        <PlayCircleFilledWhiteIcon
                          sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            fontSize: '3rem',
                            color: '#cb02cb',
                          }}
                        />
                      </Box>
                      <Typography
                        variant="body1"
                        sx={{
                          textAlign: 'center',
                          fontWeight: 'bold',
                          paddingTop: '10px',
                        }}
                      >
                        {video.title}{' '}
                        <span
                          style={{
                            marginLeft: '20px',
                            backgroundColor: '#f9dee3',
                          }}
                        >
                          {video.duration}
                        </span>
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ textAlign: 'center' }}
                        dangerouslySetInnerHTML={{ __html: video.description }}
                      />
                    </CardContent>

                    <Button
                      variant="outlined"
                      sx={{
                        margin: '0 auto',
                        border: '1px solid #cb02cb',
                        color: '#cb02cb',
                        justifyContent: 'center',
                        display: 'block',
                      }}
                    >
                      Skip
                    </Button>
                  </Card>
                );
              }),
            ),
          )}
        </Box>
      </Box>
    </Grid>
  );
}

function NotificationBoard({ setIsNoti }) {
  const [value, setValue] = useState(0);
  const access_token = useSelector((state) => state.auth.user.access);
  const allTasks = useSelector((state) => state.projects.tasks);
  const estimateChats = useSelector(
    (state) => state.Stream?.estimate_channels,
  )?.filter((item) => item.estimate);
  const estimates = useSelector((state) => state.oppertunity.estimates);
  const projects = useSelector((state) => state.projects.projects);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };
  const asyncNotifications = useSelector((state) => state.Misc.notifications);
  const [userNotifications, setUserNotifications] = useState([]);

  useEffect(() => {
    if (Array.isArray(asyncNotifications) && asyncNotifications.length) {
      const sortedNotifications = asyncNotifications.slice().sort((a, b) => {
        const createdA = moment(a.created);
        const createdB = moment(b.created);
        return createdB - createdA;
      });
      setUserNotifications(sortedNotifications);
    }
  }, [asyncNotifications]);

  const userTeam = useSelector((state) => state?.userTeam?.team?.member ?? []);

  const updateAllUnsen = (unseenNotifications) => {
    if (Array.isArray(unseenNotifications) && unseenNotifications.length) {
      unseenNotifications.forEach(async (notification) => {
        const res = await fetch(
          `${ENDPOINT}/api/superadmin/notification/update/${notification.id}/`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `JWT ${access_token}`,
            },
            body: JSON.stringify({
              notification_seen: true,
            }),
          },
        );

        const data = await res.json();
        if (!res.ok) {
          return;
        }
        // eslint-disable-next-line
        return data;
      });
    }
  };

  const UpdateStatus = async (notification) => {
    if (!notification) {
      return;
    }
    const res = await fetch(
      `${ENDPOINT}/api/superadmin/notification/update/${notification.id}/`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${access_token}`,
        },
        body: JSON.stringify({
          is_archieved: true,
          notification_seen: true,
          notification_type: notification.notification_type,
        }),
      },
    );
    if (!res.ok) {
      // eslint-disable-next-line
      return;
    }
  };

  useEffect(() => {
    if (Array.isArray(userNotifications) && userNotifications.length) {
      const toBeUpdated = userNotifications.filter(
        (eachNoti) => !eachNoti.notification_seen,
      );
      if (toBeUpdated.length) {
        updateAllUnsen(toBeUpdated);
      }
    }
  }, [userNotifications]);

  const navigate = useNavigate();
  const handleCickNotification = (item) => {
    setIsNoti(false);

    if (
      item.notification_type === 'task_status_review' ||
      item.notification_type === 'task_status_needs_more_work' ||
      item.notification_type === 'task_status_completed' ||
      item.notification_type === 'task_assigned' ||
      item.notification_type === 'quicktask_assigned' ||
      item.notification_type === 'projecttask_assigned'
    ) {
      navigate('/Tasks');
    } else if (item.notification_type === 'estimate_team') {
      const filteredEstimates =
        Array.isArray(estimates) && estimates.length
          ? estimates.find(
              (est) => Number(est.id) === Number(item.notification_estimate),
            )
          : null;
      navigate(`/Estimate_Detail/${filteredEstimates?.unique_id}`);
    } else if (item.notification_type === 'project_team') {
      const filteredProjects =
        Array.isArray(projects) && projects.length
          ? projects.find(
              (pro) => Number(pro.id) === Number(item.notification_project),
            )
          : null;
      navigate(`/Project_Detail/${filteredProjects?.unique_id}`);
    } else {
      navigate('/Chat', { state: { notificationData: item } });
    }
  };

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '0.44rem 0.88rem',
        overflowY: 'none', // Enable vertical scrolling
        scrollbarWidth: 'thin', // For Firefox
        scrollbarColor: 'transparent transparent', // For Firefox

        // Styles for Microsoft Edge
        '&::MsScrollbar': {
          width: 0,
          background: 'transparent transparent',
        },
      }}
    >
      <Typography variant="h4" color="text.primary" sx={{ fontWeight: 'bold' }}>
        Notification
      </Typography>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        sx={{
          m: '1rem 0',
          '& .MuiTabs-indicator': {
            backgroundColor: '#000',
          },
          '& .MuiTab-textColorSecondary.Mui-selected': {
            color: '#711fff',
            borderBottom: 'none',
            fontWeight: 'bold',
          },

          '& .MuiTab-textColorSecondary': {
            color: 'rgba(3, 7, 30, 0.4)',
          },

          '& .MuiTabs-flexContainer': {
            justifyContent: 'space-between',
          },
        }}
      >
        <Tab label="System Notification" />
        <Tab label="What's New" />
      </Tabs>

      <List sx={{ width: '100%', maxHeight: '73vh', overflowY: 'auto' }}>
        {Array.isArray(userNotifications) &&
        userNotifications.length &&
        userNotifications.filter((ma) => !ma.is_archieved).length ? (
          userNotifications
            .filter((ma) => !ma.is_archieved)
            .sort((a, b) =>
              a.notification_seen === b.notification_seen
                ? 0
                : a.notification_seen
                  ? 1
                  : -1,
            )
            .map((item) => {
              const sender =
                Array.isArray(userTeam) && userTeam.length
                  ? userTeam.find(
                      (teammate) =>
                        Number(teammate.id) ===
                        Number(item.notification_sender),
                    )
                  : null;
              const now = moment();
              const till = moment(item.created);

              const duration = item?.created
                ? Math.round(moment.duration(now.diff(till)).asHours())
                : 0;

              let formattedDuration;

              if (duration < 24) {
                formattedDuration = `${duration} hours Ago`;
              } else if (duration < 24 * 7) {
                const days = Math.floor(duration / 24);
                formattedDuration = `${days} days Ago`;
              } else {
                formattedDuration = moment(till).format('YYYY-MM-DD');
              }

              const channal =
                Array.isArray(estimateChats) && estimateChats.length
                  ? estimateChats.find(
                      (channals) =>
                        Number(channals.id) ===
                        Number(item.notification_channel),
                    )
                  : Array.isArray(projects) && projects.length > 0
                    ? projects.reduce((result, project) => {
                        if (result) {
                          // If the channel is already found, no need to continue the loop
                          return result;
                        }
                        if (Array.isArray(project?.project_subteam)) {
                          project.project_subteam.forEach((subteam) => {
                            if (Array.isArray(subteam.subteam_channel)) {
                              const foundChannel = subteam.subteam_channel.find(
                                (channel) =>
                                  Number(channel.id) ===
                                  Number(item.notification_channel),
                              );
                              if (foundChannel) {
                                // its trying to assign the value to the parameter which is not allowed please see and recommend what to do
                                // eslint-disable-next-line
                                result = foundChannel;
                              }
                            }
                          });
                        }
                        return result;
                      }, null)
                    : null; // Return null if projects is not an array or has length 0

              const task =
                Array.isArray(allTasks) && allTasks.length
                  ? allTasks.find(
                      (task) =>
                        Number(task.id) === Number(item.notification_task),
                    )
                  : null;

              const filteredEstimates =
                Array.isArray(estimates) && estimates.length
                  ? estimates.find(
                      (est) =>
                        Number(est.id) === Number(item.notification_estimate),
                    )
                  : null;

              const filteredProjects =
                Array.isArray(projects) && projects.length
                  ? projects.find(
                      (pro) =>
                        Number(pro.id) === Number(item.notification_project),
                    )
                  : null;

              const projectFind =
                Array.isArray(projects) && projects.length > 0
                  ? projects.reduce((result, project) => {
                      if (result) {
                        // If the channel is already found, no need to continue the loop
                        return result;
                      }

                      if (Array.isArray(project?.project_subteam)) {
                        project.project_subteam.forEach((subteam) => {
                          if (Array.isArray(subteam.subteam_channel)) {
                            const foundChannel = subteam.subteam_channel.find(
                              (channel) =>
                                Number(channel.id) ===
                                Number(item.notification_channel),
                            );

                            if (foundChannel) {
                              // its trying to assign the value to the parameter which is not allowed please see and recommend what to do
                              // eslint-disable-next-line
                              result = foundChannel;
                            }
                          }
                        });
                      }
                      return result;
                    }, null)
                  : null; // Return null if projects is not an array or has length 0

              return (
                <>
                  <Slide right>
                    <ListItem
                      sx={{
                        flexDirection: 'column',
                        width: '100%',
                        alignItems: 'flex-start',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleCickNotification(item)}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          justifyContent: 'space-between',
                          width: '100%',
                          position: 'relative',
                        }}
                      >
                        {!item.notification_seen && (
                          <div
                            style={{
                              height: '15px',
                              width: '15px',
                              borderRadius: '100%',
                              backgroundColor: '#711fff',
                              position: 'absolute',
                              left: '-6%',
                            }}
                          />
                        )}
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '.5rem',
                          }}
                        >
                          <Avatar
                            alt={
                              item?.sender?.first_name
                                ? item?.sender?.first_name
                                : null
                            }
                            src={
                              item?.sender?.avatar
                                ? item?.sender?.avatar
                                : '/static/images/avatar/1.jpg'
                            }
                          />
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alingItems: 'flex-start',
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: '1rem',
                                fontWeight: '600',
                                color: '#04071D',
                              }}
                              onClick={() => handleCickNotification(item)}
                            >
                              {sender?.first_name ?? 'Net Net'}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: '.9rem',
                                fontWeight: '500',
                                color: '#cac9cb',
                              }}
                            >
                              {formattedDuration ?? 'Just now'}
                            </Typography>
                          </Box>
                        </Box>
                        <CloseIcon
                          sx={{
                            cursor: 'pointer',
                            fill: '#03071e',
                          }}
                          onClick={() => {
                            UpdateStatus(item);
                            setUserNotifications((prev) => {
                              return prev.filter(
                                (mam) => Number(mam.id) !== Number(item.id),
                              );
                            });
                          }}
                        />
                      </Box>
                      <Typography
                        sx={{
                          fontSize: '1rem',
                          fontWeight: '550',
                          color: '#04071D',
                          my: '1rem',
                        }}
                        onClick={() => handleCickNotification(item)}
                      >
                        {item.notification_type === 'message' ? (
                          `${sender?.first_name} has just sent you a message.`
                        ) : item.notification_type === 'estimate_team' ? (
                          <span>
                            {' '}
                            You have been made part of{' '}
                            {filteredEstimates?.title ? (
                              <span style={{ color: '#8feeb2' }}>
                                {' '}
                                {filteredEstimates?.title}{' '}
                              </span>
                            ) : (
                              <span style={{ color: '#8feeb2' }}>
                                {' '}
                                Estimate{' '}
                              </span>
                            )}{' '}
                            team.
                          </span>
                        ) : item.notification_type === 'project_team' ? (
                          <span>
                            {' '}
                            You have been made part of{' '}
                            {filteredProjects?.title ? (
                              <span style={{ color: '#711fff' }}>
                                {' '}
                                {filteredProjects?.title}{' '}
                              </span>
                            ) : (
                              <span style={{ color: '#711fff' }}>
                                {' '}
                                Project{' '}
                              </span>
                            )}{' '}
                            team.
                          </span>
                        ) : item.notification_type ===
                          'task_status_needs_more_work' ? (
                          <span>
                            {task?.task_type
                              ? task?.task_type === 'quick_task'
                                ? 'Quick task'
                                : 'task'
                              : ''}{' '}
                            {task?.task_name ? (
                              <span style={{ color: '#f1b963' }}>
                                {' '}
                                {task?.task_name}{' '}
                              </span>
                            ) : (
                              <span style={{ color: '#f1b963' }}> task </span>
                            )}{' '}
                            has been changed to Needs More Work.
                          </span>
                        ) : item.notification_type ===
                          'task_status_completed' ? (
                          <span>
                            {task?.task_type
                              ? task?.task_type === 'quick_task'
                                ? 'Quick task'
                                : 'task'
                              : ''}{' '}
                            {task?.task_name ? (
                              <span style={{ color: 'green' }}>
                                {' '}
                                {task?.task_name}{' '}
                              </span>
                            ) : (
                              <span style={{ color: 'green' }}> task </span>
                            )}{' '}
                            has been changed to Completed.
                          </span>
                        ) : item.notification_type === 'task_assigned' ? (
                          <span>
                            You have been assigned to the{' '}
                            {task?.task_type ? (
                              task?.task_type === 'project_task' ? (
                                <span style={{ color: 'db7093' }}>
                                  {' '}
                                  project task{' '}
                                </span>
                              ) : (
                                <span style={{ color: 'db7093' }}>
                                  {' '}
                                  project retainer task{' '}
                                </span>
                              )
                            ) : (
                              ''
                            )}{' '}
                            {task?.task_name ? (
                              <span style={{ color: '#db7093' }}>
                                {' '}
                                {task?.task_name}{' '}
                              </span>
                            ) : (
                              <span style={{ color: '#db7093' }}>task</span>
                            )}
                            .
                          </span>
                        ) : item.notification_type === 'quicktask_assigned' ? (
                          <span>
                            You have been assigned to the{' '}
                            {task?.task_type ? (
                              task?.task_type === 'quick_task' ? (
                                <span style={{ color: 'db7093' }}>
                                  {' '}
                                  quick task{' '}
                                </span>
                              ) : (
                                <span style={{ color: 'db7093' }}>task </span>
                              )
                            ) : (
                              ''
                            )}{' '}
                            {task?.task_name ? (
                              <span style={{ color: '#db7093' }}>
                                {' '}
                                {task?.task_name}{' '}
                              </span>
                            ) : (
                              <span style={{ color: '#db7093' }}>task</span>
                            )}
                            .
                          </span>
                        ) : item.notification_type === 'task_status_review' ? (
                          <span>
                            {task?.task_type
                              ? task?.task_type === 'quick_task'
                                ? 'Quick task'
                                : 'task'
                              : ''}{' '}
                            {task?.task_name ? (
                              <span style={{ color: '#710FFF' }}>
                                {' '}
                                {task?.task_name}{' '}
                              </span>
                            ) : (
                              'task'
                            )}{' '}
                            has been changed to Submitted For Review.
                          </span>
                        ) : item.notification_type === 'user_mention' ? (
                          <span>
                            {sender?.first_name
                              ? sender?.first_name
                              : sender?.email}{' '}
                            has mentioned you in{' '}
                            {channal?.name ? (
                              <span style={{ color: '#1e90ff' }}>
                                {channal?.name}
                              </span>
                            ) : (
                              <span style={{ color: '#00bfff' }}>
                                {projectFind?.name || 'General Chat'}
                              </span>
                            )}
                          </span>
                        ) : item.notification_type === 'general_chat' ? (
                          `${sender?.first_name} has just sent a message in General Chat.`
                        ) : item?.notification_type === 'chatchannel' ? (
                          `${sender?.first_name} has sent a message in Project Chat.`
                        ) : (
                          'Has Accepted your Team Invitation.'
                        )}
                      </Typography>
                    </ListItem>
                  </Slide>
                  <Divider sx={{ width: '100%' }} />
                </>
              );
            })
        ) : (
          <Alert severity="info">No New Notifications</Alert>
        )}
      </List>
    </div>
  );
}

export { HelpCard, NotificationBoard };
