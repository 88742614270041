import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import { Alert, Avatar, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RepeatIcon from '@mui/icons-material/Repeat';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import Bounce from 'react-reveal/Bounce';
import moment from 'moment/moment';

import Test from '../../Test';
import { ENDPOINT } from '../Redux/oppor';
import { NetnetField2 } from '../../../Componenets/NetField';
import { getWorkTypes } from '../../User/redux';

function WorkTypes({
  formData,
  setFormData,
  setCurrentScope,
  dileverable,
  setDeliverable,
  allWorktypes,
  calculateLOERateName,
}) {
  const [searchQuery, setSearchQuery] = useState('');
  const access_token = useSelector((state) => state.auth.user.access);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getWorkTypes({
        access_token,
      }),
    );
  }, []);

  const [newOpen] = useState(1);
  const work = useSelector((state) => state.Misc.workTypes);
  const cate = Array.isArray(work) && work.length ? work : false;
  const [tempArr, setTempArr] = useState([]);
  const filteredList = cate
    ? cate.filter((item) =>
        item.name.toLowerCase().startsWith(searchQuery.toLowerCase()),
      )
    : null;
  const listToRender = searchQuery ? filteredList : cate;

  useEffect(() => {
    if (Array.isArray(formData.deliverable) && formData.deliverable.length) {
      const work_already = work
        .filter((wt) =>
          formData.deliverable[0].deliverable_worktype.some(
            (ele) => ele.name === wt.name,
          ),
        )
        .map((wtt) => wtt.id);
      setTempArr(work_already);
    } else {
      const work_already = work
        .filter((wt) => formData.work_type.some((ele) => ele.name === wt.name))
        .map((wtt) => wtt.id);
      setTempArr(work_already);
    }
  }, []);

  const [fuzoolSelected, setFuzoolSelected] = useState([]);

  return (
    <Grid
      item
      container
      direction="column"
      alignItems="flex-start"
      sx={{
        padding: '1rem 0.56rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: { xl: '100%', lg: '100%' },
          alignItems: 'center',
        }}
      >
        <h3 className="grey-txt" style={{ fontSize: '13px' }}>
          {' '}
          Please Select The Work Types For this Estimate.
        </h3>
        <InfoOutlinedIcon sx={{ color: '#4dfad2', fontSize: '15px' }} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: { xl: '100%', lg: '100%' },
          maxHeight: '660px',
          overflow: 'auto',
          padding: '1.56rem 1rem',
          backgroundColor: '#fff',
          border: '1px solid #d9d9d9',
          borderRadius: '8px',
          margin: '1rem 0',
          gap: '1rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1rem',
            width: '100%',
          }}
        >
          <Box
            sx={{
              backgroundColor: '#8feeb2',
              padding: '1rem 1.5rem',
              borderRadius: '8px',
              color: '#000',
              width: '100%',
              fontFamily: 'Open Sans',
              fontSize: '1.1rem',
            }}
          >
            *SELECT WORKTYPE(s)
          </Box>
          <TextField
            id="outlined-basic"
            placeholder="Search Work Type Catagory"
            variant="standard"
            color="secondary"
            style={{ width: '95%', margin: '1rem 0' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {' '}
                  <SearchIcon />{' '}
                </InputAdornment>
              ),
            }}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          {newOpen === 1 && (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '0.58rem',
                marginTop: '1rem',
                maxHeight: '400px',
                overflowY: 'auto',
              }}
            >
              {listToRender ? (
                listToRender.map((item) => (
                  <Box key={item?.id}>
                    <Box
                      className="fullTabV2"
                      sx={
                        tempArr.includes(item.id)
                          ? {
                              backgroundColor: '#9152ff',
                              color: '#fff',
                              fontWeight: '600',
                              fontFamily: 'Open Sans',
                              padding: '1rem 2rem',
                            }
                          : {
                              fontWeight: '400',
                              fontFamily: 'Open Sans',
                              padding: '1rem 2rem',
                            }
                      }
                      onClick={() => {
                        setTempArr((prev) => {
                          if (prev.length) {
                            if (prev.includes(item.id)) {
                              const index = prev.indexOf(item.id);
                              prev.splice(index, 1);
                              // eslint-disable-next-line
                              return [...prev];
                              // eslint-disable-next-line
                            } else {
                              return [...prev, item.id];
                            }
                            // eslint-disable-next-line
                          } else {
                            return [item.id];
                          }
                        });
                        setFuzoolSelected([...fuzoolSelected, item]);
                      }}
                    >
                      {item.name}
                    </Box>
                  </Box>
                ))
              ) : (
                <Alert severity="info">
                  You do not any worktypes please add your own worktypes.
                </Alert>
              )}
            </Box>
          )}

          <Box
            sx={{
              marginLeft: 'auto',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <button
              type="button"
              className="create-btn"
              onClick={async () => {
                if (
                  Array.isArray(dileverable) &&
                  dileverable.length &&
                  Array.isArray(fuzoolSelected) &&
                  fuzoolSelected.length
                ) {
                  if (tempArr) {
                    const dataaa = cate.filter((item) =>
                      tempArr.includes(item.id),
                    );

                    const updatedDeliverables = dileverable.map((element) => {
                      dataaa.forEach((dt) => {
                        const available = element.deliverable_worktype.find(
                          (it) => it.name === dt.name,
                        );
                        if (!available) {
                          element.deliverable_worktype.push({
                            name: dt.name,
                            id: dt.id,
                            hours: null,
                          });
                        }
                        element.task_deliverable.forEach((tsk) => {
                          const task_available = tsk.task_worktype.find(
                            (twt) => twt.name === dt.name,
                          );
                          if (!task_available) {
                            tsk.task_worktype.push({
                              name: dt.name,
                              id: dt.id,
                              hours: null,
                            });
                          }
                        });
                      });
                      /* eslint-disable */
                      element.deliverable_worktype =
                        element.deliverable_worktype.filter((it) =>
                          dataaa.some((dt) => dt.name === it.name),
                        );

                      element.task_deliverable.forEach((tsk) => {
                        tsk.task_worktype = tsk.task_worktype.filter((twt) =>
                          dataaa.some((dt) => dt.name === twt.name),
                        );
                      });
                      return element; // Return the updated element
                    });

                    var i = 0;
                    for (i; i < dileverable.length; i++) {
                      const response = await fetch(
                        `${ENDPOINT}/api/project/deliverable/delete/${dileverable[i].id}/`,
                        {
                          method: 'DELETE',
                          headers: {
                            Authorization: `JWT ${access_token}`,
                            'Content-Type': 'application/json',
                          },
                        },
                      );

                      if (response.ok) {
                      } else {
                        const data = await response.json();
                      }
                    }
                    for (var j = 0; j < updatedDeliverables.length; j++) {
                      const res = await fetch(
                        `${ENDPOINT}/api/project/estimate/deliverable/create/`,
                        {
                          method: 'POST',
                          headers: {
                            Authorization: `JWT ${access_token}`,
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({
                            ...updatedDeliverables[j],
                            deliverable_estimate: formData.id,
                            deliverable_name:
                              updatedDeliverables[j].deliverable_name,
                            description:
                              updatedDeliverables[j].description ?? '',
                            sort_order: j + 1,
                            deliverable_worktype: updatedDeliverables[
                              j
                            ].deliverable_worktype.map((i, n) => {
                              return {
                                name: i.name,
                                id: i.id,
                                hours: i.hours ? i.hours : null,
                              };
                            }),
                            deliverable_task:
                              Array.isArray(
                                updatedDeliverables[j].task_deliverable,
                              ) &&
                              updatedDeliverables[j].task_deliverable.length
                                ? updatedDeliverables[j].task_deliverable.map(
                                    (tsk) => {
                                      return {
                                        name: tsk.task_name,
                                        task_worktypes: tsk.task_worktype.map(
                                          (wt_tsk) => {
                                            return {
                                              id: wt_tsk.id,
                                              hours: wt_tsk.hours
                                                ? wt_tsk.hours
                                                : 0,
                                            };
                                          },
                                        ),
                                      };
                                    },
                                  )
                                : [],
                            deliverable_multiplier: [],

                            deliverable_startdate:
                              updatedDeliverables[j].deliverable_startdate,
                            deliverable_enddate:
                              updatedDeliverables[j].deliverable_enddate,
                            user_price: calculateLOERateName(
                              updatedDeliverables[j],
                              allWorktypes,
                            ),
                          }),
                        },
                      );
                      if (!res.ok) {
                        return;
                      }
                    }

                    const resGet = await fetch(
                      `${ENDPOINT}/api/opportunity/estimate/${formData?.unique_id}/`,
                      {
                        method: 'GET',
                        headers: {
                          'Content-Type': 'application/json',
                          Authorization: `JWT ${access_token}`,
                        },
                      },
                    );
                    const dataGet = await resGet.json();
                    if (!resGet.ok) {
                      return;
                    }
                    setFormData(dataGet);
                    if (
                      dataGet &&
                      Array.isArray(dataGet.deliverable) &&
                      dataGet.deliverable.length
                    ) {
                      const sortedDeliverable = dataGet.deliverable
                        .map((deliverable) => ({
                          ...deliverable,
                          deliverable_worktype:
                            deliverable.deliverable_worktype.sort((a, b) =>
                              a.name.localeCompare(b.name),
                            ),
                        }))
                        .sort((a, b) => a.sort_order - b.sort_order);

                      setDeliverable(sortedDeliverable);
                      setCurrentScope('colab');
                    }
                  }
                } else {
                  setCurrentScope('colab');
                }
                 /* eslint-enable */
              }}
            >
              Save
            </button>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}

function TargetGoals({ setFormData, setCurrentScope, formData }) {
  const [dataa, setDataa] = useState({
    payment_type: 'fixed',
    budget: formData.budget ?? 0,
    est_start_date: formData.est_startdate ? dayjs(formData.est_startdate) : '',
    est_finish_date: formData.est_enddate ? dayjs(formData.est_enddate) : '',
    duration: formData.duration ? formData.duration : '',
    month_to_month: null,
    date_selected: false,
  });
  const [repeat, setRepeat] = useState(2);
  function updateBudgetFormData(e) {
    const rawValue = e.target.value.replace(/\D/g, '');
    const numericValue = parseFloat(rawValue);

    if (!Number.isNaN(numericValue) && numericValue >= 0) {
      setDataa({
        ...dataa,
        budget: numericValue,
      });
    } else if (rawValue === '') {
      setDataa({
        ...dataa,
        budget: 0,
      });
    }
  }
  return (
    // eslint-disable-next-line
    <>
      {formData.type === 'retainer' ? (
        <Grid
          item
          container
          direction="column"
          alignItems="flex-start"
          sx={{
            padding: '1rem 0.56rem',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center',
            }}
          >
            {formData.type === 'retainer' ? (
              <h3 className="grey-txt" style={{ fontSize: '13px' }}>
                Please Select Duration Term, Budget and Start Dates
              </h3>
            ) : (
              <h3 className="grey-txt" style={{ fontSize: '13px' }}>
                Please Select Payment Type, Budget and Dates
              </h3>
            )}
            <InfoOutlinedIcon sx={{ color: '#4dfad2', fontSize: '15px' }} />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              maxHeight: '660px',
              overflow: 'auto',
              padding: '1.56rem 1rem',
              backgroundColor: '#fff',
              border: '1px solid #d9d9d9',
              borderRadius: '8px',
              margin: '1rem 0',
              gap: '1rem',
            }}
          >
            <Box
              sx={{
                backgroundColor: '#f2f5f5',
                width: '100%',
                padding: '10px 10px',
                borderRadius: '10px',
              }}
            >
              <p style={{ textAlign: 'start', fontSize: '17px' }}>
                *Select your Term (Duration)
              </p>
              <Box sx={{ display: 'flex', gap: '15px', marginTop: '10px' }}>
                <Box sx={{ display: 'flex' }}>
                  <TextField
                    placeholder="____  ____  ____"
                    value={dataa.duration}
                    onChange={(e) => {
                      setDataa({
                        ...dataa,
                        duration: e.target.value,
                      });
                    }}
                    style={{
                      width: 180,
                      backgroundColor: '#fff',
                      borderStartStartRadius: '10px',
                      borderEndStartRadius: '10px',
                    }}
                    color="secondary"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                        },
                        '&:hover fieldset': {
                          border: 'none',
                        },
                        '&.Mui-focused fieldset': {
                          border: 'none',
                        },

                        '&.Mui-error fieldset': {
                          borderColor: 'red',
                        },

                        '&.Mui-disabled fieldset': {
                          border: 'none',
                        },
                      },
                      '& .MuiFormLabel-root': {
                        color: '#03071e66',
                      },
                      '& .MuiFormLabel-root.Mui-focused': {
                        color: '#5a0bdf',
                      },
                      '& .MuiFormLabel-root.Mui-error': {
                        color: 'red',
                      },
                    }}
                    type="number"
                  />
                  <Box
                    sx={{
                      width: '80px',
                      backgroundColor: '#e6e6e6',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '0 10px',
                      borderStartEndRadius: '10px',
                      borderEndEndRadius: '10px',
                    }}
                  >
                    {repeat === 2 ? (
                      <p>Month(s)</p>
                    ) : repeat === 3 ? (
                      <p>Year(s)</p>
                    ) : repeat === 1 ? (
                      <p>Week(s)</p>
                    ) : null}
                  </Box>
                </Box>
                <Box
                  sx={{
                    backgroundColor: '#ebeded',
                    width: { xl: '40%', lg: '50%', md: '50%' },
                    display: 'flex',
                    padding: '10px 10px',
                    gap: { xl: '15px', lg: '6px', md: '5px' },
                    borderRadius: '10px',
                    alignItems: 'center',
                  }}
                >
                  <TextField
                    type="checkbox"
                    color="secondary"
                    sx={{
                      outline: 'none',
                      width: '20px',
                      height: '20px',
                    }}
                    onChange={() =>
                      setDataa({
                        ...dataa,
                        month_to_month:
                          repeat === 2
                            ? 'month'
                            : repeat === 1
                              ? 'week'
                              : repeat === 3
                                ? 'year'
                                : null,
                      })
                    }
                  />
                  <RepeatIcon
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      if (repeat === 1) {
                        setRepeat(2);
                      } else if (repeat === 2) {
                        setRepeat(3);
                      } else {
                        setRepeat(1);
                      }
                    }}
                  />
                  {repeat === 1 ? (
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        fontSize: { xl: '16px', lg: '9px' },
                      }}
                    >
                      Week to Week
                    </Typography>
                  ) : repeat === 2 ? (
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        fontSize: { xl: '16px', lg: '9px' },
                      }}
                    >
                      Month to Month
                    </Typography>
                  ) : repeat === 3 ? (
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        fontSize: { xl: '16px', lg: '9px' },
                      }}
                    >
                      Year to Year
                    </Typography>
                  ) : null}
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                width: '100%',
                backgroundColor: '#d2b8ff',
                borderRadius: '8px',
                padding: '1rem 1.1rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <p className="baseTypography2">Target Budget</p>
              <Box sx={{ display: 'flex' }}>
                <TextField
                  placeholder="Enter Amount"
                  type="text"
                  defaultValue={
                    dataa.budget
                      ? Number(dataa.budget).toLocaleString()
                      : formData.budget
                        ? Number(formData.budget).toLocaleString()
                        : '0'
                  }
                  value={
                    dataa?.budget ? Number(dataa.budget).toLocaleString() : '0'
                  }
                  onChange={(e) => {
                    updateBudgetFormData(e);
                  }}
                  style={{
                    width: 150,
                    backgroundColor: '#fff',
                    borderStartStartRadius: '10px',
                    borderEndStartRadius: '10px',
                  }}
                  color="secondary"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none',
                      },

                      '&.Mui-error fieldset': {
                        borderColor: 'red',
                      },

                      '&.Mui-disabled fieldset': {
                        border: 'none',
                      },
                    },
                    '& .MuiFormLabel-root': {
                      color: '#03071e66',
                    },
                    '& .MuiFormLabel-root.Mui-focused': {
                      color: '#5a0bdf',
                    },
                    '& .MuiFormLabel-root.Mui-error': {
                      color: 'red',
                    },
                  }}
                />
                <Box
                  sx={{
                    width: '80px',
                    backgroundColor: '#e6e6e6',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0 10px',
                    borderStartEndRadius: '10px',
                    borderEndEndRadius: '10px',
                  }}
                >
                  <p>/Month</p>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                width: '100%',
                backgroundColor: '#d4e8fb',
                borderRadius: '8px',
                padding: '1rem 1.1rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <p className="baseTypography2">Est Start Date</p>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="DD/MM/YYYY"
                  inputFormat="dd-MM-yyyy"
                  value={dataa.est_start_date || null}
                  onChange={(newValue) => {
                    setDataa({
                      ...dataa,
                      est_start_date: newValue,
                      date_selected: true,
                    });
                  }}
                  sx={{
                    width: '100%',
                    height: '60px',
                    '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                      {
                        borderColor: '#711FFF',
                      },
                    '& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused':
                      {
                        color: '#711FFF',
                      },
                    '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
                      {
                        borderColor: '#711FFF',
                      },
                    '& .MuiButtonBase-root MuiIconButton-root MuiIconButton-edgeEnd MuiIconButton-sizeMedium':
                      {
                        marginRight: '0px',
                        position: 'absolute',
                        borderRadius: '0px !important',
                        width: '100% !important',
                        height: '60px',
                      },
                    '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root': {
                      marginRight: '0px',
                      position: 'absolute',
                      borderRadius: '0px !important',
                      width: '100% !important',
                      height: '60px',
                    },
                    '& .MuiInputAdornment-root': {
                      position: 'absolute',
                      top: '28px',
                      left: '-6px',
                      borderRadius: '0px',
                      width: '100%',
                    },
                    '& .MuiSvgIcon-root': {
                      position: 'absolute',
                      right: '10px',
                    },
                    '& .MuiInputBase-root': {
                      width: '100%',
                      position: 'relative',
                    },
                    '& .MuiOutlinedInput-input': {
                      width: '100%',
                      height: '60px',
                      padding: '0px',
                      color: 'black',
                      marginLeft: '10px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      width: '100%',
                      height: '60px',
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: '1px solid #d4e8fb',
                          },
                          '&:hover fieldset': {
                            border: '1px solid #d4e8fb',
                          },
                          '&.Mui-error fieldset': {
                            border: '1px solid #d4e8fb',
                          },
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>

            <Box
              sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}
            >
              <button
                type="button"
                className="cancel-btn"
                onClick={() => {
                  setDataa({
                    payment_type: 'fixed',
                    budget: 0,
                    est_start_date: '',
                    est_finish_date: '',
                  });
                }}
              >
                Reset
              </button>
              <button
                type="button"
                className="create-btn"
                onClick={() => {
                  setFormData({
                    ...formData,
                    ...dataa,
                    budget: dataa.budget ? dataa.budget : 0,
                    est_startdate: dataa.est_start_date
                      ? new Date(dataa.est_start_date).toISOString()
                      : null,
                    est_enddate: dataa.est_finish_date
                      ? new Date(dataa.est_finish_date).toISOString()
                      : null,
                  });
                  setCurrentScope('description');
                }}
              >
                Save
              </button>
            </Box>
          </Box>
        </Grid>
      ) : (
        <Grid
          item
          container
          direction="column"
          alignItems="flex-start"
          sx={{
            padding: '1rem 0.56rem',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <h3 className="grey-txt" style={{ fontSize: '13px' }}>
              Please Select Payment Type, Budget and Dates
            </h3>
            <InfoOutlinedIcon sx={{ color: '#4dfad2', fontSize: '15px' }} />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              padding: '1.56rem 1rem',
              backgroundColor: '#fff',
              border: '1px solid #d9d9d9',
              borderRadius: '8px',
              margin: '1rem 0',
              gap: '1rem',
            }}
          >
            <Box
              sx={{
                width: '100%',
                backgroundColor: '#d2b8ff',
                borderRadius: '8px',
                padding: '1rem 1.1rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <p className="baseTypography2">Target Budget</p>
              <TextField
                label="Target Budget"
                type="text"
                defaultValue={
                  dataa.budget
                    ? Number(dataa.budget).toLocaleString()
                    : formData.budget
                      ? Number(formData.budget).toLocaleString()
                      : '0'
                }
                value={
                  dataa?.budget ? Number(dataa.budget).toLocaleString() : '0'
                }
                onChange={(e) => {
                  updateBudgetFormData(e);
                }}
                color="secondary"
                sx={{
                  width: '53%',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#d2b8ff',
                    },
                    '&:hover fieldset': {
                      borderColor: '#711fff',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#5a0bdf',
                    },

                    '&.Mui-error fieldset': {
                      borderColor: 'red',
                    },

                    '&.Mui-disabled fieldset': {
                      borderColor: 'grey',
                    },
                  },
                  '& .MuiFormLabel-root': {
                    color: '#03071e66',
                  },
                  '& .MuiFormLabel-root.Mui-focused': {
                    color: '#5a0bdf',
                  },
                  '& .MuiFormLabel-root.Mui-error': {
                    color: 'red',
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                width: '100%',
                backgroundColor: '#d4e8fb',
                borderRadius: '8px',
                padding: '1rem 1.1rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <p className="baseTypography2">Est Start Date</p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Start Date"
                  inputFormat="MM-dd-yyyy"
                  value={dataa.est_start_date || null}
                  onChange={(newValue) => {
                    setDataa({
                      ...dataa,
                      est_start_date: newValue,
                      date_selected: true,
                    });
                  }}
                  sx={{
                    width: '53%',
                    height: '60px',
                    '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                      {
                        borderColor: '#711FFF',
                      },
                    '& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused':
                      {
                        color: '#711FFF',
                      },
                    '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
                      {
                        borderColor: '#711FFF',
                      },
                    '& .MuiButtonBase-root MuiIconButton-root MuiIconButton-edgeEnd MuiIconButton-sizeMedium':
                      {
                        marginRight: '0px',
                        position: 'absolute',
                        borderRadius: '0px !important',
                        width: '100% !important',
                        height: '60px',
                      },
                    '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root': {
                      marginRight: '0px',
                      position: 'absolute',
                      borderRadius: '0px !important',
                      width: '100% !important',
                      height: '60px',
                    },
                    '& .MuiInputAdornment-root': {
                      position: 'absolute',
                      top: '28px',
                      left: '-6px',
                      borderRadius: '0px',
                      width: '100%',
                    },
                    '& .MuiSvgIcon-root': {
                      position: 'absolute',
                      right: '10px',
                    },
                    '& .MuiInputBase-root': {
                      width: '100%',
                      position: 'relative',
                    },
                    '& .MuiOutlinedInput-input': {
                      width: '100%',
                      height: '60px',
                      padding: '0px',
                      color: 'black',
                      marginLeft: '10px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      width: '100%',
                      height: '60px',
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: '1px solid #d4e8fb',
                          },
                          '&:hover fieldset': {
                            border: '1px solid #d4e8fb',
                          },
                          '&.Mui-error fieldset': {
                            border: '1px solid #d4e8fb',
                          },
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>

            <Box
              sx={{
                width: '100%',
                backgroundColor: '#d4e8fb',
                borderRadius: '8px',
                padding: '1rem 1.1rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <p className="baseTypography2">Est Finish Date</p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Finish Date"
                  inputFormat="MM-dd-yyyy"
                  value={dataa.est_finish_date || null}
                  onChange={(newValue) => {
                    setDataa({
                      ...dataa,
                      est_finish_date: newValue,
                      date_selected: true,
                    });
                  }}
                  sx={{
                    width: '53%',
                    height: '60px',
                    '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                      {
                        borderColor: '#711FFF',
                      },
                    '& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused':
                      {
                        color: '#711FFF',
                      },
                    '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
                      {
                        borderColor: '#711FFF',
                      },
                    '& .MuiButtonBase-root MuiIconButton-root MuiIconButton-edgeEnd MuiIconButton-sizeMedium':
                      {
                        marginRight: '0px',
                        position: 'absolute',
                        borderRadius: '0px !important',
                        width: '100% !important',
                        height: '60px',
                      },
                    '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root': {
                      marginRight: '0px',
                      position: 'absolute',
                      borderRadius: '0px !important',
                      width: '100% !important',
                      height: '60px',
                    },
                    '& .MuiInputAdornment-root': {
                      position: 'absolute',
                      top: '28px',
                      left: '-6px',
                      borderRadius: '0px',
                      width: '100%',
                    },
                    '& .MuiSvgIcon-root': {
                      position: 'absolute',
                      right: '10px',
                    },
                    '& .MuiInputBase-root': {
                      width: '100%',
                      position: 'relative',
                    },
                    '& .MuiOutlinedInput-input': {
                      width: '100%',
                      height: '60px',
                      padding: '0px',
                      color: 'black',
                      marginLeft: '10px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      width: '100%',
                      height: '60px',
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        border: '1px solid gray',
                        '& .MuiOutlinedInput-root': {
                          border: '1px solid gray',
                          width: '280px',
                          padding: '0px',
                          '& fieldset': {
                            border: '1px solid #000',
                          },
                          '&:hover fieldset': {
                            border: '1px solid gray',
                          },
                          '&.Mui-error fieldset': {
                            border: '1px solid #d4e8fb',
                          },
                        },
                        '& .MuiFormLabel-root-MuiInputLabel-root .Mui-error': {
                          color: '#000',
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box
              sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}
            >
              <button
                type="button"
                className="cancel-btn"
                onClick={() => {
                  setFormData({
                    ...formData,
                    budget: 0,
                    est_start_date: '',
                    est_finish_date: '',
                  });
                  setDataa({
                    payment_type: 'fixed',
                    budget: 0,
                    est_start_date: '',
                    est_finish_date: '',
                  });
                }}
              >
                Reset
              </button>
              <button
                type="button"
                className="create-btn"
                onClick={() => {
                  if (dataa.est_start_date && dataa.est_finish_date) {
                    setFormData({
                      ...formData,
                      ...dataa,
                      budget: dataa.budget ? dataa.budget : 0,
                      est_startdate: new Date(
                        new Date(dataa.est_start_date).setDate(
                          new Date(dataa.est_start_date).getDate(),
                        ),
                      ).toISOString(),
                      est_enddate: new Date(
                        new Date(dataa.est_finish_date).setDate(
                          new Date(dataa.est_finish_date).getDate(),
                        ),
                      ).toISOString(),
                    });
                  } else {
                    setFormData({
                      ...formData,
                      budget: dataa.budget ? dataa.budget : 0,
                    });
                  }
                  setCurrentScope('description');
                }}
              >
                Save
              </button>
            </Box>
          </Box>
        </Grid>
      )}
    </>
  );
}

function FirstStep({
  formData,
  setFormData,
  amIColab,
  dileverable,
  setDeliverable,
  allWorktypes,
  calculateLOERateName,
}) {
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [selected, setSelected] = useState(
    formData?.type === 'project' ? 1 : 2,
  );
  const [currentScope, setCurrentScope] = useState(null);
  const currentCompanies = useSelector((state) => state.Contacts.companies);
  const currentPeoples1 = useSelector((state) => state.Contacts.persons);
  const [description, setDiscription] = useState('');
  // eslint-disable-next-line
  const [isColab, setIsColab] = useState(amIColab);

  // eslint-disable-next-line
  function ClientSelector() {
    const [open, setOpen] = useState(
      formData.company && !formData.contact
        ? 'people'
        : formData.contact && !formData.company
          ? 'company'
          : !formData.contact && !formData.company
            ? 'company'
            : '',
    );
    const [selected, setSelected] = useState({
      contact: '',
      company: '',
      index: '',
    });
    const [myFormData, setMyFormData] = useState({
      contact: formData.contact ? formData.contact : '',
      company: formData.company ? formData.company : '',
    });
    const currentPeoples =
      currentPeoples1 && selected.company
        ? currentPeoples1.filter((item) => item.company === selected.company)
        : currentPeoples1;

    const [searchPeopleQuery, setSearchPeopleQuery] = useState('');
    const [searchQueryComp, setSearchQueryComp] = useState('');
    const currentCompaniesFiltered = currentCompanies
      ? currentCompanies.filter((item) =>
          item.name.toLowerCase().startsWith(searchQueryComp.toLowerCase()),
        )
      : null;
    const currentPeopleFiltered = currentPeoples
      ? currentPeoples.filter((item) =>
          item.first_name
            .toLowerCase()
            .startsWith(searchPeopleQuery.toLowerCase()),
        )
      : null;
    return (
      <Grid
        item
        container
        direction="column"
        alignItems="flex-start"
        sx={{
          padding: '1rem 0.56rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: { xl: '100%', lg: '100%' },
            alignItems: 'center',
          }}
        >
          <h3 className="grey-txt" style={{ fontSize: '13px' }}>
            Please Select The Company and Its Representative
          </h3>
          <InfoOutlinedIcon sx={{ color: '#4dfad2', fontSize: '15px' }} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: { xl: '100%', lg: '100%' },
            maxHeight: '660px',
            overflow: 'auto',
            padding: '1.56rem 1rem',
            backgroundColor: '#fff',
            border: '1px solid #d9d9d9',
            borderRadius: '8px',
            margin: '1rem 0',
            gap: '1rem',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Box
              sx={{
                backgroundColor: '#e7daff',
                padding: '1rem 1.5rem',
                borderRadius: '8px',
                color: '#000',
                width: '100%',
                fontFamily: 'Open Sans',
                fontSize: '1.1rem',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {selected.company || formData.company ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    color: 'blue',
                  }}
                >
                  <Avatar
                    src={
                      selected.company
                        ? selected.company
                        : formData.company
                          ? (currentCompanies.find(
                              (item) => item.id === formData.company,
                            )?.avatar ?? '/sharp.png')
                          : '/sharp.png'
                    }
                    alt={
                      selected.company
                        ? selected.company
                        : formData.company
                          ? currentCompanies.find(
                              (item) => item.id === formData.company,
                            )?.name
                          : ''
                    }
                    sx={{ marginRight: '1rem' }}
                  />
                  {selected.company
                    ? selected.company
                    : formData.company
                      ? currentCompanies.find(
                          (item) => item.id === formData.company,
                        )?.name
                      : ''}{' '}
                </div>
              ) : (
                '*Select Company'
              )}
              <ArrowDropDownIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (open === 'company') {
                    setOpen('people');
                  } else {
                    setOpen('company');
                  }
                }}
              />
            </Box>
            {open === 'company' && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%',
                  gap: '1rem',
                }}
              >
                <TextField
                  id="outlined-basic"
                  placeholder="Search Company"
                  variant="standard"
                  color="secondary"
                  style={{ width: '100%', margin: '1rem 0' }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {' '}
                        <SearchIcon />{' '}
                      </InputAdornment>
                    ),
                  }}
                  value={searchQueryComp}
                  onChange={(e) => setSearchQueryComp(e.target.value)}
                />

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    maxHeight: {
                      xl: selected.company ? '250px' : '265px',
                      lg: selected.company ? '220px' : '239px',
                      md: selected.company ? '193px' : '212px',
                    },
                    overflowY: 'auto',
                    gap: '1rem',
                  }}
                >
                  {currentCompaniesFiltered ? (
                    currentCompaniesFiltered.map((item, index) => (
                      <article
                        key={item?.id}
                        className="fullTabV2"
                        style={{
                          backgroundColor:
                            selected.company && index === selected.index
                              ? '#D2B8FF'
                              : 'white',
                          padding: '10px',
                        }}
                        onClick={() => {
                          setSelected({
                            company: item.name,
                            index,
                          });
                          setMyFormData({
                            ...myFormData,
                            company: item.id,
                          });
                          setOpen('people');
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                          }}
                        >
                          <Avatar
                            src={item.avatar ? item.avatar : 'Sharp.png'}
                            alt={item.name}
                            sx={{ marginLeft: '1rem', marginRight: '1rem' }}
                          />{' '}
                          <p
                            className="company-name"
                            style={{
                              color:
                                selected.company && index === selected.index
                                  ? '#ffffff'
                                  : 'blue',
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            {item.name}
                            <div className="dFlex" style={{ gap: '0.5rem' }}>
                              <p
                                className="state"
                                style={{ fontSize: '12px', color: '#b8b8b8' }}
                              >
                                {item && item.city ? `${item.city},` : ''}
                              </p>
                              <p
                                className="state"
                                style={{ fontSize: '12px', color: '#b8b8b8' }}
                              >
                                {item && item.state ? item.state : ''}
                              </p>
                            </div>
                          </p>
                        </Box>{' '}
                      </article>
                    ))
                  ) : (
                    <p className="txtCenter"> You Dont Have Any Companies</p>
                  )}
                </Box>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              backgroundColor: '#e7daff',
              padding: '1rem 1.5rem',
              borderRadius: '8px',
              color: '#000',
              width: '100%',
              fontFamily: 'Open Sans',
              fontSize: '1.1rem',
            }}
          >
            {selected.contact || formData.contact ? (
              <div
                className="dFlex"
                style={{ gap: '0.5rem', alignItems: 'center' }}
              >
                <Avatar
                  src={`${ENDPOINT}${selected?.contact?.avatar}`}
                  alt={
                    selected?.contact?.first_name
                      ? selected?.contact?.first_name
                      : currentPeoples.find(
                          (item) => item.id === formData.contact,
                        )?.first_name
                  }
                  sx={{ marginRight: '.8rem' }}
                />
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  <p className="company-name" style={{ color: 'black' }}>
                    {selected?.contact?.first_name
                      ? selected?.contact?.first_name
                      : currentPeoples.find(
                          (item) => item.id === formData.contact,
                        )?.first_name}{' '}
                    {selected?.contact?.last_name
                      ? selected?.contact?.last_name
                      : currentPeoples?.find(
                          (item) => item.id === formData.contact,
                        )?.last_name}
                  </p>

                  <p className="state">
                    {selected?.contact && selected?.contact?.title
                      ? selected?.contact?.title
                      : ''}
                  </p>
                  <p className="state">
                    {selected && selected?.contact?.company
                      ? selected?.contact?.company?.name
                      : ''}
                  </p>
                </Box>
                <ArrowDropDownIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (open === 'company') {
                      setOpen('people');
                    } else {
                      setOpen('company');
                    }
                  }}
                />
              </div>
            ) : (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
                onClick={() => {
                  if (open === 'company') {
                    setOpen('people');
                  } else {
                    setOpen('company');
                  }
                }}
                style={{ cursor: 'pointer' }}
              >
                <p> Select Person(s)</p>
                <ArrowDropDownIcon />
              </Box>
            )}
          </Box>
          {open === 'people' && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                gap: '1rem',
              }}
            >
              <TextField
                id="outlined-basic"
                placeholder="Search Person"
                variant="standard"
                color="secondary"
                style={{ width: '100%', margin: '1rem 0' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {' '}
                      <SearchIcon />{' '}
                    </InputAdornment>
                  ),
                }}
                value={searchPeopleQuery}
                onChange={(e) => setSearchPeopleQuery(e.target.value)}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  maxHeight: {
                    xl: selected.company ? '238px' : '240px',
                    lg: selected.company ? '208px' : '226px',
                    md: selected.company ? '180px' : '200px',
                  },
                  gap: '1rem',
                  overflowY: 'scroll',
                }}
              >
                {currentPeopleFiltered ? (
                  currentPeopleFiltered.map((item) => (
                    <article
                      key={item?.id}
                      className="fullTabV2"
                      onClick={() => {
                        setSelected({
                          ...selected,
                          contact: item,
                        });
                        setMyFormData({
                          ...myFormData,
                          contact: item.id,
                        });
                        setOpen(null);
                      }}
                      style={{
                        padding: '10px',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        <div
                          className="dFlex"
                          style={{ gap: '0.5rem', alignItems: 'center' }}
                        >
                          <Avatar
                            src={`${ENDPOINT}${item.avatar}`}
                            alt={item.first_name}
                          />
                          <Box
                            sx={{
                              width: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                            }}
                          >
                            <p
                              className="company-name"
                              style={{ color: 'black' }}
                            >
                              {item.first_name} {item?.last_name}
                            </p>
                            <p
                              className="state"
                              style={{ color: '#c1a2fa', fontSize: '12px' }}
                            >
                              {item && item.contact_type
                                ? item.contact_type
                                : ''}
                            </p>
                            <p
                              className="state"
                              style={{ color: 'blue', fontSize: '12px' }}
                            >
                              {item && item.company ? item.company : ''}
                            </p>
                          </Box>
                        </div>
                      </Box>{' '}
                    </article>
                  ))
                ) : (
                  <p className="txtCenter"> You Dont Have Any People</p>
                )}
              </Box>
            </Box>
          )}
          <Box
            sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}
          >
            <button
              type="button"
              className="cancel-btn"
              onClick={() => {
                setMyFormData({
                  contact: '',
                  company: '',
                });
                setSelected({
                  contact: '',
                  company: '',
                });
                setOpen('company');
              }}
            >
              Reset
            </button>
            <button
              type="button"
              className="create-btn"
              onClick={() => {
                setFormData({
                  ...formData,
                  ...myFormData,
                });
                setCurrentScope('goals');
              }}
            >
              Save
            </button>
          </Box>
        </Box>
      </Grid>
    );
  }

  // eslint-disable-next-line
  function Description({ setDiscription }) {
    const [html, setHtml] = useState(formData.description ?? '<p></p>');
    const myTheme = createTheme({});

    const [openLink, setOpenLink] = useState(false);
    const [btn, setBtn] = useState();
    const check = (e) => {
      setBtn(e);
    };
    return (
      <Grid
        item
        container
        direction="column"
        alignItems="flex-start"
        sx={{
          padding: '1rem 0.56rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <h3 className="grey-txt" style={{ fontSize: '13px' }}>
            Please Add Your descriptions
          </h3>
          <InfoOutlinedIcon sx={{ color: '#4dfad2', fontSize: '15px' }} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%',
            padding: '1.56rem 1rem',
            backgroundColor: '#fff',
            border: '1px solid #d9d9d9',
            borderRadius: '8px',
            margin: '1rem 0',
            gap: '1rem',
            maxHeight: '660px',
          }}
        >
          <Box
            sx={{
              backgroundColor: '#f8f9fa',
              borderRadius: '8px',
              fontFamily: 'Open Sans',
              color: '#000',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '0.8rem',
              padding: '1.56rem 1rem',
              width: '100%',
            }}
            className="Editor-para2"
          >
            <p className="baseTypography2" style={{ fontWeight: 'bold' }}>
              *Description
            </p>
            <ThemeProvider theme={myTheme}>
              <Box
                className="Test"
                sx={{
                  width: '100%',
                  minHeight: '340px',
                  paddingBottom: '0.1rem',
                  border: '1px solid #a9a9a9',
                  borderRadius: '5px',
                  '&:hover, &:focus-within': {
                    // Apply the same border color on hover and focus
                    border: '1px solid #711fff',
                  },
                }}
              >
                <Test
                  check={check}
                  btn={btn}
                  my_logic={true}
                  open={openLink}
                  setOpen={setOpenLink}
                  setHtml={setHtml}
                  html={html}
                />
              </Box>
            </ThemeProvider>
          </Box>
          <Box
            sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}
          >
            <button type="button" className="cancel-btn">
              Reset
            </button>
            <button
              type="button"
              className="create-btn"
              onClick={() => {
                setCurrentScope('team');
                setDiscription(html);
                setFormData({
                  ...formData,
                  description: html,
                });
              }}
            >
              Save
            </button>
          </Box>
        </Box>
      </Grid>
    );
  }

  // eslint-disable-next-line
  function TeamMembers({ formData, setSelectedTeam }) {
    const currentTeam = useSelector((state) => state.userTeam.team);
    const [members1, setMembers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const currentTeamFiltered = currentTeam.member
      ? currentTeam.member.filter((item) =>
          item.first_name.toLowerCase().startsWith(searchQuery.toLowerCase()),
        )
      : null;
    const Members = currentTeamFiltered.filter(
      (item) => item.first_name !== 'First Name',
    );

    useEffect(() => {
      if (
        Array.isArray(formData.subteam_estimate) &&
        formData.subteam_estimate.length &&
        formData.subteam_estimate[0].members.length
      ) {
        const member_already = formData.subteam_estimate[0].members.map(
          (item) => item.email,
        );
        setMembers(member_already);
      }
    }, []);

    return (
      <Grid
        item
        container
        direction="column"
        alignItems="flex-start"
        sx={{
          padding: '1rem 0.56rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: { xl: '100%', lg: '100%' },
            alignItems: 'center',
          }}
        >
          <h3 className="grey-txt" style={{ fontSize: '13px' }}>
            Please Select Your Team for this Estimate
          </h3>
          <InfoOutlinedIcon sx={{ color: '#4dfad2', fontSize: '15px' }} />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: { xl: '100%', lg: '100%' },
            maxHeight: '660px',
            overflow: 'auto',
            padding: '1.56rem 1rem',
            backgroundColor: '#fff',
            border: '1px solid #d9d9d9',
            borderRadius: '8px',
            margin: '1rem 0',
            gap: '1rem',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Box
              sx={{
                backgroundColor: '#e9ecef',
                padding: '1rem 1.5rem',
                borderRadius: '8px',
                color: '#000',
                width: '100%',
                fontFamily: 'Open Sans',
                fontSize: '1.1rem',
              }}
            >
              Select Your Team
            </Box>
            <TextField
              id="outlined-basic"
              placeholder="Search Team"
              variant="standard"
              color="secondary"
              style={{ width: '100%', margin: '1rem 0' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {' '}
                    <SearchIcon />{' '}
                  </InputAdornment>
                ),
              }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Box
              sx={{
                width: '100%',
                maxHeight: { xl: '310px', lg: '275px', md: '245px' },
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '1rem',
                marginTop: '1rem',
                marginBottom: '1rem',
              }}
            >
              {Members && Members
                ? Members.map((item) => (
                    <Box
                      key={item?.id ?? item?.email}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        jusitfyContent: 'space-between',
                        padding: '1rem 1rem',
                        cursor: 'pointer',
                        backgroundColor: members1.includes(item.email)
                          ? '#9152ff'
                          : 'white',
                      }}
                      className="fullTabV2"
                      onClick={() => {
                        if (!members1.includes(item.email)) {
                          setMembers([...members1, item.email]);
                        } else {
                          setMembers(
                            members1.filter((param) => param !== item.email),
                          );
                        }
                      }}
                    >
                      <div
                        className="dFlex"
                        style={{ gap: '0.5rem', alignItems: 'center' }}
                      >
                        <Avatar
                          src={`${ENDPOINT}${item.avatar}`}
                          alt="Contact"
                        />
                        <Box
                          sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            color: members1.includes(item.email)
                              ? 'white'
                              : 'blue',
                          }}
                        >
                          <p
                            className="company-name"
                            style={{
                              color: members1.includes(item.email)
                                ? 'white'
                                : 'blue',
                            }}
                          >
                            {item.first_name} {item?.last_name}
                          </p>
                          <p className="state">{item.title}</p>
                          <p className="state">
                            {item.company ? item.company.name : ''}
                          </p>
                        </Box>
                      </div>
                    </Box>
                  ))
                : 'You dont have any Team'}
            </Box>
            <Box
              sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}
            >
              <button type="button" className="cancel-btn">
                Reset
              </button>
              <button
                type="button"
                className="create-btn"
                onClick={() => {
                  // ;
                  if (members1) {
                    const filtered = currentTeam.member.filter((item) =>
                      members1.includes(item.email),
                    );
                    setSelectedTeam(filtered);
                    setFormData({
                      ...formData,
                      members: members1,
                      subteam_estimate: [
                        {
                          ...formData.subteam_estimate,
                          members: [...filtered],
                        },
                      ],
                    });
                    setCurrentScope('types');
                  }
                }}
              >
                Save
              </button>
            </Box>
          </Box>
        </Box>
      </Grid>
    );
  }

  // eslint-disable-next-line
  function FormStack({}) {
    // eslint-disable-next-line
    const [realData, setRealData] = useState({
      company: formData.company
        ? currentCompanies.filter((item) => item.id === formData.company)[0]
        : {},
      contact: formData.contact
        ? currentPeoples1.filter((item) => item.id === formData.contact)[0]
        : {},
    });
    const MemoizedAvatar = React.memo(Avatar);
    return (
      <Grid
        direction="column"
        alignItems="flex-start"
        container
        item
        xs={12}
        md={5.75}
        lg={5.73}
        xl={5.52}
        sx={{
          gap: '1rem',
          marginTop: '1rem',
          zIndex: 2,
          background: '#fff',
        }}
      >
        {!isColab && (
          <Box
            sx={{
              backgroundColor: '#F2EBFF',
              borderRadius: '8px',
              padding: {
                xl: '14px 23px',
                lg: '10px 18px',
                md: '8px 15px',
                sm: '6px 12px',
              },
              position: 'relative',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ width: '100%' }}>
              {formData.company || formData.contact ? (
                <div>
                  <h3 className="sub-title2">Client Information</h3>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <MemoizedAvatar
                        src={
                          realData?.company?.avatar
                            ? realData?.company?.avatar
                            : '/sharp.png'
                        }
                        alt={
                          realData?.company?.name
                            ? realData?.company?.name
                            : 'No'
                        }
                        style={{ marginRight: '10px' }}
                      />
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                          alignItems: 'flex-start',
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: {
                              xl: '15px',
                              lg: '12px',
                              md: '11px',
                              sm: '10px',
                            },
                            fontFamily: 'Open Sans',
                          }}
                          className="company-name"
                        >
                          {realData?.company?.name ?? 'No Company'}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: {
                              xl: '14px',
                              lg: '12px',
                              md: '11px',
                              sm: '10px',
                            },
                            fontFamily: 'Open Sans',
                          }}
                          className="baseTypography"
                        >
                          {realData?.company?.city ?? ''}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        padding: {
                          xl: '0 0 0 2.5rem',
                          lg: '0 0 0 1.5rem',
                          md: '0 0 0 1rem',
                          sm: '0 0 0 .7rem',
                        },
                        borderLeft: '1px solid #000',
                        gap: '1rem',
                      }}
                    >
                      <MemoizedAvatar
                        src={realData?.contact?.avatar ?? 'sharp/img.png'}
                        alt={realData?.contact?.first_name ?? 'No'}
                      />
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                          alignItems: 'flex-start',
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              fontSize: {
                                xl: '15px',
                                lg: '13px',
                                md: '12px',
                                sm: '10px',
                              },
                              fontFamily: 'Open Sans',
                            }}
                            className="baseTypography"
                          >
                            {realData.contact
                              ? `${realData?.contact?.first_name || ''} ${realData?.contact?.last_name || ''}`.trim()
                              : 'No Person Selected'}
                          </Typography>
                          <Typography
                            className="company-name"
                            sx={{
                              color: '#000',
                              fontSize: {
                                xl: '13px',
                                lg: '11px',
                                md: '11px',
                                sm: '10px',
                              },
                            }}
                          >
                            {realData?.contact?.contact_type ?? ''}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: {
                                xl: '13px',
                                lg: '11px',
                                md: '11px',
                                sm: '10px',
                              },
                              fontFamily: 'Open Sans',
                            }}
                            className="company-name"
                          >
                            {realData?.contact?.company ?? ''}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </div>
              ) : (
                <div>
                  <h3 className="sub-title2">Client Information</h3>
                  <span>Select Company/Person </span>{' '}
                </div>
              )}
            </Box>
            <ArrowForwardIosIcon
              sx={{
                color: '#03071e99',
                cursor: 'pointer',
                '&:hover': {
                  transform: 'scale(1.1)',
                },
              }}
              onClick={() => setCurrentScope('client')}
            />
          </Box>
        )}
        <Box
          sx={{
            backgroundColor: '#fff',
            borderRadius: '8px',
            padding: {
              xl: '14px 23px',
              lg: '10px 18px',
              md: '8px 15px',
              sm: '6px 12px',
            },
            position: 'relative',
            border: '1px solid #d9d9d9',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <h3 className="sub-title2">Target Goals</h3>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: { xl: '1rem', lg: '.6rem', md: '.4rem', sm: '.4rem' },
                paddingBottom: '1rem',
              }}
            >
              <Box
                sx={{
                  backgroundColor: '#8ADEC1',
                  fontFamily: 'Open Sans',
                  fontWeight: '500',
                  padding: '9px 10px',
                  borderRadius: '8px',
                  fontSize: { xl: '15px', lg: '13px', md: '12px', sm: '10px' },
                }}
              >
                {formData.payment_type || formData.budget ? (
                  <p className="company-name">Fixed</p>
                ) : (
                  'Payment Type'
                )}
              </Box>
              <Box
                sx={{
                  backgroundColor: '#D2B8FF',
                  fontFamily: 'Open Sans',
                  fontWeight: '500',
                  padding: '9px 10px',
                  borderRadius: '8px',
                  fontSize: { xl: '15px', lg: '13px', md: '12px', sm: '10px' },
                }}
              >
                {formData.budget ? (
                  <p className="company-name">
                    ${' '}
                    {parseFloat(formData?.budget).toLocaleString('en-US', {
                      maximumFractionDigits: 0,
                    })}
                  </p>
                ) : (
                  'Target Budget'
                )}
              </Box>
              <Box
                sx={{
                  backgroundColor: '#D4E8FB',
                  fontFamily: 'Open Sans',
                  fontWeight: '500',
                  padding: '9px 10px',
                  borderRadius: '8px',
                }}
              >
                {formData?.est_startdate || formData?.est_enddate ? (
                  <Typography
                    sx={{
                      fontSize: {
                        xl: '15px',
                        lg: '11px',
                        md: '11px',
                        sm: '10px',
                      },
                      padding: {
                        xl: '0px 0px',
                        lg: '3px 0px',
                        md: '3px 0px',
                        sm: '2px 0px',
                      },
                    }}
                    className="company-name"
                  >
                    EST START {moment(formData.est_startdate).format('MMMM DD')}
                    |
                    {formData.est_enddate
                      ? `EST END ${moment(formData.est_enddate).format('MMMM DD')}`
                      : 'FINISH DATE'}
                  </Typography>
                ) : (
                  'EST START & FINISH DATE'
                )}
              </Box>
            </Box>
          </Box>
          <ArrowForwardIosIcon
            sx={{
              color: '#03071e99',
              cursor: 'pointer',
              '&:hover': {
                transform: 'scale(1.1)',
              },
            }}
            onClick={() => setCurrentScope('goals')}
          />
        </Box>
        <Box
          sx={{
            backgroundColor: '#D2B8FF',
            borderRadius: '8px',
            padding: {
              xl: '14px 23px',
              lg: '10px 18px',
              md: '8px 15px',
              sm: '6px 12px',
            },
            position: 'relative',
            border: '1px solid #d9d9d9',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <h3 className="sub-title2">Description</h3>
            {formData.description ? (
              // eslint-disable-next-line
              <p dangerouslySetInnerHTML={{ __html: formData.description }} />
            ) : (
              <p className="fstxt">Add one or more descriptions</p>
            )}
          </div>
          <ArrowForwardIosIcon
            sx={{
              color: '#03071e99',
              cursor: 'pointer',
              '&:hover': {
                transform: 'scale(1.1)',
              },
            }}
            onClick={() => setCurrentScope('description')}
          />
        </Box>
        <Box
          sx={{
            backgroundColor: '#E9ECEF',
            borderRadius: '8px',
            padding: {
              xl: '14px 23px',
              lg: '10px 18px',
              md: '8px 15px',
              sm: '6px 12px',
            },
            position: 'relative',
            border: '1px solid #d9d9d9',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <h3 className="sub-title2">Team Members</h3>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: '10px',
              }}
            >
              {Array.isArray(formData?.subteam_estimate[0]?.members) &&
              formData?.subteam_estimate[0]?.members.length ? (
                formData?.subteam_estimate[0]?.members.map((item) => {
                  return (
                    <div
                      key={item?.id}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '15px',
                        backgroundColor: '#F2EBFF',
                        borderRadius: '10px',
                        padding: '1rem 1rem',
                      }}
                    >
                      <Avatar
                        src={item.avatar ? item.avatar : 'sharp/img.png'}
                        alt={item.first_name}
                      />
                      <Box>
                        <p style={{ fontWeight: 'bold' }}>
                          {item.first_name} {item.last_name}
                        </p>
                      </Box>
                    </div>
                  );
                })
              ) : (
                <p className="fstxt">Choose Your team Memebers.</p>
              )}
            </div>
          </div>
          <ArrowForwardIosIcon
            sx={{
              color: '#03071e99',
              cursor: 'pointer',
              '&:hover': {
                transform: 'scale(1.1)',
              },
            }}
            onClick={() => setCurrentScope('team')}
          />
        </Box>
        <Box
          sx={{
            backgroundColor: '#5FCEA899',
            borderRadius: '8px',
            padding: {
              xl: '14px 23px',
              lg: '10px 18px',
              md: '8px 15px',
              sm: '6px 12px',
            },
            position: 'relative',
            border: '1px solid #d9d9d9',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <h3 className="sub-title2">Work Types</h3>
            {Array.isArray(formData?.deliverable[0]?.deliverable_worktype) &&
            formData?.deliverable[0]?.deliverable_worktype ? (
              formData.deliverable[0].deliverable_worktype.map((item) => {
                return (
                  <button
                    type="button"
                    key={item?.id ?? item?.name}
                    className="create-btn"
                    style={{ marginRight: '5px', marginTop: '5px' }}
                  >
                    {item.name}
                  </button>
                );
              })
            ) : formData.work_type ? (
              formData.work_type.map((item) => {
                return (
                  <button
                    key={item?.id ?? item?.name}
                    type="button"
                    className="create-btn"
                    style={{ marginRight: '5px', marginTop: '5px' }}
                  >
                    {item.name}
                  </button>
                );
              })
            ) : (
              <p className="fstxt">Choose or Add your Work types</p>
            )}
          </div>
          <ArrowForwardIosIcon
            sx={{
              color: '#03071e99',
              cursor: 'pointer',
              '&:hover': {
                transform: 'scale(1.1)',
              },
            }}
            onClick={() => setCurrentScope('types')}
          />
        </Box>
      </Grid>
    );
  }
  return (
    <Grid container>
      <Grid
        container
        alignItems="flex-end"
        sx={{
          padding: '1rem 0.8rem',
          backgroundColor: '#FBF8FF',
          border: '1px solid #d9d9d9',

          borderRadius: '8px',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '0.5rem',
            width: '49%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: { xl: '92%', lg: '95%', md: '95.5%', sm: '97%' },
              alignItems: 'center',
            }}
          >
            <h3 className="sub-title2">Estimate Details</h3>
            <InfoOutlinedIcon sx={{ color: '#4dfad2', fontSize: '15px' }} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              width: { xl: '92%', lg: '95%', md: '95.5%', sm: '97%' },
            }}
          >
            <span className="fstxt" style={{ color: 'GrayText' }}>
              Enter your Estimate Type
            </span>
            <NetnetField2
              id="Cypress-AddEstimate"
              sx={{ width: { xl: '92%', lg: '95%', md: '95.5%', sm: '97%' } }}
              placeholder="Enter Estimate"
              value={formData.title}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  title: e.target.value,
                })
              }
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: {
              xl: '1rem 1rem .1rem 4rem',
              lg: '1rem .8rem .1rem 1.8rem',
              md: '.8rem .7rem .1rem 1.4rem',
              sm: '.3rem .3rem .1rem .7rem',
            },
            borderLeft: '1px solid #000',
            gap: '1rem',
            width: '40%',
          }}
        >
          <span>Select Your Estimate Type</span>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: { xl: '1rem', lg: '1rem', md: '1rem', sm: '.5rem' },
            }}
          >
            <button
              type="button"
              className={selected === 1 ? 'estaBtn slctd' : 'estaBtn'}
              onClick={() => setSelected(1)}
            >
              Project
            </button>
            <button
              type="button"
              className={selected === 2 ? 'estaBtn slctd' : 'estaBtn'}
              onClick={() => setSelected(2)}
            >
              Retainer
            </button>
          </Box>
        </Box>
      </Grid>
      <Grid item container direction="row" justifyContent="space-between">
        <FormStack
          description={description}
          selectedTeam={selectedTeam}
          dileverables={dileverable}
          setDeliverable={setDeliverable}
        />
        <Grid direction="column" alignItems="flex-start" xs={6} md={6} lg={6}>
          <Grid
            item
            container
            direction="column"
            alignItems="flex-start"
            sx={{
              gap: '1rem',
            }}
          >
            {!isColab && currentScope === 'client' && (
              <Box sx={{ width: '100%' }}>
                <Bounce left>
                  {' '}
                  <ClientSelector />
                </Bounce>{' '}
              </Box>
            )}
            {currentScope === 'goals' && (
              <Box sx={{ width: '100%' }}>
                <Bounce left>
                  <TargetGoals
                    setFormData={setFormData}
                    setCurrentScope={setCurrentScope}
                    formData={formData}
                  />
                </Bounce>
              </Box>
            )}
            {currentScope === 'description' && (
              <Box sx={{ width: '100%' }}>
                <Bounce left>
                  {' '}
                  <Description setDiscription={setDiscription} />
                </Bounce>
              </Box>
            )}
            {currentScope === 'team' && (
              <Box sx={{ width: '100%' }}>
                <Bounce left>
                  {' '}
                  <TeamMembers
                    setSelectedTeam={setSelectedTeam}
                    formData={formData}
                  />
                </Bounce>
              </Box>
            )}
            {currentScope === 'types' && (
              <Box sx={{ width: '100%' }}>
                <Bounce left>
                  <WorkTypes
                    formData={formData}
                    setFormData={setFormData}
                    setCurrentScope={setCurrentScope}
                    dileverable={dileverable}
                    setDeliverable={setDeliverable}
                    allWorktypes={allWorktypes}
                    calculateLOERateName={calculateLOERateName}
                  />
                </Bounce>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default FirstStep;
