import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import localStorage from 'redux-persist/es/storage';

import StarRateIcon from '@mui/icons-material/StarRate';
import { Avatar } from '@mui/material';

import './SideNav.css';
import { logout } from '../Auth/Redux/auth';
import { ENDPOINT } from '../../pages/Opportunity/Redux/oppor';

function SubRoute({
  task_title,
  task_path,
  dropdown,
  icon,
  activeIcon,
  profilePic,
  isActiveDropdown,
  handleDropdownToggle,
  setChatcurrentTarget,
  setPage,
  setEstimateMessage,
  setCurrentDil,
  setCurrentProject,
  setLocalProjectState,
  setProLoading,
  setCurrentTargetOfEstiimate,
  setEstimateProLoading,
  style,
  updateInnerNetNetTabs,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [hover, setHover] = useState(false);
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const Projects = useSelector((state) => state.projects.projects);
  const access_token = useSelector((state) => state.auth.user.access);
  const currentUser = useSelector((state) => state.userData.userData);

  const handleLinkClick = (e, path) => {
    e.preventDefault();
    navigate(path);
  };

  return (
    <li
      className="parentLi"
      style={{ ...style, position: task_title === 'Name' ? 'relative' : '' }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="img-box">
        {!profilePic && (
          <img
            className=""
            style={{
              color:
                location.pathname === task_path || hover
                  ? '#711fff'
                  : 'inherit',
            }}
            src={isActiveDropdown || hover ? activeIcon : icon}
            alt=""
          />
        )}
        {profilePic && (
          <Avatar
            src={currentUser.avatar ? currentUser.avatar : '/Static/img.jpg'}
            alt={currentUser?.first_name}
            sx={{ border: '2px solid #D2B8FF', height: '28px', width: '28px' }}
          />
        )}
      </div>
      <Link
        className={hover ? 'active-li' : ''}
        to={task_path}
        style={{
          position: task_title === 'Name' ? 'absolute' : '',
          top: task_title === 'Name' ? '-7px' : '',
          color: isActiveDropdown ? '#711fff' : 'inherit',
        }}
        onClick={(e) => {
          if (task_title !== 'Contacts' && task_title !== 'Name') {
            updateInnerNetNetTabs(task_title, task_path);
          }
          handleLinkClick(e, task_path);
          handleDropdownToggle();
        }}
      >
        {task_title === 'Name' ? currentUser?.first_name : task_title}
      </Link>

      <ul
        style={{
          maxHeight: isActiveDropdown ? '500px' : '0px',
          listStyle: 'none',
        }}
        className="sub-route"
      >
        {dropdown &&
          dropdown.map((item, index) => (
            <li key={item?.title}>
              {item.action === 'logout' ? (
                <div style={{ display: 'flex' }}>
                  <div className="child-box" />
                  <p
                    className="lg-out"
                    onMouseEnter={(e) => {
                      e.target.style.color = '#711fff';
                    }}
                    onMouseLeave={(e) => {
                      if (location.pathname !== item.path) {
                        e.target.style.color = 'inherit';
                      }
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(logout());
                      navigate('./Login');
                    }}
                  >
                    {item.title}
                  </p>
                </div>
              ) : (
                <>
                  <li
                    style={{
                      textAlign: 'center',
                      marginLeft: screenSize < 1401 ? '40px' : '10px',
                    }}
                  >
                    {task_title === 'Chat' &&
                      index === 0 &&
                      `Starred Projects (${dropdown.length})`}
                  </li>
                  <div className="child-box" />
                  <Link
                    to={item.path}
                    onClick={async () => {
                      if (task_title === 'Chat') {
                        updateInnerNetNetTabs('Chat', '/chat');
                        if (item.whatFav === 'Pro') {
                          setPage('chat');
                          setProLoading(true);
                          const res = await fetch(
                            `${ENDPOINT}/api/chat/channel/detail/${item?.projectChannelID}/`,
                            {
                              method: 'GET',
                              headers: {
                                'Content-Type': 'application/json',
                                Authorization: `JWT ${access_token}`,
                              },
                            },
                          );
                          const data = await res.json();
                          if (res.ok && data) {
                            const currentp = Projects.find(
                              (it) => it.title === item?.title,
                            );
                            const res2 = await fetch(
                              `${ENDPOINT}/api/project/detail/${currentp?.unique_id}/`,
                              {
                                method: 'GET',
                                headers: {
                                  'Content-Type': 'application/json',
                                  Authorization: `JWT ${access_token}`,
                                },
                              },
                            );
                            const data2 = await res2.json();
                            if (res2.ok) {
                              const cpDil = data2?.deliverable_project?.map(
                                (item) => item.deliverable_name,
                              );
                              setCurrentDil(cpDil);
                              setCurrentProject(data2);
                              setLocalProjectState(data?.message_in_channel);
                              setChatcurrentTarget(item.currentTargetChat);
                              setProLoading(false);
                            }
                          }
                        } else if (item.whatFav === 'Est') {
                          setPage('estimate');
                          setEstimateProLoading(true);
                          const res = await fetch(
                            `${ENDPOINT}/api/chat/channel/detail/${item?.currentTargetChat?.id}/`,
                            {
                              method: 'GET',
                              headers: {
                                'Content-Type': 'application/json',
                                Authorization: `JWT${access_token}`,
                              },
                            },
                          );
                          const data = await res.json();
                          if (res.ok && data) {
                            const res2 = await fetch(
                              `${ENDPOINT}/api/opportunity/estimate/${item?.currentTargetChat?.estimate?.unique_id}/`,
                              {
                                method: 'GET',
                                headers: {
                                  'Content-Type': 'application/json',
                                  Authorization: `JWT${access_token}`,
                                },
                              },
                            );
                            const data2 = await res2.json();
                            if (res2.ok && data2) {
                              setEstimateMessage(data?.message_in_channel);
                              setCurrentTargetOfEstiimate(data2);
                              setChatcurrentTarget({
                                ...data,
                                title: data?.name ?? data?.title,
                                estimate: true,
                              });
                              setEstimateProLoading(false);
                            }
                          }
                        } else {
                          setPage('people');
                          setChatcurrentTarget(item.currentTargetChat);
                        }
                      } else {
                        updateInnerNetNetTabs(item.title, item.path);
                      }
                    }}
                    style={{
                      color:
                        location.pathname === item.path ? '#711fff' : 'inherit',
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.color = '#711fff';
                    }}
                    onMouseLeave={(e) => {
                      if (location.pathname !== item.path) {
                        e.target.style.color = 'inherit';
                      }
                    }}
                  >
                    {task_title === 'Chat'
                      ? item.title.length > 8
                        ? `${item.title.slice(0, 8)}...`
                        : item.title
                      : item.title}
                  </Link>
                </>
              )}
            </li>
          ))}
      </ul>
    </li>
  );
}

function SubRoute2({
  task_title,
  task_path,
  dropdown,
  icon,
  activeIcon,
  setChatcurrentTarget,
  setPage,
  setShow,
  setCurrentDil,
  setCurrentProject,
  setLocalProjectState,
  setProLoading,
  setCurrentTargetOfEstiimate,
  setEstimateProLoading,
  setEstimateMessage,
  updateInnerNetNetTabs,
}) {
  const [hover, setHover] = useState(false);
  const [isHeight, setHeight] = useState('0px');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const currentUser = useSelector((state) => state.userData.userData);
  const Projects = useSelector((state) => state.projects.projects);
  const access_token = useSelector((state) => state.auth.user.access);
  return (
    <li
      className="parentLi"
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      onClick={() => {
        if (!dropdown) {
          setTimeout(() => {
            setShow(false);
          }, 500);
        }
      }}
    >
      <div className="img-box">
        {dropdown[0]?.title === 'Templates' ? (
          <Avatar
            src={currentUser.avatar ? currentUser.avatar : '/Static/img.jpg'}
            alt={currentUser?.first_name}
            sx={{
              border: '2px solid #D2B8FF',
              height: '28px',
              width: '28px',
            }}
          />
        ) : (
          <img
            className="nav-icon"
            src={isHeight === '0px' ? (hover ? activeIcon : icon) : activeIcon}
            alt=""
          />
        )}
      </div>

      <Link
        className={isHeight !== '0px' ? 'active-li' : ''}
        to={task_path}
        onClick={() => {
          if (isHeight === '0px') {
            setHeight('500px');
          } else {
            setHeight('0px');
          }
        }}
      >
        {task_title}
      </Link>

      <ul
        style={{ maxHeight: isHeight, listStyle: 'none' }}
        className="sub-route"
      >
        {dropdown &&
          dropdown.map((item, index) => {
            return item.action === 'logout' ? (
              <li style={{ display: 'flex' }}>
                <div className="child-box" />
                <p
                  className="lg-out"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(logout());
                    localStorage.removeItem('persist:root');
                    navigate('./Login');
                    setShow(false);
                  }}
                >
                  {item.title}
                </p>
              </li>
            ) : (
              <li>
                <li
                  style={{
                    textAlign: 'center',
                    marginLeft:
                      screenSize < 1201 && screenSize >= 1050
                        ? '40px'
                        : screenSize < 1050
                          ? '30px'
                          : '10px',
                  }}
                >
                  {' '}
                  {task_title === 'Chat' &&
                    index === 0 &&
                    `Starred Projects (${dropdown.length})`}
                </li>
                <div className="child-box" />
                <Link
                  to={item.path}
                  onClick={async () => {
                    setTimeout(() => {
                      setShow(false);
                    }, 500);
                    updateInnerNetNetTabs(item.title, item.path);
                    if (dropdown.action === 'logout') {
                      return;
                    }
                    if (task_title === 'Chat') {
                      if (item.whatFav === 'Pro') {
                        setPage('chat');
                        setProLoading(true);
                        const res = await fetch(
                          `${ENDPOINT}/api/chat/channel/detail/${item?.projectChannelID}/`,
                          {
                            method: 'GET',
                            headers: {
                              'Content-Type': 'application/json',
                              Authorization: `JWT${access_token}`,
                            },
                          },
                        );
                        const data = await res.json();
                        if (!res.ok) {
                          return;
                        }
                        if (data) {
                          const currentp = Projects.find(
                            (it) => it.title === item?.title,
                          );
                          const res = await fetch(
                            `${ENDPOINT}/api/project/detail/${currentp?.unique_id}/`,
                            {
                              method: 'GET',
                              headers: {
                                'Content-Type': 'application/json',
                                Authorization: `JWT ${access_token}`,
                              },
                            },
                          );
                          const data2 = await res.json();
                          if (res.ok) {
                            const cpDil =
                              data2 &&
                              data2.deliverable_project &&
                              data2.deliverable_project.map((item) => {
                                return item.deliverable_name;
                              });
                            setCurrentDil(cpDil);
                            setCurrentProject(data2);
                            setLocalProjectState(data?.message_in_channel);

                            setChatcurrentTarget(item.currentTargetChat);
                            setProLoading(false);
                          }
                        }
                      } else {
                        setPage('people');
                        setChatcurrentTarget(item.currentTargetChat);
                      }
                      if (item.whatFav === 'Est') {
                        setPage('estimate');
                        setEstimateProLoading(true);

                        const res = await fetch(
                          `${ENDPOINT}/api/chat/channel/detail/${item?.currentTargetChat?.id}/`,
                          {
                            method: 'GET',
                            headers: {
                              'Content-Type': 'application/json',
                              Authorization: `JWT${access_token}`,
                            },
                          },
                        );
                        const data = await res.json();
                        if (!res.ok) {
                          return;
                        }
                        if (data) {
                          const res2 = await fetch(
                            `${ENDPOINT}/api/opportunity/estimate/${item?.currentTargetChat?.estimate?.unique_id}/`,
                            {
                              method: 'GET',
                              headers: {
                                'Content-Type': 'application/json',
                                Authorization: `JWT${access_token}`,
                              },
                            },
                          );
                          const data2 = await res2.json();
                          if (res2.ok) {
                            if (data2) {
                              setEstimateMessage(data?.message_in_channel);

                              setCurrentTargetOfEstiimate(data2);
                              setChatcurrentTarget({
                                ...data,
                                title: data?.name ?? data?.title,
                                estimate: true,
                              });
                              setEstimateProLoading(false);
                            }
                          }
                        }
                      }
                    }
                  }}
                >
                  {task_title === 'Chat'
                    ? item.title.length > 8
                      ? `${item.title.slice(0, 8)}... `
                      : item.title
                    : item.title}
                  {task_title === 'Chat' && (
                    <StarRateIcon
                      sx={{
                        color: '#ffd700',
                        marginLeft: { xl: '5px', lg: '2px', md: '2px' },
                      }}
                    />
                  )}
                </Link>
              </li>
            );
          })}
      </ul>
    </li>
  );
}
export { SubRoute2 };

export default SubRoute;
